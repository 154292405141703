import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useCreateReqPatchStore } from '../../../../../../App/stores/CreateRequestStore';
import useRequestStore, { getRequestShiftPlannerList, searchShift } from '../../../../../../App/stores/RequestStore';
import Accordion from '../../../../../../Components/Accordion';
import Input01 from '../../../../../../Components/Input/Input01';
import NA from '../../../../../../Components/NA';
import { formatDate, formatTime, LogDanger } from '../../../../../../Utility/UtilityFunctions';
import ShiftSummeryModal from '../../../../../CreateRequest/RequestForms/CompaniesSection/ShiftSummeryModal';
import SelectedShift from '../../../../../Request/SelectedShift';


export default function AnalyzeShift({ toggled_accordion, setToggledAccordion, reqData }) {
    let start_time = reqData?.pickup_start_time;
    let end_time = reqData?.pickup_end_time;
    let start_date = reqData?.pickup_date;
    const [searchKey, setSearchKey] = useState('')

    const { shifts, shiftsCopy } = useRequestStore();
    // const { setIsShiftModalOpen } = useCreateRequestStore();
    const { setIsShiftModalOpen } = useCreateReqPatchStore();

    useEffect(() => {
        LogDanger("shifts::: ", shifts);
        LogDanger(" shiftsCopy ::: ", shiftsCopy);
    }, [shifts, shiftsCopy]);

    useEffect(() => {
        console.log('time', start_time, start_date);
        getRequestShiftPlannerList(start_date, start_time, parseInt(localStorage?.limadi_company_id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [start_date, start_time])

    useEffect(() => {
        searchShift(searchKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey])

    const [selectedShiftIndex, setSelectedIndex] = useState(null)

    const selectShift = (index) => {
        setSelectedIndex(index);
        setIsShiftModalOpen(true);
    }

    return (
        <>
            <Accordion
                accordion_type='dependent'
                isInitOpen={toggled_accordion === 'analyze_shift' ? true : false}
                customOnClick={() => setToggledAccordion('analyze_shift')}
                header={<div className="text-fs16 font-fw600 text-cListTitle">{t("Available Shifts")}</div>}
                body={
                    <>
                        {
                            shiftsCopy?.length === 0 ? <NA message={t("Shift Not Available")} /> :
                                <div>
                                    <div className="flex justify-between items-center space-x-8 relative mb-3">
                                        <div className='w-1/2 text-base font-fw600 text-cMainBlack mb-1'>
                                            {t("Available Shifts")}
                                        </div>
                                        <div className="relative w-1/2 flex items-center">
                                            <div className=' w-full rounded-lg outline-none'>
                                                <Input01 value={searchKey} onChange={(e) => setSearchKey(e.target.value)} name={'search'} type="search" placeholder={'search'} />
                                            </div>
                                        </div>

                                    </div>

                                    <hr />

                                    <div className="mt-3">
                                        <div className="flex flex-row justify-between">
                                            <div className='text-base font-fw600 text-cMainBlack'>{t("Pickup Date and Time")}</div>
                                            <div className='text-fs14 font-fw400 text-cBodyText'>
                                                <span className='mr-2'>{formatDate(start_date)}</span>
                                                <span>{`${formatTime(start_time)}(End Time ${formatTime(end_time)})`}</span>
                                            </div>
                                        </div>

                                    </div>


                                    {/* shift list */}

                                    <div className='mt-2'>
                                        {shifts?.map((item, index) =>
                                            <SelectedShift
                                                key={index}
                                                onClick={() => selectShift(index)}
                                                shift={item}
                                                driver={item?.driver_user}
                                                license_num={item?.car?.car_license_plate_number}
                                            />
                                        )
                                        }
                                    </div>

                                    {/* modal */}
                                    {(shifts?.length && shifts[selectedShiftIndex]) ? <ShiftSummeryModal shiftID={shifts[selectedShiftIndex].id} request_id={reqData.id} /> : ""}

                                </div>
                        }
                    </>
                }
            />

        </>
    )
}
