import axios from 'axios';
import React, {createContext, useReducer} from 'react'
import { get_init_data, request_delete, request_save, request_show, search_company } from '../Utility/url';
import { Toastr, ToastrLoading } from '../Utility/UtilityFunctions';
import { requestReducer } from "./RequestReducer";

export const RequestContext = createContext();

const RequestContextProvider = (props) => {

    const initialState = {
        // ! Basic Control States
        
        // ! Request related core Data
        id: 70,
        type: 'simple',
        status: 'init',
        // action: 'save',

        // ! Request Form related Data
        title: null,
        transport_type: null,
        pickup_date: null,
        pickup_start_time: null,
        pickup_end_time: '12:30',
        delivery_start_time: null,
        delivery_end_time: '10:30',
        pickup_address: null,
        delivery_address: null,
        pickup_comment: null,
        pickup_attachment: null,
        pickup_attachment_url: null,
        is_global: false,
        invitation_ids: [],
        
        // ! Others
        search_companies: [],
        favorite_companies: [],
        global_companies: [],
        transportation_types: [],
        favorite_addresses: [],

        // ! Loadings
        search_loading: false
    }

    const [state, dispatch] = useReducer(requestReducer, initialState)

    const ChangeType = async (type) => {
        dispatch({ type: "CHANGE_TYPE", payload: type });
        Clear()
    };
    const ChangeValue = async (key, value) => {
        console.log(`${key}: ${value}`);
        dispatch({ type: "CHANGE_VALUE", payload: {key, value} });
    };
    
    const GetInitData = async () => {
        try {
            const res = await axios.get(get_init_data);
            console.log(res.data);
            if (res.data.success) {
                dispatch({ type: "SET_INIT_DATA", payload: res.data.data });
            } else {
                Toastr(res.data.message)
            }
        } catch (err) {
            console.log(err);
            Toastr("An error occurred!")
        }
    };
    
    const LoadRequest = async () => {
        try {
            const res = await axios.get(request_show, {params: {id: state.id, data_set: ["invitations"]}});
            console.log(res.data);
            if (res.data.success) {
                dispatch({ type: "SET_DATA", payload: res.data.data });
            } else {
                Toastr(res.data.message)
            }
        } catch (err) {
            console.log(err);
            Toastr("An error occurred!")
        }
    };
    
    const SaveRequest = async (action = 'save') => {
        const data = {
            'id': state.id,
            'type': state.type,
            'status': state.status,
            'action': action,
            'title': state.title,
            'transport_type': state.transport_type,
            'pickup_date': state.pickup_date,
            'pickup_start_time': state.pickup_start_time,
            'pickup_end_time': state.pickup_end_time,
            'delivery_start_time': state.delivery_start_time,
            'delivery_end_time': state.delivery_end_time,
            'pickup_address': state.pickup_address,
            'delivery_address': state.delivery_address,
            'pickup_comment': state.pickup_comment,
            'pickup_attachment': state.pickup_attachment,
            'is_global': state.is_global,
            'invitation_ids': state.invitation_ids
        }
        
        const loading_toastr = ToastrLoading()
        console.log(loading_toastr);
        try {
            
            const res = await axios.post(request_save, data);

            console.log(res.data);
            if (res.data.success) {
                ToastrLoading(res.data.message, 'stop', 'success', loading_toastr)
                dispatch({ type: "SET_DATA", payload: res.data.data });
            } else {
                ToastrLoading(res.data.message, 'stop', 'error', loading_toastr)
            }
        } catch (err) {
            console.log(err);
            ToastrLoading("Something went wrong!", 'stop', 'error', loading_toastr)
        }
    };

    const Clear = () => {
        dispatch({ type: "CLEAR" });
    }
    
    const Delete = async () => {
        try {
            const res = await axios.post(request_delete, {id: state.id});
            console.log(res.data);
            if (res.data.success) {
                Toastr(res.data.message, "success")
                Clear()
            } else {
                Toastr(res.data.message)
            }
        } catch (err) {
            console.log(err);
            Toastr("An error occurred!")
        }
    }
    
    const searchCompany = async (text, rate) => {
        if(text === '' && rate === []) {
            dispatch({ type: "SEARCH_COMPANY", payload: [] });
            return
        }
        try {
            dispatch({ type: "CHANGE_VALUE", payload: {key: "search_loading", value: true} });
            const res = await axios.get(search_company, {params: {search: text, rate: rate}});
            dispatch({ type: "CHANGE_VALUE", payload: {key: "search_loading", value: false} });
            console.log(res.data);
            if (res.data.success) {
                dispatch({ type: "SEARCH_COMPANY", payload: res.data.data });
            } else {
                Toastr(res.data.message)
            }
        } catch (err) {
            console.log(err);
            Toastr("An error occurred!")
        }
    }

    return (
      <RequestContext.Provider
        value={{
            // ! Basic Control States

            // ! Request related core Data
            id: state.id,
            type: state.type,
            status: state.status,
            
            // ! Request Form related Data
            title: state.title,
            transport_type: state.transport_type,
            pickup_date: state.pickup_date,
            pickup_start_time: state.pickup_start_time,
            pickup_end_time: state.pickup_end_time,
            delivery_start_time: state.delivery_start_time,
            delivery_end_time: state.delivery_end_time,
            pickup_address: state.pickup_address,
            delivery_address: state.delivery_address,
            pickup_comment: state.pickup_comment,
            pickup_attachment: state.pickup_attachment,
            pickup_attachment_url: state.pickup_attachment_url,
            is_global: state.is_global,
            invitation_ids: state.invitation_ids,

            // ! Others
            search_companies: state.search_companies,
            favorite_companies: state.favorite_companies,
            global_companies: state.global_companies,
            transportation_types: state.transportation_types,
            favorite_addresses: state.favorite_addresses,

            // ! Loading
            search_loading: state.search_loading,

            // ! Functions
            ChangeType,
            ChangeValue,
            GetInitData,
            LoadRequest,
            SaveRequest,
            Clear,
            Delete,
            searchCompany,
        }}
      >
        {props.children}
      </RequestContext.Provider>
    );
}

export default RequestContextProvider;
