import { t } from 'i18next';
import React from 'react'
import { iWarning } from '../../../../../../Utility/Sources';

export default function ShiftExpiredInfo() {
  return (
    <>
      <div className="flex justify-center items-center pt-5">
        <img src={iWarning} alt="" className="w-[22px]" />
        <span className="pl-2 font-bold italic text-sm text-cRed">
          {t("Shift Time is Expired")}
        </span>
      </div>
    </>
  )
}
