import { t } from 'i18next';
import React, { useContext, useEffect, useState } from "react";
import {
  IoIosArrowForward,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
} from "react-icons/io";
import CommonButton from '../../../../../Components/Button/CommonButton';
import Input02 from '../../../../../Components/Input/Input02';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { CloudRequestContext } from '../../../../../Context/CloudRequestContext';
// import useUpdateLogger from '../../App/hooks/useUpdateLogger';

const MultiTransportSelector = ({
  data_id,
  cleared = !data_id ? true : false,
  setCleared,
  finalValue,
}) => {
  const {
    isAddFilter,
    setMultiTransportType,
    filterTransportType,
    setAddFilterTransportId,
    setFilterTransportTypeUpdate,
    showFilterModal,
    dataChk, setDataChk
  } = useContext(CloudRequestContext);
  const [show_transportation_modal, setTransportationModal] = useState(false);
  const [selected, setSelected] = useState(data_id);

  const submit = () => {
    setCleared(false);
    const selected_transportation_type = filterTransportType.find(
      (item) => item.id === selected
    );
    setMultiTransportType(selected_transportation_type.title);
    setAddFilterTransportId(selected_transportation_type.id);
    setFilterTransportTypeUpdate(selected_transportation_type.id);
    finalValue(selected_transportation_type.title);
    setDataChk(selected_transportation_type.title ?? "");
    setTransportationModal(false);
  };

  // useUpdateLogger("dataChk", dataChk)
  // useUpdateLogger("cleared", cleared)

  useEffect(() => {
    setDataChk(cleared)
    if (cleared === true) {
      setSelected(null);
      console.log("NULLED", selected);
    }
  }, []);

  useEffect(() => {
    setFilterTransportTypeUpdate(data_id);
    if (!isAddFilter && data_id) {
      setSelected(data_id + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_id]);

  //console.log("dataChk",dataChk)

  useEffect(() => {
    filterTransportType.map((item, index) => {
      if (index + 1 === data_id) {
        setDataChk(item?.title ?? "");
      }
    })
  }, [data_id, filterTransportType])


  return (
    <div>
      <Input02
        onClick={() => {
          setTransportationModal(true);
        }}
        name="transport_type"
        value={
          dataChk
            ? dataChk
            : ""
        }
        className="cursor-pointer"
        label={t("Transportation Type")}
        type="text"
        placeholder={t("Select Transportation Type")}
        icon={<IoIosArrowForward className="h-[70%] cursor-pointer" />}
        is_readonly={true}
      />
      <CommonModal
        showModal={show_transportation_modal}
        setShowModal={setTransportationModal}
        modalTitle={t("Select Transportation Type")}
        mainContent={
          <div>
            <div>
              <div className="max-h-[40vh] overflow-y-auto pt-5">
                {filterTransportType.map((item) => (
                  <div
                    key={item?.id}
                    onClick={() => { setSelected(item?.id) }}
                    className={`${selected === item?.id && "border-emerald-600"
                      } border border-transparent flex justify-between items-center py-3 px-4 mb-3 cursor-pointer rounded-lg bg-cListItem text-gray-600 font-bold`}
                  >
                    {item?.title}
                    {/* -->uid: {item?.id} */}
                    {selected === item?.id ? (
                      <IoMdRadioButtonOn className="text-xl text-emerald-600" />
                    ) : (
                      <IoMdRadioButtonOff className="text-xl" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-5 w-full flex justify-center">
              <CommonButton
                btnLabel={t("Submit")}
                onClick={() => submit()}
                isDisabled={selected === null}
                colorType={selected === null ? "base" : "primary"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MultiTransportSelector;
