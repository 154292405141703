import { t } from 'i18next';
import axios from 'axios';
import create from 'zustand'
import { kuGetRequestsNew,  kuGlobalRequestTableIndex } from '../../Utility/url';
import { Toastr, ToastrLoading } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';
import { k_data_set, role } from '../Utility/const';


const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useGlobalRequestStore = create((set, get) => ({

    globalRequestIndex: "",
    setGlobalRequestIndex: (data) => set({ globalRequestIndex: data }),

    globalRequestList: [],
    setGlobalRequestList: (data) => set({ globalRequestList: data }),

    globalRequestSearch: "",
    setGlobalRequestSearch: (data) => set({ globalRequestSearch: data }),

}))
export default useGlobalRequestStore;

//! api related functions


export const getViewGlobalRequestsData = async (user_id,search) => {
    const { setGlobalRequestList } = useGlobalRequestStore.getState();
    if(search) setIsLoadingSearch(true); setLoading(true);

    let body = {
        user_id:user_id,
        take: 1000,
        search: search,
        // filter: filter,
        // order_by: requests_order,
        // is_asc: is_asc,
    };

    console.log('body', body);

    try {
        const res = await axios.post(kuGlobalRequestTableIndex, body);
        console.log('getViewGlobalRequestsData: ', res.data);

        if (res.data.success) {
            setGlobalRequestList(res?.data?.data?.data);
        }
        else {
            Toastr({ message: res?.data?.message });
        }
        if(search) setIsLoadingSearch(false); setLoading(false);
        return true;
    } catch (err) {
        Toastr(({ message: t("An error occurred!") }))
        if(search) setIsLoadingSearch(false); setLoading(false);
        console.log('getViewGlobalRequestsData: ', err)
        return false;
    }
}

