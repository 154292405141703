import axios from 'axios';
import { t } from 'i18next';

import create from 'zustand'
import { kuAddLicense, kuDeleteLicense, kuGetLicense, kuGetLicenseAll, kuGetLicenseDetails, kuLicenseAction, kuRenewCarLicense, kuSearchCarLicenseApplication, kuUpdateCar, kuUpdateLicense } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useLicenseStore = create((set) => ({

    showAdminApplyLicense: false,
    setShowAdminApplyLicense: (showAdminApplyLicense) => set({ showAdminApplyLicense }),

    showDeactivateLicense: false,
    setShowDeactivateLicense: (showDeactivateLicense) => set({ showDeactivateLicense }),

    showDeleteLicense: false,
    setShowDeleteLicense: (showDeleteLicense) => set({ showDeleteLicense }),

    showUpdateLicense: false,
    setShowUpdateLicense: (showUpdateLicense) => set({ showUpdateLicense }),

    showAcceptModal: false,
    setShowAcceptModal: (showAcceptModal) => set({ showAcceptModal }),

    showRejectModal: false,
    setShowRejectModal: (showRejectModal) => set({ showRejectModal }),

    showUpdateApplication: false,
    setShowUpdateApplication: (showUpdateApplication) => set({ showUpdateApplication }),

    licensePackID: 0,
    setLicensePackID: (licensePackID) => set({ licensePackID }),

    licenseTakeAmount: 10,
    setLicenseTakeAmount: (licenseTakeAmount) => set({ licenseTakeAmount }),

    licenseFilterType: "",
    setLicenseFilterType: (licenseFilterType) => set({ licenseFilterType }),

    licenseApplicationID: 0,
    setLicenseApplicationID: (licenseApplicationID) => set({ licenseApplicationID }),

    licenseDetails: {},
    setLicenseDetails: (licenseDetails) => set({ licenseDetails }),

    selectedBanner: {},
    setSelectedBanner: (selectedBanner) => set({ selectedBanner }),

    contactMessageReply: "",
    setContactMessageReply: (value) => set({ contactMessageReply: value }),

    allLicenseList: [],
    setAllLicenseList: (allLicenseList) => set({ allLicenseList }),

    allApplicationList: [],
    setAllApplicationList: (allApplicationList) => {
        set({ allApplicationList: allApplicationList });
        // console.log('allApplicationList', allApplicationList);
    },

    is_license_application_searching: false,
    setIsLicenseApplicationSearching: (data) => set({ is_license_application_searching: data }),

    applicationFilter:"",
    setApplicationFilter: (value) => set({ applicationFilter: value }),

    applicationSearchValue:"",
    setApplicationSearchValue: (value) => set({ applicationSearchValue: value }),

    applicationPageUrl:"",
    setApplicationPageUrl: (value) => set({ applicationPageUrl: value }),
}))

export default useLicenseStore;


//Get all Application
export const getAllApplication = async (url = null) => {
    const { setLoading } = useGeneralStore.getState();
    const { setAllApplicationList, licenseTakeAmount } = useLicenseStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(url ?? kuGetLicense, { params: { flag: "admin", take: licenseTakeAmount } });
        console.log("getAllApplication",res.data);

        console.log('getAllApplication: ', res.data);
        if (res.data.success) {
            setAllApplicationList(res.data.data);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}


//Get License Application Details 
export const getApplicationDetails = async (id) => {
    const { setLoading } = useGeneralStore.getState();
    const { setLicenseDetails, licenseTakeAmount } = useLicenseStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuGetLicenseDetails, { params: { car_id: id, take: licenseTakeAmount } });
        console.log("getApplicationDetails");

        console.log('getApplicationDetails: ', res.data);
        if (res.data.success) {
            setLicenseDetails(res.data.data);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}



// search license application
export const searchLicenseApplication = async (key = '', url = null, filtering = false) => {

    const { setAllApplicationList, setIsLicenseApplicationSearching, licenseTakeAmount } = useLicenseStore.getState();

    // console.log("111URL::", url + key);
    try {
        setIsLicenseApplicationSearching(true);
        console.log("URL::", url + key);

        let res = {};
        if (url) {
            console.log("333URL::", url + key);
            res = await axios.get(url + `&${key}`, { params: { take: licenseTakeAmount } });
        } else {
            res = await axios.get(kuSearchCarLicenseApplication + `?${key}`, { params: { take: licenseTakeAmount } });
        }

        console.log('searchLicenseApplication: ', res.data);

        if (res.data.success) {
            setAllApplicationList(res.data.data);
        }
        else Toastr(res.data.message);

        setIsLicenseApplicationSearching(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setIsLicenseApplicationSearching(false);
        console.log('searchLicenseApplication: ', e);
    }
}


// Get License
export const getAllLicense = async () => {
    const { setLoading } = useGeneralStore.getState();
    const { setAllLicenseList } = useLicenseStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuGetLicenseAll, { params: { flag: "admin" } });
        console.log("getAllLicense");

        console.log('getAllLicense: ', res.data);
        if (res.data.success) {
            setAllLicenseList(res.data.data);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}


// Add License
export const createNewLicense = async (form) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        let body = {
            number: form?.number,
            price: form?.price,
            description: form?.description,
            duration: (form?.month * 30) + (form?.year * 365),
        }
        console.log(body);
        setLoading(true);
        const res = await axios.post(kuAddLicense, body);

        console.log('createNewLicense: ', res.data);
        if (res.data.success) {
            await getAllLicense();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
        return false;
    }
}



// update License
export const updateLicense = async (form) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        // let body = {
        //     id: form?.id,
        //     number: form?.number,
        //     price: form?.price,
        //     description: form?.description,
        //     duration: (form?.month * 30) + (form?.year * 365),
        // }
        console.log(form);
        setLoading(true);
        const res = await axios.post(kuUpdateLicense, form);

        console.log('updateLicense: ', res.data);
        if (res.data.success) {
            await getAllLicense();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
        return false;
    }
}


// delete License
export const deleteLicense = async () => {
    const { setLoading } = useGeneralStore.getState();
    const { licensePackID } = useLicenseStore.getState();

    try {
        setLoading(true);
        const res = await axios.post(kuDeleteLicense, {
            // params: {
            id: licensePackID,
            // }
        });

        console.log('deleteLicense: ', res.data);
        if (res.data.success) {
            await getAllLicense();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
        return false;
    }
}

// update license status
export const updateLicenseStatus = async (form) => {
    const { setLoading } = useGeneralStore.getState();
    try {
        // let body = {
        //     id: form?.id,
        //     status: form?.license_status,
        //     license_start_date: form?.license_start,
        //     license_end_date: form?.license_end,
        // };

        console.log("OK => BODY:::", form);
        // return;
        setLoading(true);
        const res = await axios.post(kuLicenseAction, form);
        console.log(res.data);

        if (res.data.success) {
            // await getAllApplication();
            Toastr(res.data.message,'success');
            await getApplicationDetails(form.id);
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message, 'stop', 'error');
            setLoading(false);
            return false;
        }
    } catch (err) {
        console.log(err);
        Toastr('An error occurred!', 'stop', 'success');
        setLoading(false);
    }
}

// toggle application activate / deactivate
export const toggleApplicationStatus = async (body) => {
    const { setLoading } = useGeneralStore.getState();

    // const { setAllApplicationList, licenseTakeAmount } = useLicenseStore.getState();

    try {
        setLoading(true);
        // return 
        console.log(body);
        const res = await axios.post(kuLicenseAction, body);

        console.log('getAllApplication: ', res.data);
        if (res.data.success) {
            await getApplicationDetails(body.id);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}


//r: Manually apply license or update
export const adminApplyForLicense = async (form, renewLicense = false) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        let body;
        if (renewLicense) {
            body = {
                user_id: form.user_id,
                id: form.id,
                license_id: form.license_id,
                renew_license_start: form.renew_license_start,
                purchase_license_comment: form.purchase_license_comment,
            };
        }
        else {
            body = {
                user_id: form.user_id,
                id: form.id,
                license_id: form.license_id,
                license_start: form.renew_license_start,
                comment: form.purchase_license_comment,
            };
        }

        // return 
        console.log("adminApplyForLicense Body before axios:::", body);

        setLoading(true)
        let res = {};
        if (renewLicense) res = await axios.post(kuRenewCarLicense, body);
        else res = await axios.post(kuUpdateCar, body);

        console.log('adminApplyForLicense: ', res.data);

        if (res.data.success) {
            await getApplicationDetails(form.id);
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
};