import React from 'react'
import useCustomerStore, { userToggleStatus } from '../../../../App/stores/CustomerStore'
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function DeactivateCustomer() {
    const { showDeactivateCustomerModal, setShowDeactivateCustomerModal } = useCustomerStore();

    const {customerDetails} = useCustomerStore();
    
    let status = customerDetails?.is_active ? 0 : 1;
    const body = {
      user_id: customerDetails?.id,
      is_active: status
    }

    return (
        <>
            <CommonModal
                showModal={showDeactivateCustomerModal}
                setShowModal={setShowDeactivateCustomerModal}

                modalTitle="deactivate confirmation"
                mainContent={
                    <div className='py-5 text-base text-center'>
                        Are you sure you want to deactivate this customer account ?
                    </div>
                }

                primaryActionButton={
            
                    <CommonButton colorType='danger' btnLabel='Yes' onClick={()=>userToggleStatus(customerDetails?.id,body,"customer")} />
                }

                secondaryActionButton={
                    <CommonButton btnLabel='no' />
                }
            />
        </>
    )
}
