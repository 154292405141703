import { getBase64 } from "../../Utility/UtilityFunctions";
import { useEffect, useState } from "react";
import CommonInput from "../../Components/Input/CommonInput";
import CommonLabel from "../../Components/Label/CommonLabel";
import CommonButton from "../../Components/Button/CommonButton";
import useBannerStore, { updateBanner } from "../../App/stores/BannerStore";
import DropzoneImageUploader from "../../Components/Input/DropzoneImageUploader";
import { DropzoneCustom } from "../../Components/Dropzone/DropzoneCustom";
import useGeneralStore from "../../App/stores/GeneralStore";

function UpdateBanner() {
  const { selectedBanner, setShowUpdateBanner } = useBannerStore();
  const { setTrackingUpload } = useGeneralStore();
  const [imageUpdated, setImageUpdated] = useState("");
  const [updateForm, setUpdateForm] = useState({
    title: "",
    image: "",
    app_type: "",
    start_date: "",
    end_date: "",
    description: "",
  });

  const submitUpdateBannerData = async (e) => {
    e.preventDefault();
    console.log("updateFrom", updateForm);
    if (
      updateForm.title &&
      // updateForm.start_date &&
      // updateForm.end_date &&
      updateForm.app_type
    ) {
      let updateSuccess = await updateBanner(updateForm, imageUpdated);
      if (updateSuccess) {
        setUpdateForm({
          title: "",
          image: "",
          app_type: "",
          start_date: "",
          end_date: "",
          description: "",
        });
        setShowUpdateBanner(false)
        e.preventDefault();
      }
    }
  }

  useEffect(() => {
    setUpdateForm(selectedBanner);
    setTrackingUpload(false);

    console.log("selectedBanner::::::", selectedBanner);

  }, [selectedBanner]);

  useEffect(() => {
    console.log("updateForm::::::::", updateForm);
  })

  return (
    <>
      <div className="p-5 mt-5 w-full bg-white rounded-md md:mt-0 md:w-1/2">

        <div className="font-semibold text-gray-700 text-fs28 font-poppins">Update Banner</div>
        <form onSubmit={submitUpdateBannerData} className="">

          <div className="pt-5">
            <DropzoneCustom
              value={updateForm.image}
              onUpload={(e) => {
                console.log(e);
                getBase64(e, (res) => {
                  setImageUpdated(res);
                })
              }}
            />
          </div>

          <div className="pt-5">
            <CommonInput
              // required={true}
              label={"Name"}
              value={updateForm.title}
              onChange={(e) => {
                setUpdateForm({ ...updateForm, "title": e.target.value });
              }}
              type={"text"}
              name={"title"}
              placeholder={"Give a name"}
            />
          </div>

          {/* <div className="pt-5">
            <CommonInput
              allowPastDates={true}
              label={"Start Date"}
              value={updateForm.start_date}
              onChange={(e) => {
                if (updateForm.end_date < e.target.value) {
                  setUpdateForm({ ...updateForm, "start_date": e.target.value, "end_date": "" });
                } else {
                  setUpdateForm({ ...updateForm, "start_date": e.target.value });
                }
              }}
              name={"start_date"}
              type={"date"}
            />
          </div>

          <div className="pt-5">
            <CommonInput
              // required={true}
              label={"End Date"}
              disabled={updateForm.start_date ? false : true}
              startDate={updateForm.start_date}
              value={updateForm.end_date}
              onChange={(e) => setUpdateForm({ ...updateForm, "end_date": e.target.value })}
              name={"end_date"}
              type={"date"}
            />
          </div> */}

          <div className="">
            <div className="pt-5 pb-3">
              <CommonLabel text="text-fs16" labelText="For"
              // required={true} 
              />
            </div>
            <select
              // required={true}
              value={updateForm.app_type}
              onChange={(e) => { setUpdateForm({ ...updateForm, "app_type": e.target.value }) }}
              name="app_type"
              className={`
              w-full 
             bg-gray-100 
             capitalize 
             cursor-pointer 
             border-cInputBorder 
             rounded-lg p-2.5
             border-r-[10px]
             border-r-transparent
             ${updateForm.app_type === "" ? "text-gray-400" : "text-black"}`}
              aria-label="Select App Type"
            >
              <option className="text-fs16 text-black" value="" selected>Select App Type</option>
              <option className="text-fs16 text-black" value="company">Company</option>
              <option className="text-fs16 text-black" value="customer">Customer</option>
              <option className="text-fs16 text-black" value="driver">Driver</option>
            </select>
          </div>



          <div className="pt-5">
            <CommonInput
              textarea={true}
              max_char_limit={255}
              is_show_char_limit={true}
              rows={10}
              label={"description"}
              value={updateForm?.description ?? ""}
              onChange={(e) => setUpdateForm({ ...updateForm, "description": e.target.value })}
            />
          </div>


          <div className="flex justify-center pt-5">
            <CommonButton
              type="submit"
              btnLabel={"save changes"}
              // onClick={async (e) => {

              // }}
              selected={true}
            />
          </div>

        </form>
      </div>
    </>
  );
}

export default UpdateBanner;
