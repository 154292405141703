import { createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios';
import { kuGetCompany, kuGetCustomers, kuGetDrivers, kuGetLicense, kuGetRating, kuLicenseAction } from '../../Utility/url';
import { ToastrLoading } from '../../Utility/UtilityFunctions';

const initialState = {
    isLoading: true,
    dashboard: [],
    tempDashboard: [],
    licenseApplication: [],
    customers: [],
    drivers: [],
    company: [],
    rating: [],
    isUpdate: false,
    form: {
        id: null,
        license_status: '',
        license_start: null,
        license_end: null,
    },
}

/*
|--------------------------------------------------------------------------
| managing state of license application
|--------------------------------------------------------------------------
|
*/
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        changeValue: (state, action) => {
            const { name, value } = action.payload;
            console.log('name: ', name);
            console.log('value: ', value);
            // state.licenseData[name] = value;
            state.form[name] = value
            console.log('value log: ', state.form[name]);
        },
        setDashboard: (state, action) => {
            state.dashboard = action.payload
            state.tempDashboard = action.payload
        },
        setLicenseApplication: (state, action) => {
            state.licenseApplication = action.payload
        },
        setLoading: (state, action) => {
            console.log('setLoading: ' + action.payload);
            state.isLoading = action.payload
        },
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setDrivers: (state, action) => {
            state.drivers = action.payload
        },
        setCompany: (state, action) => {
            state.company = action.payload
        },
        setRating: (state, action) => {
            state.rating = action.payload
        },
        setIsUpdate: (state, action) => {
            const {licenseId, isUpdate} = action.payload
            console.log('licenseID: ', licenseId);
            console.log('isUpdate: ', isUpdate);

            // state update
            state.isUpdate = isUpdate;

            if (licenseId) {
                const tempLicense = state.licenseApplication.find((license) => license.id === licenseId)
                console.log('temp lic: ', current(tempLicense));

                state.form.id = tempLicense.id                
                state.form.license_start = tempLicense.license_start
                state.form.license_end = tempLicense.license_end
                state.form.license_status = tempLicense.license_status
            } else {
                state.form.id = null
                state.form.license_start = ''
                state.form.license_end = ''
                state.form.license_status = ''
            }            
        },
    },
})

/*
|--------------------------------------------------------------------------
| api related functions
|--------------------------------------------------------------------------
|
*/

export const handleSetLicenseApplication = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetLicense);
        console.log('handleSetLicenseApplication: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setLicenseApplication(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleSetCustomers = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetCustomers);
        console.log('handleSetCustomers: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setCustomers(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleSetDrivers = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetDrivers);
        console.log('handleSetDrivers: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setDrivers(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleSetCompany = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetCompany);
        console.log('handleSetCompany: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setCompany(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleSetRating = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetRating);
        console.log('handleSetRating: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setRating(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleUpdateLicense = (state) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
             
        const body = { id: state.form.id, license_status: state.form.license_status, license_start: state.form.license_start, license_end: state.form.license_end }

        dispatch(setLoading(true))
        const res = await axios.post(kuLicenseAction, body)

        console.log('handleUpdateLicense: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetLicenseApplication())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
        
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}



export const { setLoading, setCustomers, setDrivers, setCompany, setRating, setLicenseApplication, changeValue, setIsUpdate  } = dashboardSlice.actions
export default dashboardSlice.reducer