import { Tooltip } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleDeleteBanner, setIsImageChanged, setIsUpdate } from '../../App/Slice/BannerSlice'
import useBannerStore from '../../App/stores/BannerStore'
import DeleteIcon from "../../Images/icons/Delete.png"
import EditIcon from "../../Images/icons/Edit.png"

const BannerItemButton = ({ item }) => {
  const { setSelectedBanner, setShowUpdateBanner, setShowDeleteBanner } = useBannerStore();

  return (
    <div className="flex items-center space-x-5 mr-0">
      <span className="">

        <button onClick={() => {
          setSelectedBanner(item);
          setShowUpdateBanner(true);
        }}>
          <Tooltip title="Edit this banner" >
            <img src={EditIcon} alt="" />
          </Tooltip>
        </button>

      </span>

      <span className="">
        <button onClick={() => {
          setSelectedBanner(item);
          setShowDeleteBanner(true);
        }}>
          <Tooltip title="Delete this banner" >
            <img src={DeleteIcon} alt="" />
          </Tooltip>
        </button>
      </span>
    </div>
  )
}

export default BannerItemButton
