/* This example requires Tailwind CSS v2.0+ */
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { BaseUrlSrc } from "../../Utility/url";
import { CgLogOut, CgProfile } from 'react-icons/cg';
import { t } from "i18next";
import { iDemoUser } from "../../Utility/Sources";
import CommonImage from "../Image/CommonImage";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ProfileDropdown() {
    const { user, Logout } = useContext(AuthContext);

    return (
        <Menu as="div" className="relative inline-block h-10 ">
            <Menu.Button className="inline-flex justify-center w-full text-sm font-medium text-gray-700 rounded-full shadow-sm">
                <div className="w-8 h-8 overflow-hidden bg-gray-200 border rounded-full md:h-10 md:w-10">
                    {user ? (
                        <CommonImage
                            className="object-cover w-full h-full"
                            src={user?.image}
                            alt=""
                            dummyImage={iDemoUser}
                        />
                    ) : (
                        <img
                            className="object-cover w-full h-full"
                            src={iDemoUser}
                            alt=""
                        />
                    )}
                </div>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 w-56 mt-2 text-right origin-top-right bg-white rounded-md shadow-lg focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={"/profile"}
                                    className={classNames(
                                        active ? "bg-gray-100  text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm text-right font-medium"
                                    )}
                                >
                                    <div className="flex flex-row items-center justify-start ">
                                        <div className="mr-2 text-cBodyText text-fs16 font-fw400"><CgProfile /></div>
                                        <div className="mr-2 text-cBodyText text-fs16 font-fw400">{t("Profile")}</div>
                                    </div>
                                </Link>
                            )}
                        </Menu.Item>
                        <hr />
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    type="submit"
                                    onClick={Logout}
                                    className={classNames(
                                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block w-full text-right px-4 py-2 text-sm font-medium"
                                    )}
                                >
                                    <div className="flex flex-row items-center justify-start">
                                        <div className="mr-2 text-lg text-cBodyText text-fs16 font-fw400">
                                            <CgLogOut />
                                        </div>
                                        <div className="text-cBodyText text-fs16 font-fw400">{t("Logout")}</div>
                                    </div>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
