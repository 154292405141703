/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { Outlet, useParams } from "react-router-dom";
import CommonButton from "../../../../../Components/Button/CommonButton";
import DefaultButton from "../../../../../Components/Button/DefaultButton";
import Calendar from "../../../../../Components/Calendar";
import Dropdown from "../../../../../Components/Dropdown";
import Input02 from "../../../../../Components/Input/Input02";
import TimePicker from "../../../../../Components/Input/TimePicker";
import BackLinks from "../../../../../Components/Layout/BackLinks";
import Modal from "../../../../../Components/Modal";
import CommonModal from "../../../../../Components/Modal/CommonModal";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import { formatDate, PageTitle, validateDateTime } from "../../../../../Utility/UtilityFunctions";
import SelectDriver from "./SelectDriver";
import SelectLicensePlate from "./SelectLicensePlate";
import SelectInput from "../../../../../Components/Select/SelectInput";
import CommonCheckbox from "../../../../../Components/Checkbox/CommonCheckbox";

const ShiftPlannerManagement = () => {
  const {
    setIsCreatingShift,
    isShiftHistory,
    setClearOnSubmitShift,
    setIsShiftDetailsData,
    setIsShiftDetailsID,
    setSearchKey,
    showFilterModal,
    setShowFilterModal,
    setFilterState,
    licensePlateValue,
    filterShiftPlannerList,
    selectedDriver,
    isCompleted,
    setIsDeleted,
    setIsCompleted,
    setLicensePlateValue,
    setSelectedDriver,
    isDeleted,
    tempCarName,
    setTempCarName,
    setCarId,
    filterStartDate,
    setFilterStartDate,
    filterEndDate,
    setFilterEndDate,
    filterStartTime,
    setFilterStartTime,
    filterEndTime,
    setFilterEndTime,
    setChipData,
    onResetFilterHandler,

    filterStatus,
    setFilterStatus,
    is_maintenance,
    setIs_maintenance,driverUserId
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [showStatusTypeModal, setShowStatusTypeModal] = useState(false);

  const ChangeStartDateValue = async (name, value) => setFilterStartDate(value);

  const ChangeEndDateValue = async (name, value) => setFilterEndDate(value);

  const onPickStartDateSelect = (time) => setFilterStartTime(time);

  const onPickEndDateSelect = (time) => setFilterEndTime(time);

  // const onResetFilterHandler = () => {
  //   setClearOnSubmitShift(true);
  //   setFilterStartDate("");
  //   setFilterEndDate("");
  //   setFilterStartTime("");
  //   setFilterEndTime("");
  //   setLicensePlateValue("");
  //   setSelectedDriver("");
  //   setTempCarName("");
  //   setCarId("");
  //   setIsDeleted(false);
  //   setIsCompleted(false);
  // };

  useEffect(() => {
    onResetFilterHandler()
  }, [])

  const onSubmitHandler = async () => {
    setSearchKey('');
    setClearOnSubmitShift(false);
    setIsCreatingShift(false);

    if (!isDeleted && !isCompleted) {
      if (isShiftHistory) {
        await filterShiftPlannerList({
          user_id: company_id,
          start_date: filterStartDate ? filterStartDate : "",
          end_date: filterEndDate ? filterEndDate : "",
          start_time: filterStartTime ? filterStartTime : "",
          end_time: filterEndTime ? filterEndTime : "",
          plate_number: licensePlateValue ? [licensePlateValue] : "",
          driver_name: selectedDriver ? [selectedDriver] : "",
          driver_id: driverUserId ? [driverUserId] : "",
          type: "history",
          status: filterStatus ? [filterStatus] : null,
          is_maintenance: is_maintenance,
          take: 1000
        }, false);
      } else {
        await filterShiftPlannerList({
          user_id: company_id,
          start_date: filterStartDate ? filterStartDate : "",
          end_date: filterEndDate ? filterEndDate : "",
          start_time: filterStartTime ? filterStartTime : "",
          end_time: filterEndTime ? filterEndTime : "",
          plate_number: licensePlateValue ? [licensePlateValue] : "",
          driver_name: selectedDriver ? [selectedDriver] : "",
          driver_id: driverUserId ? [driverUserId] : "",
          status: filterStatus ? [filterStatus] : null,
          is_maintenance: is_maintenance,
          take: 1000
        }, false);
      }
    } else {
      await filterShiftPlannerList({
        user_id: company_id,
        start_date: filterStartDate ? filterStartDate : "",
        end_date: filterEndDate ? filterEndDate : "",
        start_time: filterStartTime ? filterStartTime : "",
        end_time: filterEndTime ? filterEndTime : "",
        plate_number: licensePlateValue ? [licensePlateValue] : "",
        driver_name: selectedDriver ? [selectedDriver] : "",
        driver_id: driverUserId ? [driverUserId] : "",
        // status:
        //   isDeleted && isCompleted
        //     ? ""
        //     : isDeleted
        //       ? "delete"
        //       : isCompleted
        //         ? "complete"
        //         : "",
        type: "history",
        take: 1000
      }, false);
    }
    //generateFilterChipData();
  };

  const generateFilterChipData = () => {
    let chipData = {};
    filterStartDate && (chipData["start_date"] = formatDate(filterStartDate));
    filterEndDate && (chipData["end_date"] = formatDate(filterEndDate));
    filterStartTime && (chipData["start_time"] = filterStartTime);
    filterEndTime && (chipData["end_time"] = filterEndTime);
    licensePlateValue && (chipData["plate_number"] = licensePlateValue);
    filterStatus && (chipData["status"] = filterStatus === "init" ? "Not Started" : filterStatus);
    is_maintenance && (chipData["Is Maintenance"] = parseInt(is_maintenance) === 1 ? "Is Maintenance" : "");
    selectedDriver && (chipData["driver_name"] = selectedDriver);
    if (isDeleted && isCompleted) {
      chipData["deleted"] = "deleted";
      chipData["completed"] = "completed";
    }
    else if (isDeleted) chipData["deleted"] = "deleted";
    else if (isCompleted) chipData["completed"] = "completed";

    //console.log("chipData", chipData);
    setChipData(chipData);
  }

  useEffect(() => { setIsShiftDetailsID(null) }, [isShiftHistory]);

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Shift Planner");
    setSearchKey("");
  }, []);

  return (
    <>
      {/* Filter Shifts Modal */}
      <CommonModal
        showModal={showFilterModal}
        setShowModal={setShowFilterModal}
        modalTitle={"Filter Shifts"}
        mainContent={
          <div className="pt-5">
            <div>
              <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] outline-none">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeStartDateValue("start_date", date);
                      }}
                      className="outline-none cursor-pointer"
                      className2="w-full outline-none"
                      value={filterStartDate ? formatDate(filterStartDate) : ""}
                      label="Start Date"
                      type="text"
                      placeholder="Start Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeStartDateValue("start_date", date);
                      }}
                      init_date={filterStartDate ? filterStartDate : ""}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeEndDateValue("end_date", date);
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      name="end_date"
                      value={filterEndDate ? formatDate(filterEndDate) : ""}
                      label="End Date"
                      type="text"
                      placeholder="End Date"
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeEndDateValue("end_date", date);
                      }}
                      init_date={filterEndDate ? filterEndDate : ""}
                    />
                  }
                />
              </div>

              <div className="grid grid-cols-2 gap-2 mb-12 md:gap-8 2xl:gap-12">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={filterStartTime ? filterStartTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      label="Start Time"
                      type="text"
                      placeholder="Start Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={onPickStartDateSelect}
                      init_time={filterStartTime ? filterStartTime : ""}
                    // init_avg={pickup_avg}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={filterEndTime ? filterEndTime : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      className2="w-full"
                      className="cursor-pointer"
                      label="End Time"
                      type="text"
                      placeholder="End Time"
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={onPickEndDateSelect}
                      init_time={filterEndTime ? filterEndTime : ""}
                    // init_avg={pickup_avg}
                    />
                  }
                />
              </div>

              {/* hp select car / license plate popup */}
              <div className="text-center">
                <SelectLicensePlate tempName={tempCarName ? tempCarName : ""} />
              </div>

              {/* hy select driver popup */}
              <div className="text-center mt-s20">
                <SelectDriver tempName={selectedDriver ? selectedDriver : ""} />
              </div>

              <div className="my-s20 grid grid-cols-2 gap-2 md:gap-4 2xl:gap-8 h-[80px] outline-none">
                <SelectInput
                  disabled={is_maintenance}
                  label={"Status"}
                  value={filterStatus}
                  onChange={(e) => {
                    setFilterStatus(e.target.value)
                  }}
                  dataArray={
                    [
                      {
                        title: "Not Started",
                        value: 'init',
                        selected: false
                      }, {
                        title: "Upcoming",
                        value: 'upcoming',
                        selected: false
                      }, {
                        title: "Ongoing",
                        value: 'ongoing',
                        selected: false,
                      },
                      {
                        title: "Completed",
                        value: 'complete',
                        selected: false,
                      },
                    ]
                  }
                  placeholder="Selected Status"
                />
                <div className="flex items-center justify-center">
                  <CommonCheckbox
                    label={'in maintenance'}
                    checked={is_maintenance ? true : false}
                    onChange={() => {
                      //console.log("is_maintenance",is_maintenance)
                      setFilterStatus("")
                      setIs_maintenance(is_maintenance ? 0 : 1);
                    }} />
                </div>
              </div>

              {/*
              {/* hy filter actions */}
              <div className="flex flex-row items-center justify-between">
                {/* hg reset filters */}
                <CommonButton
                  btnLabel={"Reset Filter"}
                  colorType={
                    filterStartDate ||
                      filterEndDate ||
                      filterStartTime ||
                      filterEndTime ||
                      licensePlateValue ||
                      selectedDriver ||
                      isCompleted ||
                      isDeleted ||
                      is_maintenance ||
                      filterStatus
                      ?
                      "danger"
                      :
                      "base"
                  }
                  onClick={() => {
                    onResetFilterHandler();
                  }}
                  isDisabled={
                    filterStartDate ||
                      filterEndDate ||
                      filterStartTime ||
                      filterEndTime ||
                      licensePlateValue ||
                      selectedDriver ||
                      isCompleted ||
                      isDeleted ||
                      is_maintenance ||
                      filterStatus
                      ?
                      false
                      :
                      true
                  }
                />

                {/* hb submit filter */}
                <CommonButton
                  btnLabel={"Filter Shifts"}
                  // colorType={
                  //   filterStartDate ||
                  //     filterEndDate ||
                  //     filterStartTime ||
                  //     filterEndTime ||
                  //     licensePlateValue ||
                  //     selectedDriver ||
                  //     isCompleted ||
                  //     isDeleted ||
                  //     is_maintenance ||
                  //     filterStatus
                  //     ?
                  //     "primary"
                  //     :
                  //     "base"
                  // }
                  onClick={async() => {

                    let x = true;
                    x = await validateDateTime(filterStartDate , filterStartTime, filterEndDate, filterEndTime);
                    if (!x) return;
                    // submitFilterPopup();
                    onSubmitHandler();
                    setShowFilterModal(false);
                    setFilterState(false);

                    // hg clear previous shift details, selection etc
                    setIsShiftDetailsID(null);
                    setIsShiftDetailsData({});
                  }}
                  // isDisabled={
                  //   filterStartDate ||
                  //     filterEndDate ||
                  //     filterStartTime ||
                  //     filterEndTime ||
                  //     licensePlateValue ||
                  //     selectedDriver ||
                  //     isCompleted ||
                  //     isDeleted ||
                  //     is_maintenance ||
                  //     filterStatus
                  //     ?
                  //     false
                  //     :
                  //     true
                  // }
                />
              </div>
            </div>
            {/* hg status type modal */}
            <Modal
              show_modal={showStatusTypeModal}
              setShowModal={setShowFilterModal}
              full_content={
                <div className="inline-block w-full max-w-[35vw] p-5 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                  <div className="relative">
                    <AiFillCloseCircle
                      onClick={() => setShowStatusTypeModal(false)}
                      className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
                    />
                    <div className="px-5 text-xl font-bold text-center text-gray-700">
                      Select Shift Type
                    </div>
                    <div className="pt-5 pb-2 text-center">
                      <label
                        htmlFor="complete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 rounded-[5px] ${isCompleted ? "ring-1 ring-cSecondary" : ""
                          }`}
                      >
                        <div>Completed</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="complete"
                          type={"checkbox"}
                          checked={isCompleted}
                          onChange={() => {
                            setIsCompleted(!isCompleted);
                          }}
                        />
                      </label>

                      <label
                        htmlFor="delete"
                        className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 my-4 rounded-[5px] ${isDeleted ? "ring-1 ring-cSecondary" : ""
                          }`}
                      >
                        <div>Deleted</div>
                        <input
                          className="w-[18px] h-[18px] accent-cSecondary"
                          id="delete"
                          type={"checkbox"}
                          checked={isDeleted}
                          onChange={() => {
                            setIsDeleted(!isDeleted);
                          }}
                        />
                      </label>
                    </div>

                    <div className="flex flex-row justify-center">
                      <DefaultButton
                        label={"Submit"}
                        type="warning"
                        onSubmit={() => {
                          setShowStatusTypeModal(false);
                        }}
                        canSubmit={isCompleted || isDeleted ? true : false}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        }
      />
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "/users/companies/details/" + company_id },
          { label: "Shift Planner", linkTo: "" },
        ]}
      />
      <Outlet />
    </>
  );
};

export default ShiftPlannerManagement;




// /* eslint-disable react-hooks/exhaustive-deps */
// import { useContext, useEffect, useState } from "react";
// import { AiFillCloseCircle } from "react-icons/ai";
// import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
// import { Outlet, useLocation, useParams } from "react-router-dom";
// import CommonButton from "../../../../../Components/Button/CommonButton";
// import DefaultButton from "../../../../../Components/Button/DefaultButton";
// import Calendar from "../../../../../Components/Calendar";
// import Dropdown from "../../../../../Components/Dropdown";
// import Input02 from "../../../../../Components/Input/Input02";
// import TimePicker from "../../../../../Components/Input/TimePicker";
// import BackLinks from "../../../../../Components/Layout/BackLinks";
// import Modal from "../../../../../Components/Modal";
// import CommonModal from "../../../../../Components/Modal/CommonModal";
// import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
// import { formatDate, PageTitle } from "../../../../../Utility/UtilityFunctions";
// import SelectDriver from "./SelectDriver";
// import SelectLicensePlate from "./SelectLicensePlate";

// const ShiftPlannerManagement = () => {
//   const {
//     setIsCreatingShift,
//     isShiftHistory,
//     setClearOnSubmitShift,
//     setIsShiftDetailsData,
//     setIsShiftDetailsID,
//     setSearchKey,
//     showFilterModal,
//     setShowFilterModal,
//     setFilterState,
//     licensePlateValue,
//     filterShiftPlannerList,
//     selectedDriver,
//     isCompleted,
//     setIsDeleted,
//     setIsCompleted,
//     setLicensePlateValue,
//     setSelectedDriver,
//     isDeleted,
//     tempCarName,
//     setTempCarName,
//     setCarId,
//     filterStartDate,
//     setFilterStartDate,
//     filterEndDate,
//     setFilterEndDate,
//     filterStartTime,
//     setFilterStartTime,
//     filterEndTime,
//     setFilterEndTime,
//     setChipData,
//   } = useContext(ShiftPlannerContext);
//   const location = useLocation();

//   const { company_id } = useParams();

//   const [showStatusTypeModal, setShowStatusTypeModal] = useState(false);

//   const ChangeStartDateValue = async (name, value) => setFilterStartDate(value);

//   const ChangeEndDateValue = async (name, value) => setFilterEndDate(value);

//   const onPickStartDateSelect = (time) => setFilterStartTime(time);

//   const onPickEndDateSelect = (time) => setFilterEndTime(time);

//   const onResetFilterHandler = () => {
//     setClearOnSubmitShift(true);

//     setFilterStartDate("");
//     setFilterEndDate("");

//     setFilterStartTime("");
//     setFilterEndTime("");

//     setLicensePlateValue("");
//     setSelectedDriver("");
//     setTempCarName("");
//     setCarId("");

//     setIsDeleted(false);
//     setIsCompleted(false);
//   };

//   const onSubmitHandler = async () => {
//     setSearchKey('');
//     setClearOnSubmitShift(false);
//     setIsCreatingShift(false);

//     if (!isDeleted && !isCompleted) {
//       if (isShiftHistory) {
//         await filterShiftPlannerList({
//           user_id: company_id,
//           start_date: filterStartDate ? filterStartDate : "",
//           end_date: filterEndDate ? filterEndDate : "",
//           start_time: filterStartTime ? filterStartTime : "",
//           end_time: filterEndTime ? filterEndTime : "",
//           plate_number: licensePlateValue ? licensePlateValue : "",
//           driver_name: selectedDriver ? selectedDriver : "",
//           type: "history",
//         });
//       } else {
//         await filterShiftPlannerList({
//           user_id: company_id,
//           start_date: filterStartDate ? filterStartDate : "",
//           end_date: filterEndDate ? filterEndDate : "",
//           start_time: filterStartTime ? filterStartTime : "",
//           end_time: filterEndTime ? filterEndTime : "",
//           plate_number: licensePlateValue ? licensePlateValue : "",
//           driver_name: selectedDriver ? selectedDriver : "",
//         });
//       }
//     } else {
//       await filterShiftPlannerList({
//         user_id: company_id,
//         start_date: filterStartDate ? filterStartDate : "",
//         end_date: filterEndDate ? filterEndDate : "",
//         start_time: filterStartTime ? filterStartTime : "",
//         end_time: filterEndTime ? filterEndTime : "",
//         plate_number: licensePlateValue ? licensePlateValue : "",
//         driver_name: selectedDriver ? selectedDriver : "",
//         status:
//           isDeleted && isCompleted
//             ? ""
//             : isDeleted
//               ? "delete"
//               : isCompleted
//                 ? "complete"
//                 : "",
//         type: "history",
//       });
//     }
//     generateFilterChipData();
//   };

//   const generateFilterChipData = () => {
//     let chipData = {};
//     filterStartDate && (chipData["start_date"] = formatDate(filterStartDate));
//     filterEndDate && (chipData["end_date"] = formatDate(filterEndDate));
//     filterStartTime && (chipData["start_time"] = filterStartTime);
//     filterEndTime && (chipData["end_time"] = filterEndTime);
//     licensePlateValue && (chipData["plate_number"] = licensePlateValue);
//     selectedDriver && (chipData["driver_name"] = selectedDriver);
//     if (isDeleted && isCompleted) {
//       chipData["deleted"] = "deleted";
//       chipData["completed"] = "completed";
//     }
//     else if (isDeleted) chipData["deleted"] = "deleted";
//     else if (isCompleted) chipData["completed"] = "completed";

//     console.log("chipData", chipData);
//     setChipData(chipData);
//   }

//   useEffect(() => { setIsShiftDetailsID(null) }, [isShiftHistory]);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     PageTitle("Limadi | Shift Planner");
//     setSearchKey("");
//   }, []);

//   return (
//     <>
//       {/* Filter Shifts Modal */}
//       <CommonModal
//         showModal={showFilterModal}
//         setShowModal={setShowFilterModal}
//         modalTitle={"Filter Shifts"}
//         mainContent={
//           <div className="pt-5">
//             <div>
//               <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] outline-none">
//                 <Dropdown
//                   width={null}
//                   button={
//                     <Input02
//                       onChange={(date) => {
//                         setClearOnSubmitShift(false);
//                         ChangeStartDateValue("start_date", date);
//                       }}
//                       className="outline-none cursor-pointer"
//                       className2="w-full outline-none"
//                       value={filterStartDate ? formatDate(filterStartDate) : ""}
//                       label="Start Date"
//                       type="text"
//                       placeholder="Start Date"
//                       is_readonly={true}
//                       icon={<BsCalendar2WeekFill className="h-[70%]" />}
//                     />
//                   }
//                   body={
//                     <Calendar
//                       selectAction={(date) => {
//                         setClearOnSubmitShift(false);
//                         ChangeStartDateValue("start_date", date);
//                       }}
//                       init_date={filterStartDate ? filterStartDate : ""}
//                     />
//                   }
//                 />

//                 <Dropdown
//                   width={null}
//                   button={
//                     <Input02
//                       onChange={(date) => {
//                         setClearOnSubmitShift(false);
//                         ChangeEndDateValue("end_date", date);
//                       }}
//                       className="cursor-pointer"
//                       className2="w-full"
//                       name="end_date"
//                       value={filterEndDate ? formatDate(filterEndDate) : ""}
//                       label="End Date"
//                       type="text"
//                       placeholder="End Date"
//                       is_readonly={true}
//                       icon={<BsCalendar2WeekFill className="h-[70%]" />}
//                     />
//                   }
//                   body={
//                     <Calendar
//                       selectAction={(date) => {
//                         setClearOnSubmitShift(false);
//                         ChangeEndDateValue("end_date", date);
//                       }}
//                       init_date={filterEndDate ? filterEndDate : ""}
//                     />
//                   }
//                 />
//               </div>

//               <div className="grid grid-cols-2 gap-2 mb-12 md:gap-8 2xl:gap-12">
//                 <Dropdown
//                   width={null}
//                   button={
//                     <Input02
//                       value={filterStartTime ? filterStartTime : ""}
//                       onChange={(e) => {
//                         setClearOnSubmitShift(false);
//                       }}
//                       className="cursor-pointer"
//                       className2="w-full"
//                       label="Start Time"
//                       type="text"
//                       placeholder="Start Time"
//                       icon={<BsClockFill className="h-[70%] cursor-pointer" />}
//                     />
//                   }
//                   body={
//                     <TimePicker
//                       selectAction={onPickStartDateSelect}
//                       init_time={filterStartTime ? filterStartTime : ""}
//                     // init_avg={pickup_avg}
//                     />
//                   }
//                 />

//                 <Dropdown
//                   width={null}
//                   button={
//                     <Input02
//                       value={filterEndTime ? filterEndTime : ""}
//                       onChange={(e) => {
//                         setClearOnSubmitShift(false);
//                       }}
//                       className2="w-full"
//                       className="cursor-pointer"
//                       label="End Time"
//                       type="text"
//                       placeholder="End Time"
//                       icon={<BsClockFill className="h-[70%] cursor-pointer" />}
//                     />
//                   }
//                   body={
//                     <TimePicker
//                       selectAction={onPickEndDateSelect}
//                       init_time={filterEndTime ? filterEndTime : ""}
//                     // init_avg={pickup_avg}
//                     />
//                   }
//                 />
//               </div>

//               {/* hp select car / license plate popup */}
//               <div className="text-center ">
//                 <SelectLicensePlate tempName={tempCarName ? tempCarName : ""} />
//               </div>

//               {/* hy select driver popup */}
//               <div className="text-center mt-s20">
//                 <SelectDriver tempName={selectedDriver ? selectedDriver : ""} />
//               </div>
//               {location.pathname === "/shift-planner/shift-history" ? (
//                 <div
//                   onClick={() => {
//                     console.log("SELECT TYPE!!!");
//                     setShowStatusTypeModal(true);
//                   }}
//                   className="my-5 text-center cursor-pointer"
//                 >
//                   <Input02
//                     label={"Status"}
//                     placeholder="Select Status"
//                     is_disabled={true}
//                     value={
//                       isCompleted && isDeleted
//                         ? "Completed, Deleted"
//                         : isCompleted
//                           ? "Completed"
//                           : isDeleted
//                             ? "Deleted"
//                             : ""
//                     }
//                     onChange={() => { }}
//                     className2="cursor-pointer"
//                     className="cursor-pointer"
//                   />
//                 </div>
//               ) : (
//                 <div className="mb-5"></div>
//               )}

//               {/* hy filter actions */}
//               <div className="flex flex-row items-center justify-between">
//                 {/* hg reset filters */}
//                 <CommonButton
//                   btnLabel={"Reset Filter"}
//                   colorType={
//                     filterStartDate ||
//                       filterEndDate ||
//                       filterStartTime ||
//                       filterEndTime ||
//                       licensePlateValue ||
//                       selectedDriver ||
//                       isCompleted ||
//                       isDeleted
//                       ?
//                       "danger"
//                       :
//                       "base"
//                   }
//                   onClick={() => {
//                     onResetFilterHandler();
//                   }}
//                   isDisabled={
//                     filterStartDate ||
//                       filterEndDate ||
//                       filterStartTime ||
//                       filterEndTime ||
//                       licensePlateValue ||
//                       selectedDriver ||
//                       isCompleted ||
//                       isDeleted
//                       ?
//                       false
//                       :
//                       true
//                   }
//                 />

//                 {/* hb submit filter */}
//                 <CommonButton
//                   btnLabel={"Filter Shifts"}
//                   colorType={
//                     filterStartDate ||
//                       filterEndDate ||
//                       filterStartTime ||
//                       filterEndTime ||
//                       licensePlateValue ||
//                       selectedDriver ||
//                       isCompleted ||
//                       isDeleted
//                       ?
//                       "primary"
//                       :
//                       "base"
//                   }
//                   onClick={() => {
//                     // submitFilterPopup();
//                     onSubmitHandler();
//                     setShowFilterModal(false);
//                     setFilterState(false);

//                     // hg clear previous shift details, selection etc
//                     setIsShiftDetailsID(null);
//                     setIsShiftDetailsData({});
//                   }}
//                   isDisabled={
//                     filterStartDate ||
//                       filterEndDate ||
//                       filterStartTime ||
//                       filterEndTime ||
//                       licensePlateValue ||
//                       selectedDriver ||
//                       isCompleted ||
//                       isDeleted
//                       ?
//                       false
//                       :
//                       true
//                   }
//                 />
//               </div>
//             </div>
//             {/* hg status type modal */}
//             <Modal
//               show_modal={showStatusTypeModal}
//               setShowModal={setShowFilterModal}
//               full_content={
//                 <div className="inline-block w-full max-w-[35vw] p-5 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
//                   <div className="relative">
//                     <AiFillCloseCircle
//                       onClick={() => setShowStatusTypeModal(false)}
//                       className="absolute top-0 right-0 text-2xl text-gray-600 cursor-pointer"
//                     />
//                     <div className="px-5 text-xl font-bold text-center text-gray-700">
//                       Select Shift Type
//                     </div>
//                     <div className="pt-5 pb-2 text-center">
//                       <label
//                         htmlFor="complete"
//                         className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 rounded-[5px] ${isCompleted ? "ring-1 ring-cSecondary" : ""
//                           }`}
//                       >
//                         <div>Completed</div>
//                         <input
//                           className="w-[18px] h-[18px] accent-cSecondary"
//                           id="complete"
//                           type={"checkbox"}
//                           checked={isCompleted}
//                           onChange={() => {
//                             setIsCompleted(!isCompleted);
//                           }}
//                         />
//                       </label>

//                       <label
//                         htmlFor="delete"
//                         className={`select-none cursor-pointer text-cIcon bg-cMoreLiteGrey flex justify-between items-center px-5 py-3 my-4 rounded-[5px] ${isDeleted ? "ring-1 ring-cSecondary" : ""
//                           }`}
//                       >
//                         <div>Deleted</div>
//                         <input
//                           className="w-[18px] h-[18px] accent-cSecondary"
//                           id="delete"
//                           type={"checkbox"}
//                           checked={isDeleted}
//                           onChange={() => {
//                             setIsDeleted(!isDeleted);
//                           }}
//                         />
//                       </label>
//                     </div>

//                     <div className="flex flex-row justify-center">
//                       <DefaultButton
//                         label={"Submit"}
//                         type="warning"
//                         onSubmit={() => {
//                           setShowStatusTypeModal(false);
//                         }}
//                         canSubmit={isCompleted || isDeleted ? true : false}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               }
//             />
//           </div>
//         }
//       />
//       <BackLinks
//         linksArray={[
//           { label: "companies", linkTo: "/users/companies" },
//           { label: "company Details", linkTo: "/users/companies/details/" + company_id },
//           { label: "Shift Planner", linkTo: "" },
//         ]}
//       />
//       <Outlet />
//     </>
//   );
// };

// export default ShiftPlannerManagement;
