import { t } from 'i18next';
import React from "react";
// import RightArroWhite from '../../Images/icons/arrow-right-white.svg';
// custom imports
// import RightArrow from '../../Images/icons/right_arrow.svg';


const Stoppage = ({
  is_time_fit = false,
  stopNo = "---",
  stopTitle = "<- title ->",
  totalPackages = "---",
  status = "<- status ->",
  addressDetails = "<- detailed address ->",
  distance = "---",
  time = "<- time ->",
}) => {

  return (
    <div className={`${!is_time_fit ? "bg-cRed" : "bg-cLine"}  px-5 py-2 rounded-lg flex justify-between items-center my-5`}>
      <div className="flex  mr-5 ">
        <div className="flex flex-col">
          <div className="flex flex-col items-center justify-center bg-white rounded-full w-[50px] h-[50px]">
            <span className="text-sm text-cListIcon">{t("Stop")}</span>
            <span className="text-sm text-cListIcon font-semibold">{stopNo}</span>
          </div>
          <div className={`${!is_time_fit ? "text-cWhite" : "text-cBodyText "}  flex flex-col items-center justify-center text-[10px] py-2`}>
            <span>{distance} {t("KM")}</span>
            <span>{time}</span>
          </div>
        </div>
        <div className="border-r-[1px] border-cWhite ml-2 mr-2"></div>
        <div className="flex flex-col justify-around items-start">
          <span className={`${!is_time_fit && "text-cWhite"}  text-lg font-bold`}>{stopTitle}</span>
          <div className={`${!is_time_fit ? "text-cWhite" : "text-cIcon"}  flex justify-between text-[10px]`}>
            <span>{totalPackages} {t("Packages")}</span>
            <span className="pl-5">{status}</span>
          </div>
          <span className={`${!is_time_fit ? "text-cWhite" : "text-cPrimary"}   text-xs underline cursor-pointer`}>{addressDetails}</span>
        </div>
      </div>
      <div>
        {/* <img src={!is_time_fit ? RightArroWhite : RightArrow} alt="" /> */}
      </div>
    </div>
  );
};

export default Stoppage;
