import React from 'react';
import useDriverStore from '../../../../App/stores/DriverStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

const AssignedCompaniesDeclineModal = () => {

    const { showAssignedCompaniesDeclineModal,setShowAssignedCompaniesDeclineModal } = useDriverStore();

    return (
        <div>
            <CommonModal
                showModal={showAssignedCompaniesDeclineModal}
                setShowModal={setShowAssignedCompaniesDeclineModal}
                modalTitle="confirmation"
                mainContent={
                    <>
                        <div className='py-s20 text-center'>Are you sure you want to Decline the Company?</div>

                        <div className='flex justify-center'>
                            <CommonButton
                                btnLabel='ok'
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default AssignedCompaniesDeclineModal;