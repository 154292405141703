import { t } from 'i18next';
import axios from 'axios';
import create from 'zustand'
import { kuRequestDetails, kuGetRequestsNew, kuShiftPlannerList, kuRequestCount } from '../../Utility/url';
import { Toastr, ToastrLoading, formatDate, formatTime } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';
import { k_data_set, role } from '../Utility/const';


const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useRequestStore = create((set, get) => ({

    requestID: null,
    setRequestID: (id) => set((state) => state.requestID = id),

    savedIndex: null,
    setSavedIndex: (data) => set({ savedIndex: data }),

    request_details: {},
    setRequestDetails: (data) => set({ request_details: data }),

    request_active_section: k_data_set.saved,
    setRequestActiveSection: (data) => set({ request_active_section: data }),

    request_count_data: {},
    setRequestCountData: (data) => set({ request_count_data: data }),

    total_request_count: 0,
    setTotal_request_count: (data) => set({ total_request_count: data }),

    request_list: {},
    setRequestList: (data) => set({ request_list: data }),

    request_search_key: '',
    setRequestSearchKey: (key) => {
        set({ request_search_key: key })
        return;
    },

    setTableRequests: (data, body) => {
        const { request_active_section } = useRequestStore.getState();
        console.log("body******", body)
        if (data?.all) get().setRequestList(data?.all);
        if (data?.saved) get().setRequestList(data?.saved?.data);
        if (data?.invitations) get().setRequestList(data?.invitations?.data);
        if (data?.in_bidding) get().setRequestList(data?.in_bidding?.data);
        if (data?.awarded) get().setRequestList(data?.awarded?.data);
        if (data?.not_planned && request_active_section === k_data_set.not_planned) get().setRequestList(data?.not_planned?.data);
        if (data?.ongoing) get().setRequestList(data?.ongoing?.data);
        if (data?.complete) get().setRequestList(data?.complete?.data);
        if (data?.history) get().setRequestList(data?.history?.data);
    },

    not_planned_stops: [],
    generateStops: (data) => {
        set({ not_planned_stops: [] });
        let x = [];
        data?.stops?.forEach(item => {
            const stop = {
                id: item.id,
                date: data.date,
                start_time: "",
                end_time: "",
            }
            x.push(stop);
        })
        set({ not_planned_stops: x });
        console.log('generateStops:', x);
    },


    setShiftsSearchResult: (data) => set((state) => state.shifts = data),

    stops: [],
    updateStopInfo: (index, name, value) => set((state) => state.stops[index][name] = value),

    biddingCompaniesDetails: false,
    setBiddingCompaniesDetails: (data) => set({ biddingCompaniesDetails: data }),

    biddingCompaniesDetailsModal: false,
    setBiddingCompaniesDetailsModal: (data) => set({ biddingCompaniesDetailsModal: data }),

}))

//! api related functions


export const getRequestsData = async (data_set, user_id, search, user) => {
    const { setTableRequests, request_active_section } = useRequestStore.getState();
    if (search === "") setLoading(true); else setIsLoadingSearch(true);
    let body = {}
    if (request_active_section === k_data_set.not_planned) {
        body = {
            user_id: parseInt(user_id),
            take: 1000,
            data_set: [data_set],
            search: search,
            filter: { is_planned:  0  },
            order_by: "pickup_starts_at",
            is_asc: 1
        };
    }
    else if (request_active_section === k_data_set.awarded_admin) {
        body = {
            user_id: parseInt(user_id),
            take: 1000,
            data_set: [data_set],
            search: search,
            filter: { is_planned: 1 },
            is_asc: 1
        };
    }
    else {
        body = {
            user_id: parseInt(user_id),
            take: 1000,
            data_set: [data_set],
            search: search,
            is_asc: 1
        };
    }

    console.log('body', body);

    try {
        const res = await axios.get(kuGetRequestsNew, {params: body});
        console.log('getRequestsData: ', res.data);

        if (res.data.success) {
            setTableRequests(res?.data?.data, body);
            if (search) setIsLoadingSearch(false); else setLoading(false);
            return true;
        }
        else {
            Toastr({ message: res?.data?.message });
            if (search === "") setLoading(false); else setIsLoadingSearch(false);
            return false;
        }
    } catch (err) {
        Toastr(({ message: t("An error occurred!") }))
        if (search === "") setLoading(false); else setIsLoadingSearch(false);
        console.log('getRequestsData: ', err)
        return false;
    }
}

export const getRequestCount = async (user_id, withLoading = true, user) => {
    const { setRequestCountData, setTotal_request_count } = useRequestStore.getState();
    if (withLoading) setLoading(true);

    try {
        const res = await axios.get(kuRequestCount, { params: { user_id: parseInt(user_id) } });
        console.log('getRequestCount: ', res.data);

        if (res.data.success) {
            setRequestCountData(res?.data?.data);
            if (user === "company") {
                setTotal_request_count(
                    parseInt(res?.data?.data?.saved)
                    + parseInt(res?.data?.data?.complete)
                    + parseInt(res?.data?.data?.history)
                    + parseInt(res?.data?.data?.in_bidding)
                    + parseInt(res?.data?.data?.invitation)
                    + parseInt(res?.data?.data?.ongoing)
                    + parseInt(res?.data?.data?.awarded)
                    + parseInt(res?.data?.data?.not_planned)
                )
            } else if (user === "customer") {
                setTotal_request_count(
                    parseInt(res?.data?.data?.saved)
                    + parseInt(res?.data?.data?.history)
                    + parseInt(res?.data?.data?.in_bidding)
                    + parseInt(res?.data?.data?.ongoing)
                    + parseInt(res?.data?.data?.awarded)
                )
            }

        }
        else {
            Toastr({ message: res?.data?.message });
        }
        if (withLoading) setLoading(false)
        return true;
    } catch (err) {
        Toastr(({ message: t("An error occurred!") }))
        if (withLoading) setLoading(false)
        console.log('getRequestCount: ', err)
        return false;
    }
}

export const defineDataSet = (type, user_role) => {
    if (type === k_data_set.saved) return user_role === role.customer ? ['invitations'] : ['invitations', 'shift_plan', 'my_bid',];
    else if (type === k_data_set.invitation) return user_role === role.customer ? ['biddings', 'invitations'] : ['biddings', 'invitations', 'shift_plan', 'my_bid'];
    else if (type === k_data_set.in_bidding) return ['biddings', 'invitations', 'shift_plan', 'my_bid'];
    else if (type === k_data_set.not_planned || type === 'awarded' || type === 'on-going') return user_role === role.customer ? ['awarded'] : ['awarded', 'shift_plan', 'my_bid'];
    else if (type === k_data_set.completed || type === 'history') return user_role === role.customer ? ['awarded', 'history', 'complete'] : ['awarded', 'history', 'complete', 'shift_plan', 'my_bid'];
    else return ['invitations', 'biddings', 'awarded', 'history', 'complete', 'shift_plan', 'my_bid'];
}

export const getRequestDetails = async (type, id, user_role, user_id) => {
    if (!id) {
        Toastr({ message: t("Invalid Request!") })
        return;
    }
    const { setRequestDetails } = useRequestStore.getState();

    const data_set = defineDataSet(type, user_role);

    setLoading(true);
    console.log("Request Details body", { data_set: data_set, user_id: user_id, request_id: id });

    try {
        const res = await axios.get(kuRequestDetails, { params: { id: id, data_set: data_set, user_id: user_id } });
        console.log('getRequestDetails: ', res);

        if (res?.data?.success) {
            setRequestDetails(res?.data?.data);

        } else {
            // Toastr({ message: res?.data?.message })
        }

        setLoading(false);
    } catch (err) {
        console.log('getRequestDetails: ', err);
        Toastr(({ message: t("An error occurred!") }));
        setLoading(false);
    }
}



export const definePickupAndDeliveryStatus = (data, type) => {

    if (type === 'pickup') {
        if (data?.pickup_status === 'delivered' || data?.pickup_status === "not_delivered")
            return `${data?.pickup_status === 'not_delivered' ? 'Not' : ''} Picked up: ${formatDate(data?.pickup_driver_complete_date)}, ${data?.pickup_driver_complete_time ? formatTime(data?.pickup_driver_complete_time) : 'No date & time'}`;
        else
            return `Exp. Pickup: ${formatDate(data?.pickup_expected_date)}, ${formatTime(data?.pickup_expected_time)}`;
    } else {
        if (data?.status === 'delivered' || data?.status === "not_delivered")
            return `${data?.status === 'not_delivered' ? 'Not' : ''} Delivered: ${formatDate(data?.delivery_driver_complete_date)}, ${data?.delivery_driver_complete_time ? formatTime(data?.delivery_driver_complete_time) : 'No date & time'}`;
        else
            return `Exp. Delivery: ${data?.delivery_expected_time && data?.delivery_expected_date ?
                `${formatDate(data?.delivery_expected_date)},${formatTime(data?.delivery_expected_time)}` : 'No expected time'}`;
    }
}

export const getRequestShiftPlannerList = async (start_date, start_time, user_id) => {
    setLoading(true)
    try {
        console.log("body: getRequestShiftPlannerList = ", start_date, start_time, user_id);
        const res = await axios.get(kuShiftPlannerList + `?start_time=${start_time}&start_date=${start_date}&is_maintenance=${0}&user_id=${user_id}`);
        console.log("getRequestShiftPlannerList:", res.data);
        if (res.data.success) {
            useRequestStore.getState().setShifts(res.data.data);
            useRequestStore.getState().setShiftsCopy(res.data.data);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false)
    } catch (err) {
        console.log('getRequestShiftPlannerList: ', err);
        ToastrLoading(t("An error occurred!"));
        setLoading(false)
    }
};




//! helper functions

export const searchShift = (key) => {
    const { shiftsCopy, setShiftsSearchResult } = useRequestStore.getState();

    // eslint-disable-next-line array-callback-return
    const result = shiftsCopy.filter((item) => {
        if (
            item.driver_user.name.toLowerCase().includes(key) ||
            item.car.car_license_plate_number.toLowerCase().includes(key)
        ) {
            return item;
        }
    });
    console.log("result: ", key);
    setShiftsSearchResult(result);

}


export default useRequestStore;