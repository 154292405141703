/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CommonButtonOutlined from '../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../Components/CircularProgressBar';
import SearchInput from '../../../../../Components/Input/SearchInput';
import BackLinks from '../../../../../Components/Layout/BackLinks';
import ListSkeleton01 from '../../../../../Components/Skeletons/ListSkeleton01';
import { CarManagementContext } from '../../../../../Context/CarManagementContext';
import { iRefresh } from '../../../../../Utility/Sources';
import { PageTitle } from '../../../../../Utility/UtilityFunctions';
import AddCar from "./AddCar";
import Car from "./Car";
import LicenseModal from './LIcenseModal';
import DeleteCarModal from './Modals/DeleteCarModal';
import UpdateCar from "./UpdateDriver";
import useCarStore from '../../../../../App/stores/CarStore';
import LimadiSearchBox from '../../../../../Components/Input/LimadiSearchBox';
import EmptyMessage from '../../../../../Components/EmptyMessage';

function CarManagement() {
  const {
    detailsState,
    updateDetailsState,
    getAllCar,
    allCar,
    selectedIndex,
    setSelectedIndex,
    selectCarFoo,
    isLoading,
    searchKey,
    searchCar,
    setUpdatingDetails,
    setSearchKey,
    setAddTypeUpdateFormChange,
    dispatch,
    setLicenseStartDate,
    selectedCar,
  } = useContext(CarManagementContext);

  const { company_id } = useParams();

  const {setCarIndex}=useCarStore();
  // console.log('company_id', company_id);

  // hg actions to take on component load
  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Car Management");
    getAllCar(company_id);
    setSelectedIndex(null);
    setSearchKey("");
  }, []);


  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshFoo = async () => {
    setSearchKey("");
    setIsRefreshing(true);
    await getAllCar(company_id);
    setIsRefreshing(false);
  };

  const ClearFormData = () => {
    setLicenseStartDate("");
    let eventToSend = {
      target: {
        name: "purchaseLicenseComment",
        value: "",
      },
    };
    setAddTypeUpdateFormChange(eventToSend);
    let tmpIndex = "";
    let tmpId = "";
    dispatch({
      type: "SET_SELECTED_LICENSE_INDEX",
      payload: { index: tmpIndex, id: tmpId },
    });
    console.log("ClearFormData Done !");
  };

  useEffect(() => {
    ClearFormData();
  }, [selectedCar]);

  return (
    <>
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "/users/companies/details/" + company_id },
          { label: "car management", linkTo: "" },
        ]}
      />
      {/* title */}
      <div className="flex flex-row justify-between align-middle card">
        <div className="flex items-center w-2/3">
          <div className="text-2xl font-bold mt-[5px] mr-5">
            {t("Car Management")} ({allCar?.length})
          </div>
          {/* refresh icon button */}
          {!isRefreshing ? (
            <img
              onClick={refreshFoo}
              src={iRefresh}
              alt="refresh-icon"
              className="h-8 cursor-pointer"
            />
          ) : (
            <CircularProgressBar />
          )}
        </div>

        {/* <CommonButtonOutlined
          btnLabel={t("Add Car")}
          onClick={() => {
            updateDetailsState("add");
            setSelectedIndex(null);
          }}
        /> */}

      </div>

      {/* content */}
      <div className="grid grid-cols-12 gap-2 md:gap-4 2xl:gap-8">
        {/* favorite address list */}
        <div className="col-span-12 mt-5 lg:col-span-5">
          <div className="flex-col justify-start bg-white shadow rounded-xl">
            {/* search-bar */}
            <div className="px-5 py-5">
              <LimadiSearchBox
                onSearchClear={()=>{
                  setSearchKey("");
                  searchCar("");
                }}
                fullWidth={true}
                withClearSearch={true}
                placeholder={t("search")}
                type="search"
                onChange={(e)=>searchCar(e.target.value)}
                name="searchKey"
                value={searchKey}
              />
            </div>

            <ul className="overflow-y-auto h-screen">
              {isLoading ? (
                <ListSkeleton01 />
              ) : (
                allCar?.length > 0 ? allCar?.map((car, index) => {
                  return (
                    <Car
                      key={index}
                      data={car}
                      isSelected={selectedIndex === index ? true : false}
                      onSelect={() => {
                        setCarIndex(index)
                        selectCarFoo(index);
                        setUpdatingDetails(true);
                      }}
                    />
                  );
                }) : <EmptyMessage />
              )}

              <div className="mb-5"></div>
            </ul>
          </div>
        </div>

        {/* favorite address details */}
        <div className="col-span-12 mt-5 lg:col-span-7">
          {/* {detailsState === "add" ? <AddCar /> : <UpdateCar />} */}
          <UpdateCar />
        </div>
      </div>

      <LicenseModal />
      <DeleteCarModal />
    </>
  );
}

export default CarManagement;
