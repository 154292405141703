import create from 'zustand';
import { kuGetCommonDetails, kuGetAllCustomers, kuResetPassword, kuSearchUser, kuToggleStatus, kuAddUser } from '../../Utility/url';
import useGeneralStore from './GeneralStore';
import axios from 'axios';
import { Toastr } from '../../Utility/UtilityFunctions';
import { t } from 'i18next';
import useCompanyStore, { getCompanyDetails } from './CompanyStore';
import useDriverStore, { getDriverDetails } from './DriverStore';

const useCustomerStore = create((set, get) => ({
  is_profile_open: true,
  setProfileOpen: (value) => set({ is_profile_open: value }),

  customers: {},
  setCustomers: (value) => {
    console.log('all_customers', value.data);
    set({ customers: value });
    set({ all_customers: value?.data });
    get().setTempAllCustomers(value?.data);
  },

  all_customers: [],
  setAllCustomers: (value) => set({ all_customers: value }),

  customerDetails: {},
  setCustomerDetails: (value) => set({ customerDetails: value }),

  customerTakeAmount: 10,
  setCustomerTakeAmount: (value) => set({ customerTakeAmount: value }),

  customerDeleteID: 0,
  setCustomerDeleteID: (value) => set({ customerDeleteID: value }),

  temp_all_customers: [],
  setTempAllCustomers: (value) => set({ temp_all_customers: value }),

  showAddCustomer: false,
  setShowAddCustomer: (value) => set({ showAddCustomer: value }),

  is_searching: false,
  setIsSearching: (value) => set({ is_searching: value }),

  is_show_customer_delete_modal: false,
  setIsShowCustomerDeleteModal: (value) => set({ is_show_customer_delete_modal: value }),

  showEditCustomerModal: false,
  setShowEditCustomerModal: (value) => set({ showEditCustomerModal: value }),

  showDeactivateCustomerModal: false,
  setShowDeactivateCustomerModal: (value) => set({ showDeactivateCustomerModal: value }),

  showResetPasswordCustomer: false,
  setShowResetPasswordCustomer: (value) => set({ showResetPasswordCustomer: value }),

  customerSearchValue: "",
  setCustomerSearchValue: (value) => set({ customerSearchValue: value }),

  customerPageUrl: "",
  setCustomerPageUrl: (value) => set({ customerPageUrl: value }),

  customerFilterValue: "",
  setCustomerFilterValue: (value) => set({ customerFilterValue: value }),

}));


// add new customer  
export const addNewCustomer = async (addNewCustomerForm) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    // return console.log("ADD new customer", addNewCustomerForm);
    setLoading(true);
    const res = await axios.post(kuAddUser, addNewCustomerForm);
    console.log('addNewCustomer: ', res.data);

    if (res.data.success) {
      Toastr(res.data.message, 'success');
      await getCustomers();
      return true;
    }
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('addNewCustomer: ', e);
  }
}



// get customers 
export const getCustomers = async (paginationUrl = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setCustomers, customerTakeAmount } = useCustomerStore.getState();

  try {
    setLoading(true);
    let targetUrl = "";
    if (paginationUrl !== "") {
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = kuGetAllCustomers + targetUrl;
    } else {
      targetUrl = kuGetAllCustomers;
    }

    const res = await axios.get(targetUrl, { params: { take: customerTakeAmount } });
    console.log('getCustomers: ', res.data);

    if (res.data.success) {
      setCustomers(res.data.data);
      setLoading(false);
      return true
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false
    }
  } catch (e) {
    Toastr(t("An error occurred!"));
    console.log('getCustomers: ', e);
    setLoading(false);
    return false
   
  }
}


// search in users
export const searchUsers = async (key = '', role = 'customer', take, url = null) => {
  const { setCustomers, setIsSearching } = useCustomerStore.getState();
  const { setCompanies } = useCompanyStore.getState();
  const { setDrivers } = useDriverStore.getState();

  try {
    setIsSearching(true);
    let res
    if (url) {
      res = await axios.get(url + `&key=${key}&role=${role}`, { params: { take: take } });
    } else {
      res = await axios.get(kuSearchUser + `?key=${key}&role=${role}`, { params: { take: take } });
    }

    console.log('searchUsers: ', res.data);

    if (res.data.success) {
      if (role === 'customer') setCustomers(res.data.data);
      else if (role === 'company') setCompanies(res.data.data);
      else if (role === 'driver') setDrivers(res.data.data);
    }
    else Toastr(res.data.message);

    setIsSearching(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setIsSearching(false);
    console.log('searchUsers: ', e);
  }
}

// get customer details
export const getCustomerDetails = async (id = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setCustomerDetails } = useCustomerStore.getState();

  try {
    setLoading(true);

    const res = await axios.get(kuGetCommonDetails, { params: { user_id: id } });
    // console.log('getCustomerDetails: ', res.data);

    if (res.data.success) setCustomerDetails(res.data.data);
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('getCustomerDetails: ', e);
  }
}

// e    reset password   
export const resetPassword = async (body) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    const { password, password_confirmation } = body
    // if (password !== password_confirmation) {
    //   Toastr("Passwords Don't Match", "warning");
    //   return
    // }
    // if (password.length < 8 || password_confirmation.length < 8) {
    //   Toastr("Password Minimum 8 Characters", "warning");
    //   return
    // }
    setLoading(true);
    const res = await axios.post(kuResetPassword, body);
    console.log('resetPassword: ', res.data);
    if (res.data.success) {
      setLoading(false);
      Toastr(res.data.message, "success")
      return true;
    } else {
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (error) {
    console.log("resetPassword", error);
    Toastr(t("An error occurred!"));
    setLoading(false);
    return false;
  }
}

// toggle status of customer  
export const userToggleStatus = async (userId, body, user) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    if (userId === null || body === null || user === null) {
      Toastr(t("An error occurred!"));
      return;
    }
    setLoading(true);
    const res = await axios.post(kuToggleStatus, body);
    console.log('userToggleStatus: ', res.data);
    if (res.data.success) {
      if (user === "company") return getCompanyDetails(parseInt(userId));
      if (user === "customer") return getCustomerDetails(parseInt(userId));
      if (user === "driver") return getDriverDetails(parseInt(userId));
    } else {
      Toastr(res.data.message);
      setLoading(false);
    }
  } catch (error) {
    console.log("userToggleStatus", error);
    Toastr(t("An error occurred!"));
    setLoading(false);
  }
}

export default useCustomerStore;