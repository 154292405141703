import React from 'react'
import { t } from "i18next";

function EmptyMessage({ message = t("No data found!") }) {
    return (
        <div className="text-[18px] text-[#bdbdbd] italic font-semibold text-center my-10">
            {message}
        </div>
    )
}

export default EmptyMessage