/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import BackLinks from "../../../../../Components/Layout/BackLinks";
import { PageTitle } from "../../../../../Utility/UtilityFunctions";

const TrackOnlineManagement = () => {

  const {company_id}=useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Track Online Driver")
  }, [])

  return (
    <> 
    <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "/users/companies/details/" + company_id },
          { label: "Track Online Driver", linkTo: "" },
        ]}
      />
    <Outlet /> </>
  );
};

export default TrackOnlineManagement;
