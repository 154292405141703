import React from 'react'
import { userToggleStatus } from '../../../../App/stores/CustomerStore';
import useDriverStore from '../../../../App/stores/DriverStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function DeactivateDriver() {
    const { showDeactivateDriverModal, setShowDeactivateDriverModal } = useDriverStore();

    const {driverDetails} = useDriverStore();

    let status = driverDetails?.is_active ? 0 : 1;

    const body = {
      user_id: driverDetails?.id,
      is_active: status
    }

    return (
        <>
            <CommonModal
                showModal={showDeactivateDriverModal}
                setShowModal={setShowDeactivateDriverModal}
                modalTitle="deactivate confirmation"
                mainContent={
                    <div className='py-5 text-base text-center'>
                        Are you sure you want to deactivate this Driver Account ?
                    </div>
                }

                primaryActionButton={
                    <CommonButton colorType='danger' btnLabel='Yes' onClick={()=>userToggleStatus(driverDetails?.id,body,"driver")} />
                }

                secondaryActionButton={
                    <CommonButton btnLabel='no' />
                }
            />
        </>
    )
}
