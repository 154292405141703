import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import CommonButton from '../../../../../Components/Button/CommonButton';
import Input02 from '../../../../../Components/Input/Input02';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { CloudRequestContext } from '../../../../../Context/CloudRequestContext';

const RequestTypeSelector = ({
  data_id = null,
  request_type = "",
  cleared = false,
  setCleared,
  finalValue,
}) => {
  const {
    isExpressType,
    isAdvancedType,
    setIsExpressType,
    setIsAdvancedType,
    currentSelection,
  } = useContext(CloudRequestContext);
  const [show_transportation_modal, setTransportationModal] = useState(false);
  const [typeDataFormatted, setTypeDataFormatted] = useState("");

  const submit_req = () => {
    var inp_data = "";
    setTypeDataFormatted(inp_data);
    if (isAdvancedType && isExpressType) {
      inp_data = "Simple and Advanced";
    } else {
      if (isAdvancedType) {
        inp_data = "Advance";
      } else if (isExpressType) {
        inp_data = "Simple";
      }
    }
    setTypeDataFormatted(inp_data);
    finalValue(inp_data);
  };

  useEffect(() => {
    submit_req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection]);

  useEffect(() => {
    submit_req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpressType, isAdvancedType]);

  useEffect(() => {
    if (cleared) {
      setIsExpressType(false);
      setIsAdvancedType(false);
      setTypeDataFormatted("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleared]);

  return (
    <div>
      <Input02
        onClick={() => {
          setTransportationModal(true);
        }}
        name="request_type"
        value={cleared ? "" : typeDataFormatted}
        className="cursor-pointer"
        label={t("Request Type*")}
        type="text"
        placeholder={t("Select Request Type")}
        icon={<IoIosArrowForward className="h-[70%] cursor-pointer" />}
        is_readonly={true}
      />


      <CommonModal
        showModal={show_transportation_modal}
        setShowModal={setTransportationModal}
        modalTitle={t("Select Request Type")}
        mainContent={
          <div>
            <div>
              <div className="pt-5 max-h-[17vh]">
                <div
                  key={1}
                  onClick={() => {
                    setIsExpressType(!isExpressType);
                  }}
                  className={`${isExpressType && "border-emerald-600"
                    } border border-transparent flex justify-between items-center py-3 px-4 mb-3 cursor-pointer rounded-lg bg-cListItem text-gray-600 font-bold`}
                >
                  {t("Simple Delivery")}
                  <input
                    id="1"
                    className="w-4 h-4 mr-5"
                    type={"checkbox"}
                    checked={isExpressType}
                    onChange={() => setIsExpressType(!isExpressType)}
                  />
                  {/* {isExpressType ? (<Checkbox checked /> ): (<Checkbox />)} */}
                </div>

                <div
                  key={2}
                  onClick={() => {
                    setIsAdvancedType(!isAdvancedType);
                  }}
                  className={`${isAdvancedType && "border-emerald-600"
                    } border border-transparent flex justify-between items-center py-3 px-4 mb-3 cursor-pointer rounded-lg bg-cListItem text-gray-600 font-bold`}
                >
                  {t("Advanced Delivery")}
                  <input
                    id="2"
                    className="w-4 h-4 mr-5"
                    type={"checkbox"}
                    checked={isAdvancedType}
                    onChange={() => setIsAdvancedType(!isAdvancedType)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-5 w-full flex justify-center">
              <CommonButton
                btnLabel={t("Submit")}
                onClick={() => {
                  submit_req();
                  setCleared(false);
                  setTransportationModal(false);
                }}
                isDisabled={!isExpressType && !isAdvancedType}
                colorType={!isExpressType && !isAdvancedType ? "base" : "primary"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RequestTypeSelector;
