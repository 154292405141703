import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { changeFocusAndZoom } from '../../../../../App/stores/MapStore';
import { TrackOnlineContext } from '../../../../../Context/TrackOnlineContext';
import { iRightArrow } from '../../../../../Utility/Sources';
import { DistanceIntoKM } from '../../../../../Utility/UtilityFunctions';

const RoutePlan = ({ data = {}, isSelected, onSelect, setTargetStop, isInAddStop }) => {
  const { currentRouteSelection, setCurrentRouteSelection, setSelectedStop } = useContext(TrackOnlineContext);
  const location = useLocation();

  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    focusOnSelectedStop(data);
    switch (data.status) {
      case "init":
        setStatusText("");
        break;
      case "complete":
        setStatusText("Complete");
        break;
      case "not_delivered":
        setStatusText("Not delivered");
        break;
      case "ongoing":
        setStatusText("On going");
        break;
      case "hold":
        setStatusText("On hold");
        break;
      default:
        //
        break;
    }
  }, [data]);

  useEffect(() => { focusOnSelectedStop(data) }, [data])

  const focusOnSelectedStop = (data) => {
    // console.log("focusOnSelectedStop", data);
    changeFocusAndZoom(data.lat, data.lng, 15)
  }

  const navigate = useNavigate()

  return (
    <div className="flex item-center justify-between w-full cursor-pointer select-none">
      {
        // location.pathname === "/track-online-driver/route-planner/" &&
        (<div className={`w-[5px] my-3 ${currentRouteSelection === data.id ? "bg-cListIcon" : "bg-[#ffffff]"} mr-[10px]`} ></div>)}
      <div
        className={`flex justify-between items-center rounded-br5 ${location.pathname === "/track-online-driver/route-planner"
          ? "mr-2"
          : "ml-[14px] mr-2"
          } px-[10px] py-[14px] my-[10px] ${data.status === "complete" ? "opacity-[0.55]" : "opacity-100"
          } bg-cListItem w-full`}
        onClick={() => {
          if (isInAddStop) {
            navigate(-1)
            setTimeout(() => {
              setCurrentRouteSelection(data.id);
              setSelectedStop(data.id);
              setTargetStop(data)
            }, 500);
          } else {
            setCurrentRouteSelection(data.id);
            setSelectedStop(data.id);
            setTargetStop(data)
          }
        }}
      >
        <div className="flex ">
          <div className="flex flex-col items-center mr-[6px]">
            <div className="flex flex-col justify-center items-center rounded-full h-10 w-10 bg-white text-cListIcon ">
              {data.q_index && <span className="text-[10px]">{t("Stop")}</span>}
              <span className="text-xs font-bold">
                {data.q_index
                  ? data.q_index < 10
                    ? "0" + data.q_index
                    : data.q_index
                  : "0"}
              </span>
            </div>
            <div className="flex flex-col justify-center items-center pt-2 gs-text-body">
              <span className="text-[10px] font-normal">
                {data ? DistanceIntoKM(data.distance) + " KM" : "0"}
              </span>
              <span className="text-[10px] truncate">
                {data.approx_time ? data.approx_time : "Approx. Time"}
              </span>
            </div>
          </div>
          <div className="mr-1 ml-1 border-r-2 border-white"></div>
          <div className="flex flex-col justify-center pl-2">
            <span className="text-[14px] font-semibold max-w-[15vw] truncate">
              {data ? data.title : "No title"}
              {/* {"<--PATH--> "}
              {location.pathname} */}
            </span>
            <div className="flex items-center max-w-[20vw] text-[#828282] text-[10px]">
              <div className="max-w-[12vw] truncate">
                {data.stop_details ? data.stop_details?.products?.length : "00"}{" "}
                {t("Packages")}
              </div>
              <span className="pl-2 capitalize">{statusText}</span>
            </div>
            <span onClick={() => focusOnSelectedStop(data)} className="text-cPrimary text-xs underline">
              {data ? data.address : "No address"}
            </span>
          </div>
        </div>
        <div>
          <img src={iRightArrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default RoutePlan;
