/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { ImSpinner2 } from 'react-icons/im';
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import useCloudRequestStore, { getCloudReqDetails } from "../../../../../App/stores/CloudRequestStore";

import EmptyMessage from "../../../../../Components/EmptyMessage";
import SearchInput from "../../../../../Components/Input/SearchInput";
import { CloudRequestContext } from "../../../../../Context/CloudRequestContext";
import { iClose } from "../../../../../Utility/Sources";
import FilterDetailsRow from "./FilterDetailsRow";

const FilterResult = () => {

  const {
    currentSelection,
    filterSearchList,
    setSearchFilterByChip,
    getFilterSearchListByFreeText,
    chip,
    setChip,
    filterBK,
    setCurrentSelection,
    tmpFreeText,
    setTmpFreeText,
    filterTemp,
    getFilterSearchList,
    setFilter,
    is_global_req_free_text_searching,
  } = useContext(CloudRequestContext);

  const { isStartFreeTextSearch, setStartFreeTextSearch, setShowCloudRequestModal } = useCloudRequestStore()

  const [isKeepType, setIsKeepType] = useState(true);
  const [searchValueLocal, setSearchValueLocal] = useState("");
  const [valueTmpFreeText] = useDebounce(searchValueLocal, 1000);

  const { company_id } = useParams();

  useEffect(() => { localStorage.setItem("limadi_company_id", company_id) }, [company_id]);

  useEffect(() => {
    // ? values of body object
    var transportation_value =
      filterBK?.length &&
      filterBK?.find(function (item) {
        return item?.id === currentSelection;
      })?.transportation_type;

    var type_value =
      isKeepType &&
      filterBK?.length &&
      filterBK?.find(function (item) {
        return item.id === currentSelection;
      })?.request_type;

    setSearchFilterByChip({
      type: type_value,
      transport_type: transportation_value,
    });
  }, [currentSelection, isKeepType]);

  useEffect(() => {
    setCurrentSelection(null)
    isStartFreeTextSearch && setTmpFreeText(valueTmpFreeText);

  }, [valueTmpFreeText]);

  useEffect(() => {
    setFilter(filterTemp);
    (isEmptyObject(chip) || chip?.city?.length === 0) && setCurrentSelection(null);
    console.log('is chip empty', isEmptyObject(chip));
  }, [chip]);


  useEffect(() => {
    if (window.location.pathname === "/users/companies/details/" + company_id + "/global-request") getFilterSearchList(company_id, true, tmpFreeText);
    console.log('chip', chip);
  }, [chip, tmpFreeText]);

  useEffect(() => {
    console.log('filterSearchList:::::', filterSearchList);
  }, [filterSearchList]);

  function isEmptyObject(obj) {
    var name;
    for (name in obj) {
      if (obj.hasOwnProperty(name)) {
        return false;
      }
    }
    return true;
  }

  // document.onkeydown = function (e) {
  //   switch (e.key) {

  //     case 'Enter':
  //       getFilterSearchListByFreeText(valueTmpFreeText, company_id);
  //       break;

  //     default:
  //       return 0;
  //   }
  // };

  return (
    <>

      <div className="card flex max-h-[70vh]">
        <div className="flex justify-between items-center pb-5">
          <div className="xl:text-2xl text-lg font-bold text-cBodyText">
            {t("Available Requests (") + (filterSearchList?.length ?? 0) + ")"}
          </div>

          {/* e    request-search-bar */}
          <div className="2xl:w-[300px] relative">
            <SearchInput
              placeholder={t("search request")}
              type="search"
              onChange={(e) => {
                setSearchValueLocal(e.target.value)
                searchValueLocal.length > 0 && setStartFreeTextSearch(true)
              }}
              name="searchKey"
              value={searchValueLocal}
            />
            {is_global_req_free_text_searching ?
              <div className="absolute right-2 pl-3 top-2">
                <ImSpinner2 className="animate-spin duration-150 text-gray-500 border-gray-400 w-5 h-[60%]" />
              </div> : <></>}
          </div>
        </div>

        {/* //e      chips::begin */}
        {chip ? (
          <div className={`w-auto h-auto flex items-center flex-wrap justify-start`}>
            {/*e     request type */}
            <div className="">
              {chip.type &&
                (chip?.type === "simple_and_advance" ? (
                  <div className="flex items-center">
                    <div
                      onClick={() => {
                        let tempChip = { ...chip };
                        delete tempChip.type;
                        tempChip.type = "advance";
                        console.log("tempChip2: ", tempChip);
                        setChip(tempChip);
                        setIsKeepType(false);
                      }}
                      className="flex flex-row items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm">
                      <div className="mr-2 text-cBodyText font-normal capitalize">
                        Simple
                      </div>
                      <div className="cursor-pointer select-none">
                        <img src={iClose} alt="close-icon" />
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        let tempChip = { ...chip };
                        delete tempChip.type;
                        tempChip.type = "simple";
                        console.log("tempChip2: ", tempChip);
                        setChip(tempChip);
                        setIsKeepType(false);
                      }}
                      className="flex flex-row items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                    >
                      <div className="mr-2 text-cBodyText font-normal capitalize">
                        Advance
                      </div>
                      <div className="cursor-pointer select-none">
                        <img src={iClose} alt="close-icon" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        let tempChip = { ...chip };
                        delete tempChip.type;
                        console.log("tempChip2: ", tempChip);
                        setChip(tempChip);
                        setIsKeepType(false);
                      }}
                      className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                    >
                      <div className="mr-2 text-cBodyText font-normal capitalize">
                        {chip.type}
                      </div>
                      <div className="cursor-pointer select-none">
                        <img src={iClose} alt="close-icon" />
                      </div>
                    </div>
                  </>
                ))}
            </div>

            {/*e     transportation type */}
            <div className="pb-0">
              {chip.transport_type && (
                <div
                  onClick={() => {
                    let tempChip = { ...chip };
                    delete tempChip.transport_type;
                    console.log("tempChip2: ", tempChip);
                    setChip(tempChip);
                    setIsKeepType(false);
                  }}
                  className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                >
                  <div className="mr-2 text-cBodyText font-normal capitalize">
                    {chip.transport_type}
                  </div>
                  <div className="cursor-pointer select-none">
                    <img src={iClose} alt="close-icon" />
                  </div>
                </div>
              )}
            </div>

            {/*e     start date */}
            <div className="pb-0" >
              {chip.start_date && (
                <div
                  onClick={() => {
                    let tempChip = { ...chip };
                    delete tempChip.start_date;
                    console.log("tempChip2: ", tempChip);
                    setChip(tempChip);
                    setIsKeepType(false);
                  }}
                  className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                >
                  <div className="mr-2 text-cBodyText font-normal capitalize">
                    {chip.start_date}
                  </div>
                  <div className="cursor-pointer select-none">
                    <img src={iClose} alt="close-icon" />
                  </div>
                </div>
              )}
            </div>

            {/*b     end date */}
            <div className="pb-0">
              {chip.end_date && (
                <div
                  onClick={() => {
                    let tempChip = { ...chip };
                    delete tempChip.end_date;
                    console.log("tempChip2: ", tempChip);
                    setChip(tempChip);
                    setIsKeepType(false);
                  }}
                  className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                >
                  <div className="mr-2 text-cBodyText font-normal capitalize">
                    {chip.end_date}
                  </div>
                  <div className="cursor-pointer select-none">
                    <img src={iClose} alt="close-icon" />
                  </div>
                </div>
              )}
            </div>

            {/*b     start time */}
            <div className="pb-0">
              {chip.start_time && (
                <div
                  onClick={() => {
                    let tempChip = { ...chip };
                    delete tempChip.start_time;
                    console.log("tempChip2: ", tempChip);
                    setChip(tempChip);
                    setIsKeepType(false);
                  }}
                  className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                >
                  <div className="mr-2 text-cBodyText font-normal capitalize">
                    {chip.start_time}
                  </div>
                  <div className="cursor-pointer select-none">
                    <img src={iClose} alt="close-icon" />
                  </div>
                </div>
              )}
            </div>

            {/*b     end time */}
            <div className="pb-0">
              {chip.end_time && (
                <div
                  onClick={() => {
                    let tempChip = { ...chip };
                    delete tempChip.end_time;
                    console.log("tempChip2: ", tempChip);
                    setChip(tempChip);
                    setIsKeepType(false);
                  }}
                  className="flex flex-row justify-between items-center bg-cLine cursor-pointer rounded-full px-3 py-1 my-1 mx-2 text-sm"
                >
                  <div className="mr-2 text-cBodyText font-normal capitalize">
                    {chip.end_time}
                  </div>
                  <div className="cursor-pointer select-none">
                    <img src={iClose} alt="close-icon" />
                  </div>
                </div>
              )}
            </div>

            {/*l     city */}
            <div className="flex items-center justify-start space-x-2.5 flex-wrap max-w-full">
              {chip.city &&
                chip.city.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      let { city } = chip; city = city.filter(city => city !== item); setChip({ ...chip, city });
                    }}
                    className="flex flex-row justify-between items-center space-x-2.5 bg-cLine cursor-pointer rounded-full text-sm min-w-max px-2.5 py-1 my-1.5"
                  >
                    <div className="text-cBodyText font-normal capitalize min-w-max">
                      {item}
                    </div>
                    <div className="cursor-pointer select-none">
                      <img src={iClose} alt="close-icon" />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : ""}

        {/* //! chips::end */}

        {/*//! request filter result list */}
        <div className="overflow-y-auto space-y-s10">
          {filterSearchList?.length ? (
            filterSearchList?.map((item, index) => (
              <FilterDetailsRow
                key={index}
                title={item.title}
                stops_count={item.stops_count}
                products_count={item.products_count}
                pickup_starts_in={item.pickup_starts_in}
                percentage={item.percentage}
                target_id={item.id}
                type={item.type}
                onClick={async () => {
                  await getCloudReqDetails({ request_id: item.id });
                  setShowCloudRequestModal(true);
                }}
              />
            ))
          ) :
            <>
              <EmptyMessage message={t("No Data Available!")} className="mb-0 mt-0" />
              <EmptyMessage message={t("Filter/Search Global Request.")} className="mt-0" />
            </>
          }
        </div>
      </div>
    </>
  );
};

export default FilterResult;
