import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Accordion from '../../../../../../Components/Accordion';
import { CloudRequestContext } from '../../../../../../Context/CloudRequestContext';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import Pickup from "../../TrackOnline/Details/Pickup";
import StopsListAccordion from "../../TrackOnline/Details/StopsListAccordion";
import PackageList from "../../TrackOnline/Lists/PackageList";
import PackageListBar from "../../TrackOnline/TitleBars/PackageListBar";

const PackageDetailsSp = () => {
  const {
    selectedPackageRequest,
    setSelectedPackage,
    setSelectedPackageRequest,
  } = useContext(TrackOnlineContext);
  const { shiftSummeryData, getShiftSummeryData } =
    useContext(CloudRequestContext);
  const { isShiftHistory, setClearDetailsPanel, setClearHistoryDetailsPanel } =
    useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [showParentAccordion, setShowParentAccordion] = useState(1);

  useEffect(() => {
    if (selectedPackageRequest) {
      getShiftSummeryData(selectedPackageRequest, parseInt(company_id));
      // console.log("API called");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPackageRequest]);

  useEffect(() => {
    setSelectedPackageRequest(null);
    setSelectedPackage(null);
    window.scrollTo(0, 0);
    setClearDetailsPanel(false);
    setClearHistoryDetailsPanel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isShiftHistory ? (
        <PackageListBar backLink={"/users/companies/details/" + company_id + "/shift-planner/shift-history"} />
      ) : (
        <PackageListBar backLink={"/users/companies/details/" + company_id + "/shift-planner"} />
      )}

      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <PackageList />

        <div className="col-span-12 lg:col-span-8 mt-5 gs-text-main-black">
          {selectedPackageRequest ? (
            <div>
              <div className="card min-h-[25rem] p-[20px]">
                <Accordion
                  key={0}
                  customOnClick={() => setShowParentAccordion(0)}
                  accordion_type="dependent"
                  isInitOpen={showParentAccordion === 0 ? true : false}
                  classNameHeader="text-[16px] font-semibold"
                  header={t("Pickup")}
                  body={<Pickup shiftSummeryData={shiftSummeryData} />}
                />
                <Accordion
                  key={1}
                  customOnClick={() => setShowParentAccordion(1)}
                  accordion_type="dependent"
                  isInitOpen={showParentAccordion === 1 ? true : false}
                  classNameHeader="text-[16px] font-semibold"
                  header={`${t("Stops")} (${shiftSummeryData.stops ? shiftSummeryData.stops.length : "0"
                    })`}
                  body={
                    <StopsListAccordion ShiftSummeryData={shiftSummeryData} />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="card min-h-[25rem] p-[20px]">
              <div className="flex justify-center items-center font-bold italic text-slate-400 min-h-[350px]">
                {t("Select an item to see details")}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PackageDetailsSp;
