import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuCompanyLow, kuCustomerLow, kuDashboardCards, kuDashboardLicense, kuDashboardMessage, kuReqNoBid, kuReqNoBidAction, kuReqOnGoing } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useDashboardStore = create((set) => ({

    dashboardCardData: {},
    setDashboardCardData: (data) => set({ dashboardCardData: data }),

    dashboardTopTableData: {},
    setDashboardTopTableData: (data) => {
        console.log('setDashboardTopTableData data', data);
        set({ dashboardTopTableData: data })
    },

    showNewMsgReply: false,
    setShowNewMsgReply: (data) => set({ showNewMsgReply: data }),

    showDeleteMessage: false,
    setShowDeleteMessage: (data) => set({ showDeleteMessage: data }),

    searchLoading: false,
    setSearchLoading: (data) => set({ searchLoading: data }),

    searchKey: null,
    setSearchKey: (data) => set({ searchKey: data }),

    message: 0,
    setMessage: (data) => set({ message: data }),

    dashTopTakeAmount: 10,
    setDashTopTakeAmount: (data) => set({ dashTopTakeAmount: data }),

    messageUserID: 0,
    setMessageUserID: (data) => set({ messageUserID: data }),

    searchLicenseKey: "",
    setSearchLicenseKey: (data) => set({ searchLicenseKey: data }),

    dashboardLicenseListTemp: [],
    setDashboardLicenseListTemp: (data) => set({ dashboardLicenseListTemp: data }),

    dashboardLicenseList: [],
    setDashboardLicenseList: (data) => set({ dashboardLicenseList: data }),

    dashboardMessageList: [],
    setDashboardMessageList: (data) => set({ dashboardMessageList: data }),

    contactMessageList: [],
    setContactMessageList: (data) => set({ contactMessageList: data }),

    is_searching: false,
    setIsSearching: (value) => set({ is_searching: value }),

}))

export default useDashboardStore;


export const getDashboardCards = async () => {
    const { setLoading } = useGeneralStore.getState();
    const { setDashboardCardData } = useDashboardStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuDashboardCards);
        console.log('getDashboardCards: ', res.data.data);

        if (res.data.success) setDashboardCardData(res.data.data);
        else Toastr(res.data.message);

        setLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setLoading(false);
        console.log('getDashboardCards: ', e);
    }
}



export const getDashboardLicense = async (type = "") => {
    const { setLoading } = useGeneralStore.getState();
    const { setDashboardLicenseList, setDashboardLicenseListTemp } = useDashboardStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuDashboardLicense, { params: { status: type, take: 500 } });
        console.log('getDashboardLicense: ', res.data.data);

        if (res.data.success) {
            setDashboardLicenseList(res.data.data.data);
            setDashboardLicenseListTemp(res.data.data.data);
        } else Toastr(res.data.message);

        setLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setLoading(false);
        console.log('getDashboardLicense: ', e);
    }
}


export const getDashboardMessage = async (statusType = "") => {
    const { setLoading } = useGeneralStore.getState();
    const { setDashboardMessageList } = useDashboardStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuDashboardMessage, { params: { status: statusType, is_seen: 0, take: 10 } });
        console.log('getDashboardMessage: ', res.data.data);

        if (res.data.success) {
            setDashboardMessageList(res.data.data.data);
        } else Toastr(res.data.message);
        setLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setLoading(false);
        console.log('getDashboardMessage: ', e);
    }
}


export const searchLicenseTable = (key) => {

    const {setIsSearching, dashboardLicenseListTemp, setDashboardLicenseList } = useDashboardStore.getState();
    setIsSearching(true)

    // eslint-disable-next-line array-callback-return
    const result = dashboardLicenseListTemp?.filter((item) => {
        if (
            item?.name.toLowerCase().includes(key.toLowerCase()) ||
            item?.license?.number.toLowerCase().includes(key.toLowerCase())
        ) {
            console.log(item);
            return item;
        }
    });

    setDashboardLicenseList(result);
    setTimeout(() => {
        setIsSearching(false)
      }, 500); 
};

// b:       Dashboard top part
export const getDashboardTop = async (queryType = "customer_low_activity", url = null) => {
    console.log('getDashboardTop: ', queryType);
    const { setLoading } = useGeneralStore.getState();
    const { setDashboardTopTableData, dashTopTakeAmount } = useDashboardStore.getState();

    try {
        setLoading(true);
        let res = {}
        if (queryType === "req_no_bid") { res = await axios.get(url ?? kuReqNoBid, { params: { take: dashTopTakeAmount } }); }
        else if (queryType === "customer_no_response") { res = await axios.get(url ?? kuReqNoBidAction, { params: { take: dashTopTakeAmount } }); }
        else if (queryType === "req_ongoing") { res = await axios.get(url ?? kuReqOnGoing, { params: { take: dashTopTakeAmount } }); }
        else if (queryType === "customer_low_activity") { res = await axios.get(url ?? kuCustomerLow, { params: { take: dashTopTakeAmount } }); }
        else if (queryType === "company_low_activity") { res = await axios.get(url ?? kuCompanyLow, { params: { take: dashTopTakeAmount } }); }

        if (res.data.success) {
            setDashboardTopTableData(res.data.data);
        } else Toastr(res.data.message);

        setLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setLoading(false);
        console.log('getDashboardTop: ', e);
    }
}



//       search Dashboard top part
export const searchDashboardTop = async (queryType = "", url = null, searchKey = "") => {
    const { setDashboardTopTableData, dashTopTakeAmount, setSearchLoading } = useDashboardStore.getState();
    try {
        setSearchLoading(true);
        let res = {}

        if (queryType === "req_no_bid") {
            if (url) {
                res = await axios.get(url, { params: { take: dashTopTakeAmount, search: searchKey } });
            } else {
                res = await axios.get(kuReqNoBid, { params: { take: dashTopTakeAmount, search: searchKey } });
            }
        }

        else if (queryType === "customer_no_response") {
            if (url) {
                res = await axios.get(url, { params: { take: dashTopTakeAmount, search: searchKey } });
            } else {
                res = await axios.get(kuReqNoBidAction, { params: { take: dashTopTakeAmount, search: searchKey } });
            }
        }

        else if (queryType === "req_ongoing") {
            if (url) {
                res = await axios.get(url, { params: { take: dashTopTakeAmount, search: searchKey } });
            } else {
                res = await axios.get(kuReqOnGoing, { params: { take: dashTopTakeAmount, search: searchKey } });
            }
        }

        else if (queryType === "customer_low_activity") {
            if (url) {
                res = await axios.get(url, { params: { take: dashTopTakeAmount, search: searchKey } });
            } else {
                res = await axios.get(kuCustomerLow, { params: { take: dashTopTakeAmount, search: searchKey } });
            }
        }

        else if (queryType === "company_low_activity") {
            if (url) {
                res = await axios.get(url, { params: { take: dashTopTakeAmount, search: searchKey } });
            } else {
                res = await axios.get(kuCompanyLow, { params: { take: dashTopTakeAmount, search: searchKey } });
            }
        }

        if (res.data.success) {
            setDashboardTopTableData(res.data.data);
        } else Toastr(res.data.message);

        setSearchLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setSearchLoading(false);
        console.log('searchDashboardTop: ', e);
    }
}

