import useChatStore, { deleteMessage, getChatList } from '../../../App/stores/chatStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonModal from '../../../Components/Modal/CommonModal';

const ChatDeleteModal = () => {

    const { showDeleteChatModal, setShowDeleChatModal,selectChat,chat_search,message_array,setSelectChat } = useChatStore();

    return (
        <div>
            <CommonModal
                showModal={showDeleteChatModal}
                setShowModal={setShowDeleChatModal}
                modalTitle={"Confirmation"}
                modalSpace={true}
                mainContent={
                    <>
                        <div className='text-center my-s20 font-fw500 text-fs16 text-cMainBlack'>Are you sure you want to delete the conversation?</div>
                        <div className='flex justify-center'>
                            <CommonButton
                                onClick={async () => {
                                    const success = await deleteMessage(selectChat?.id);
                                    if (success) {
                                        await getChatList(chat_search, false, false,message_array?.total+1);
                                        setSelectChat({});
                                        setShowDeleChatModal(false);
                                    }
                                }}
                                btnLabel='Delete' 
                                colorType='danger' 
                            />
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default ChatDeleteModal