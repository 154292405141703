import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { formatDate, formatTimeHourMinutes, getOrdinalNumber } from '../../../../../../Utility/UtilityFunctions';

const ShiftLogBookTable = ({ summaryData })=>{
    const {isShiftDetailsData} = useContext(ShiftPlannerContext);
    const status = isShiftDetailsData?.log_books_details?.shift_status;
    const { t } = useTranslation();

    return (
        <div className='w-full' >
            <div className='pb-4 text-base limadi-medium'>{("Logbook")}</div>
            <table className='w-full font-medium'>

                <LogTableRow breakIndex='Shift Starts'
                    timeFrame={isShiftDetailsData?.start_date && isShiftDetailsData?.start_time ?
                        `${formatDate(isShiftDetailsData?.start_date)}, ${formatTimeHourMinutes(isShiftDetailsData?.start_time)}` ?? '--, --' : '--, --'} />

                {(isShiftDetailsData?.status === "init") &&
                    ((isShiftDetailsData?.starts_in_raw <= -1 && isShiftDetailsData?.starts_in_time_unit === "days") ||
                    (isShiftDetailsData?.starts_in_raw <= -24 && isShiftDetailsData?.starts_in_time_unit === "hour")) ?
                    <LogTableRow breakIndex='Shift Time' timeFrame={"Expired"} />
                    : <>
                        {(status === 'ongoing' || status === 'break' || status === 'complete') &&
                            <LogTableRow breakIndex={t('Driver Started')}
                                timeFrame={isShiftDetailsData?.log_books_details?.shift_start ? formatDate(isShiftDetailsData?.log_books_details?.shift_start, true) ?? '--, --' : '--, --'} />}

                        {
                            isShiftDetailsData?.log_books?.length ? isShiftDetailsData?.log_books?.map((item, index) =>
                                <>
                                    <LogTableRow
                                        key={index}
                                        breakIndex={getOrdinalNumber((index + 1)) + ' Break  Starts'}
                                        timeFrame={item?.break_start ? formatDate(item?.break_start, true) ?? '--, --' : '--, --'}
                                    />
                                    {item?.break_end && <LogTableRow
                                        key={index + 500}
                                        breakIndex={getOrdinalNumber((index + 1)) + ' Break  Ends'}
                                        timeFrame={item?.break_end ? formatDate(item?.break_end, true) ?? '--, --' : '--, --'}
                                    />}
                                </>
                            )
                                : ""
                        }
                        {status === 'complete' && isShiftDetailsData?.ended_at?
                                        <LogTableRow breakIndex={t('Shift Ended')}
                                            timeFrame={isShiftDetailsData?.ended_at ?
                                                formatDate(isShiftDetailsData?.ended_at, true) ?? '--, --' : '--, --'} /> : ""}
                    </>
                }
            </table>
        </div>
    )
}

export default ShiftLogBookTable


export const LogTableRow = ({
    breakIndex = '1st Break',
    startsAt = 'Start/End: ',
    timeFrame = 'Start/End: 9. Mar. 2023, 08:50',
}) => {
    return (
        <>
            <tr className='border-b-[1px] border-cMainBlack text-cMainBlack text-sm'>
                {/* <td className='max-w-[180px] pl-0 truncate font-normal'>{breakIndex}</td> */}
                <td className='min-w-[80px] text-left'>{breakIndex}:</td>
                <td className='min-w-[80px] text-right'>{timeFrame}</td>
            </tr> <tr className='h-3'></tr>
        </>
    )
}
