/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import CommonInput from '../../Components/Input/CommonInput';
import CommonButton from '../../Components/Button/CommonButton';
import { LoginLogo } from '../../App/Utility/ImageImports';
import { useTranslation } from 'react-i18next';
import SplashScreen from '../../Components/Layout/SplashScreen';
import useGeneralStore from '../../App/stores/GeneralStore';

const Login = (props) => {
    const { t } = useTranslation();
    const { isAuth, loading, Login } = useContext(AuthContext)
    const [showFlash, setShowFlash] = useState(true)
    const { is_logged_out, setLoggedOut } = useGeneralStore();

    useEffect(() => {
        is_logged_out && setShowFlash(false);
        let tm;
        if (is_logged_out) tm = setTimeout(() => setLoggedOut(false), 3000);
        else tm = setTimeout(() => setShowFlash(false), 3000);

        return () => clearTimeout(tm);
    }, [])

    const [user_data, setUserData] = useState({
        "email": '',
        "password": ''
    });
    const onChange = (e) => { setUserData({ ...user_data, [e.target.name]: e.target.value }) }

    const onSubmit = (e) => {
        e.preventDefault()

        console.log(user_data);
        Login(user_data)
    }



    if (loading || showFlash) return <SplashScreen />

    if (isAuth) return <Navigate to="/" />;

    return (
        <div className="flex justify-center lg:px-[10vw] items-center w-full h-screen text-cMainBlack bg-cBGLiteGrey">
            <div className="lg:w-full space-y-[80px] lg:space-y-0 rounded-br10 grid lg:grid-cols-2 grid-cols-1 lg:items-center lg:space-x-[100px]">
                <div className="flex flex-col items-center drop-shadow-md">
                    <img src={LoginLogo} alt="" className="w-s200 lg:w-s250" />
                    <div className="font-poppins text-fs28 lg:text-fs36 font-bold text-center mt-5 mb-s10">
                        {t("Admin Sign In")}
                    </div>
                    <div className="text-fs18 lg:text-fs16 font-semibold text-center">
                        {t("Please sign in to continue")}
                    </div>
                </div>

                <form onSubmit={onSubmit} action="" className="w-[400px] rounded-br10 bg-white shadow-lg p-5">
                    <CommonInput
                        onChange={onChange}
                        value={user_data.email}
                        name="email"
                        label={t("email")}
                        type="email"
                        placeholder={t("Email...")}
                        required={true}
                    />
                    <div className="pt-5"></div>
                    <CommonInput
                        onChange={onChange}
                        value={user_data.password}
                        name="password"
                        label={t("password")}
                        type={"password"}
                        placeholder={t("Password...")}
                        required={true}
                        togglePasswordBtn={true}
                    />
                    <div className="flex justify-center pt-5">
                        <CommonButton
                            type="submit"
                            btnLabel={t("Sign In")}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login