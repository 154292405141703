import { t } from 'i18next';
import { useContext, useEffect } from "react";
import { FavoriteAddressContext } from '../../../../../../Context/FavoriteAddressContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';


const ChooseFavAddress = ({ fullAddressTitle, addressLat, addressLng, company_id }) => {
  const { setShowFavAddress } = useContext(TrackOnlineContext);
  //hg get fav address list with api call
  const { getFavoriteAddress, favoriteAddress } = useContext(FavoriteAddressContext);

  //   hg: make api call on component mount
  useEffect(() => {
    getFavoriteAddress(company_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="mt-5 max-h-[400px] overflow-y-auto mr-1">
      <div className="relative">
        <div className="">
          {favoriteAddress
            ? favoriteAddress.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  fullAddressTitle(item.address);
                  addressLat(item.lat);
                  addressLng(item.lng);
                  setShowFavAddress(false);
                }}
                className="bg-cMoreLiteGrey mt-[10px] py-3 px-5 rounded-lg hover:gs-line-inside-box cursor-pointer"
              >
                {item.address}
              </div>
            ))
            : t("Loading data...")}
        </div>
      </div>
    </div>
  );
};

export default ChooseFavAddress;
