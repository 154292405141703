import firebase from 'firebase'
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from '../stores/GeneralStore';
import useChatStore from '../stores/chatStore';

export const firebase_config = {
    apiKey: "AIzaSyCZQkO3jdTZi2KRR_YtG5ZVqr1NiLC3qx4",
    authDomain: "limadi-48272.firebaseapp.com",
    projectId: "limadi-48272",
    storageBucket: "limadi-48272.appspot.com",
    messagingSenderId: "496271938049",
    appId: "1:496271938049:web:2e0fabae2a457009082600",
    measurementId: "G-P9CS2CXYTF"
}

firebase.initializeApp({
    apiKey: "AIzaSyCZQkO3jdTZi2KRR_YtG5ZVqr1NiLC3qx4",
    authDomain: "limadi-48272.firebaseapp.com",
    projectId: "limadi-48272",
    storageBucket: "limadi-48272.appspot.com",
    messagingSenderId: "496271938049",
    appId: "1:496271938049:web:2e0fabae2a457009082600",
    measurementId: "G-P9CS2CXYTF"
})

const messaging = firebase.messaging();
let message = "you got a new message";

messaging && messaging.onMessage((payload) => {
    
    useChatStore.getState().setMessageNotification(true);
    useChatStore.getState().setFirebaseNewMessage(true);
    let x = localStorage.getItem('numOfUnreadNotification').toString();
    let y = parseInt(x);
    localStorage.setItem("numOfUnreadNotification", ++y);
    useGeneralStore.getState().setHasUnseenNotification();
    console.log('Message received. ', payload);
    // Toastr(payload.notification.body, 'success')
});

export default firebase