import { t } from 'i18next';
import CommonImage from '../../../../../Components/Image/CommonImage';
import { iRightArrow,iCar } from '../../../../../Utility/Sources';

function Car({ data, isSelected, onSelect }) {
  return (
    <div
      className="flex flex-row items-center justify-start "
      onClick={onSelect}
    >
      <div
        className={` w-[5px] h-[50px] mb-s10 ${isSelected ? "bg-cListIcon" : "bg-[#ffffff]"
          }`}
      ></div>

      <div className="flex flex-row h-[70px] w-full justify-between items-center bg-cLiteGrey my-[5px] ml-[15px] mr-[20px] rounded-br5 cursor-pointer">
        <div className="flex flex-row items-center justify-start">
     
          <div className="h-[50px] max-w-[50px] min-w-[50px] ml-s10 rounded-full overflow-hidden z-10 bg-white items-center flex flex-row justify-center ">
            <CommonImage
              src={data?.image}
              dummyImage={iCar}
              className={`${data.image == null ? "p-[0px] h-full w-full object-cover" : "h-full w-full object-cover"
                }`}
              alt=""
            />
          </div>
          <div className="flex flex-col pl-[10px]">
            <div className="font-semibold text-sm w-full md:w-[160px]  truncate">
              {data.name}
            </div>
            <div className="text-sm font-normal text-cTextBody">
              {data.car_license_plate_number}
            </div>
          </div>
        </div>
        <div className="flex items-center gs-text-body text-[10px]">
          <div className="pr-[8px] font-semibold">
            <span className="text-cPrimary">
              {data?.license_status === "pending" && t("Pending")}
            </span>
            <span className="text-cPrimary">
              {data?.license_status === "accepted" && t("Accepted")}
            </span>
            <span className="text-cPrimary">
              {data?.license_status === "expire_warning" && t("Expire soon")}
            </span>
            <span className="text-cSecondary">
              {data?.license_status === "active" && t("Active")}
            </span>
            <span className="text-cSecondary">
              {data?.license_status === "processing" && t("Processing")}
            </span>
            
            <span className="text-cRed">
              {data?.license_status === "rejected" && t("Rejected")}
            </span>
            <span className="text-cRed">
              {data?.license_status === "no_license" && ("No License")}
            </span>
            <span className="text-cRed">
              {data?.license_status === "expired" && t("Expired")}
            </span>
          </div>
          <img src={iRightArrow} alt="" className='pr-s10' />
        </div>
      </div>
    </div>
  );
}

export default Car;
