import { t } from 'i18next';
import { useContext } from "react";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

const ConfirmHold = ({ company_id, shift_id }) => {
  const { takeStopAction, setShowHoldModal, getRoutePlannerList, getTrackOnlineList, setSelectedStop, setCurrentRouteSelection } = useContext(TrackOnlineContext);

  return (
    <div className="">
      <div className="relative">
        <div className="py-5 text-center text-fs16">
          {t("Are you sure you want to make the stop On Hold?")}
        </div>

        <div className="flex flex-row justify-center">
          <CommonButton
            btnLabel={t("Yes")}
            onClick={async () => {
              await takeStopAction("hold", company_id);
              await setShowHoldModal(false);

              if (window.location.pathname.includes("route-planner")) {
                setSelectedStop(null);
                setCurrentRouteSelection(null);
                await getRoutePlannerList(shift_id, true);
                await getTrackOnlineList(false, company_id);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmHold;
