import axios from 'axios';
import create from 'zustand'
import { kuAdvancedFilterMessage, kuGetMessage, kuMessageSeenToggle, kuReplyMessage, kuWebMessage, kuWebMessageSeen } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useContactStore = create((set) => ({

    showReplyMessage: false,
    setShowReplyMessage: (showReplyMessage) => set({ showReplyMessage }),

    showFilterMessage: false,
    setShowFilterMessage: (showFilterMessage) => set({ showFilterMessage }),

    showFilterMessageWeb: false,
    setShowFilterMessageWeb: (showFilterMessageWeb) => set({ showFilterMessageWeb }),

    showDeleteMessage: false,
    setShowDeleteMessage: (showDeleteMessage) => set({ showDeleteMessage }),

    filterMode: false,
    setFilterMode: (filterMode) => set({ filterMode }),

    webFilterMode: false,
    setWebFilterMode: (webFilterMode) => set({ webFilterMode }),

    webMessageDetails: false,
    setWebMessageDetails: (webMessageDetails) => set({ webMessageDetails }),

    paginationUrl: "",
    setPaginationUrl: (paginationUrl) => set({ paginationUrl }),

    webPaginationUrl: "",
    setWebPaginationUrl: (webPaginationUrl) => set({ webPaginationUrl }),

    webSearchValue: "",
    setWebSearchValue: (webSearchValue) => set({ webSearchValue }),

    filterForm: {
        status: "", // user type [company, customer, driver], for all use "" empty string
        is_reply: null,
        is_seen: null,
        date: "", //[weekly, monthly, custom]
        custom_date: "",
        start_date: "",
        end_date: "",
    },
    setFilterForm: (filterForm) => set({ filterForm }),

    customDateShow: {
        status: "", // user type [company, customer, driver], for all use "" empty string
        is_reply: null,
        is_seen: null,
        date: "", //[weekly, monthly, custom]
        custom_date: "",
        start_date: "",
        end_date: "",
    },
    setCustomDateShow: (customDateShow) => set({ customDateShow }),

    filterFormWeb: {
        take: 10,
        is_reply: null,
        is_seen: null,
        date: "", //[weekly, monthly, custom]
        custom_date: "",
        start_date: "",
        end_date: "",
    },
    setFilterFormWeb: (filterFormWeb) => set({ filterFormWeb }),

    customDateShowWeb: {
        is_reply: null,
        is_seen: null,
        date: "", //[weekly, monthly, custom]
        custom_date: "",
        start_date: "",
        end_date: "",
    },

    setCustomDateShowWeb: (customDateShowWeb) => set({ customDateShowWeb }),


    messageUserID: 0,
    setMessageUserID: (messageUserID) => set({ messageUserID }),

    selectedMessageID: 0,
    setSelectedMessageID: (selectedMessageID) => set({ selectedMessageID }),

    contactMessageReply: "",
    setContactMessageReply: (value) => set({ contactMessageReply: value }),

    contactMessageList: [],
    setContactMessageList: (contactMessageList) => set({ contactMessageList }),

    contactWebMessageList: [],
    setContactWebMessageList: (contactWebMessageList) => set({ contactWebMessageList }),

    is_searching: false,
    setIsSearching: (value) => set({ is_searching: value }),

}))

export default useContactStore;


// green: Get Messages
export const HandleGetMessages = async (paginationUrl = "") => {

    const { setLoading } = useGeneralStore.getState();
    const { setContactMessageList, filterMode } = useContactStore.getState();
    try {
        console.log("filterMode OFF", filterMode);

        setLoading(true);

        let targetUrl = "";

        if (paginationUrl !== "") {
            // targetUrl = paginationUrl.indexOf("?");
            paginationUrl = JSON.stringify(paginationUrl);
            targetUrl = paginationUrl.slice(
                paginationUrl.indexOf("?"),
                paginationUrl.length - 1
            );
            targetUrl = kuGetMessage + targetUrl;
        } else {
            targetUrl = kuGetMessage;
        }


        const res = await axios.get(targetUrl, {
            params: {
                take: 10
            }
        })
        console.log('handleGetMessages: ', res.data);
        if (res.data.success) {
            setContactMessageList(res.data.data);
        } else {
            Toastr(res.data.message,);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
    }
}

// blue: reply Messages
export const HandleReplyMessages = async () => {
    const { setLoading } = useGeneralStore.getState();
    const { selectedMessageID, contactMessageReply, setContactMessageReply } = useContactStore.getState();
    try {
        console.log("contactMessageReply========", contactMessageReply, selectedMessageID);
        setLoading(true);
        const res = await axios.post(kuReplyMessage, {
            id: selectedMessageID,
            reply: contactMessageReply,
        })
        console.log('HandleReplyMessages: ', res.data);
        if (res.data.success) {
            HandleGetMessages();

            setContactMessageReply("")
            setLoading(false);
            Toastr(res.data.message, "success");
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
        return false;
    }
}

// p: Advanced Filter contact us
export const advancedFilterMessage = async (form, url) => {
    const { setLoading } = useGeneralStore.getState();
    const { setContactMessageList, filterMode } = useContactStore.getState();
    try {
        console.log("filterMode ON::::", filterMode);

        const body = {
            take: 10,

            status: form.status,
            is_reply: form.is_reply,
            is_seen: form.is_seen,
            date: form.date,
            start_date: form.start_date,
            end_date: form.end_date,
        }

        // r: remove the parameters that are empty for getting all data of that type
        if (body.status === "") delete body.status;
        if (body.is_reply === null) delete body.is_reply;
        if (body.is_seen === null) delete body.is_seen;
        if (body.date === "") delete body.date;
        if (body.start_date === "") delete body.start_date;
        if (body.end_date === "") delete body.end_date;

        // return 
        console.log("advancedFilterMessage========", body);

        setLoading(true);

        const res = await axios.get(url ?? kuAdvancedFilterMessage, { params: body })
        console.log('advancedFilterMessage: ', res.data);

        if (res.data.success) {
            setContactMessageList(res.data.data);
            setLoading(false);
            Toastr(res.data.message, "success");
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
        return false;
    }
}


// toggle read message
export const toggleReadMessage = async (message_id, url) => {

    const { setLoading } = useGeneralStore.getState();

    try {
        // return 
        console.log("MESSAGE_ID::::::", message_id, "  URL::::", url);
        setLoading(true);
        const res = await axios.post(kuMessageSeenToggle, { id: message_id })
        console.log('advancedFilterMessage: ', res.data);

        if (res.data.success) {
            HandleGetMessages(url);
            setLoading(false);
            Toastr(res.data.message, "success");
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
        return false;
    }
}



//App Mark a unread message
export const appMarkUnseenMessage = async (id) => {
    const { setLoading } = useGeneralStore.getState();
    const { paginationUrl, filterForm } = useContactStore.getState();
    try {
        setLoading(true);
        const res = await axios.post(kuWebMessageSeen, { id: id });
        console.log("markUnseenMessage res.data:::: ", res);

        if (res.data.success) {
            Toastr(res.data.message, "success");
            await HandleGetMessages(paginationUrl)
            // advancedFilterMessage
            setLoading(false);
            return true;
        } else {
            Toastr({ message: res.data.message, type: "error" });
            setLoading(false);
            return false;
        }

    } catch (error) {
        console.log("markUnseenMessage: ", error);
        Toastr({ message: "An error occurred!", type: "error" });
        setLoading(false);
        return false;
    }
};


//web Mark a unread message
export const webMarkUnseenMessage = async (id) => {
    const { setLoading } = useGeneralStore.getState();
    const { webPaginationUrl, filterFormWeb } = useContactStore.getState();
    try {
        setLoading(true);
        const res = await axios.post(kuWebMessageSeen, { id: id, type: "guest" });
        console.log("webMarkUnseenMessage res.data:::: ", res.data.message);

        if (res.data.success) {
            await Toastr(res.data.message, "success");
            await getWebMessages(webPaginationUrl, filterFormWeb)
            // advancedFilterMessage
            setLoading(false);
            return true;
        } else {
            Toastr({ message: res.data.message, type: "error" });
            setLoading(false);
            return false;
        }

    } catch (error) {
        console.log("webMarkUnseenMessage: ", error);
        Toastr({ message: "An error occurred!", type: "error" });
        setLoading(false);
        return false;
    }
};



// green: Get Messages
export const getWebMessages = async (url = "") => {
    const { setLoading } = useGeneralStore.getState();
    const { setContactWebMessageList, filterFormWeb, setIsSearching } = useContactStore.getState();
    try {
        setLoading(true);
        const res = await axios.get(url === "" ? kuWebMessage : url, {
            params:{  
                take: 10,
                is_reply: filterFormWeb.is_reply,
                is_seen: filterFormWeb.is_seen,
                date: filterFormWeb.date,
                custom_date: filterFormWeb.custom_date
            }
        })
        console.log('getWebMessages: ', res.data);
        if (res.data.success) {
            setContactWebMessageList(res.data.data);
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
        return false;
    }
}



// green: Get Messages
export const getWebMessagesSearch = async (url = "", searchValue="") => {
    const { setLoading } = useGeneralStore.getState();
    const { setContactWebMessageList, filterFormWeb, setIsSearching } = useContactStore.getState();
    try {
      setIsSearching(true) 

        const res = await axios.get(url === "" ? kuWebMessage : url, {params:{  take: 10,search: searchValue}})
        console.log('getWebMessages: ', res.data);
        if (res.data.success) {
            setContactWebMessageList(res.data.data);
             setIsSearching(false) 
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setIsSearching(false) 
        return false;
    }
}



// toggle read message
export const toggleReadWebMessage = async (message_id, url) => {
    const { setLoading } = useGeneralStore.getState();
    try {
        // return 
        console.log("MESSAGE_ID::::::", message_id, "  URL::::", url);
        setLoading(true);
        const res = await axios.post(kuMessageSeenToggle, { id: message_id, type: "guest" })
        console.log('toggleReadWebMessage: ', res.data);

        if (res.data.success) {
            getWebMessages(url);
            setLoading(false);
            Toastr(res.data.message, "success");
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr(("An error occurred!"));
        setLoading(false);
        return false;
    }
}


//contact Filter value clear 
export const contactFilterClear = () => {
    useContactStore.getState().setFilterForm({
        status: "",
        is_reply: null,
        is_seen: null,
        date: "",
        custom_date: "",
        start_date: "",
        end_date: "",
    });
}



//contact Filter value clear 
export const webContactFilterClear = () => {
    useContactStore.getState().setFilterFormWeb({
        is_reply: null,
        is_seen: null,
        date: "",
        custom_date: "",
        start_date: "",
        end_date: "",
    });
}