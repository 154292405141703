import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuAddFavoriteAddress, kuDeleteFavoriteAddress, kuGetFavoriteAddress, kuUpdateFavoriteAddress } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useFavAddressStore = create((set) => ({
    allFavAddressList: [],
    setAllFavAddressList: (allFavAddressList) => set({ allFavAddressList }),

    allFavAddressListTemp: [],
    setAllFavAddressListTemp: (allFavAddressListTemp) => set({ allFavAddressListTemp }),

    updateAddress: {},
    setUpdateAddress: (value) => set({ updateAddress: value }),

    addAddressSelected: "",
    setAddAddressSelected: (value) => set({ addAddressSelected: value }),

    addressKey: "",
    setSearchKey: (value) => set({ addressKey: value }),

    isAddAddress: true,
    setIsAddAddress: (value) => set({ isAddAddress: value }),

    showSomeModal: false,
    setShowSomeModal: (value) => set({ showSomeModal: value }),

}))

export default useFavAddressStore;

// get all fav address
export const getAllFavAddress = async (user_id = 0) => {
    console.log("FA ID::::", user_id);
    const { setLoading } = useGeneralStore.getState();
    const { setAllFavAddressList, setAllFavAddressListTemp,setAddAddressSelected,setUpdateAddress } = useFavAddressStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuGetFavoriteAddress, {
            params: {
                user_id: user_id
            }
        })

        console.log('getAllFavAddress: ', res.data);
        if (res.data.success) {
            setAllFavAddressList(res.data.data);
            setAllFavAddressListTemp(res.data.data);
            if(res.data.data?.length > 0){
                setAddAddressSelected(0);
                setUpdateAddress(res.data.data[0]);
            }
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}

// add fav address
export const addFavAddress = async (addForm, u_id) => {
    console.log("FA add form::::", addForm);
    const { setLoading } = useGeneralStore.getState();

    try {
        setLoading(true);

        const body = {
            "user_id": u_id,
            "title": addForm.title,
            "address": addForm.address,
            "lat": addForm.lat,
            "lng": addForm.lng,
        }

        const res = await axios.post(kuAddFavoriteAddress, body);

        console.log('addFavAddress: ', res.data);
        if (res.data.success) {
            getAllFavAddress(u_id);
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }

    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}



// update fav address
export const updateFavAddress = async (updateForm) => {
    console.log("FA update form::::", updateForm);
    const { setLoading } = useGeneralStore.getState();

    try {
        setLoading(true);

        const body = {
            "user_id": updateForm.user_id,
            "fav_id": updateForm.id,
            "title": updateForm.title,
            "address": updateForm.address,
            "lat": updateForm.lat,
            "lng": updateForm.lng
        }

        const res = await axios.post(kuUpdateFavoriteAddress, body);

        console.log('updateFavAddress: ', res.data);
        if (res.data.success) {
            getAllFavAddress(updateForm.user_id);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}



// delete fav address
export const deleteFavAddress = async (fav_id, u_id) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        setLoading(true);

        const body = {
            "user_id": u_id,
            "fav_id": fav_id
        }

        const res = await axios.post(kuDeleteFavoriteAddress, body);

        console.log('updateFavAddress: ', res.data);
        if (res.data.success) {
            getAllFavAddress(u_id);
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }

    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}


// blue: search fav-address

// export const searchFavoriteAddress = (event) => {

//     const { setSearchKey, allFavAddressListTemp, setAllFavAddressList } = useFavAddressStore.getState();

//     setSearchKey(event.target.value);
//     console.log(event.target.value);
//     // setDetailsState("add");

//     // eslint-disable-next-line array-callback-return
//     const result = allFavAddressListTemp.filter((item) => {
//         if (
//             item.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
//             item.address.toLowerCase().includes(event.target.value.toLowerCase())
//         ) {
//             console.log(item);
//             return item;
//         }
//     });

//     setAllFavAddressList(result);
// };

