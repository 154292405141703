import { t } from 'i18next';
import { useContext } from "react";
import SmLinearProgressBar from '../../../../../../Components/ProgressBar/SmLinearProgressBar';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iAdvanceReq, iSimpleReq } from '../../../../../../Utility/Sources';


const Request = ({ requestData }) => {
  const { selectedRequest, setSelectedRequest } =
    useContext(TrackOnlineContext);

  return (
    <div
      onClick={() => {
        setSelectedRequest(requestData.id);
      }}
      className="flex item-center justify-between w-full"
    >
      <div
        className={`w-[5px] my-[0px] ${selectedRequest === requestData.id ? "bg-cListIcon" : "bg-[#ffffff]"} mr-[10px]`}
      ></div>

      <div className="px-[10px] rounded-br5 h-[80px] w-full flex items-center justify-start cursor-pointer select-none bg-cLiteGrey">

        <div className='flex'>
          <div className="bg-white rounded-full w-s60 h-s60 flex justify-center items-center">
            {requestData.type === "advance" ? (
              <img src={iAdvanceReq} alt="advance" className='' />
            ) : (
              <img src={iSimpleReq} alt="simple" className='' />
            )}
          </div>
        </div>
        <div className="pl-s10 w-full flex flex-col items-start justify-start relative">
          <div className="text-[16px] font-semibold gs-text-main-black capitalize max-w-[14vw] truncate">
            {requestData.title ? requestData.title : "No title"}
          </div>
          <div className="text-sm gs-text-body max-w-[20vw] truncate py-0">
            {requestData.pickup_address ? requestData.pickup_address : "No address"}
          </div>
          <div className="w-full h-5">
            <SmLinearProgressBar
              type={requestData.type === "simple" && "warning"}
              percentage={requestData.percentage}
              textLabel={`${requestData.stops_complete_count}/${requestData.stops_count} ${t("Stops")} (${requestData.products_complete_count}/${requestData.products_count} ${t("Packages")})`}
              textLabelStyle="text-[12px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
