import React from 'react'
import { useState } from 'react';

const DashboardCard = ({ subTitle, brandTitle, number = null, onClick, isCompact = false }) => {
  const [color, setColor] = useState("text-cMainBlack");
  const [color2, setColor2] = useState("text-cTextBody");
  return (
    <div
      onClick={onClick} onMouseOver={() => {
        setColor("text-cWhite")
        setColor2("text-cWhite")
      }}
      onMouseOut={() => {
        setColor("text-cMainBlack")
        setColor2("text-cTextBody")
      }}

      className={`w-full relative flex flex-col justify-center items-center bg-white rounded-lg cursor-pointer hover:bg-cPrimary border border-cLine select-none ${isCompact ? "h-[93.4px]" : "h-s150"}`}
    >

      {brandTitle && <div className={`text-base font-fw600 pt-s10 ${color2}`}>{brandTitle}</div>}
      {number !== null && <div className={`font-fw600 ${isCompact ? "text-fs24 py-s10" : "text-fs40 py-s10"} ${color}`} >{number}</div>}
      {subTitle && <div className={`text-fs12 h-h24 font-fw500 pb-s10 ${color2}`} >{subTitle}</div>}
    </div>
  )
}

export default DashboardCard
