// import { useLocation } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUpdateLogger from '../../../../App/Hooks/useUpdateLogger';
import useCustomerStore, { getCustomerDetails } from '../../../../App/stores/CustomerStore';
import useFavAddressStore from '../../../../App/stores/FavAddressStore';
import BackLinks from '../../../../Components/Layout/BackLinks';
import { BaseUrlSrc } from '../../../../Utility/url';
import CommonDetailsPanel from '../../CommonDetailsPanel';
import { FavoriteCompanyContext } from '../../../../Context/FavoriteCompanyContext';
import RequestSection from '../../Common/RequestSection/RequestSection';
import Content from '../../Common/RequestSection/Content';
export default function CustomerDetailsOutlet() {

  const {
    setShowEditCustomerModal,
    setIsShowCustomerDeleteModal,
    setShowDeactivateCustomerModal,
    customerDetails,
    setCustomerDeleteID,
    setShowResetPasswordCustomer
  } = useCustomerStore();

  const { setSearchKey,setSelectedIndex,setFavCompanyDetails,setFavCompanyRatingInfo } = useContext(FavoriteCompanyContext);

  const { setIsAddAddress, setAddAddressSelected } = useFavAddressStore();

  useUpdateLogger("customerDetails", customerDetails);

  const navigateTo = useNavigate();

  useEffect(() => {
    getCustomerDetails(parseInt(window.location.pathname.slice(25)));

    window.scrollTo(0, 0);
  }, [])

  const { customer_id } = useParams();

  return (
    <div>
      <BackLinks
        linksArray={[
          { label: "customers", linkTo: "/users/customers" },
          { label: "customer Details", linkTo: "" },
        ]}
      />
      <CommonDetailsPanel
        customDeactivateFn={() => { setShowDeactivateCustomerModal(true) }}
        name={customerDetails?.name}
        email={customerDetails?.email}
        address={customerDetails?.address}
        phone={customerDetails?.phone}
        image={customerDetails?.image ? (customerDetails?.image) : ""}
        badge={customerDetails?.role}
        is_active={customerDetails?.is_active}
        id={customerDetails?.id}
        userData="customer"
        totalReq={customerDetails?.total_request}
        completedReq={customerDetails?.total_complete_request}
        memberSince={customerDetails?.joined}
        userId={customer_id}
        admin_access={customerDetails?.admin_access}
        optionsArray={[
          {
            title: "Favorite Company", 
            action: () => {
              // setDetailsState("fav-company");
              setSelectedIndex(null);
              setFavCompanyDetails({});
              setFavCompanyRatingInfo({})
              setSearchKey("")
              let u_id = parseInt(window.location.pathname.slice(25));
              navigateTo("/users/customers/details/" + u_id + "/favorite-company/");
            }
          },
          {
            title: "Favorite Address", action: () => {
              // setDetailsState("fav-address"); 
              let u_id = parseInt(window.location.pathname.slice(25));
              navigateTo("/users/customers/details/" + u_id + "/favorite-address/");
              setIsAddAddress(true);
              setAddAddressSelected("")
            }
          },
          { title: "Edit Account", action: () => { setShowEditCustomerModal(true); } },
          { title: "Reset Password", action: () => { setShowResetPasswordCustomer(true); } },
          // { title: "Deactivate Account", action: () => { setShowDeactivateCustomerModal(true); } },
          { title: "Delete Account", action: () => { setIsShowCustomerDeleteModal(true); setCustomerDeleteID(customer_id); } },
        ]}
      />

      <div className="mt-s20"></div>
      <RequestSection />

      <Content />

    </div>
  )

}
