import React from 'react'

function DefaultButton({
  label,
  onSubmit,
  type = "button",
  bgColor = "bg-[#F89818]",
  canSubmit = true,
  width = "w-36",
  className,
}) {
  return (
    <div className={className}>
      <button
        disabled={canSubmit ? false : true}
        onClick={onSubmit}
        type={type}
        className={`text-white ${bgColor} opacity-90 hover:opacity-100 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-0 mb-2 ${width}  ${canSubmit ? "cursor-pointer" : "cursor-not-allowed"}`} >
        {" "}
        {label}
      </button>
    </div>
  );
}

export default DefaultButton