import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../../Components/Button/CommonButton';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { DriverManagementContext } from '../../../../../Context/DriverManagementContext';

export default function DeleteDriverModal() {
    const { t } = useTranslation();
    const { showModal, setShowModal, deleteDriver } = useContext(DriverManagementContext);
    return (
        <>
            {/*hy delete modal */}
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={t("Delete Driver")}
                mainContent={
                    <>
                        <div >
                            <div className="pt-5 pb-5 text-fs16 text-center">
                                {t("Are you sure you want to delete this Driver ?")}
                            </div>

                            <div className="flex flex-row justify-between">
                                <CommonButton
                                    btnLabel={t("Cancel")}
                                    onClick={() => setShowModal(false)}
                                />

                                <CommonButton
                                    btnLabel={t("Delete")}
                                    colorType="danger"
                                    onClick={() => {
                                        deleteDriver();
                                        setShowModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}
