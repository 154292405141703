import Clamp from "react-multiline-clamp";
import { role } from '../../../App/Utility/const';
import CommonImage from '../../../Components/Image/CommonImage';
import { iDemoUser } from '../../../Utility/Sources';

const CommonChatBox = ({ user_type = "", isSeen = false, name = "", date_time = "", onClick = () => { }, image = "" }) => {

    return (
        <div onClick={onClick} className={`w-full cursor-pointer rounded-br4 flex space-x-2`}>
            <CommonImage alt="" src={image} dummyImage={iDemoUser} className='max-w-[44px] min-w-[44px] h-[44px] rounded-full' />
            <div className="space-y-[2px] w-full">
                <div className={`capitalize text-fs14 ${isSeen ? "text-[#89919E] font-fw400" : "text-cMainBlack font-fw600"}`}>
                    <Clamp withTooltip lines={1}>{name}</Clamp>
                </div>

                <div className={`flex justify-between w-full ${isSeen ? "text-[#89919E]" : "text-cMainBlack"}`}>
                    <div className='capitalize break-all text-cNero font-fw400 text-fs12'>{user_type === role.admin ? "Admin" : user_type}</div>
                    {date_time && <div className='max-w-[100px] min-w-[100px] flex items-end justify-end font-fw400 text-fs10'>{date_time}</div>}
                </div>

            </div>
        </div>
    )
}

export default CommonChatBox