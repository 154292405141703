import React from 'react'
import { BsClock } from 'react-icons/bs'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { formatDate } from '../../Utility/UtilityFunctions'
import NA from '../NA'

function NotificationDetails({ notification, selectedNotification }) {
    return (
        <>
            <div className="bg-white p-2 md:px-8 py-5 shadow rounded-xl">

                {notification.length === 0 ? (
                    <NA />
                ) : (
                    <div>
                        <div className="text-lg font-semibold text-[#333333]">
                            {selectedNotification.title}
                        </div>
                        <div className="text-[#595959] font-normal text-[14px]">
                            {selectedNotification.description}
                        </div>

                        <div className="flex justify-end items-center text-gray-500 text-xs">
                            <div className="flex justify-end items-center mr-4">
                                <FaRegCalendarAlt className="mr-1 text-sm" />
                                {formatDate(Date.parse(selectedNotification.created_date))}
                            </div>

                            <div className="flex justify-end items-center ">
                                <BsClock className="mr-1 text-sm" />
                                {selectedNotification.created_time}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default NotificationDetails