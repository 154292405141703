import React from "react";
import { addFAQ } from "../../../App/stores/SettingsStore";
import CommonModal from "../../../Components/Modal/CommonModal";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import { useState } from "react";
import CommonLabel from "../../../Components/Label/CommonLabel";
import { useTranslation } from "react-i18next";

const AddFAQModal = ({ show_add_faq_modal = false, setShowAddFaqModal }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ qus: '', ans: '', type: '' });

  const handleSubmit = (e) => {
    // console.log("form: ", form);
    e.preventDefault();
    addFAQ(form);
    setShowAddFaqModal(false);
    setForm({ qus: "", ans: "", type: "" });
  }

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setForm({ ...form, [name]: value });
    // console.log('form: ', form);
  }

  const modalClose = () => {
    setShowAddFaqModal(false);
    setForm({ qus: "", ans: "", type: "" });
  }

  return (
    <CommonModal
      showModal={show_add_faq_modal}
      setShowModal={setShowAddFaqModal}
      modalTitle={t("Add FAQ")}
      mainContent={
        <div className="pt-5 text-left">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-between">
              <div className="text-left w-full">
                <CommonInput
                  required={true}
                  className=""
                  label={t("Question")}
                  placeholder={t("Write a Question..")}
                  name="qus"
                  type="text"
                  value={form.qus}
                  onChange={handleChange}
                  max_input={80}
                />
              </div>
              <div className="px-s10"></div>
              <div className="w-full">
                <div className="text-gray-600 text-right w-full text-fs16 font-bold pb-s15">
                  <CommonLabel labelText={t("Select App Type")} required={true} />
                </div>
                <select
                  required={true}
                  onChange={handleChange}
                  name="type"
                  className={` 
                  w-full 
                 bg-gray-100 
                 capitalize 
                 cursor-pointer 
                 border-cInputBorder 
                 rounded-lg p-2.5
                 border-r-[10px]
                 border-r-transparent
                  ${form.type === "" ? "text-gray-400" : "text-black"}
                  `
                  }
                  border-r-transparent
                  aria-label={t("Select App Type")}
                >
                  <option className="text-fs16 text-black" value="" selected>{t("Select App Type")}</option>
                  <option className="text-fs16 text-black" value="company">Company</option>
                  <option className="text-fs16 text-black" value="customer">Customer</option>
                  <option className="text-fs16 text-black" value="driver">Driver</option>
                </select>
              </div>
            </div>

            <div className="pt-5"></div>

            <CommonInput
              label={t("Answer")}
              disablePaste={false}
              textarea={true}
              max_char_limit={500}
              is_show_char_limit={true}
              rows={4}
              name="ans"
              value={form.ans}
              onChange={handleChange}
              required={true}
              placeholder={t("FAQ Answer...")}
            />

            <div className="pt-5 flex flex-row justify-between">
              <CommonButton
                colorType="danger"
                btnLabel={t("Cancel")}
                type={"button"}
                onClick={() => modalClose()}
              />
              <CommonButton type="submit" btnLabel={t("add")} />
            </div>
          </form>
        </div>
      }
    />

  );
};

export default AddFAQModal;
