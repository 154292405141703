import React from "react";
import { iAttachment } from "../../Utility/Sources";

const UploadButton = ({
  title = "Select Attachment",
  onClick,
  subTitle,
  className,
}) => {
  return (
    <div
      onClick={onClick}
      className={`hover:gs-line-inside-box bg-cMoreLiteGrey gs-border-radius-5 text-sm font-semibold py-[15px] cursor-pointer select-none ${className}`}
    >
      <div className="flex justify-center items-center">
        <img className="pr-[10px]" src={iAttachment} alt="attachment-icon" />
        {title}
      </div>
      {subTitle ? (
        <div className="text-xs gs-text-button-placeholder font-normal text-center">
          {subTitle}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UploadButton;
