import axios from 'axios';
import React, {createContext, useReducer} from 'react'
import { company_details } from '../Utility/url';
import { companyReducer } from "./CompanyReducer";

export const CompanyContext = createContext();

const CompanyContextProvider = (props) => {

    const initialState = {
      is_loading_details: false,
      company_details: null
    }

    const [state, dispatch] = useReducer(companyReducer, initialState)

    const GetCompanyDetails = async (id) => {
      try {
        dispatch({ type: "CHANGE_VALUE", payload: {key: "company_details", value: null} });
        dispatch({ type: "CHANGE_VALUE", payload: {key: "is_loading_details", value: true} });
        const res = await axios.get(company_details, {params: {id}});
        console.log(res.data);
        if (res.data.success) {
          dispatch({ type: "CHANGE_VALUE", payload: {key: "company_details", value: res.data.data} });
          dispatch({ type: "CHANGE_VALUE", payload: {key: "is_loading_details", value: false} });
        } else {
          console.log(res.data.message);
        }
        dispatch({ type: "CHANGE_VALUE", payload: {key: "is_loading_details", value: false} });
      } catch (err) {
        console.log(err);
        dispatch({ type: "CHANGE_VALUE", payload: {key: "is_loading_details", value: false} });
      }
    };

    return (
      <CompanyContext.Provider
        value={{
          is_loading_details: state.is_loading_details,
          company_details: state.company_details,
          GetCompanyDetails,
        }}
      >
        {props.children}
      </CompanyContext.Provider>
    );
}

export default CompanyContextProvider;
