import Switch from '@mui/material/Switch';
import React from 'react'
import Logo from '../../../Components/Image/Logo'
import { alpha, styled } from '@mui/material/styles';

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#F89818',
        '&:hover': {
            backgroundColor: alpha('#F89818', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#F89818',
    },
}));

function Option  ({ label, source, className, hasSwitch, onClick, checked, type, onChange, isActive = false })  {
  return (
    <div
      className={`bg-gray-100 text-sm h-10 rounded-md flex justify-between 
        cursor-pointer  ${className} ${isActive && "text-cBrand"}`}
      onClick={onClick}
    >
      <div className="mt-[10px] pl-3"> {label} </div>
      {hasSwitch ? (
        <GreenSwitch checked={checked} onChange={() => onChange(type)} />
      ) : (
        <div className="mt-[10px] pr-3">
          {" "}
          <Logo source={source} />{" "}
        </div>
      )}
    </div>
  );
}

export default Option