import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuGetProfileDetails, kuUpdateProfile } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';


const { setLoading } = useGeneralStore.getState()

const useProfileStore = create((set) => ({
  user_info: {},
  setUserInfo: (data) => set({ user_info: data }),
}))




export const getProfileInfo = async () => {
  const { setUserInfo } = useProfileStore.getState()
  try {
    setLoading(true)

    const res = await axios.get(kuGetProfileDetails + '?is_admin=1');
    console.log("getProfileInfo: ", res.data);

    if (res.data.success) {
      setUserInfo(res.data.data);
    }
    else Toastr(res.data.message);

    setLoading(false)
  } catch (err) {
    Toastr(t("An error occurred!"));
    setLoading(false)
    console.log('getProfileInfo: ', err);
  }
}

export const updateProfileInfo = async (name, image = null, is_old_image = true) => {
  const { setUserInfo } = useProfileStore.getState()

  if (!name) {
    Toastr(t("Name field is required!"));
    return;
  }

  try {
    setLoading(true)

    let body = {}
    if (image && !is_old_image) {
      body = {
        name: name,
        image: image
      }
    } else body = { name: name }

    // console.log('body', body);

    const res = await axios.post(kuUpdateProfile + '?is_admin=1', body);
    console.log("updateProfileInfo: ", res.data);

    if (res.data.success) {
      setUserInfo(res.data.data);

    }
    else Toastr(res.data.message);

    setLoading(false)
    return;
  } catch (err) {
    Toastr(t("An error occurred!"));
    setLoading(false)
    console.log('updateProfileInfo: ', err);
    return;
  }
}



export default useProfileStore;
