import React from 'react'

/** 
* custom submit button 
**/
function CustomButton({ submit, selected, label, width = 'w-36' }) {
    return (
        <div className="mt-5 w-full flex justify-center cursor-pointer">
            <button
                onClick={submit}
                type="button"
                className={`capitalize mr-4 inline-flex justify-center px-4 py-2 ${width} text-fs16 font-medium text-white 
                ${selected ? 'bg-limadi' : 'bg-gray-400 '} border border-transparent rounded-md`}
                disabled={selected === null}
            >
                {label}
            </button>
        </div>
    )
}

export default CustomButton