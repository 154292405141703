import { getBase64 } from "../../Utility/UtilityFunctions";
import CommonInput from "../../Components/Input/CommonInput";
import CommonButton from "../../Components/Button/CommonButton";
import { useState } from "react";
import { createNewBanner } from "../../App/stores/BannerStore";
import CommonLabel from "../../Components/Label/CommonLabel";
// import Dropzone from "../../Components/Input/DropzoneImageUploader";
import { DropzoneCustom } from "../../Components/Dropzone/DropzoneCustom";

function AddBanner() {
  const [withImage, setWithImage] = useState("");
  const [clearImage, setClearImage] = useState(false);
  const [addFrom, setAddForm] = useState({
    title: "",
    app_type: "",
    // start_date: "",
    // end_date: "",
    description: "",
  });
  return (
    <>
      <div className="pt-5 px-5 mt-5 w-full bg-white rounded-md md:mt-0 md:w-1/2">

        <div className="font-semibold text-gray-700 text-fs28 font-poppins">Add Banner</div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className=""
        >
          <div className="pt-5">
            <DropzoneCustom
              showClearBtn={true}
              clearImage={clearImage}
              onUpload={(e) => {
                console.log(e);
                getBase64(e, (res) => {
                  // console.log(res);
                  setWithImage(res);
                })
              }}
            />

            {/* <DropzoneArea
              getPreviewIcon={handlePreviewIcon}
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => console.log('Files:', files)}
              filesLimit={1}
            /> */}
          </div>

          <div className="pt-5">
            <CommonInput
              label={"Name"}
              required={true}
              onChange={(e) => setAddForm({ ...addFrom, "title": e.target.value })}
              type={"text"}
              name={"title"}
              value={addFrom.title}
              placeholder={"Give a name"}
            />
          </div>

          {/* <div className="">
            <CommonInput
              required={true}
              label={"Start Date"}
              value={addFrom.start_date}
              onChange={(e) => {
                if (addFrom.end_date < e.target.value) {
                  setAddForm({ ...addFrom, "start_date": e.target.value, "end_date": "" });
                } else {
                  setAddForm({ ...addFrom, "start_date": e.target.value });
                }
              }
              }
              name={"start_date"}
              type={"date"}
            />
          </div>

          <div className="pt-5">
            <CommonInput
              required={true}
              label={"End Date"}
              value={addFrom.end_date}
              disabled={addFrom.start_date ? false : true}
              startDate={addFrom.start_date}
              onChange={(e) => setAddForm({ ...addFrom, "end_date": e.target.value })}
              name={"end_date"}
              type={"date"}
            />
          </div> */}

          <div className="mb-5">
            <div className="pt-5 pb-3">
              <CommonLabel text="text-fs16" labelText="For" required={true} />
            </div>
            <select
              required={true}
              value={addFrom.app_type}
              onChange={(e) => { setAddForm({ ...addFrom, "app_type": e.target.value }) }}
              name="app_type"
              className={`
               w-full 
              bg-gray-100 
              capitalize 
              cursor-pointer 
              border-cInputBorder 
              rounded-lg p-2.5
              border-r-[10px]
              border-r-transparent
              ${addFrom.app_type ==="" ? "text-gray-400": "text-black"}`}
              aria-label="Select App Type"
            >
              <option className="text-fs16 text-black" value="" selected>Select App Type</option>
              <option className="text-fs16 text-black" value="company">Company</option>
              <option className="text-fs16 text-black" value="customer">Customer</option>
              <option className="text-fs16 text-black" value="driver">Driver</option>
            </select>
          </div>


          <div className="pb-5">
            <CommonInput
              placeholder="Description"
              textarea={true}
              max_char_limit={255}
              is_show_char_limit={true}
              rows={10}
              label={"description"}
              value={addFrom.description}
              onChange={(e) => setAddForm({ ...addFrom, "description": e.target.value })}
            />
          </div>

          <div className="flex justify-center">
            <CommonButton
              type="submit"
              btnLabel={"Add"}
              onClick={async (e) => {
                console.log("ok addFrom", addFrom);
                if (
                  addFrom.title &&
                  // addFrom.start_date &&
                  // addFrom.end_date &&
                  addFrom.app_type
                ) {
                  let addSuccess = await createNewBanner(addFrom, withImage);
                  if (addSuccess) {
                    console.log("addSuccess::::", addSuccess);
                    setClearImage(true);
                    setWithImage("");
                    setAddForm({
                      title: "",
                      image: "",
                      app_type: "",
                      // start_date: "",
                      // end_date: "",
                      description: "",
                    });
                    e.preventDefault();
                  }
                }
              }}
              selected={true}
            />
          </div>

          <br />
        </form>
      </div>
    </>
  );
}

export default AddBanner;
