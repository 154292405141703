import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Accordion from '../../../../../../Components/Accordion';
import { CloudRequestContext } from '../../../../../../Context/CloudRequestContext';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

import Pickup from "../../TrackOnline/Details/Pickup";
import StopsListAccordion from "../../TrackOnline/Details/StopsListAccordion";
import RequestList from "../../TrackOnline/Lists/RequestList";
import RequestListBar from "../../TrackOnline/TitleBars/RequestListBar";

const RequestDetailsSp = () => {
  const { shiftSummeryData, getShiftSummeryData } =
    useContext(CloudRequestContext);

  const { selectedRequest, setSelectedRequest } =
    useContext(TrackOnlineContext);

  const {
    isShiftHistory,
    setClearDetailsPanel,
    setClearHistoryDetailsPanel,
    clearRequestDetailsPanel,
    // setClearRequestDetailsPanel,
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [parentAccordions, setParentAccordions] = useState(0);

  // hg api call for selected item
  useEffect(() => {
    if (selectedRequest) {
      getShiftSummeryData(selectedRequest, parseInt(company_id));
    }
    console.log("selectedRequest::::", selectedRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRequest]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setClearDetailsPanel(false);
    setClearHistoryDetailsPanel(false);

    if (!shiftSummeryData) {
      setSelectedRequest(null);
      
    }

    if (clearRequestDetailsPanel) {
      setSelectedRequest(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {isShiftHistory ? (
        <RequestListBar backLink={"/shift-planner/shift-history"} />
      ) : (
        <RequestListBar backLink={"/shift-planner"} />
      )}
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <RequestList />

        {selectedRequest ? (
          <div className="col-span-12 xl:col-span-7 mt-5 min-h-fit">
            <div className="card min-h-[25rem] max-h-fit p-[20px]">
              <div className="py-[0px] text-2xl font-bold capitalize">
                {shiftSummeryData?.title}
              </div>
              {shiftSummeryData?.type === "simple" ? (
                <Accordion
                  key={0}
                  customOnClick={() => setParentAccordions(0)}
                  isInitOpen={parentAccordions === 0 ? true : false}
                  accordion_type="dependent"
                  classNameHeader="text-[16px] font-semibold"
                  header={t("Simple")}
                  body={
                    <Pickup
                      key={Math.floor(Math.random() * 1000) + 1}
                      shiftSummeryData={shiftSummeryData}
                    />
                  }
                />
              ) : (
                <Accordion
                  key={0}
                  customOnClick={() => setParentAccordions(0)}
                  isInitOpen={parentAccordions === 0 ? true : false}
                  accordion_type="dependent"
                  classNameHeader="text-[16px] font-semibold"
                  header={t("Pickup")}
                  body={
                    <Pickup
                      key={Math.floor(Math.random() * 1000) + 1}
                      shiftSummeryData={shiftSummeryData}
                    />
                  }
                />
              )}

              {shiftSummeryData?.type === "simple" ? (
                ""
              ) : (
                <Accordion
                  key={1}
                  customOnClick={() => setParentAccordions(1)}
                  isInitOpen={parentAccordions === 1 ? true : false}
                  accordion_type="dependent"
                  classNameHeader="text-[16px] font-semibold"
                  header={`${t("Stops")} (${shiftSummeryData?.stops ? shiftSummeryData.stops.length : "0"
                    })`}
                  body={
                    <StopsListAccordion
                      key={Math.floor(Math.random() * 1000) + 1}
                      ShiftSummeryData={shiftSummeryData}
                    />
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <div className="col-span-12 lg:col-span-7 mt-5">
            <div className="card h-[25rem] flex justify-center items-center">
              <div className="font-semibold text-gray-500 italic">
                {t("Select an item to see details")}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestDetailsSp;
