import React from "react";
// icons
import iRatingStar from "../../Images/icons/rating_star.svg";
import iRatingStar2 from "../../Images/icons/rating_star2.svg";

const RatingIntType = ({ rate = 2 }) => {
    return (
        <div className="text-center">
            <div className="flex flex-row justify-center">
                <img
                    className="pr-1"
                    src={rate > 0 ? iRatingStar : iRatingStar2}
                    alt="rating star"
                />
                <img
                    className="pr-1"
                    src={rate > 1 ? iRatingStar : iRatingStar2}
                    alt="rating star"
                />
                <img
                    className="pr-1"
                    src={rate > 2 ? iRatingStar : iRatingStar2}
                    alt="rating star"
                />
                <img
                    className="pr-1"
                    src={rate > 3 ? iRatingStar : iRatingStar2}
                    alt="rating star"
                />
                <img
                    className=""
                    src={rate > 4 ? iRatingStar : iRatingStar2}
                    alt="rating star"
                />
            </div>
        </div>
    );
};

export default RatingIntType;
