import { t } from 'i18next';
import { Tooltip } from "@mui/material";
import { formatDate } from '../../../../../../Utility/UtilityFunctions';

const Pickup = ({ shiftSummeryData }) => {
  return (
    <div className="bg-cMoreLiteGrey p-[10px] rounded-[5px]">
      <div className="flex justify-between items-center mb-5">
        {shiftSummeryData.pickup_title ? (
          <>
            <div className="text-sm gs-text-icon-color">{t("Pickup Title")}</div>
            <div className="text-sm gs-text-body text-right font-semibold max-w-[10vw] truncate">
              {shiftSummeryData.pickup_title}
            </div>
          </>
        ) : (
          <>
            <div className="text-sm gs-text-icon-color">{t("Request Title")}</div>
            <div className="text-sm gs-text-body text-right font-semibold max-w-[10vw] truncate">
              {shiftSummeryData?.title}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Delivery Type")}</div>
        <div className="text-sm gs-text-body text-right font-semibold">
          {shiftSummeryData.type ? (
            shiftSummeryData.type
          ) : (
            <span className="text-gray-400">No delivery type</span>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Transportation Type")}</div>
        <div className="text-sm gs-text-body text-right font-semibold">
          {shiftSummeryData.transport_type ? (
            shiftSummeryData.transport_type
          ) : (
            <span className="text-gray-400">No transportation type</span>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Pickup Date")}</div>
        <div className="text-sm gs-text-body text-right font-semibold">
          {shiftSummeryData.pickup_date ? (
            formatDate(shiftSummeryData.pickup_date)
          ) : (
            <span className="text-gray-400">No date</span>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Pickup Start Time")}</div>
        <div className="text-sm gs-text-body text-right font-semibold">
          {shiftSummeryData.pickup_start_time ? (
            shiftSummeryData.pickup_start_time
          ) : (
            <span className="text-gray-400">No start time</span>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Pickup End Time")}</div>
        <div className="text-sm gs-text-body text-right font-semibold">
          {shiftSummeryData.pickup_end_time ? (
            shiftSummeryData.pickup_end_time
          ) : (
            <span className="text-gray-400">No end time</span>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Pickup Address")}</div>
        <Tooltip title={shiftSummeryData?.pickup_address}>
          <div
            onClick={() => {
              navigator.clipboard.writeText(shiftSummeryData?.pickup_address);
            }}
            className="text-sm gs-text-body text-right font-semibold max-w-[16vw] truncate cursor-pointer select-none"
          >
            {shiftSummeryData.pickup_address ? (
              shiftSummeryData.pickup_address
            ) : (
              <span className="text-gray-400">No address</span>
            )}
          </div>
        </Tooltip>
      </div>
      {shiftSummeryData?.delivery_address &&
        shiftSummeryData?.type === "simple" ? (
        <div className="flex justify-between items-center mb-5">
          <div className="text-sm gs-text-icon-color">{t("Delivery Address")}</div>
          <Tooltip title={shiftSummeryData?.delivery_address}>
            <div
              onClick={() => {
                navigator.clipboard.writeText(
                  shiftSummeryData?.delivery_address
                );
              }}
              className="text-sm gs-text-body text-right font-semibold max-w-[16vw] truncate cursor-pointer select-none"
            >
              {shiftSummeryData?.delivery_address ? (
                shiftSummeryData?.delivery_address
              ) : (
                <span className="text-gray-400">No delivery address</span>
              )}
            </div>
          </Tooltip>
        </div>
      ) : (
        ""
      )}

      <div className="flex flex-col items-start justify-start mb-5">
        <div className="text-sm gs-text-icon-color">{t("Comment")}</div>
        <div className="text-sm gs-text-body text-left pt-[10px]">
          {shiftSummeryData.pickup_comment ? (
            shiftSummeryData.pickup_comment
          ) : (
            <span className="text-gray-400">No comment</span>
          )}
        </div>
      </div>
      <div className="flex justify-between mb-5 text-sm">
        <div className="gs-text-icon-color">{t("Attachment")}</div>
        <div className="rounded-lg overflow-hidden">
          {shiftSummeryData.pickup_driver_attachment ? (
            <img
              src={shiftSummeryData.pickup_driver_attachment}
              alt="attachment"
              className="cursor-pointer"
            />
          ) : (
            <span className="text-gray-400 text-right">No attachment</span>
          )}
        </div>
      </div>
      <div className="-mb-5"></div>
    </div>
  );
};

export default Pickup;
