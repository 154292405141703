import { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import CommonInput from "../../Components/Input/CommonInput";


function PlayGround() {
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")

    return (
        <div className='h-[80vh] w-full  flex flex-col items-center justify-center bg-teal-100 p-10'>

            <div className="pb-10 w-full text-center font-semibold text-[36px] text-red-900">PLAY GROUND</div>

            <div className="py-5 text-fs18 font-semibold text-lime-800">
                <form>
                    <CommonInput
                        label={"PASSWORD"}
                        togglePasswordBtn={true}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                    />
                    <CommonInput
                        label={"AGAIN PASSWORD"}
                        togglePasswordBtn={true}
                        type="password"
                        onChange={(e) => setPasswordAgain(e.target.value)}
                        value={passwordAgain}
                    />

                    <div className="pb-5"></div>

                    <PasswordChecklist
                        rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => { }}
                    />
                </form>
            </div>


        </div>
    )
}

export default PlayGround