import { t } from 'i18next';
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShiftPlannerContext } from '../../../../../Context/ShiftPlannerContext';
import { iDemoUser, iMaintenance, iRightArrow } from '../../../../../Utility/Sources';
import { BaseUrlSrc } from '../../../../../Utility/url';
import { formatDate } from '../../../../../Utility/UtilityFunctions';
import CommonImage from '../../../../../Components/Image/CommonImage';

const Shift = ({ data }) => {
  const {
    getShiftPlannerDetails,
    setIsShiftDetailsID,
    isShiftDetailsID,
  } = useContext(ShiftPlannerContext);

  const { driver_id } = useParams();
  const navigateTo = useNavigate();

  return (

    <div className="flex flex-row items-center justify-start w-full h-full cursor-pointer"
      onClick={() => {
        console.log("calling onClick for driver shift details...");
        setIsShiftDetailsID(data.id);
        getShiftPlannerDetails(data.id, driver_id, true, data?.company_user_id);
        if (window.location.pathname !== ("/users/drivers/details/" + driver_id + "/shift-planner/shift-history")) {
          navigateTo("/users/drivers/details/" + driver_id + "/shift-planner")
        }
      }}
    >
      <div className={`w-s5 h-s50 mt-s10 ${isShiftDetailsID === data.id ? "bg-cListIcon" : "bg-white"}`}></div>
      <div className="flex justify-between w-full h-[75px] rounded-br5 bg-cLiteGrey mx-5 px-s10 py-5 mt-2 cursor-pointer select-none">
        <div className="flex items-center">
          {data.is_maintenance ? (
            <div className="flex items-center justify-center bg-white rounded-full h-s50 w-s50">
              <img src={iMaintenance} alt="" />
            </div>
          ) : data?.car_image ? (
            <div className="flex justify-start items-center rounded-full gs-list-icon-color min-w-[40px] max-w-[40px] h-[45px] overflow-hidden">
              <CommonImage
                src={data?.car_image === null ? iDemoUser : (BaseUrlSrc + data?.car_image)}
                alt="img-shift"
                className="object-cover w-full h-full"
              />
            </div>
          ) : (
            <div className="w-10 h-10 rounded-full bg-slate-600"></div>
          )}
          <div className="flex flex-col pl-2 space-y-s4">
            {data?.is_maintenance ? (
              <span className="text-sm font-[500] max-w-[100px] truncate">
                {t("In Maintenance")}
              </span>
            ) : (
              <span className="text-sm font-[500] max-w-[170px] truncate capitalize gs-list-title-black">
                {data?.car_name}
              </span>
            )}

            <span className="text-xs gs-text-body">
              {data?.number_plate}
            </span>

            <span className="text-xs gs-text-body">
              {formatDate(data?.start_date)} ({data?.start_time?.slice(0, -3)} - {data?.end_time?.slice(0, -3)})
            </span>

          </div>
        </div>
        <div className="flex items-center justify-center ">
          <div className="flex-col capitalize gs-text-body text-[10px] space-y-s10 justify-center items-end lg:hidden xl:flex 2xl:flex md:flex sm:flex flex">
            {/* <span>{formatDate(data?.start_date)}</span>

            <span>
              {data?.start_time?.slice(0, -3)} - {data?.end_time?.slice(0, -3)}
            </span> */}
            {data?.status === "init"? "Not Started":data?.status}
          </div>
          <img className="pl-2" src={iRightArrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Shift;
