import React from 'react'
import { iRightArrow } from '../../../../Utility/Sources'

function Address({ data, isSelected, onSelect, index }) {
  // useUpdateLogger("index", index);
  return (
    <div className='flex flex-row justify-start items-center' onClick={onSelect}>
      <div className={` w-[5px] h-[50px] my-[5px] ${isSelected ? "bg-cSelected" : 'bg-[#ffffff]'} `}></div>

      <div className=' flex flex-row h-[70px] w-full justify-between items-center bg-cLiteGrey my-[5px] ml-[15px] mr-[20px] rounded-br5 cursor-pointer'>
        <div className={`flex flex-col pl-s10 py-s10`}>
          <div className='font-semibold text-sm pb-s10'>{data.title}</div>
          <div className='font-normal text-sm text-cTextBody max-w-[40vw] lg:max-w-[10vw] xl:max-w-[15vw] 2xl:max-w-[17vw] truncate'>{data.address}</div>
        </div>

        <img src={iRightArrow} alt="" className='pr-s10' />

      </div>
    </div>
  )
}

export default Address