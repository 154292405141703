import { t } from 'i18next';
import axios from 'axios';
import create from 'zustand'
import { kuRatingAndReview, kuRequestDetails, } from '../../Utility/url';
import { Toastr, ToastrLoading, formatDate, formatTime } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';
import { k_data_set, role } from '../Utility/const';


const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useFavoriteCompaniesStore = create((set, get) => ({

    ratingValue: '0',
    setRatingValue: (value) => set({ ratingValue: value }),

    ratingAndReviewDetails: {},
    setRatingAndReviewDetails: (value) => set({ ratingAndReviewDetails: value }),

    showReviewModal: false,
    setShowReviewModal: (value) => set({ showReviewModal: value }),

    ratingAndReview: [],
    setRatingAndReview: (value) => set({ ratingAndReview: value }),

    tampRatingAndReview: [],
    setTampRatingAndReview: (value) => set({ tampRatingAndReview: value }),

    selectedCompanyId: '',
    setSelectedCompanyId: (value) => set({ selectedCompanyId: value }),

}))

//! api related functions


export const getRatingAndReview = async (company_user_id) => {

    const { setRatingAndReviewDetails, setRatingAndReview, setTampRatingAndReview } = useFavoriteCompaniesStore.getState();
    setLoading(true);
    if (!company_user_id) {
        Toastr({ message: t("Invalid Company!") })
        return;
    }
    console.log("company_user_id", company_user_id)

    try {
        const res = await axios.get(kuRatingAndReview, { params: { company_user_id: company_user_id } });
        console.log('getRatingAndReview: ', res?.data);

        if (res?.data?.success) {
            setRatingAndReviewDetails(res?.data?.data);
            setRatingAndReview(res?.data?.data?.reviews);
            setTampRatingAndReview(res?.data?.data?.reviews);

        } else {
            Toastr({ message: res?.data?.message })
        }

        setLoading(false);
    } catch (err) {
        console.log('getRatingAndReview: ', err);
        Toastr(({ message: t("An error occurred!") }));
        setLoading(false);
    }
}



export default useFavoriteCompaniesStore;