export const sType = { // Settings Type
    'CHANGE_PASSWORD': 'CHANGE_PASSWORD',
    'LANGUAGE': 'LANGUAGE',
    'TERMS_AND_CONDITIONS': 'TERMS_AND_CONDITIONS',
    'CONTACT_LIMADI': 'CONTACT_LIMADI',
    'FAQ': 'FAQ',
};

export const faDetailsType = { // favorite address Details Type
    'ADD': 'ADD', 
    'UPDATE': 'UPDATE',
    'NONE': 'NONE',
};