/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useCloudRequestStore from "../../../../../App/stores/CloudRequestStore";
import CommonButton from "../../../../../Components/Button/CommonButton";
import CommonInput from "../../../../../Components/Input/CommonInput";
import { CloudRequestContext } from "../../../../../Context/CloudRequestContext";
import CitySelector from "./CitySelector";
import MultiTransportSelector from "./MultiTransportSelector";
import RequestTypeSelector from "./RequestTypeSelector";

const AddFilter = () => {
  const { company_id } = useParams();
  const {
    setIsAddFilter,
    setIsManageFilter,
    addFilterName,
    setAddFilterName,
    saveNewlyCreatedFilter,
    isExpressType,
    isAdvancedType,
    setCurrentSelection,
    setFilterSearchList,
    setStartSearch,
    saveSuccessAdd,
    setSaveSuccessAdd,
    setAddFilterReqType,
    setAddFilterTransportId,
    resetAddFilterForm,
  } = useContext(CloudRequestContext);

  const cancelAddFilter = () => {
    setIsAddFilter(false);
    setIsManageFilter(false);
  };

  const [isClrReq, setIsClrReq] = useState(false);
  const [isClrTransport, setIsClrTransport] = useState(false);
  const [isClrCity, setIsClrCity] = useState(false);

  const { setSelectedTitles, selectedTitles } = useCloudRequestStore();

  const OnAddFilterClearFormData = () => {
    setAddFilterName("");
    setAddFilterReqType("");
    setAddFilterTransportId(0);

    setIsClrReq(true);
    setIsClrTransport(true);
    setIsClrCity(true);

    setSaveSuccessAdd(false);
  };

  useEffect(() => {
    if (saveSuccessAdd) {
      OnAddFilterClearFormData();
    }
  }, [saveSuccessAdd]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentSelection(null);
    setIsAddFilter(true);

    // setFilterSearchList({});
    setStartSearch(false);
    resetAddFilterForm()
  }, []);

  useEffect(() => {
    if (selectedTitles) {
      setSelectedTitles(null)
    }
  }, [])

  return (
    <div className="card">
      <div className="text-xl font-bold mb-5">{t("Add Filter")}</div>

      <CommonInput
        label="Title*"
        placeholder="Title for the filter"
        // required={true}
        name="title"
        type="text"
        value={addFilterName}
        onChange={(e) => {
          setAddFilterName(e.target.value);
        }}
      />
      <div className="pb-5"></div>

      <RequestTypeSelector
        request_type=""
        data_id={null}
        cleared={isClrReq}
        setCleared={setIsClrReq}
        finalValue={() => { }}
      />
      <div className="pt-5"></div>
      <MultiTransportSelector
        cleared={isClrTransport}
        setCleared={setIsClrTransport}
        finalValue={() => { }}
      />
      <div className="pt-5"></div>
      <CitySelector
        cleared={isClrCity}
        setCleared={setIsClrCity}
        finalValue={() => { }}
      />

      <div className="flex flex-row justify-between pt-5">
        <Link to={"/users/companies/details/" + company_id + "/global-request"}>
          <CommonButton
            btnLabel={t("Cancel")}
            colorType="danger"
            onClick={cancelAddFilter}
          />
        </Link>

        <div>
          <CommonButton
            btnLabel={t("Add")}
            onClick={() => {
              saveNewlyCreatedFilter(company_id);
            }}
            isDisabled={addFilterName && (isExpressType || isAdvancedType) ? false : true}
            colorType={addFilterName && (isExpressType || isAdvancedType) ? "primary" : "base"}
          />
        </div>

      </div>
    </div>
  );
};

export default AddFilter;
