import React from 'react'
import NotificationDropdown2 from '../Notification/NotificationDropdown2'
import ProfileDropdown from '../Profile/ProfileDropdown'

const Header = ({ isSidebarOpen }) => {


  return (
    <header className={`header bg-white shadow fixed h-15 w-full z-40 transition-all ${isSidebarOpen ? 'pl-64' : 'pl-20'}`}>
      <div className="py-4">
        <div className="mx-2 sm:mx-4 md:mx-8 lg:mx-10 xl:mx-12 flex justify-end items-center">

          <div className="flex justify-between items-center space-x-5">

            <NotificationDropdown2 />

            <ProfileDropdown />

          </div>
        </div>
      </div>
    </header>
  )
}

export default Header