import React, { useRef } from "react";
import { CloseIcon } from "../../App/Utility/ImageImports";
import Search from '../../Images/icons/search.svg'

/**
 * custom text input
 */
const SearchInput = ({ onClick, onChange, name, value, label, placeholder, type = 'search'}) => {

  return (
    <div className="">
      <div className="text-sm font-bold text-gray-600">{label}</div>
      <div className='absolute mt-[8px] ml-2'> <img src={Search} alt="" /> </div>
      <input
        onClick={onClick}
        onChange={onChange}
        type={type}
        name={name}
        value={value}
        className="ring-1 outline-none ring-[#D1D5DB] rounded-br5 px-4 py-2 pl-9 w-full text-sm bg-gray-100"
        placeholder={placeholder}
      />
    </div>
  )
}

export default SearchInput