import React from 'react'
import useBannerStore, { handleDeleteBanner } from '../../App/stores/BannerStore'
import CommonModal from '../Modal/CommonModal';
import CommonButton from './CommonButton';

export default function DeleteBanner() {
    const { showDeleteBanner, setShowDeleteBanner, setShowUpdateBanner } = useBannerStore();
    return (
        <CommonModal
            showModal={showDeleteBanner}
            setShowModal={setShowDeleteBanner}
            modalTitle="Confirmation"
            mainContent={
                <>
                    <div className="flex justify-center py-5">
                        Are you sure you want to delete this banner ?
                    </div>
                    <div className="flex justify-between items-center">
                        <CommonButton btnLabel='cancel' onClick={() => {
                            setShowDeleteBanner(false);
                        }} />
                        <CommonButton btnLabel='delete' colorType='danger' onClick={() => {
                            let delSuccess = handleDeleteBanner();
                            if (delSuccess) {
                                setShowUpdateBanner(false);
                                setShowDeleteBanner(false);
                            }
                        }} />
                    </div>
                </>
            }
        />
    )
}
