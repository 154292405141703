import React from 'react'
import LangCheckIcon from '../../../Images/icons/lang_check.svg';

function LanguageItem({ label, isActive, onClick }) {
    return (
        <div onClick={onClick} className='h-[36px] cursor-pointer'>
            <div className='flex flex-row justify-between mb-[5px]'>
                <li className={`${isActive ? 'font-semibold' : 'font-medium'}`}> {label} </li>
                {isActive ? <img src={LangCheckIcon} alt="lang check" /> : <div></div>}
            </div>
            <hr />

        </div>
    )
}

export default LanguageItem