import React from 'react'
import CommonImage from '../../../Components/Image/CommonImage'
import { formatDate } from '../../../Utility/UtilityFunctions'

function LowCompanyRow({ data, num }) {
    return (
        <tr className='hover:bg-cGridView'>
            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center px-s10'>
                {num}
            </td>

            <td className='border-r-[1px] border-b p-s10 text-left max-w-[150px] min-w-[150px] truncate'>
                <div className='flex items-center'>
                    <div className='mx-s5 max-w-[40px] min-w-[40px]'>
                        <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
                    </div>
                    <div className='capitalize items-center pl-s10 truncate'>{data?.name?data?.name:''}</div>
                </div>
            </td>

            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left'>
                <span className="pl-s10">{data?.email ? data?.email : ""}</span>
            </td>

            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left capitalize'>
                <span className="pl-s10">{data?.cvr ? data?.cvr : ""}</span>
            </td>

            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
                <span className="pl-s10">{data?.total_request ? data?.total_request : ""}</span>
            </td>

            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
                <span className="pl-s10">{data?.last_cancel_req ? data?.last_cancel_req : ""}</span>
            </td>

            <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
                <span className="pl-s10">{data?.status ? formatDate(data?.status) : ""}</span>
            </td>

        </tr>
    )
}

export default LowCompanyRow