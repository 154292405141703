import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getWebMessages, getWebMessagesSearch, webContactFilterClear } from '../../../App/stores/ContactStore'
import useContactStore, { advancedFilterMessage } from '../../../App/stores/ContactStore'
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined'
import TitleBar from '../../../Components/Common/TitleBar'
import CommonTable from '../../../Components/Table/CommonTable'
import NoDataRow from '../../../Components/Table/NoDataRow'
import { iFilterBtn, iFilterBtnWhite } from '../../../Utility/Sources'
import WebMessageItem from './WebMessageItem'
import { useDebounce } from 'use-debounce';
import { PageTitle } from '../../../Utility/UtilityFunctions'

export default function WebMessage() {

    const {
        setShowFilterMessageWeb,
        filterMode,
        setFilterMode,
        filterForm,
        setWebPaginationUrl,
        contactWebMessageList,
        webSearchValue,
        setWebSearchValue,
        webPaginationUrl,
        is_searching
    } = useContactStore();

    const { t } = useTranslation();

    const messageTableHeaders = [
        { index: 1, name: t("name") },
        { index: 2, name: t("subject") },
        { index: 3, name: t("message") },
        { index: 5, name: t("date") },
        // { index: 6, name: t("action") },
    ];

    const [searchValue] = useDebounce(webSearchValue, 500);

    useEffect(() => {
        PageTitle('Limadi | Contact-web');
        window.scrollTo(0, 0);
        // if (filterMode) setFilterMode(false);
        // getWebMessages(webPaginationUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        fetchWebMessage()
    }, [searchValue]);


    const fetchWebMessage = async () => {
        if (webSearchValue) {
            webContactFilterClear()
            await getWebMessagesSearch(webPaginationUrl, webSearchValue);
        } else {
            if (contactWebMessageList.current_page) {
                let urlToLoad = contactWebMessageList.path + "?page=" + contactWebMessageList.current_page;
                await getWebMessages(urlToLoad, webSearchValue);
            } else {
                await getWebMessages(webPaginationUrl, webSearchValue);
            }
        }
    }

    // console.log("messageTableHeaders",contactWebMessageList)

    return (
        <>
            <div>
                {/* <MessageTitleBar type={'submit'} title={t("contact us")} label={'Send Message'} /> */}
                <TitleBar
                    label={t("contact us")}
                    count={contactWebMessageList?.data?.length}
                    onClick={() => {
                        // if (filterMode) setFilterMode(false);
                        webContactFilterClear()
                        // setCustomDateShow(false);
                        getWebMessages();
                    }}
                    rightCornerComponent={
                        <CommonButtonOutlined
                        
                            onClick={() => {
                                setShowFilterMessageWeb(true);
                                setWebSearchValue("")
                            }}
                            leftIcon={<img src={iFilterBtn} alt="filter" />}
                            leftIconHover={<img src={iFilterBtnWhite} alt="filter-alter" />}
                            btnLabel="Filter"
                        />
                    }
                />
                <div className="justify-between space-x-0 w-full md:flex md:space-x-5">

                    {/* green: message table... */}
                    <CommonTable
                        search_loading={is_searching}
                        shoSearchBox={true}
                        searchValue={webSearchValue}
                        withClearSearch={true}
                        onSearchClear={() => setWebSearchValue("")}
                        searchOnChange={(e) => setWebSearchValue(e.target.value)}

                        headers={messageTableHeaders}
                        showingFrom={contactWebMessageList?.from}
                        showingTo={contactWebMessageList?.to}
                        totalResult={contactWebMessageList?.total}
                        items={contactWebMessageList?.data?.length > 0 ? contactWebMessageList.data?.map((message, index) => (<WebMessageItem key={index} data={message} />)) : <NoDataRow />}
                        paginationObject={contactWebMessageList}
                        showPagination={
                            contactWebMessageList?.last_page !== 1 ? true : false
                        }
                        paginationOnClick={(url) => {
                            console.log(url);
                            setWebPaginationUrl(url);
                            console.log("filterMode::::", filterMode);
                            if (filterMode === true) advancedFilterMessage(filterForm, url);
                            else getWebMessages(url);
                        }}
                    />
                </div>
            </div>
        </>
    )
}

