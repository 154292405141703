import { useContext, useEffect, useState } from "react";
import { TrackOnlineContext } from "../../../../../../Context/TrackOnlineContext";
import { iRightArrow } from "../../../../../../Utility/Sources";

const PackageItem = ({ item, index = 0 }) => {
  // to check if item is selected
  const {
    selectedPackage,
    setSelectedPackage,
    setSelectedPackageRequest,
    setIsOpenFIrstAccordion,
  } = useContext(TrackOnlineContext);
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    switch (item.stop_status) {
      case "delivered":
        setStatusText("complete");
        break;
      case "hold":
        setStatusText("on hold");
        break;

      case "not_delivered":
        setStatusText("not delivered");
        break;

      default:
        //
        break;
    }
  }, [item]);

  return (
    <div className="flex item-center justify-between w-full">
      <div
        className={`border-l-[5px] ${selectedPackage === item.id
            ? "border-l-cListIcon"
            : "border-l-[#ffffff]"
          } pr-[10px]`}
      ></div>

      <div
        onClick={() => {
          setIsOpenFIrstAccordion(item?.stop_title);
          setSelectedPackage(item.id);
          setSelectedPackageRequest(item.req_id);
          console.log("setSelectedPackage::", item.id);
          console.log("item.req_id::", item);
        }}
        className="flex justify-between items-center w-full bg-cGrayV2 p-[10px] rounded-[5px] cursor-pointer select-none h-[75px]"
      >
        <div>
          <div className="gs-text-main-black text-sm font-semibold max-w-[20vw] truncate">
            {item.text ? item.text : "No package"}
          </div>
          <div className="gs-text-body text-xs flex pt-[5px]">
            <div className="pr-[25px] max-w-[15vw] truncate">
              {item?.stop_title ?? "No title"}
            </div>
            <div className="capitalize">{statusText}</div>
          </div>
        </div>
        <div>
          <img src={iRightArrow} alt="right-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default PackageItem;
