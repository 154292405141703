/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const Dropzone = ({ onUpload, content = null, no_preview = false, isCsv = false }) => {
  // !This dropzone accepts multiple files
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {

    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));

    onUpload(acceptedFiles[0])
    console.log(acceptedFiles[0])
  }, [])

  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/gif': ['.gif'],
    'image/svg': ['.svg'],
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    accept: isCsv ? { 'text/csv': ['.csv'] } : accept,
  })


  return (
    <div {...getRootProps()} className='cursor-pointer'>
      <input {...getInputProps()} />
      {!content && <div className="flex justify-center items-center border-dashed border-2 border-gray-400 bg-gray-200 p-5 rounded-lg text-sm">
        {t("Drag & Drop or")}
        <button type="button" onClick={() => {
          console.log("OPEN !!!!!");
          open();

        }}
        //  className="text-blue-600 mx-2"
        >
          {t("Browse")}
        </button>
      </div>}

      {content && content}

      {!no_preview && <div className="grid grid-cols-2 gap-4">
        {files.map(file => (
          <img key={file.name} src={file.preview} className="shadow rounded-lg" alt="Not Found" />
        ))}
      </div>}
    </div>
  )
}

export default Dropzone