import React from 'react'
import { dateDiffCalendar, formatDate } from '../../../Utility/UtilityFunctions'

function LicenseHistoryRows({ data }) {

    //console.log("ggg",data)

    return (
        <tr className={`border-collapse border-b-[1px] border-cNmSelect`} >
            <th className='text-center font-normal p-s10 border-r-[1px] max-w-[80px] min-w-[80px]'>
                {data?.license?.number ? data?.license?.number : ""}
            </th>

            <th className='text-center font-normal p-s10 border-r-[1px] max-w-[80px] min-w-[80px]'>
                {data?.license?.price ? `DKK ${data?.license?.price?.toLocaleString("da-DK")}` : ""}
            </th>

            <th className='text-center font-normal p-s10 border-r-[1px] max-w-[80px] min-w-[80px]'>
                {data?.license?.duration ? data?.license?.duration : ""}
            </th>

            <th className='text-center font-normal p-s10 border-r-[1px]  max-w-[100px] min-w-[100px]'>
                {data?.start_date ? formatDate(data?.start_date) : ""}
            </th>

            <th className={`text-center font-normal  border-r-[1px] 
            ${data?.status === 'active' || data?.status === "expire_warning" ? 'p-s10' : 'py-s18  px-s10'}`}>

                {data?.status === 'active' || data?.status === "expire_warning" ? <div className='flex flex-col'>
                    <span className='text-fs14'>{formatDate(data?.end_date) ?? ''}</span>
                    {/* <span className='text-fs12'>{dateDiffCalendar(new Date(), data?.end_date)} {" remaining"}</span> */}
                </div> : <span className='text-fs14'>{formatDate(data?.end_date) ?? ''}</span>}
            </th>

            <th className='capitalize text-center font-normal p-s10 border-r-[1px]'>
                <span
                    className={`
                    ${data?.status === "active" || data?.status === "accepted" ?
                            "text-cSuccess"
                            : data?.status === "rejected" ? "text-cRed"
                                : data?.status === "no_license" ? "text-cRed" : "text-cPrimary"}
                `}
                >

                    {data?.status === "no_license" ? "no license" : data?.status === "expire_warning" ? "expire soon" : data?.status}
                    {/* {data?.status === "expire_warning" ? "about to expire" : data?.status} */}
                </span>
            </th>

        </tr>
    )
}

export default LicenseHistoryRows