import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useCompanyStore from "../../../../../App/stores/CompanyStore";
import LinearProgressBar from "../../../../../Components/ProgressBar/LinearProgressBar";
import { iInvitation, iInvitationFill } from "../../../../../Utility/Sources";


const FilterDetailsRow = ({
  title = null,
  stops_count = 7,
  products_count = 35,
  pickup_starts_in = 5,
  percentage = -1,
  target_id = -1,
  type = "simple",
  onClick = () => { },
}) => {
  const { company_id } = useParams();
  const { setCompanyID } = useCompanyStore();

  return (
    <>
      {title ? (
        <div
          className="flex items-center cursor-pointer bg-cListItem rounded-br5 px-2 pt-[10px] text-cBodyText"
          onClick={() => { onClick(); setCompanyID(company_id); }}
        >

          <div className="col-span-3 2xl:col-span-2 relative w-[3.7rem] h-[3.7rem] min-w-[3.5rem] bg-white rounded-full mx-auto my-auto items-center flex justify-center mr-2">

            <img src={type === 'simple' ? iInvitationFill : iInvitation} alt="" />

          </div>

          <div className="flex flex-col ml-2 w-full">
            <span className="font-bold capitalize">
              {title}
            </span>
            <span className="text-sm text-cBodyText">
              {stops_count} Stops ({products_count} Packages)
            </span>
            <div className="pt-[2px] h-[40px]">
              <LinearProgressBar
                percentage={percentage}
                textLabel={`Closed in ${pickup_starts_in}`}
                type={type}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className=" mx-auto font-semibold text-gray-500 italic">
          {("Sorry! No Data Found, Select another One")}
        </div>
      )}


    </>
  );
};

export default FilterDetailsRow;
