import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import { UploadIcon } from '../../../../../App/Utility/ImageImports';
import CommonButton from '../../../../../Components/Button/CommonButton';
import CommonImage from '../../../../../Components/Image/CommonImage';
import CommonInput from '../../../../../Components/Input/CommonInput';
import { CarManagementContext } from '../../../../../Context/CarManagementContext';
import { iCar, iInfo, NoImage } from '../../../../../Utility/Sources';
import { formatDate } from '../../../../../Utility/UtilityFunctions';
import useLicenseStore from '../../../../../App/stores/LicenseStore';
import useCarStore from '../../../../../App/stores/CarStore';
import CarAcceptModal from './Modals/CarAcceptModal';
import CarRejectModal from './Modals/CarRejectModal';
import EmptyMessage from '../../../../../Components/EmptyMessage';

function UpdateCar() {
  const {
    updateCar,
    setUpdateChange,
    updateCarFoo,
    setPopWarning,
    setOpenLicenseModal,
    getAllLicenseList,
    updatingDetails,
    setUpdatingDetails,
    dispatch,
    allCar,
    selectCarFoo,
    selectedIndex,
    setImgUpdated,
    setTargetCarId,
    setDoRenew,
    setTargetUserId
  } = useContext(CarManagementContext);

  const inputFile = useRef(null);
  const { company_id } = useParams();
  const [tempUpImage, setTempUpImage] = useState(null);

  const onButtonClick = () => {
    //hb `current` points to the mounted file input element
    if (!updatingDetails) {
      console.log("[EDIT-MODE]: Recording your click !!");
      inputFile.current.click();
    }
  };

  const { setShowCarRejectModal, setShowAcceptModal } = useCarStore();

  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      // console.log('RESULT', reader.result)
      dispatch({ type: "SET_UPDATE_CAR_IMAGE", payload: reader.result });
      setImgUpdated(true);
      // setIsFileChange(true);
      setTempUpImage(reader.result);
      console.log("reader.result: ", reader.result);
    };
    reader.readAsDataURL(file);
    // this.setState({ file }); /// if you want to upload latter
  };

  useEffect(() => {
    if (selectedIndex !== null) {
      // selectCarFoo(selectedIndex);
      //console.log("target data: ", allCar[selectedIndex]);
      // setTargetLicense(allCar[selectedIndex]);
      setTargetCarId(allCar[selectedIndex]?.id);
    }
    setImgUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCar, selectedIndex]);

  // console.log(updateCar);
  useEffect(() => {
    setUpdatingDetails(true);
    setTargetUserId(company_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log("SSS", updateCar)

  return (
    <>
      {updateCar?.id ? <form onSubmit={(e) => e.preventDefault()} className=" card">

        <div className={`flex flex-col items-center ${!updatingDetails ? "pt-10" : ""}`}>
          <div className="w-full text-2xl font-bold text-center mb-s15">
            Car Details
          </div>
          <div
            className={`flex justify-center items-center rounded-full w-[100px] h-[100px] overflow-hidden bg-cGrayV2 relative ${updatingDetails ? "" : ""}`}
          >
            <input
              onChange={onChangeFile.bind(this)}
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
            />
            {!updatingDetails ? (
              tempUpImage ? (
                <img
                  // onClick={onButtonClick}
                  src={tempUpImage}
                  className="object-cover w-full h-full"
                  alt="Car logo LOCAL"
                />
              ) : (
                <CommonImage
                  // onClick={onButtonClick}
                  src={updateCar?.image}
                  dummyImage={NoImage}
                  className={`${updateCar?.image ? "h-full w-full object-cover" : "p-[20px] h-full w-full object-cover"
                    }`}
                  alt="Car logo SERVER"
                />
              )
            ) : (
              <div className="">
                <CommonImage
                  // onClick={onButtonClick}
                  src={updateCar?.image}
                  dummyImage={iCar}
                  className={`${updateCar?.image ? "h-full w-full object-cover" : "p-[0px] h-full w-full object-cover"
                    }`}
                  alt={`car-logo-api`}
                />
              </div>
            )}
            <div
              onClick={onButtonClick}
              className={`gs-img-overlay w-[100px] h-[40px] absolute bottom-0 left-0 opacity-0 ${!updatingDetails ? "cursor-pointer hover:opacity-100" : ""}`}
            >
              <img
                src={UploadIcon}
                alt="upload a pic"
                className="w-[34px] mx-auto pt-1 z-20"
              />
            </div>
          </div>
          <div className="text-[16px] font-semibold pt-4 capitalize">
            <span className="text-cPrimary">
              {updateCar?.licenseStatus === "pending" && t("Pending")}
            </span>
            <span className="text-cPrimary">
              {updateCar?.licenseStatus === "accepted" && t("Accepted")}
            </span>
            <span className="text-cPrimary">
              {updateCar?.licenseStatus === "expire_warning" && t("Expire soon")}
            </span>
            <span className="text-cSecondary">
              {updateCar?.licenseStatus === "active" && t("Active")}
            </span>
            <span className="text-cSecondary">
              {updateCar?.licenseStatus === "processing" && t("Processing")}
            </span>

            <span className="text-cRed">
              {updateCar?.licenseStatus === "rejected" && t("Rejected")}
            </span>
            <span className="text-cRed">
              {updateCar?.licenseStatus === "no_license" && t("No License")}
            </span>
            <span className="text-cRed">
              {updateCar?.licenseStatus === "expired" && t("Expired")}
            </span>
          </div>
          {/* 
          {
            updateCar.licenseStatus === "active" ||
              updateCar?.licenseStatus === "expire_warning" ? (
              <CommonDateList title={"Expire Date"} date={formatDate(updateCar.licenseEnd)}
                onClick={() => setPopWarning(true)}
                icon={updateCar?.licenseStatus === "expire_warning" ? true : false}
              />
            ) : (
              ""
            )
          } */}
          <div className='mt-1'></div>
          <>
            {updateCar.licenseStatus !== "no_license" && <CommonDateList title={"License Active On"}
              date={updateCar.license_start ? formatDate(updateCar?.license_start) : "No start date"}
              onClick={() => setPopWarning(true)}
              icon={false}
            />}

            {(updateCar.license_end) && <CommonDateList title={"License Expire On"}
              date={updateCar.license_end ? formatDate(updateCar?.license_end) : "No end date"}
              onClick={() => setPopWarning(true)}
              icon={false}
            />}
          </>
        </div>



        <div className="flex items-center justify-between pt-5">
          {/* disabled if updatingDetails === true */}
          <CommonInput
            // required={true}
            label={t("Car Name")}
            placeholder={t("Car Name")}
            name="name"
            max_input={55}
            value={updateCar?.name}
            onChange={setUpdateChange}
            disabled={updatingDetails}
          />

          {/* disabled if updatingDetails === true */}
          <CommonInput
            // required={true}
            label={t("License Number")}
            placeholder={t("License Number")}
            name="licenseNumber"
            value={updateCar?.licenseNumber}
            onChange={setUpdateChange}
            disabled={updatingDetails}
            maxLength={6}
            max_input={7}
          />
        </div>

        <div className="pb-5"></div>
        {/* disabled if updatingDetails === true */}
        <CommonInput
          // required={true}
          textarea={true}
          max_char_limit={500}
          is_show_char_limit={true}
          label={updatingDetails ? t("Car Specification") : t("Car Specification")}
          placeholder={t("Enter your car specification")}
          name="comment"
          max_input={255}
          value={updateCar?.comment}
          onChange={setUpdateChange}
          disabled={updatingDetails}
        />

        <div className="flex flex-row items-center justify-between pt-5">
          {/* <CommonButton
          btnLabel={t("Delete")}
          colorType="danger"
          onClick={() => setPopDelWarning(true)}
        /> */}

          {/* {!updatingDetails ? (
          <CommonButton
            type='submit'
            btnLabel={t("Save")}
            colorType={updateCar?.name && updateCar?.licenseNumber ? "primary" : "base"}
            isDisabled={updateCar?.name && updateCar?.licenseNumber ? false : true}
            onClick={() => {
              updateCarFoo("update", parseInt(company_id));
              setUpdatingDetails(true);
            }}
          />
        ) : updateCar?.licenseStatus === "rejected" ||
          updateCar?.licenseStatus === "expired" ||
          updateCar?.licenseStatus === "no_license" ? (
          <CommonButton
            type='submit'
            width='w-[180px]'
            btnLabel={
              updateCar?.licenseStatus === "expire_warning" ||
                updateCar?.licenseStatus === "expired"
                ? t("Renew License")
                : t("Apply For License")
            }

            onClick={() => {
              getAllLicenseList();
              setOpenLicenseModal(true);
              if (
                updateCar?.licenseStatus === "expire_warning" ||
                updateCar?.licenseStatus === "expired"
              ) {
                setDoRenew(true);
              } else {
                setDoRenew(false);
              }
            }}
          />
        ) : (
          ""
        )} */}

          {updateCar?.licenseStatus === "rejected" ||
            updateCar?.licenseStatus === "expire_warning" ||
            updateCar?.licenseStatus === "expired" ||
            updateCar?.licenseStatus === "no_license" ||
            updateCar?.licenseStatus === "rejected"
            ?
            <CommonButton
              type='submit'
              width='w-[180px]'
              btnLabel={
                updateCar?.licenseStatus === "expire_warning" ||
                  updateCar?.licenseStatus === "expired" ? t("Renew License")
                  : updateCar?.licenseStatus === "no_license" ? t("Apply For License") :
                    updateCar?.licenseStatus === "rejected" && t('Apply Again')
              }

              onClick={() => {
                getAllLicenseList();
                setOpenLicenseModal(true);
                if (
                  updateCar?.licenseStatus === "expire_warning" ||
                  updateCar?.licenseStatus === "expired"
                ) {
                  setDoRenew(true);
                } else {
                  setDoRenew(false);
                }
              }}
            /> : ""}

          <div className={`flex justify-between pt-5 w-full ${updateCar?.licenseStatus === "pending" ? "flex-row" : "flex-row-reverse"}`}>

            {updateCar?.licenseStatus === "pending" &&
              <CommonButton btnLabel='Reject' colorType='danger' onClick={() => { setShowCarRejectModal(true) }} />}
            {
              updateCar?.licenseStatus === "pending" ?
                <CommonButton btnLabel='Accept' onClick={() => setShowAcceptModal(true)} />
                : updateCar?.licenseStatus === "processing" ?
                  <CommonButton btnLabel='Activate' onClick={() => setShowAcceptModal(true)} />
                  : ""
            }

          </div>
          <CarAcceptModal company_id={company_id} />
          <CarRejectModal company_id={company_id} />
        </div>
      </form>
        :
        <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
          <EmptyMessage message={t('Please select a car to see details.')} />
        </div>
      }
    </>
  );
}

export default UpdateCar;

export const CommonDateList = ({ date, onClick, icon = false, title = "" }) => {
  return (
    <>
      <div className="flex items-center pb-1 font-semibold">
        <span>{title}: {date}</span>
        <div
          onClick={onClick}
          className="pl-[10px] cursor-pointer select-none"
        >
          {icon && (
            <img src={iInfo} alt={"info-circle-icon"} />
          )}
        </div>
      </div>
    </>
  )
}
