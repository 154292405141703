import { t } from 'i18next';
import { useState } from 'react';
import Image from '../../../../../../Components/Image/Image';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { formatDate } from '../../../../../../Utility/UtilityFunctions';
import ProductListModal from './ProductListModal';

export default function StopDetailsModal({ show_modal, setShowModal, index = null, products = [], stop = null }) {
  const [showProductListModal, setShowProductListModal] = useState(false);

  return (
    <>
      <CommonModal
        showModal={show_modal}
        setShowModal={setShowModal}
        scrollModal={false}
        modalTitle="Stop Details"
        mainContent={
          <>
            <div className='border-b-2 pb-2.5 mb-5 text-xl font-bold'>{t("Stop")} {index !== null && index + 1}</div>

            <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Title")}</div>
              <div className="col-span-2 text-lg">{index !== null && stop?.title ? stop.title : <span className="text-gray-400">No titl</span>}</div>
            </div>

            {stop?.date && <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Delivery Date")}</div>
              <div className="col-span-2 text-lg">{index !== null && stop?.date ? formatDate(stop.date) : <span className="text-gray-400">No delivery dat</span>}</div>
            </div>}

            {stop?.start_time && <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Delivery Time")}</div>
              <div className="col-span-2 text-lg">{index !== null && stop?.start_time ? stop.start_time : <span className="text-gray-400">No delivery time</span>}</div>
            </div>}

            <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Delivery Address")}</div>
              <div className="col-span-2 text-lg">{index !== null && stop?.address ? stop.address : <span className="text-gray-400">No delivery address</span>}</div>
            </div>
            <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Total Products")}</div>
              <div className="col-span-2 text-lg">
                {index !== null && stop?.products ? stop?.products?.length : "0"}
                <span onClick={() => setShowProductListModal(true)} className='text-limadi font-bold cursor-pointer hover:border-b border-limadi ml-5'>
                  View
                </span>
              </div>
            </div>
            <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Comment")}</div>
              <div className="col-span-2 text-lg">{index !== null && stop?.comment ? stop.comment : <span className="text-gray-400">No comment</span>}</div>
            </div>
            <div className="grid grid-cols-5 gap-2 md:gap-8 2xl:gap-12 mb-5">
              <div className="col-span-3 text-lg text-gray-500">{t("Attachment")}</div>
              <div className="col-span-2 rounded-lg overflow-hidden w-36">
                <Image src={stop?.attachment} className="cursor-pointer" />
              </div>
            </div>

            {/* Product List Modal */}
            <ProductListModal showModal={showProductListModal} setShowModal={setShowProductListModal} products={products} />
          </>

        } />
    </>
  )
}
