import React, { useEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation } from "react-router-dom";

const Layout = (props) => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const [width, setWidth] = useState(window.innerWidth);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth))
    // console.log(width);

    if (width <= 1200) setIsSidebarOpen(false)
    else setIsSidebarOpen(true)

  }, [width])


  return (
    <div className="flex flex-row min-h-screen text-gray-800 bg-cPress">
      <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <main className="flex flex-col items-end flex-grow w-full transition-all duration-150 ease-in main">
        <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
        <div className={`w-full ${isSidebarOpen ? 'pl-64' : 'pl-20'} pt-[4.5rem]`}>
          <div className={`${location.pathname !== '/chat' ? "m-2 sm:m-4 md:m-8 lg:m-12 xl:my-12 xl:mx-12" :"mt-2 sm:mt-4 md:mt-8 lg:mt-12 xl:mt-12 mx-2 sm:mx-4 md:mx-8 lg:mx-12 xl:mx-12"} `}>
            {props.children}
          </div>
        </div>
      </main>
    </div>
  )
}

export default Layout