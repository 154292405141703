/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonInput from '../../../../../../Components/Input/CommonInput';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { iMaintenance, iShare } from '../../../../../../Utility/Sources';
import { DistanceIntoKM, TimeIntoHours } from '../../../../../../Utility/UtilityFunctions';
import ShiftPlannerBar from "../Bars/ShiftPlannerBar";
import ShiftPlannerList from "../ShiftLists/ShiftPlannerList";

const HistoryDetails = ({ title, reg_id, date, time_schedule, image_path }) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const {
    isShiftDetailsData,
    shortMonth,
    setShortMonth,
    shortDateNo,
    setShortDateNo,
    getShiftHistoryList,
    shareShiftEmail,
    SetShareShiftEmail,
    setClearDetailsPanel,
    setIsShiftDetailsData,
    clearHistoryDetailsPanel,
  } = useContext(ShiftPlannerContext);

  const { driver_id } = useParams()

  useEffect(() => {
    if (isShiftDetailsData?.start_date) {
      let d_date = new Date(isShiftDetailsData?.start_date.toString());
      const month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      setShortMonth(month[d_date.getMonth()]);
      setShortDateNo(d_date.getDate());
    }
  }, [isShiftDetailsData]);

  useEffect(() => {
    console.log("isShiftDetailsData:::", isShiftDetailsData);
    if (clearHistoryDetailsPanel) {
      getShiftHistoryList(driver_id, true);
      setIsShiftDetailsData({});
    }

    setClearDetailsPanel(true);
  }, []);

  return (
    <>
      <CommonModal
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        modalTitle={t("Share Shift History")}
        mainContent={
          <div>
            <div className="pt-5 text-left">
              <CommonInput
                type="email"
                label={t("Email")}
                placeholder={t("Enter email address..")}
                value={shareShiftEmail}
                onChange={(e) => {
                  SetShareShiftEmail(e.target.value);
                }}
              />
            </div>

            <div className="pt-5 flex flex-row justify-center">
              <CommonButton
                btnLabel={t("Submit")}
                onClick={() => {
                  setShowShareModal(false);
                }}
                isDisabled={!shareShiftEmail ? true : false}
                colorType={shareShiftEmail ? "primary" : "base"}
              />
            </div>
          </div>
        }
      />
      <ShiftPlannerBar />
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <ShiftPlannerList />
        <div className="col-span-12 lg:col-span-8 mt-5">
          {!isShiftDetailsData?.id ? (
            <div className="card">
              <div className="h-[40vh] flex justify-center text-center gs-text-placeholder font-semibold italic text-lg">
                <div className="flex justify-center items-center text-cPlaceholder">
                  {t("Select a Shift to see details")}
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="flex justify-between items-center pb-2">
                <div className="text-2xl font-bold">{t("History Details")}</div>
                {/* <div
                  onClick={() => {
                    console.log("SHARE!!!");
                    setShowShareModal(true);
                  }}
                  className="flex flex-row justify-center items-center cursor-pointer"
                >
                  <img src={iShare} alt="" />
                </div> */}
              </div>
              <div className="bg-cListItem p-5 rounded-xl">
                <div className="flex justify-between items-center ">
                  <div className="flex items-center">
                    <div className="flex flex-col justify-center items-center bg-cLine rounded-full h-[60px] w-[60px]">
                      <span className="text-cListIcon font-semibold text-xl">
                        {shortDateNo < 10 ? "0" + shortDateNo : shortDateNo}
                      </span>
                      <span className="text-cListIcon text-[10px]">
                        {shortMonth}
                      </span>
                    </div>
                    <div className="flex flex-col pl-2">
                      <span className="text-sm">
                        {isShiftDetailsData?.weekday}
                      </span>
                      <span className="text-sm">
                        {isShiftDetailsData?.car && isShiftDetailsData?.car.name}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-sm">
                      {isShiftDetailsData
                        ? isShiftDetailsData?.start_time?.slice(0, -3)
                        : ""}
                      -
                      {isShiftDetailsData
                        ? isShiftDetailsData?.end_time?.slice(0, -3)
                        : ""}
                    </span>
                    <span className="text-sm">
                      {isShiftDetailsData?.car &&
                        isShiftDetailsData?.car.car_license_plate_number}
                    </span>
                  </div>
                </div>

                {isShiftDetailsData?.is_maintenance === 0 ? (
                  <>
                    {/* hg mid section 01 */}
                    <div className="flex flex-row justify-around w-full pt-5">
                      <Link to="request-details">
                        <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                          <span className="text-sm text-[#828282]">
                            {t("Request")}
                          </span>
                          <span className="text-lg font-bold">
                            {isShiftDetailsData?.request_completed}/
                            {isShiftDetailsData?.request_count}
                          </span>
                        </div>
                      </Link>

                      <Link to="stop-details">
                        <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                          <span className="text-sm text-[#828282]">{t("Stops")}</span>
                          <span className="text-lg font-bold">
                            {isShiftDetailsData?.stops_completed}/
                            {isShiftDetailsData?.stops_count}
                          </span>
                        </div>
                      </Link>

                      <Link to="package-details">
                        <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                          <span className="text-sm text-[#828282]">
                            {t("Packages")}
                          </span>
                          <span className="text-lg font-bold">
                            {isShiftDetailsData?.products_completed}/
                            {isShiftDetailsData?.products_count}
                          </span>
                        </div>
                      </Link>
                    </div>

                    {/* hb mid section 02 */}
                    <div className="flex flex-row justify-around w-full py-5">
                      <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                        <span className="text-sm text-[#828282]">{t("Breaks")}</span>
                        <span className="text-lg font-bold">
                          {isShiftDetailsData?.breaks === 0
                            ? "0"
                            : TimeIntoHours(isShiftDetailsData?.breaks)}
                        </span>
                      </div>

                      <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                        <span className="text-sm text-[#828282]">{t("Driven")}</span>
                        <span className="text-lg font-bold">
                          {isShiftDetailsData?.driven === 0
                            ? "0"
                            : DistanceIntoKM(isShiftDetailsData?.driven)}
                          /
                          {isShiftDetailsData?.driven_count === 0
                            ? "0"
                            : DistanceIntoKM(isShiftDetailsData?.driven_count)}
                        </span>
                      </div>

                      <div className="flex flex-col items-center justify-center bg-white w-[130px] h-[70px] rounded-lg">
                        <span className="text-sm text-[#828282]">
                          {t("Shift Hours")}
                        </span>
                        <span className="text-lg font-bold">
                          {isShiftDetailsData?.shift_hours === 0
                            ? "0"
                            : TimeIntoHours(isShiftDetailsData?.shift_hours)}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mb-5"></div>
                )}

                <div className="flex flex-col text-sm">
                  <span className=" font-semibold">{t("Comment")}</span>
                  <span className="text-cBodyText">
                    {isShiftDetailsData?.comment}
                  </span>
                </div>
                {isShiftDetailsData?.is_maintenance ? (
                  <div className="flex justify-center items-center pt-5">
                    <img src={iMaintenance} alt="" />
                    <span className="pl-2 font-bold italic text-sm text-cRed">
                      {t("In Maintenance")}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HistoryDetails;
