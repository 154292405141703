/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../Utility/UtilityFunctions';
import { useTranslation } from 'react-i18next';
import CommonTable from '../../../Components/Table/CommonTable';
import useCompanyStore, { getCompanies } from '../../../App/stores/CompanyStore';
import useCustomerStore, { searchUsers } from '../../../App/stores/CustomerStore';
import CompanyTableRow from './CompanyTableRow';
import TitleBar from '../../../Components/Common/TitleBar';
import NoDataRow from '../../../Components/Table/NoDataRow';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import useGeneralStore from '../../../App/stores/GeneralStore';


const ManageCompanies = () => {
    const {
        companies,
        all_companies,
        companySearchValue,
        setCompanySearchValue,
        companyTakeAmount,
        setCompanyTakeAmount,
        setCompanyPageUrl,
        setShowAddCompanyModal,
        companyPageUrl }
        = useCompanyStore();

    const { is_searching } = useCustomerStore();

    const { setLoading } = useGeneralStore()

    const { t } = useTranslation();

    //const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        PageTitle('Limadi | Companies');
        // getCompanies();
    }, []);

    useEffect(() => {
        // ! Using debounce
        const timer = setTimeout(async () => {

            if (companySearchValue) {
                // if (!companyPageUrl) {await setCompanyTakeAmount(10);}
                searchUsers(companySearchValue, 'company', companyTakeAmount, companyPageUrl)
            } else {
                setCompanySearchValue("")
                if (!companyPageUrl) {
                    // await setCompanyTakeAmount(10);
                    await getCompanies();
                } else await getCompanies(companyPageUrl);
            }
        }, companySearchValue === '' ? 0 : 500);
        return () => clearTimeout(timer);
    }, [companySearchValue])


    const header = [
        { index: 1, name: t("name") },
        { index: 2, name: t("email") },
        { index: 3, name: t("CVR") },
        { index: 4, name: t("phone") },
        { index: 5, name: t("status") },
        // { index: 6, name: t("actions") },
    ];


    return (
        <>
            <TitleBar
                label={'Companies'}
                count={companies?.total}
                onClick={async () => { await getCompanies(companyPageUrl); setCompanySearchValue("") }}
                rightCornerComponent={
                    <CommonButtonOutlined btnLabel={'Add Company'} onClick={() => { setShowAddCompanyModal(true) }} />
                }
            />

            <div className="justify-between w-full space-x-0 md:flex md:space-x-5">

                <CommonTable
                    filterFunction={false}
                    filterComponent={<FilterDropDown />}
                    headers={header}
                    showingFrom={companies?.from}
                    topRightComponent={<TakeItem />}
                    showingTo={companies?.to}
                    totalResult={companies?.total}
                    items={
                        all_companies?.length > 0 ? all_companies?.map((message, index) => < CompanyTableRow key={index} data={message} />)
                            : <NoDataRow />
                    }
                    shoSearchBox={true}
                    searchOnChange={(e) => {
                        setCompanySearchValue(e.target.value)
                        setCompanyPageUrl("")
                    }}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setCompanySearchValue("")
                        setCompanyPageUrl("")
                    }}
                    searchValue={companySearchValue}
                    paginationObject={companies}
                    showPagination={companies?.last_page !== 1 ? true : false}
                    search_loading={is_searching}
                    paginationOnClick={async (url) => {
                        // console.log(url);
                        setCompanyPageUrl(url)
                        if (companySearchValue) {
                            searchUsers(companySearchValue, 'company', companyTakeAmount, url)
                        } else await getCompanies(url);

                    }}
                />
            </div>

            {/* Modals */}
            {/* //todo:: implement for company  */}
            {/* <DeleteCustomerModal /> */}
        </>
    )
}

export default ManageCompanies


const TakeItem = () => {
    const { setCompanyTakeAmount, companyTakeAmount, companySearchValue, setCompanyPageUrl } = useCompanyStore();
    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                value={companyTakeAmount ? companyTakeAmount : 10}
                onChange={async (e) => {
                    console.log(e.target.value);
                    setCompanyTakeAmount(e.target.value);
                    console.log(e.target.value);
                    setCompanyPageUrl()
                    if (companySearchValue) {
                        searchUsers(companySearchValue, 'company', e.target.value)
                    } else await getCompanies();
                }}
                className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
            >
                <option
                    className='py-s10 text-cMainBlack'
                    selected={companyTakeAmount === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cMainBlack'
                    selected={companyTakeAmount === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={companyTakeAmount === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={companyTakeAmount === 100}
                    value={100}
                >100</option>
            </select>
            <span className='pl-s10'>Entries</span>

        </div>
    )
}


const FilterDropDown = () => {

    const { companyFilterValue, setCustomerSearchValue, setCompanyFilterValue } = useCompanyStore();

    return (
        <div>
            <select onChange={async (e) => {
                // await setLicenseTakeAmount(10);
                await setCustomerSearchValue('')
                // console.log(e.target.value);
                setCompanyFilterValue(e.target.value)
                // searchLicenseApplication('status=' + e.target.value);

            }} id="cars" className='pl-5 font-semibold w-s200 h-s37 rounded-br5 select-style space-y-s5'>
                <option className='py-s10 text-cMainBlack' value="">Choose Status</option>
                <option className='py-s10 text-cMainBlack' selected={companyFilterValue === 1} value="1">Active</option>
                <option className='py-s10 text-cMainBlack' selected={companyFilterValue === 0} value="0">Inactive</option>
            </select>
        </div>
    )
}