import React from 'react'

const Input01 = ({ min, max, onClick, type, onChange, name, value, label, placeholder }) => {
  return (
    <div className="mb-5">
      <div className="text-gray-600 text-sm font-bold mb-2">{label}</div>
      <input min={min} max={max} onClick={onClick} onChange={onChange} type={type} name={name} value={value ? value : ''} 
      className="bg-gray-100 px-4 py-2 w-full block text-sm outline-none rounded-md" placeholder={placeholder} />
    </div>
  )
}

export default Input01