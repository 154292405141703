import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import useDriverStore, { getDriverDetails } from '../../../../App/stores/DriverStore';
import BackLinks from '../../../../Components/Layout/BackLinks'
import { BaseUrlSrc } from '../../../../Utility/url';
import CommonDetailsPanel from '../../CommonDetailsPanel'
import MiniCard from '../../MiniCard'

export default function DriverDetails() {
    const [detailsState, setDetailsState] = useState("request");
    const { setShowDeactivateDriverModal, setShowDeleteDriverModal, setShowResetPassDriverModal, setShowEditDriverModal, driverDetails, setDriverDeleteID } = useDriverStore();

    useEffect(() => {
        getDriverDetails(parseInt(window.location.pathname.slice(23)));

        window.scrollTo(0, 0);
    }, [])

    const { driver_id } = useParams();
    const navigateTo = useNavigate();

    return (
        <div>
            <BackLinks
                linksArray={[
                    { label: "Drivers", linkTo: "/users/drivers" },
                    { label: "Driver Details", linkTo: "" },
                ]}
            />
            <CommonDetailsPanel
                customDeactivateFn={() => { setShowDeactivateDriverModal(true) }}
                name={driverDetails?.name}
                company_details={driverDetails?.company}
                image={driverDetails?.image ? (driverDetails?.image) : ""}
                email={driverDetails?.email}
                phone={driverDetails?.phone}
                address={driverDetails?.address}
                memberSince={driverDetails?.joined}
                totalReq={driverDetails?.total_request}
                completedReq={driverDetails?.total_complete_request}
                badge='Genie Info Tech'
                is_active={driverDetails?.is_active}
                id={driverDetails?.id}
                userData="driver"
                userId={driver_id}
                optionsArray={[
                    { inActive: false, title: "Shift Planner", action: () => { navigateTo("/users/drivers/details/" + driver_id + "/shift-planner") } },
                    // { title: "Favorite Company", action: () => { navigateTo("/users/drivers/details/" + driver_id + "/fav-company") } },
                    { title: "Favorite Address", action: () => { navigateTo("/users/drivers/details/" + driver_id + "/fav-address") } },
                    // { title: "All Request", action: () => { setDetailsState("request"); } },
                    // {inActive:true, title: "Assigned Companies", action: () => { navigateTo("/users/drivers/details/" + driver_id + "/assigned-companies") } },
                    { title: "Edit Account", action: () => { setShowEditDriverModal(true) } },
                    { inActive:false, title: "Reset Password", action: () => { setShowResetPassDriverModal(true) } },
                    // { title: "Deactivate Account", action: () => { setShowDeactivateDriverModal(true) } },
                    // { inActive:true, title: "Delete Account", action: () => { setShowDeleteDriverModal(true); setDriverDeleteID(driver_id) } },
                ]}
            />

            {/* todo: when Driver's request section unlocks, they will be visible here */}
            {/* {detailsState === "request" ? <RequestState /> : ""} */}

        </div>
    )
}

const RequestState = () => {

    return (
        <div className="flex items-center justify-between pt-5 space-x-5">
            <MiniCard title='total shifts' number='120' />
            <MiniCard title='assigned shifts' number='120' />
            <MiniCard title='ongoing shifts' number='120' />
            <MiniCard title='completed shifts' number='120' />
        </div>
    )
}