import React from 'react'
import Calendar from '../../Components/Calendar'

const CustomDatePicker = () => {
  return (
    <div>
        <Calendar/>
    </div>
  )
}

export default CustomDatePicker