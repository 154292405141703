import { t } from 'i18next';
import { BaseUrlSrc } from '../../../../../../Utility/url';
import { formatDate, formatDateOrTime, timeDiffHours } from '../../../../../../Utility/UtilityFunctions';
import ProductAccordion from "./ProductAccordion";

const StopDetailAccordion = ({ targetData }) => {
  return (
    <div className="bg-cMoreLiteGrey gs-text-main-black p-[10px] rounded-[5px]">
      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Stop Title")}</div>
        <button className="text-sm gs-text-body text-right font-semibold max-w-[20vw] truncate">
          {targetData.title ? (
            targetData.title
          ) : (
            <span className="text-gray-400">No title</span>
          )}
        </button>
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Address")}</div>
        <button className="text-sm gs-text-body text-right font-semibold max-w-[20vw] truncate">
          {targetData.address ? (
            targetData.address
          ) : (
            <span className="text-gray-400">No address</span>
          )}
        </button>
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Date")}</div>
        <button className="text-sm gs-text-body text-right font-semibold">
          {targetData.date ? (
            formatDate(targetData.date)
          ) : (
            <span className="text-gray-400">No date</span>
          )}
        </button>
      </div>

      <div className="flex justify-between items-center mb-5">
        <div className="text-sm gs-text-icon-color">{t("Start Time")}</div>
        <button className="text-sm gs-text-body text-right font-semibold">
          {targetData.start_time ? (
            formatDateOrTime(targetData.start_time + targetData.date) +
            " (Avg " +
            timeDiffHours(
              targetData.end_time,
              targetData.start_time,
              targetData.date
            ) +
            " hr)"
          ) : (
            <span className="text-gray-400">No start time</span>
          )}
        </button>
      </div>
      <div className="py-3 font-semibold">{t("Product List")}</div>
      {targetData.products ? (
        <ProductAccordion
          key={targetData.id}
          productArray={targetData.products}
        />
      ) : (
        "No products"
      )}

      <div className="py-1 font-semibold">{t("Comment")}</div>
      <div className="text-sm gs-text-icon-color">
        {targetData.comment ? targetData.comment : "No comment"}
      </div>

      <div className="py-1 mb-4 font-semibold">{t("Attachment")}</div>
      <div className="text-sm gs-text-icon-color overflow-hidden rounded-[5px]">
        {targetData.attachment ? (
          <img src={BaseUrlSrc + targetData.attachment} alt="" className="w-[200px]" />
        ) : (
          <span className="text-gray-400 text-right">No attachment</span>
        )}
      </div>
    </div>
  );
};

export default StopDetailAccordion;
