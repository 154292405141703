import React from 'react'
import { iRightArrow } from '../../../../../Utility/Sources'

//grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12
function RequestItem({ title="Title",description="description" ,isSelected, onSelect, index }) {

  return (
    <div className='flex flex-row items-center justify-start w-full' onClick={onSelect}>
      <div className={`w-[5px] h-[50px] my-[5px] ${isSelected ? "bg-cSelected" : 'bg-[#ffffff]'} `}></div>

      <div className='grid grid-cols-12 gap-2 max-h-[70px] min-w-[70px] w-full  bg-cLiteGrey my-[5px] ml-[15px] mr-[20px] rounded-br5 cursor-pointer'>
        <div className={`col-span-11 flex flex-col pl-s10 py-s10`}>
          <div className='text-sm font-semibold capitalize truncate pb-s10'>Request ID - {title}</div>
          <div className='font-normal text-sm text-cTextBody max-w-[40vw] lg:max-w-[10vw] xl:max-w-[15vw] 2xl:max-w-[17vw] truncate'>{description}</div>
        </div>

        <img src={iRightArrow} alt="" className='py-6 pr-s10 col-span-1 max-h-[20px] min-h-[20px] max-w-[20px] min-w-[20px] ' />

      </div>
    </div>
  )
}

export default RequestItem