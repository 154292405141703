import React, { useState } from 'react';

const CommonShowMoreLess = ({ data,totalLetters="80" }) => {

    const [showMore, setShowMore] = useState(false);

    const getText = (text) => {
        if (text?.length <= totalLetters) return <div>{text}</div>;
        if (text?.length > totalLetters && showMore) {
            return (
                <>
                    <p className=''>{text}</p>
                    <button
                        className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400"
                        onClick={() => setShowMore(false)}>
                        Show Less
                    </button>
                </>
            );
        }
        if (text?.length > totalLetters) {
            return (
                <>
                    <p>{text.slice(0, totalLetters)} ...</p>
                    <button
                        className="cursor-pointer text-cBrandColor mt-s5 text-fs14 font-fw400"
                        onClick={() => setShowMore(true)}>
                        Show more
                    </button>
                </>
            );
        }
    };

    return (
        <div>
            {getText(data)}
        </div>
    );
};

export default CommonShowMoreLess;