import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { commonUserDelete } from '../../../../App/stores/CompanyStore';
import useCustomerStore from '../../../../App/stores/CustomerStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function DeleteCustomerModal() {
  const { is_show_customer_delete_modal, setIsShowCustomerDeleteModal, customerDetails, customerDeleteID } = useCustomerStore();
  const { t } = useTranslation();

  const [confirmationText, setConfirmationText] = useState("");
  
  const navigateTo = useNavigate();
  useEffect(() => setConfirmationText(""), [customerDeleteID]);

  return (
    <CommonModal
      showModal={is_show_customer_delete_modal}
      setShowModal={setIsShowCustomerDeleteModal}
      modalTitle={t("Confirmation")}
      mainContent={
        <form onSubmit={(e) => e.preventDefault()} className='pt-5'>
          <div className="text-base text-center">
            {t("Are you sure you want to delete this customer account?")}
          </div>

          <div className="py-5">
            <CommonInput
              required={true}
              disableCopy={true}
              disablePaste={true}
              label={
                <span className="mt-5 text-center normal-case text-fs14">
                  Please type{" "}
                  <span className='font-bold select-none text-cRed text-fs16'>{customerDetails?.name}</span>
                  {" "}to confirm.
                </span>
              }
              placeholder="Enter customer name"
              value={confirmationText}
              onChange={(e) => {
                setConfirmationText(e.target.value);
              }}
            />
          </div>

          <div className="flex justify-center items-center">
            <CommonButton
              onClick={async () => {
                // return console.log(customerDeleteID);
                let delSuccess = await commonUserDelete(customerDeleteID);
                if (delSuccess) {
                  setConfirmationText("");
                  navigateTo("/users/customers");
                  setIsShowCustomerDeleteModal(false);
                }
              }}
              isDisabled={confirmationText === customerDetails?.name ? false : true}
              colorType={confirmationText === customerDetails?.name ? 'danger' : 'base'}
              type='submit'
              btnLabel={t("I understand, delete")}
              width="w-[180px]"
            />
          </div>
        </form>
      }
    />
  )
}
