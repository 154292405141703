import { useState } from 'react';
import { useEffect } from 'react';
import { RotatingLines } from "react-loader-spinner";
import { ThreeDots } from 'react-loader-spinner';
import { iChatBabbleFrom, iChatBabbleTo } from '../../../App/Utility/ImageImports';
import { BaseUrlSrc } from '../../../Utility/url';
import useGeneralStore from '../../../App/stores/GeneralStore';

const ChatMessageItem = ({
    messageText = 'Hello',
    isReplyType = false,
    isReed = false,
    time = "",
    fail = false,
    newMsg = false,
    attachment = "",

}) => {

    const fileExtension = attachment?.split('.')?.pop()?.toLowerCase();

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

    const [loading, setLoading] = useState(true);

    const { setShowImagePreviewSRC, setShowImagePopup } = useGeneralStore();

    useEffect(() => {
        const image = new Image();
        image.src = attachment;

        const handleLoad = () => {
            setLoading(false);
        };

        const handleError = () => {
            setLoading(false);
        };

        image.addEventListener('load', handleLoad);
        image.addEventListener('error', handleError);

        return () => {
            image.removeEventListener('load', handleLoad);
            image.removeEventListener('error', handleError);
        };
    }, [attachment]);


    return (
        <div>
            <div className={`flex items-start space-x-3 ${isReplyType ? 'justify-end' : ''}`}>
                <div className="flex">
                    {!isReplyType && <div className='flex items-end justify-end'>
                        <img alt='' src={iChatBabbleFrom} className='max-w-[8px] min-w-[8px] h-[15px]' />
                    </div>}
                    <div className={`p-2 rounded-br6 ${isReplyType ? 'bg-cFloralWhite' : 'bg-[#EFEFEF]'}`}>
                        {
                            attachment &&
                            (loading ? (
                                <div className='h-[350px] w-[350px] flex justify-center items-center mb-1'>
                                    <RotatingLines
                                        width="100"
                                        strokeColor="#F89818"
                                        strokeWidth={4}
                                        strokeWidthSecondary={3}
                                    />
                                </div>
                            ) :
                                <div className={`${isReplyType ? "flex justify-end items-end" : "flex justify-start items-start"} `}>
                                    <img
                                        onClick={() => {
                                            setShowImagePopup(true);
                                            setShowImagePreviewSRC(attachment);
                                        }}
                                        src={imageExtensions?.includes(fileExtension) ? BaseUrlSrc + attachment : attachment}
                                        alt="" className={`min-w-full max-w-[calc(100vh-400px)] mb-1 object-cover rounded-br6 h-[350px] cursor-pointer`}
                                    />
                                </div>
                            )
                        }

                        {
                            messageText &&
                            <div className={`max-w-[calc(100vh-400px)] whitespace-pre-line text-fs14 font-fw400 text-[#333333] w-full break-all flex justify-start`}>
                                {messageText}
                            </div>
                        }

                        {
                            time && <div className='flex justify-end mt-1 space-x-1'>
                                <div className='text-fs10 font-fw400 text-[#89919E]'>{time}</div>
                            </div>
                        }

                    </div>
                    {isReplyType && <div className='flex items-end justify-end'>
                        <img alt='' src={iChatBabbleTo} className='max-w-[8px] min-w-[8px] h-[15px]' />
                    </div>}

                </div>
            </div>
            {fail && <div className='flex justify-end text-fs12 text-cRed font-fw400'>Failed to send</div>}
            {
                newMsg && <div className='flex justify-end space-x-1'>
                    <div className='text-fs10 text-cBrand font-fw400'>Sending</div>
                    <div>
                        <ThreeDots
                            height="20"
                            width="20"
                            radius="9"
                            color="#F89818"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default ChatMessageItem