import { createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios'
import { kuAddPackage, kuDeletePackage, kuGetPackage, kuUpdatePackage } from '../../Utility/url'
import { Toastr, ToastrLoading } from '../../Utility/UtilityFunctions'

const initialState = {
    packages: [],
    form: {
        id: null,
        number: '',
        price: '',
        duration: 0,
        description: '',
        year: 0,
        month: 0
    },
    isLoading: false,
    isUpdate: false,
}
export const packageSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {
        changeValue:(state, action) => {
            const {name, value} = action.payload
            // console.log('name: ', name);
            // console.log('value: ', value);      
            state.form[name] = value
        },
        setPackages:(state, action) => {
            state.packages = action.payload            
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        deletePackage: (state, action) => {
            state.packages = action.payload
        },
        setIsUpdate: (state, action) => {
            const {packageId, isUpdate} = action.payload
            console.log('packageID: ', packageId);
            console.log('isUpdate: ', isUpdate);

            // state update
            state.isUpdate = isUpdate;

            if (packageId) {
                const tempPackage = state.packages.find((pack) => pack.id === packageId)
                console.log('current', current(tempPackage));

                state.form.id = tempPackage.id
                state.form.duration = tempPackage.duration
                state.form.price = tempPackage.price
                state.form.description = tempPackage.description
                state.form.month = tempPackage.month
                state.form.year = tempPackage.year
            } else {
                state.form.id = null
                state.form.number = ''
                state.form.duration = 0
                state.form.price = ''
                state.form.description = ''
                state.form.month = 0
                state.form.year = 0
            }            
        },
        resetForm: (state) => {
            state.form.id = null
            state.form.number = ''
            state.form.duration = 0
            state.form.price = ''
            state.form.description = ''
            state.form.description = 0
            state.form.description = 0
        },
    },
})

// Get Packages
export const handleSetPackages = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetPackage)
        console.log('handleSetPackages: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setPackages(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Delete Package
export const handleDeletePackage = (state, id) => async (dispatch) => {
    let toastr = ToastrLoading()
    
    try {
        const body = { id: id }
        // console.log('body', body);
        // return

        dispatch(setLoading(true))
        const res = await axios.post(kuDeletePackage, body)

        console.log('handleDeletePackage: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetPackages())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Add Package
export const handleAddPackage = (state) => async (dispatch) => {

    if (state.form.number === '') {
        Toastr('Package type is required')
        return
    }

    if (state.form.price.length === 0) {
        Toastr('Price is required')
        return
    }

    if (state.form.description.length === 0) {
        Toastr('Description is required')
        return
    }

    let toastr = ToastrLoading()

    try {
        const duration = (state.form.year * 365) + (state.form.month * 30) 
        const body = { number: state.form.number, price: state.form.price, duration: duration, description: state.form.description }
        // console.log(body);
        // return
        // const body = { number: state.form.number, price: state.form.price, duration: state.form.year + (state.form.day * 30) , description: state.form.description }

        dispatch(setLoading(true))
        // console.log(body);
        // return

        const res = await axios.post(kuAddPackage, body)

        console.log('handleAddPackage: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetPackages())
            dispatch(resetForm())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Update Package
export const handleUpdatePackage = (state) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
        const duration = (state.form.year * 365) + (state.form.month * 30) 
        const body = { id: state.form.id, number: state.form.number, price: state.form.price, duration: duration, description: state.form.description }
        // const body = { id: state.form.id, price: state.form.price, duration: state.form.duration, description: state.form.description }

        // console.log('body', body);
        // return

        dispatch(setLoading(true))
        const res = await axios.post(kuUpdatePackage, body)

        console.log('handleUpdatePackage: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetPackages())
            dispatch(resetForm())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
        
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}


export const { changeValue, setPackages, setLoading, deletePackage, setIsUpdate, resetForm } = packageSlice.actions;
export default packageSlice.reducer