/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonButtonOutlined from "../../../../Components/Button/CommonButtonOutlined";
import ChipWithStar from "../../../../Components/Chip/ChipWithStar";
import CircularProgressBar from "../../../../Components/CircularProgressBar";
import EmptyMessage from "../../../../Components/EmptyMessage";
import SearchInput from "../../../../Components/Input/SearchInput";
import BackLinks from "../../../../Components/Layout/BackLinks";
import CommonModal from "../../../../Components/Modal/CommonModal";
import ListSkeleton01 from "../../../../Components/Skeletons/ListSkeleton01";
import { FavoriteCompanyContext } from "../../../../Context/FavoriteCompanyContext";
import { iRefresh } from "../../../../Utility/Sources";
import { PageTitle, Toastr } from "../../../../Utility/UtilityFunctions";
import AddFavCompany from "./AddFavCompany";
import Company from "./Company";
import CompanyDetails from "./CompanyDetails";
import LimadiSearchBox from "../../../../Components/Input/LimadiSearchBox";

const FavoriteCompanies = ({ userType }) => {
  const {
    getFavoriteCompany,
    favoriteCompany,
    isAddingFavCompany,
    setIsAddingFavCompany,
    setNotFavoriteCompanyUID,
    favCompanyDetails,
    favCompanyRatingInfo,
    searchFavCompany,
    searchKey,
    setSearchKey,

    isOneStar,
    isTwoStar,
    isThreeStar,
    isFourStar,
    isFiveStar,

    setIsOneStar,
    setIsTwoStar,
    setIsThreeStar,
    setIsFourStar,
    setIsFiveStar,

    selectedIndex,
    getCompanyDetails,
    setSelectedIndex,setFavCompanyDetails,setFavCompanyRatingInfo

  } = useContext(FavoriteCompanyContext);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFilterRating, setIsFilterRating] = useState(false);

  const { customer_id } = useParams();
  const { driver_id } = useParams();

  const [userID, setUserID] = useState(0);

  const refreshFoo = async () => {
    setSearchKey("")
    setSelectedIndex(null);
    setFavCompanyDetails({});
    setFavCompanyRatingInfo({})
    setIsRefreshing(true);
    await getFavoriteCompany(userID);
    // Toastr("Data Fetch Completed!", "success");
    await console.log("favoriteCompany: ", favoriteCompany);
    setIsRefreshing(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    PageTitle("Limadi | Favorite Company")
    if (userType === "customer") {
      setUserID(parseInt(customer_id))
      getFavoriteCompany(customer_id)
    } else if (userType === "driver") {
      setUserID(parseInt(driver_id));
      getFavoriteCompany(driver_id)
    }
  }, []);

  // useEffect(() => {
  //   selectedIndex && getCompanyDetails()
  // }, [selectedIndex])

  return (
    <>
      {/* starts::title bar */}
      <CommonModal
        showModal={isAddingFavCompany}
        setShowModal={setIsAddingFavCompany}
        mainContent={
          <div>
            <AddFavCompany userType={userType} UID={userID} />
          </div>
        }
      />

      {userType === "customer" ?
        <BackLinks
          linksArray={[
            { label: "customers", linkTo: "/users/customers" },
            { label: "customer Details", linkTo: "/users/customers/details/" + userID },
            { label: "favorite company", linkTo: "" },
          ]}
        />
        : ""}

      {userType === "driver" ? <BackLinks
        linksArray={[
          { label: "drivers", linkTo: "/users/drivers" },
          { label: "driver Details", linkTo: "/users/drivers/details/" + userID },
          { label: "favorite company", linkTo: "" },
        ]}
      /> : ""}

      <div className="flex flex-row justify-between align-middle card">
        <div className="flex items-center w-2/3">
          <div className="mr-5 text-2xl font-bold">
            {t("Favorite Company")} ({favoriteCompany.length})
          </div>
          {!isRefreshing ? (
            <img
              onClick={refreshFoo}
              src={iRefresh}
              alt="refresh-icon"
              className="h-8 cursor-pointer"
            />
          ) : (
            <CircularProgressBar />
          )}
        </div>
        {/* <CommonButtonOutlined
          btnLabel={t("Add Company")}
          onClick={() => {
            setIsAddingFavCompany(true);
            console.log("UID :::::: ", userID);
            setNotFavoriteCompanyUID(userID);
            // getNotFavoriteCompany();
          }}
        /> */}
      </div>
      {/* ends::title bar */}

      {/* starts::page-contents*/}
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <div className="col-span-12 mt-5 bg-white rounded-xl shadow lg:col-span-5">
          <div className="flex-col justify-start bg-white">
            {/* search bar */}
            <div className="flex justify-start items-center">
              <div className="px-5 pt-5 w-full">
                {/* <SearchInput

                  placeholder={t("search")}
                  type="search"
                  onChange={searchFavCompany}
                  name="searchKey"
                  value={searchKey}
                /> */}

                <LimadiSearchBox
                  onSearchClear={() => {
                    setSearchKey("");
                    searchFavCompany("");
                  }}
                  fullWidth={true}
                  withClearSearch={true}
                  placeholder={t("search")}
                  type="search"
                  onChange={(e) => searchFavCompany(e.target.value)}
                  name="searchKey"
                  value={searchKey}
                />
              </div>

              {/* //! don't remove this option, temporary remove */}
              {/* <div onClick={() => setIsFilterRating(!isFilterRating)} className="bg-gray-500 p-3 rounded-[4px] cursor-pointer select-none">
                <img src={iStar} alt="" />
              </div> */}
            </div>

            {/* filter star section */}
            <div
              className={` flex-col px-5 select-none py-3 ${isFilterRating ? "flex" : "hidden"
                }`}
            >
              <div className="flex">
                <ChipWithStar
                  rate={1}
                  onClick={() => {
                    setIsOneStar(!isOneStar);
                  }}
                  selected={isOneStar}
                />
                <div className="px-5">
                  <ChipWithStar
                    rate={2}
                    onClick={() => {
                      setIsTwoStar(!isTwoStar);
                    }}
                    selected={isTwoStar}
                  />
                </div>
              </div>
              <div className="flex pt-5">
                <ChipWithStar
                  rate={3}
                  onClick={() => {
                    setIsThreeStar(!isThreeStar);
                  }}
                  selected={isThreeStar}
                />
                <div className="pl-5">
                  <ChipWithStar
                    rate={4}
                    onClick={() => {
                      setIsFourStar(!isFourStar);
                    }}
                    selected={isFourStar}
                  />
                </div>
              </div>
              <div className="pt-5">
                <ChipWithStar
                  rate={5}
                  onClick={() => {
                    setIsFiveStar(!isFiveStar);
                  }}
                  selected={isFiveStar}
                />
              </div>
            </div>

            <ul className="overflow-y-auto  scroll-smooth">
              {isRefreshing ? (
                <ListSkeleton01 />
              ) : favoriteCompany?.length ? (
                favoriteCompany?.map((item) =>
                  <Company
                    data={item}
                    key={item.id} />)
              ) : (
                <>
                  <EmptyMessage />
                </>
              )}
            </ul>
          </div>
        </div>

        <div className="col-span-12 mt-5 lg:col-span-7">
          {favCompanyDetails.name ? (
            <div className="card">
              {!isAddingFavCompany && (
                <CompanyDetails
                  data={favCompanyDetails}
                  reviewData={favCompanyRatingInfo}
                  userID={userID}
                />
              )}
            </div>
          ) : (
            <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
              <EmptyMessage message={t('Please select a favorite company to see details.')} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FavoriteCompanies;
