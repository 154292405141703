/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Dropdown from '../../../Components/Dropdown';
import Input02 from '../../../Components/Input/Input02';
import { formatDate } from '../../../Utility/UtilityFunctions';
import { BsCalendar2WeekFill } from 'react-icons/bs';
import Calendar from '../../../Components/Calendar';
import useLicenseStore, { updateLicenseStatus } from '../../../App/stores/LicenseStore';
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonButton from '../../../Components/Button/CommonButton';
import { useTranslation } from 'react-i18next';

function UpdateLicenseModal() {
    const { showUpdateApplication, setShowUpdateApplication, licenseApplicationID, allApplicationList } = useLicenseStore();
    const [targetApplication, setTargetApplication] = useState();
    const { t } = useTranslation();

    const [status, setStatus] = useState([
        { name: ("pending"), value: 'pending' },
        { name: ("active"), value: 'active' },
        { name: ("accepted"), value: 'accepted' },
        { name: ("rejected"), value: 'rejected' },
    ])

    useEffect(() => {
        let targetItem = allApplicationList?.data?.find((item) => item.id === licenseApplicationID);

        setTargetApplication(targetItem);
    }, [licenseApplicationID]);

    return (
        <>
            <CommonModal
                showModal={showUpdateApplication}
                setShowModal={setShowUpdateApplication}
                modalTitle={t("change license status")}
                mainContent={
                    <form onSubmit={(e) => e.preventDefault()} className="flex flex-col justify-center w-full mt-5 space-y-10">

                        <Dropdown
                            width={"w-full"}
                            button={
                                <Input02
                                    onChange={(e) => {
                                        setTargetApplication({ ...targetApplication, "license_start": e });
                                    }}
                                    className="cursor-pointer"
                                    name="license_start_date"
                                    value={formatDate(new Date(targetApplication?.license_start))}
                                    label="License Start (optional)"
                                    type="text"
                                    placeholder="Select date"
                                    is_readonly={true}
                                    icon={<BsCalendar2WeekFill className="h-[70%]" />}
                                />
                            }
                            body={
                                <Calendar
                                    selectAction={(e) => {
                                        setTargetApplication({ ...targetApplication, "license_start": e });
                                    }}
                                    init_date={new Date(targetApplication?.license_start)}
                                />
                            }
                        />

                        <Dropdown
                            width={"w-full"}
                            button={
                                <Input02
                                    onChange={(e) => {
                                        setTargetApplication({ ...targetApplication, "license_end": e });
                                    }}
                                    className="cursor-pointer"
                                    name="license_end_date"
                                    value={formatDate(new Date(targetApplication?.license_end))}
                                    label="License End (optional)"
                                    type="text"
                                    placeholder="Pickup Date"
                                    is_readonly={true}
                                    icon={<BsCalendar2WeekFill className="h-[70%]" />}
                                />
                            }
                            body={
                                <Calendar
                                    selectAction={(e) => {
                                        setTargetApplication({ ...targetApplication, "license_end": e });
                                    }}
                                    init_date={new Date(targetApplication?.license_end)}
                                />
                            }
                        />

                        <div className='capitalize'>
                            <div className="mb-2 text-sm font-bold text-gray-600">{t("Status")}</div>

                            <select
                                required={true}
                                value={targetApplication?.license_status}
                                onChange={(e) => {
                                    setTargetApplication({ ...targetApplication, "license_status": e.target.value });
                                }}
                                className='w-full px-4 py-2 text-sm capitalize bg-gray-100 rounded-md outline-none cursor-pointer' name="status"
                            >
                                {status.map((item, index) =>
                                    <option className='capitalize cursor-pointer' key={index} value={item.value} selected={item.value === targetApplication?.license_status}>
                                        {item.name}
                                    </option>
                                )}
                            </select>
                        </div>

                        <div className="flex justify-center pt-5">
                            <CommonButton
                                type='submit'
                                btnLabel={t("save changes")}
                                onClick={() => {
                                    let updateSuccess = updateLicenseStatus(targetApplication);

                                    if (updateSuccess) {
                                        setShowUpdateApplication(false);
                                    }
                                }}
                            />
                        </div>

                    </form>
                }
            />
        </>
    )
}

export default UpdateLicenseModal