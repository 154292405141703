import React from "react";
import { IoArrowBackOutline,IoChevronForwardOutline } from "react-icons/io5";
function LayoutTest3() {
  return (
    <div>
     <div className="mb-3 text-fs20 font-fw600 flex justify-start"><IoArrowBackOutline className="my-s4"/><span className="mb-s8 ml-s8">Message Details</span></div>

     <div className="mb-s12 text-fs10 font-fw600 flex justify-start ml-s4"><span className="text-cBtnBorder">Dashboard </span><IoChevronForwardOutline className="my-s4"/><span>Invoice Details</span></div>

            <div className="border-4 border-cChartBg2 rounded-2xl">
              <div className="w-[100px] lg:col-span-1 md:w-full"></div>
              <div className="w-full lg:col-span-2 lg:block">
                <div className="w-full grid conversation-row-grid">
                  <div className="relative flex items-center p-s12 text-cTextBlack">
                    <img
                      className="object-cover w-28 h-28 rounded-full"
                      src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                      alt="username"
                    />
                    <div className="block ml-s8 my-s8 font-bold text-cTextBlack">
                      <span className="text-fs20 ">Shahid Sumon</span><br></br>
                      <span className="text-fs12" >shahidsumon@gmail.com</span><br></br>
                      <span className="text-fs12">+8801644461655</span><br></br>
                      <span className="text-fs12">Joined : 5 jun 2022</span>
                    </div>
                   
                  </div>

                  <div className="relative w-full p-s15 overflow-y-auto">
                    <ul className="space-y-6">
                    
                      <li>
                      <span className="flex justify-start">10:48</span>
                      <div className="flex justify-start">
                        <div className="relative max-w-xl px-s16 py-s8 bg-cLink rounded-bl-lg shadow">
                          <span className="block text-cLine">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</span>
                        </div>
                        </div>
                      </li>

                      <li>
                      <span className="flex justify-end">10:49</span>
                      <div className="flex justify-end">
                        <div className="relative max-w-xl px-s16 py-s8 text-cListTitle bg-cNmSelect rounded shadow">
                          <span className="block">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups </span>
                        </div>
                        </div>
                      </li>
          
                      <li>
                      <span className="flex justify-start">10:50</span>
                        <div className="flex justify-start">
                        <div className="relative max-w-xl px-s16 py-s8 text-cLine bg-cLink rounded shadow">
                          <span className="block">
                          Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups
                          </span>
                        </div>
                        </div>
                      </li>

                      <li >
                      <span className="flex justify-end">10:52</span>
                        <div className="flex justify-end">
                        <div className="relative max-w-xl px-s16 py-s8 text-cListTitle bg-cNmSelect rounded shadow">
                          <span className="block">Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</span>
                        </div>
                        </div>
                      </li>
  
                    </ul>
                  </div>

                  <div className="flex items-center justify-between w-full p-s12 ">
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-s24 h-s24 text-cListTitle"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>

                    <input
                      type="text"
                      placeholder="Message"
                      className="block w-full py-s8 pl-s16 mx-s12 bg-cNmSelect focus:ring focus:ring-cChartBg2 rounded-full outline-none focus:text-gray-700"
                      name="message"
                      required
                    />
                    <button type="submit">
                      <svg
                        className="w-s20 h-s20 text-cListTitle origin-center transform rotate-90"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </div>
  );
}

export default LayoutTest3;
