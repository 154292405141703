import { t } from 'i18next';
import { useContext, useEffect } from "react";
import ScanSearchSolidBox from '../../../../../../Components/Input/ScanSearchSolidBox';
import ListSkeleton01 from '../../../../../../Components/Skeletons/ListSkeleton01';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

import RoutePlan from "../RoutePlan";
const RoutePlannerList = ({ setTargetStop, isInAddStop = false }) => {
  const {
    routeList,
    setCurrentRouteSelection,
    isLoading,
    searchStop,
    searchStoptKey,
    setSelectedStop,
  } = useContext(TrackOnlineContext);

  // hg set selection to null on mount component
  useEffect(() => {
    setCurrentRouteSelection(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="col-span-12 lg:col-span-5 mt-5">
      <div className="bg-white shadow rounded-xl flex-col justify-start">
        {/* search-bar */}
        <div className="flex items-center justify-center px-5 pt-5 pb-s10 ">
          <ScanSearchSolidBox
            className={"w-full"}
            placeholder={t("Search")}
            type="search"
            onChange={(e) => {
              searchStop(e);
              setCurrentRouteSelection(null);
              setSelectedStop(null);
            }}
            name="searchKey"
            value={searchStoptKey}
          />
        </div>
        <ul className="overflow-y-auto max-h-[60vh] scroll-smooth">
          {isLoading ? (
            <ListSkeleton01 />
          ) : routeList.length ? (
            routeList.map((item, index) => (
              <RoutePlan key={index} data={item} setTargetStop={setTargetStop} isInAddStop={isInAddStop} />
            ))
          ) : (
            <div className="text-center mx-auto font-semibold gs-text-placeholder italic">
              {t("No Results Available")}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default RoutePlannerList;
