/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFavAddressStore, { getAllFavAddress } from '../../../../App/stores/FavAddressStore';
import CircularProgressBar from '../../../../Components/CircularProgressBar';
import BackLinks from '../../../../Components/Layout/BackLinks';
import { FavoriteAddressContext } from '../../../../Context/FavoriteAddressContext';
import { iRefresh } from '../../../../Utility/Sources';
import { PageTitle } from '../../../../Utility/UtilityFunctions';
import Address from './Address';
import UpdateAddress from './UpdateAddress';
import EmptyMessage from '../../../../Components/EmptyMessage';
import LimadiSearchBox from '../../../../Components/Input/LimadiSearchBox';

function FavoriteAddress({ userType }) {
    const { searchFavoriteAddress } = useContext(FavoriteAddressContext);

    const { setSearchKey, setIsAddAddress, allFavAddressList, addAddressSelected, setAddAddressSelected, setUpdateAddress, addressKey } = useFavAddressStore();

    const { company_id } = useParams();
    const { customer_id } = useParams();
    const { driver_id } = useParams();

    const [u_ID, setU_ID] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
        PageTitle("Limadi | Favorite Address")

        if (userType === "company") {
            getAllFavAddress(company_id);
            setU_ID(company_id);
        }
        else if (userType === "customer") {
            getAllFavAddress(customer_id);
            setU_ID(customer_id);
        }
        else if (userType === "driver") {
            getAllFavAddress(driver_id);
            setU_ID(driver_id);
        }
        setSearchKey("")
    }, [])


    return (
        <>
            {userType === "company" ?
                <BackLinks
                    linksArray={[
                        { label: "companies", linkTo: "/users/companies" },
                        { label: "company Details", linkTo: "/users/companies/details/" + company_id },
                        { label: "favorite address", linkTo: "" },
                    ]}
                /> : ""}

            {userType === "customer" ?
                <BackLinks
                    linksArray={[
                        { label: "customers", linkTo: "/users/customers" },
                        { label: "customer Details", linkTo: "/users/customers/details/" + customer_id },
                        { label: "favorite address", linkTo: "" },
                    ]}
                /> : ""}

            {userType === "driver" ?
                <BackLinks
                    linksArray={[
                        { label: "drivers", linkTo: "/users/drivers" },
                        { label: "driver Details", linkTo: "/users/drivers/details/" + driver_id },
                        { label: "favorite address", linkTo: "" },
                    ]}
                /> : ""}


            {/* title */}
            <div className='flex flex-row justify-between align-middle card'>
                <div className='flex items-center w-2/3'>
                    <div className='text-2xl font-bold mt-[5px] mr-5'>{t("Favorite Address")}{" "}({allFavAddressList.length})
                    </div>
                    {/* refresh icon button */}
                    {
                        0 ?
                            <CircularProgressBar />
                            :
                            <img
                                onClick={() => {
                                    setSearchKey("");
                                    getAllFavAddress(u_ID);
                                }}
                                src={iRefresh}
                                alt="refresh-icon"
                                className='h-8 cursor-pointer'
                            />

                    }
                </div>

                {/* <CommonButtonOutlined
                    btnLabel={t("Add Favorite Address")}
                    onClick={() => {
                        setIsAddAddress(true);
                        setAddAddressSelected("");
                    }}
                    width="w-[180px]"
                /> */}
            </div>

            {/* content */}
            <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">

                {/* favorite address list */}
                <div className="col-span-12 mt-5 bg-white rounded-xl shadow lg:col-span-4">
                    <div className="flex-col justify-start">
                        {/* search bar */}
                        <div className='px-5 py-5'>
                            <LimadiSearchBox
                                fullWidth={true}
                                onSearchClear={() => {
                                    setSearchKey("");
                                    searchFavoriteAddress("");
                                }}
                                withClearSearch={true}
                                placeholder={t('search')}
                                type="search"
                                onChange={(e) => { searchFavoriteAddress(e.target.value) }}
                                name="searchKey"
                                value={addressKey}
                            />
                        </div>

                        <ul className='overflow-y-auto max-h-[60vh] scroll-smooth'>
                            {
                                allFavAddressList?.length > 0 ? allFavAddressList.map((item, index) =>
                                    <Address
                                        key={index}
                                        index={index}
                                        data={item}
                                        isSelected={addAddressSelected === index ? true : false}
                                        onSelect={() => {
                                            setAddAddressSelected(index);
                                            setUpdateAddress(item);
                                            setIsAddAddress(false);
                                        }}
                                    />
                                )
                                    : <EmptyMessage />
                            }
                        </ul>
                    </div>

                </div>

                {/* favorite address details */}
                <div className="col-span-12 mt-5 lg:col-span-8">
                    {/* {isAddAddress ? <AddAddress u_id={u_ID} /> : <UpdateAddress u_id={u_ID} />} */}
                    {
                        allFavAddressList?.length > 0 ? <UpdateAddress u_id={u_ID} /> :

                            <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
                                <EmptyMessage message={t('Please select a favorite Address to see details.')} />
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default FavoriteAddress