import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useContactStore, { appMarkUnseenMessage, HandleReplyMessages } from '../../App/stores/ContactStore'
import { NoUserImage } from '../../App/Utility/ImageImports'
import CommonButton from '../../Components/Button/CommonButton'
import CommonShowMoreLess from '../../Components/CommonShowMoreLess'
import CommonLabel from '../../Components/Label/CommonLabel'
import CommonModal from '../../Components/Modal/CommonModal'
import { BaseUrlSrc } from '../../Utility/url'
import { formatDate } from '../../Utility/UtilityFunctions'

export default function ReplyMessage({ data }) {
    const { selectedMessageID, contactMessageList, showReplyMessage, setShowReplyMessage } = useContactStore();

    const { t } = useTranslation();

    const [targetMsg, setTargetMsg] = useState({});

    useEffect(() => {
        let temp = contactMessageList?.data?.length > 0 ? contactMessageList?.data.find((item) =>
            item?.id === selectedMessageID ? item : null
        ) : "";
        console.log(temp);
        setTargetMsg(temp);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMessageID, contactMessageList]);

    return (
        <CommonModal
            showModal={showReplyMessage}
            setShowModal={setShowReplyMessage}
            modalTitle={t("Message Details")}
            // subtitle="Change License Status"
            mainContent={
                <ReplyForm targetMsg={targetMsg} />
            }
        />
    )
}

function ReplyForm({ targetMsg }) {

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { contactMessageReply, setShowReplyMessage, setContactMessageReply, selectedMessageID, setShowDeleteMessage } = useContactStore();
    console.log(targetMsg);
    const handleSubmitReply = () => {
        console.log("LOL");
        if (contactMessageReply) {
            console.log("OKK", contactMessageReply, selectedMessageID);
            let replySuccess = HandleReplyMessages();
            if (replySuccess) {
                setShowReplyMessage(false);
            }
        }
    }
    return (
        <div className='pt-5 w-full bg-white rounded-br10'>
            <div className='flex justify-between items-center w-full bg-cMessageTitleBG p-s10 rounded-br10'>
                <div className='flex items-center space-x-5'>

                    <div className=''>
                        <div className="relative">
                            <img className='object-cover w-11 h-11 rounded-full border-4 border-white' src={targetMsg?.user?.image ? (BaseUrlSrc + targetMsg?.user?.image) : NoUserImage} alt="user-img" />
                            {targetMsg?.is_seen === 0 ?
                                <div className='text-white bg-cRed px-s5 rounded-full text-[10px] w-fit absolute -top-1 -right-4'>New</div>
                                : ""}
                        </div>
                    </div>
                    <div>
                        <div className='text-fs14 font-fw600'>{targetMsg?.user?.name}</div>
                        <div className='text-fs14 text-cBodyText'>{targetMsg?.user?.email}</div>
                    </div>
                </div>
                <CommonButton
                    onClick={() => {
                        if (targetMsg?.user?.role === 'driver') {
                            setShowReplyMessage(false)
                            navigateTo(`/users/drivers/details/${targetMsg?.user?.id}`)
                        }
                        else if (targetMsg?.user?.role === 'company') {
                            setShowReplyMessage(false)
                            navigateTo(`/users/companies/details/${targetMsg?.user?.id}`)
                        }
                        else if (targetMsg?.user?.role === 'private') {
                            setShowReplyMessage(false)
                            navigateTo(`/users/customers/details/${targetMsg?.user?.id}`)
                        }
                    }}
                    fullRounded={true}
                    btnLabel="View Profile"
                    width='w-[110px]' />
            </div>

            <div className='pt-5'>
                <div >
                    <div className='flex pb-5'>
                        <div className='text-sm font-semibold capitalize text-fs16 text-cChipText'>Subject:</div>
                        <span className="pl-s5 text-cMessageReplyBody text-fs14">
                            {targetMsg?.subject}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <CommonLabel labelText={t("Message Details")} />
                        <div className='text-cIcon'>
                            {formatDate(targetMsg?.created_at)}
                        </div>
                    </div>
                    <div className='w-full rounded-br10 mt-s10'>
                        <div className='text-fs14 overflow-hidden'>
                            {<CommonShowMoreLess totalLetters="90" data={targetMsg?.message} />}
                        </div>
                        {/* <div className='flex justify-end items-center mt-3'>
                            <small className='text-fs12 font-fw600 text-cBodyText'>
                                <div className='flex items-center space-x-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <div>
                                    </div>
                                </div>
                            </small>
                        </div> */}
                    </div>
                </div>

                <div className="pt-5">
                    <hr />
                </div>

                {targetMsg?.reply ?
                    <div className='pt-5'>
                        <CommonLabel labelText={t("Reply")} />
                        <div className='w-full bg-cMessageReplyBG rounded-br10 p-s20 mt-s10'>
                            <p className='break-all'>
                                {targetMsg?.reply}
                            </p>
                            <div className='flex justify-end items-center mt-3'>
                                <small className='text-fs12 font-fw600 text-cBodyText'>
                                    <div className='flex items-center space-x-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <div>
                                            {formatDate(targetMsg?.updated_at)}
                                        </div>
                                    </div>
                                </small>
                            </div>
                        </div>
                    </div>
                    : ""
                }

                {/* {targetMsg?.reply ?
                    (
                        <div className="pt-5">
                            <CommonButton btnLabel={t("Delete")} colorType="danger" onClick={() => setShowDeleteMessage(true)} />
                        </div>
                    ) :
                    <form onSubmit={(e) => e.preventDefault()} className='pt-5'>
                        <CommonInput
                            required={true}
                            textarea={true}
                            max_char_limit={500}
                            is_show_char_limit={true}
                            name={'body'}
                            label={t("Reply Message")}
                            placeholder={t("Write Message")}
                            value={contactMessageReply}
                            onChange={(e) => setContactMessageReply(e.target.value)}
                        />
                        <div className='flex justify-between pt-5'>
                            <CommonButton btnLabel={t("Delete")} colorType="danger" onClick={() => setShowDeleteMessage(true)} />
                            <CommonButton btnLabel={t("Send Reply")} type="submit" onClick={handleSubmitReply} />
                        </div>
                    </form>
                    } */}

                <div className='mt-s20 flex justify-center'>
                    <CommonButton
                        onClick={() => {
                            const success = appMarkUnseenMessage(targetMsg?.id)
                            if (success) { setShowReplyMessage(false) }
                        }}
                        btnLabel='Mark Unseen' />
                </div>
            </div>
        </div>
    )
}