import { t } from 'i18next';
import { useContext } from 'react'
import useCompanyStore from '../../../../../../App/stores/CompanyStore';
import { takeShiftAction } from '../../../../../../App/stores/ShiftPlannerStore';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';

export default function StartStopShiftModal({ showModal, setShowModal, status, shift_id }) {
  const { getShiftPlannerDetails, getShiftPlannerList, setUpdateShift, setIsShiftDetailsID, setIsShiftDetailsData } = useContext(ShiftPlannerContext);
  const { companyID } = useCompanyStore();

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t("Shift Action")}
      mainContent={
        <>
          <div className="py-5 text-center text-fs16">
            {status === 'start' ? t("Are you sure you want to start this shift?") : t("Are you sure you want to stop this shift?")}
          </div>

          <div className="flex justify-center">
            <CommonButton
              btnLabel={t("Confirm")}
              onClick={async () => {
                //! to stop the shift need to pass action as complete
                let actionSuccess = await takeShiftAction((status === 'start' ? 'start' : 'complete'), shift_id, getShiftPlannerDetails, getShiftPlannerList, companyID, true);
                if (actionSuccess) {
                  await setUpdateShift({});
                  await setIsShiftDetailsID(null);
                  await setIsShiftDetailsData({});

                  setShowModal(false);
                }
              }}
            />

          </div>
        </>
      }
    />
  )
}
