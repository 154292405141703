import React, { useEffect, useState } from "react";

const CommonButton = ({
  btnLabel = "Trek",
  colorType = "primary",
  isDisabled = false,
  customStyle,
  onClick = () => { },
  width = "w-[140px]",
  type = 'button',
  fullRounded = false,
  dropShadow = false,
  leftIcon,
  leftIconPadding="pr-5"
}) => {
  // colorType="basic", "danger", "primary", "success", "warning", "white"
  const [colorCode, setColorCode] = useState("bg-cPlaceholder");
  const [textColorCode, setTextColorCode] = useState("text-white");

  const colorCheckup = (colorType) => {
    switch (colorType) {
      case "basic":
        setColorCode("bg-cPlaceholder");
        setTextColorCode("text-cTextBlack");
        break;
      case "danger":
        setColorCode("bg-cRed");
        setTextColorCode("text-white");
        break;
      case "primary":
        setColorCode("bg-cBrand hover:bg-cBrandDark");
        setTextColorCode("text-white");
        break;
      case "success":
        setColorCode("bg-cSuccess");
        setTextColorCode("text-white");
        break;
      case "warning":
        setColorCode("bg-cPending");
        setTextColorCode("text-white");
        break;
      case "white":
        setColorCode("bg-white");
        setTextColorCode("text-cBrand");
        break;

      default:
        setColorCode("bg-cPlaceholder");
        setTextColorCode("text-cTextBlack");
        break;
    }
  };

  useEffect(() => {
    colorCheckup(colorType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorType]);

  return (
    <button
      type={type}
      onClick={() => {
        if (!isDisabled)
          onClick();
      }}
      className={`outline-none flex items-center justify-center select-none h-s36 font-fw600 text-fs14 capitalize duration-300   
      ${fullRounded ? "rounded-full" : "rounded-br5"}
      ${dropShadow ? "opacity-100 drop-shadow-sm" : "drop-shadow-none"}
      ${customStyle}
      ${colorCode}
      ${isDisabled ? "cursor-not-allowed bg-cPlaceholder text-cWhite" : "cursor-pointer"}
      ${textColorCode}
      ${width}       
      `}
    >
      {leftIcon && <div className={`${leftIconPadding}`}>{leftIcon}</div>}
      <div>{btnLabel}</div>
    </button>
  );
};

export default CommonButton;
