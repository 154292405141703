import React from "react";
import { useState } from "react";
import { AddIconBrand, AddIconWhite } from "../../App/Utility/ImageImports";

export default function AddBtnOutlined({
  btnLabel = "Add Something",
  isDisabled = false,
  CustomStyles,
  onClick,
  width,
}) {
  const [onFocusStatus, setOnFocusStatus] = useState(false);

  return (
    <div
      onMouseOver={() => setOnFocusStatus(true)}
      onMouseOut={() => setOnFocusStatus(false)}
      onClick={onClick}
      className={`px-5 h-s36  flex items-center justify-center rounded-br5 select-none ring-[1px] ring-cBrand w-fit
      hover:text-white hover:bg-cBrand duration-300
      text-cBrand text-fs14 font-fw500
      ${isDisabled ? "cursor-not-allowed" : "cursor-pointer"}
      ${CustomStyles}    
      ${width} 
      `}
    >
      <div className="w-s16 h-sw-s16 mr-s10">
        <img
          src={onFocusStatus === true ? AddIconWhite : AddIconBrand}
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
      <div className="capitalize">{btnLabel}</div>
    </div>
  );
}
