import { t } from 'i18next';
import { useContext } from "react";
import SearchBoxSolid from '../../../../../../Components/Input/SearchBoxSolid';
import ListSkeleton01 from '../../../../../../Components/Skeletons/ListSkeleton01';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import Request from './Request';

const RequestList = () => {
  const { isLoading } = useContext(ShiftPlannerContext);
  const { requestList, searchRequest, searchRequestKey, setSelectedRequest } =
    useContext(TrackOnlineContext);

  return (
    <div className="col-span-12 xl:col-span-5 mt-5">
      <div className="bg-white shadow rounded-xl flex-col justify-start pb-4">
        {/* search bar */}
        <div className="flex justify-between items-center px-5 pt-5 pb-s10">
          <div className="w-full ">
            <SearchBoxSolid
              placeholder={t("search")}
              type="search"
              onChange={(e)=>{
                searchRequest(e);
                setSelectedRequest(null)
              }}
              name="searchKey"
              value={searchRequestKey}
            />
          </div>
        </div>
        <ul className="overflow-y-auto overflow-x-hidden max-h-[60vh] scroll-smooth">
          {isLoading ? (
            <ListSkeleton01 />
          ) : requestList?.length ? (
            requestList.map((item, index) => (
              <div key={index} className="mt-[10px] pr-5">
                <Request requestData={item} />
              </div>
            ))
          ) : (
            <div className="text-center mx-auto font-semibold gs-text-placeholder italic">
              {t("No Results Available")}
            </div>
          )}
          {/* <div className="mb-3"></div> */}
        </ul>
      </div>
    </div>
  );
};

export default RequestList;
