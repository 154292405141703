import React from 'react';
import useDriverStore from '../../../../App/stores/DriverStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

const AssignedCompaniesModal = () => {

    const { showAssignedCompaniesModal, setShowAssignedCompaniesModal } = useDriverStore();

    return (
        <div>
            <CommonModal
                showModal={showAssignedCompaniesModal}
                setShowModal={setShowAssignedCompaniesModal}
                modalTitle="Assigned Companies"
                mainContent={
                    <>
                        <div className='py-s20 text-center'>Are you sure you want to Accept the Company ?</div>

                        <div className='flex justify-center'>
                            <CommonButton
                                btnLabel='ok'
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default AssignedCompaniesModal;