import React from 'react';
import { Link } from 'react-router-dom';

const OverViewItem = ({ title = "", data = "", cursor = "cursor-pointer" }) => {
    return (
        <div>
            {/* <Link to="package-details"> */}
                <div className={`text-center flex flex-col p-2 bg-cListItem rounded-br5  w-[140px] ${cursor} select-none`}>
                    <span className="text-sm">{title}</span>
                    <span className="text-center text-[18px] font-semibold gs-text-main-black">
                        {data}
                    </span>
                </div>
            {/* </Link> */}
        </div>
    );
};

export default OverViewItem;