import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import UploadButton from '../../../../../../Components/Button/UploadButton';
import CommonInput from '../../../../../../Components/Input/CommonInput';
import Dropzone from '../../../../../../Components/Input/DropzoneImageUploader';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { getBase64 } from '../../../../../../Utility/UtilityFunctions';

const ConfirmDelivery = ({ status = -2, company_id, shift_id }) => {
  const {
    setShowIncompleteDeliveryModal,
    setShowDeliveryModal,
    setSignatureImage,
    signatureImage,
    attachmentImage,
    setAttachmentImage,
    confirmationComment,
    setConfirmationComment,
    takeStopAction,
    setSelectedStop,
    setCurrentRouteSelection,
    getRoutePlannerList,
    getTrackOnlineList,
  } = useContext(TrackOnlineContext);
  const [title, setTitle] = useState("");

  // hg process image into base64 after upload selected
  const onPickImageUpSignature = (file) =>
    getBase64(file, (result) => setSignatureImage(result));
  const onPickImageUpAttachment = (file) =>
    getBase64(file, (result) => setAttachmentImage(result));

  //   hg set the dynamic title
  useEffect(() => {
    if (status === 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setTitle(t("Mark as Complete"));
    } else {
      setTitle(t("Mark as Incomplete"));
    }
    setAttachmentImage(null);
    setSignatureImage(null);
    setConfirmationComment("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="gs-text-main-black">
      <div className="relative">
        <AiFillCloseCircle
          onClick={() => {
            if (status === 1) {
              setShowDeliveryModal(false);
            } else {
              setShowIncompleteDeliveryModal(false);
            }
          }}
          className="absolute top-0 right-0 text-2xl cursor-pointer text-gray-600"
        />
        <div className="pb-5 text-center font-bold text-xl">{title}</div>
        <div className="pb-5 text-center">
          {!signatureImage ? (
            <Dropzone
              onUpload={onPickImageUpSignature}
              content={
                <>
                  <UploadButton
                    // onClick={() => {
                    //   console.log("Signature");
                    // }}
                    title={t("Signature")}
                    subTitle={t("Only 1 signature image at a time. Maximum 10 MB.")}
                  />
                </>
              }
            />
          ) : (
            <div className="mx-auto w-full h-[150px] rounded-lg overflow-hidden shadow-lg relative">
              <AiFillCloseCircle
                onClick={() => {
                  setSignatureImage(null);
                }}
                className="absolute top-0 right-0 text-2xl cursor-pointer gs-text-red"
              />
              <img
                className="w-full h-full object-cover"
                src={signatureImage}
                alt=""
              />
            </div>
          )}
          <div className="pb-s5"></div>
          <CommonInput
            textarea={true}
            max_char_limit={500}
            is_show_char_limit={true}
            placeholder={t("Type comment here..")}
            onChange={(e) => setConfirmationComment(e.target.value)}
            value={confirmationComment}
          />
          <div className="pb-5"></div>
          {!attachmentImage ? (
            <Dropzone
              onUpload={onPickImageUpAttachment}
              content={
                <>
                  <UploadButton
                    // onClick={() => {
                    //   console.log("Attachment");
                    // }}
                    title={t("Attachment")}
                    subTitle={t("Only 1 attachment at a time. Maximum 10 MB.")}
                  />
                </>
              }
            />
          ) : (
            <div className="mx-auto w-[41.75rem] h-[150px] rounded-lg overflow-hidden shadow-lg relative">
              <AiFillCloseCircle
                onClick={() => {
                  setAttachmentImage(null);
                }}
                className="absolute top-0 right-0 text-2xl cursor-pointer gs-text-red"
              />
              <img
                className="w-full h-full object-cover"
                src={attachmentImage}
                alt=""
              />
            </div>
          )}
        </div>

        <div className="flex flex-row justify-center">
          <CommonButton
            btnLabel={title}
            width={"w-[180px]"}
            onClick={async () => {
              if (status === 1) {
                //b    when status is 1:      
                await takeStopAction("delivered", company_id);
                setShowDeliveryModal(false);
              } else {
                //b    when status is not 1:      
                await takeStopAction("not_delivered", company_id);
                setShowIncompleteDeliveryModal(false);
              }

              if (window.location.pathname.includes("route-planner")) {
                console.log("RELOADING route planner Lists");
                setSelectedStop(null);
                setCurrentRouteSelection(null);
                await getRoutePlannerList(shift_id, true);
                await getTrackOnlineList(false, company_id);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelivery;
