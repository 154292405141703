import React from "react";
import { BsClock } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";
import { formatDate } from "../../Utility/UtilityFunctions";

function NotificationItem({ data, isSelected, onSelect }) {
  return (
    <div className="flex flex-row justify-start items-center" onClick={onSelect}>
      <div className={` w-[5px] h-[50px] my-[5px] ${isSelected ? "bg-slate-500" : "bg-[#F8F8F8]"} py-0`}></div>

      <div className=" flex flex-row h-[80px] w-full justify-between items-center bg-[#F8F8F8] my-[5px] ml-[15px] mr-[20px] rounded-md cursor-pointer">
        <div className="flex flex-col pl-[10px] w-full">
          <div className={`font-bold text-sm mb-1 ${data.is_seen === 1 ? "text-[#828282]" : "text-[#333333]"}`} >
            {data.title}
          </div>

          <div className={`text-xs ${data.is_seen === 1 ? "text-[#828282]" : "text-[#595959] text-ellipsis"}`} >
          {data.description.toString().length > 40 ? (data.description.substring(0, 40) + '...') : data.description}
          </div>

          <div className="flex justify-end mr-2.5 items-center text-gray-500 text-xs mt-1">
            <div className="flex justify-end items-center mr-4">
              <FaRegCalendarAlt className="mr-1 text-sm" />
              {formatDate(data.created_date)}
            </div>
            <div className="flex justify-end items-center ">
              <BsClock className="mr-1 text-sm" />
              {data.created_time}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationItem;
