import { t } from 'i18next';
import { useContext, useEffect } from "react";
import Accordion from '../../../../../../Components/Accordion';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import StopDetailAccordion from "./StopDetailAccordion";

const StopsListAccordion = ({ ShiftSummeryData }) => {
  const { isOpenFIrstAccordion, setIsOpenFIrstAccordion } =
    useContext(TrackOnlineContext);

  useEffect(() => {
    console.log("isOpenFIrstAccordion::::", isOpenFIrstAccordion);
  }, [isOpenFIrstAccordion]);
  return (
    <div className="bg-cMoreLiteGrey p-[10px] pb-[20px] rounded-[5px] max-h-[500px] overflow-y-auto">
      {ShiftSummeryData && ShiftSummeryData.stops ? (
        ShiftSummeryData.stops.map((item, index) => (
          // console.log(item.title),
          <Accordion
            key={index}
            accordion_type="dependent"
            isInitOpen={
              isOpenFIrstAccordion === item?.title ? true : false
            }
            customOnClick={() => setIsOpenFIrstAccordion(item?.title)}
            classNameHeader="text-[16px] font-semibold"
            header={item.title}
            body={
              <StopDetailAccordion
                key={Math.floor(Math.random() * 1000) + 1}
                targetData={item}
              />
            }
          />
        ))
      ) : (
        <div className="text-sm font-semibold gs-text-list-icon-color italic">
          {t("No Stops found !")}
        </div>
      )}
    </div>
  );
};

export default StopsListAccordion;
