import useGeneralStore from "../../App/stores/GeneralStore";
import { iRefresh } from "../../Utility/Sources";
import CircularProgressBar from "../CircularProgressBar";

const TitleBar = ({ label, count, onClick, showLoadingBtn = true, rightCornerComponent, hideCounter = false }) => {

  const { isLoading } = useGeneralStore();

  return (
    <>
      <div className="flex flex-row justify-between p-5 mb-5 w-full bg-white shadow-sm rounded-br20">
        <div className="flex justify-between items-center w-full">

          {/* left side items */}
          <div className="flex items-center text-fs24 font-fw600">
            <div className="capitalize text-fs28 font-fw600">{label}</div>
            {!hideCounter && <div className="pl-s5">({count ?? 0})</div>}

            {showLoadingBtn &&
              <div className="pl-5">
                {
                  isLoading ?
                    <CircularProgressBar />
                    :
                    <img
                      onClick={onClick}
                      src={iRefresh}
                      alt="refresh-icon"
                      className='cursor-pointer'
                    />
                }
              </div>}
          </div>

          {/* right side items */}
          <div>
            {rightCornerComponent}
          </div>

        </div>

      </div>
    </>
  );
}

export default TitleBar;
