import React, { useState } from 'react'
import useCompanyStore from '../../../../App/stores/CompanyStore';
import { resetPassword } from '../../../../App/stores/CustomerStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { checkValidPassword, Toastr } from '../../../../Utility/UtilityFunctions';

export default function ResetPasswordCompany() {

    const { showResetPassCompanyModal, setShowResetPassCompanyModal } = useCompanyStore();

    return (
        <>
            <CommonModal
                showModal={showResetPassCompanyModal}
                setShowModal={setShowResetPassCompanyModal}
                modalTitle="reset password"
                mainContent={
                    <div>
                        <ResetPassForm />
                    </div>
                }

            />
        </>
    )
}

const ResetPassForm = () => {
    const { setShowResetPassCompanyModal, companyDetails } = useCompanyStore();

    const [user_data, setUserData] = useState({
        "user_id": companyDetails?.id,
        "password": '',
        "password_confirmation": '',
    });

    const onChange = (e) => { setUserData({ ...user_data, [e.target.name]: e.target.value }) }


    const HandleResetPassword = async (e) => {
        e.preventDefault()
        if (user_data?.password !== user_data.password_confirmation) return Toastr('Both password fields should be same !');
        if (checkValidPassword(user_data?.password).status === false) return Toastr(checkValidPassword(user_data?.password).message);

        let value = await resetPassword(user_data);
        if (value) setShowResetPassCompanyModal(false);
    }


    return (
        <form onSubmit={HandleResetPassword}>
            <div className="py-5">
                <CommonInput
                    label={"New Password"}
                    placeholder="Enter New Password"
                    required={true}
                    type='password'
                    name="password"
                    value={user_data?.new_password}
                    onChange={onChange}
                    togglePasswordBtn={true}
                />

                <div className="pb-5"></div>

                <CommonInput
                    label={"Confirm Password"}
                    placeholder={"Re-enter New Password"}
                    required={true}
                    type='password'
                    name="password_confirmation"
                    value={user_data?.confirm_password}
                    onChange={onChange}
                    togglePasswordBtn={true}
                />
            </div>
            <div className="flex justify-between">
                <CommonButton onClick={() => setShowResetPassCompanyModal(false)} colorType='danger' btnLabel='cancel' />
                <CommonButton
                    //isDisabled={}
                    type='submit'
                    btnLabel='save changes'
                />
            </div>
        </form>
    )
}