import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuRouteAction } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useTrackOnlineDriverStore = create((set) => ({
    // pickup_points: [],
    // setPickupPoints: (value) => set({ pickup_points: value }),
    // setPickupPoints: (data) => set((state) => state.pickup_points = data),

    stops_pickup_points: [],
    setStopsPickupPoints: (data) => set((state) => state.stops_pickup_points = data),

    isSetPickupPoint: true,
    setIsSetPickupPoint: (data) => set((state) => state.isSetPickupPoint = data),

    // route_planner_pickup_points: [],
    // setRoutePlannerPickupPoints: (data) => set((state) => state.route_planner_pickup_points = data),

    isTrackOnlineListApiCalled: false,
    setTrackOnlineListApiCalled: (value) => set({ isTrackOnlineListApiCalled: value }),

    // isShowReleaseStopModal: false,
    // setShowReleaseStopModal: (data) => set((state) => state.isShowReleaseStopModal = data),

    // shift_id: null,
    // setShiftId: (data) => set((state) => state.shift_id = data),

    hasMarkerPoint: false,
    setHasMarkerPoint: (value) => set({ hasMarkerPoint: value }),

    // hasRoutePlannerMarkerPoint: false,
    // setHasRoutePlannerMarkerPoint: (data) => set((state) => state.hasRoutePlannerMarkerPoint = data),

    // is_custom_stop_date_valid: false,
    // setIsCustomStopDateValid: (data) => set((state) => state.is_custom_stop_date_valid = data),

    // shift_date_time_range: null,
    // setShiftDateTimeRange: (data) => set((state) => state.shift_date_time_range = data),
}))

const { setLoading } = useGeneralStore.getState()


export const releaseStop = async (route_id, action) => {

    try {
        if (!route_id) {
            Toastr(t("Invalid Stop!"));
            return;
        } else if (!action) {
            Toastr(t("Invalid Action!"));
            return;
        }
        setLoading(true)

        const res = await axios.post(kuRouteAction, { id: route_id, action: action });
        console.log('releaseStop: ', res.data)

        if (res.data.success) {
            setLoading(false)
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false)
            return false;
        }
    } catch (err) {
        Toastr(t("An error occurred!"));
        setLoading(false)
        return false
    }
}

export default useTrackOnlineDriverStore;


export const useTrackOnlineDriverPatchStore = create((set) => ({

    hasMarkerPoint: false,
    setHasMarkerPoint: (value) => set({ hasMarkerPoint: value }),

    isSetPickupPoint: false,
    setIsSetPickupPoint: (value) => set({ isSetPickupPoint: value }),

    isShowReleaseStopModal: false,
    setShowReleaseStopModal: (value) => set({ isShowReleaseStopModal: value }),

    shift_id: false,
    setShiftId: (value) => set({ shift_id: value }),

    pickup_points: [],
    setPickupPoints: (value) => set({ pickup_points: value }),

    shift_date_time_range: [],
    setShiftDateTimeRange: (value) => set({ shift_date_time_range: value }),

    is_custom_stop_date_valid: false,
    setIsCustomStopDateValid: (value) => set({ is_custom_stop_date_valid: value }),

    route_planner_pickup_points: [],
    setRoutePlannerPickupPoints: (value) => set({ route_planner_pickup_points: value }),

    hasRoutePlannerMarkerPoint: false,
    setHasRoutePlannerMarkerPoint: (value) => set({ hasRoutePlannerMarkerPoint: value }),

}));