/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useGeneralStore from '../App/stores/GeneralStore';
import SplashScreen from '../Components/Layout/SplashScreen';
import { AuthContext } from '../Context/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isAuth } = useContext(AuthContext);
    const { is_app_initialized, is_user_loading } = useGeneralStore();

    // return !isAuth ? (!is_app_initialized ? <Navigate to="/login" /> : <SplashScreen />) : <Outlet />;
    return (is_app_initialized && !is_user_loading) ? (isAuth ? <Outlet /> : <Navigate to={'/login'} />) : <SplashScreen />;
}

export default ProtectedRoute