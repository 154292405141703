/* eslint-disable array-callback-return */
import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuAdvanceCalculation, kuFastCalculation, kuShiftPlannerDetails, kuShiftPlannerList } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import { here_api_key } from '../Utility/app_secrets';
import useGeneralStore, { suggestionFormat } from './GeneralStore';

const useCreateRequestStore = create((set, get) => ({
    shifts: [],
    shiftsCopy: [],
    setShits: (data) => set((state) => {
        state.shifts = data.shiftList
        state.shiftsCopy = data.shiftList
        if (data.reqData?.shift_plan) {
            const index = state.shifts.findIndex((shift) => shift.id === data.reqData.shift_plan.id)
            state.setSelectedShiftIndex(index)
            // console.log('selected_shift', index);
            state.setShiftInfo({
                shift_id: state.shifts[index].id,
                bid_details: data.reqData.my_bid?.details ?? '',
                budget: parseInt(data.reqData.my_bid?.budget ?? 0),
            })
        } else {
            state.setShiftInfo({
                shift_id: null,
                bid_details: data.reqData.my_bid?.details ?? '',
                budget: parseInt(data.reqData.my_bid?.budget ?? 0),
            })
        }
    }),
    setShiftsSearchResult: (data) => set((state) => state.shifts = data),

    selectedShiftIndex: null,
    setSelectedShiftIndex: (index) => set((state) => state.selectedShiftIndex = index),

    isShiftModalOpen: false,
    setIsShiftModalOpen: (isOpen) => set((state) => state.isShiftModalOpen = isOpen),

    isShiftDetailsLoading: false,
    setIsShiftDetailsLoading: (isOpen) => set({ isShiftDetailsLoading: isOpen }),

    shiftDetails: null,
    setShiftDetails: (data) => set((state) => state.shiftDetails = data),

    isFastCalculate: true,
    setIsFastCalculate: (status) => set((state) => state.isFastCalculate = status),

    isCalculateLoading: false, // both fast and advance calculate
    setIsCalculateLoading: (status) => set((state) => state.isCalculateLoading = status),

    fastCalculationData: null,
    setFastCalculationData: (data) => set((state) => state.fastCalculationData = data),

    isAdvanceCalculate: false,
    setIsAdvanceCalculate: (status) => set((state) => state.isAdvanceCalculate = status),

    advanceCalculationData: null,
    setAdvanceCalculationData: (data) => set((state) => state.advanceCalculationData = data),

    shift_info: {
        bid_details: '',
        shift_id: null,
        budget: 0,
    },
    setShiftInfo: (data) => set((state) => state.shift_info = data),

    validation_info: [],
    setValidationInfo: (data) => set((state) => {
        state.validation_info = data
        console.log('validation_info', state.validation_info);
    }),
    stop_count: 0,
    setStopCount: (data) => set((state) => state.stop_count = data),
    stop_fields_validate: [],
    setStopFieldsValidate: (data) => set((state) => state.stop_fields_validate = data),


    // mass import
    is_show_generate_table_modal: false,
    setShowGenerateTableModal: (data) => set({ is_show_generate_table_modal: data }),
    has_stops: false,
    setHasStops: (data) => set({ has_stops: data }),
    is_mass_import_form_fullscreen: false,
    setMassImportFormFullscreen: (data) => set({ is_mass_import_form_fullscreen: data }),
    stops: [],
    setStops: (data) => {
        set({ stops: data });
        // console.log('stops', data);
    },
    setStopPropertyValue: async (index, property, value, event) => {
        // console.log('setStopPropertyValue', index, property, value, event);
        set(get().stops[index][property] = value);
    },
    removeStop: async (index) => {
        // prevent auto suggestion on paste
        if (get().is_click_on_validate) {
            await set({ is_click_on_validate: false })
            await set({ stops: get().stops.filter((stop, i) => i !== index) });
            setTimeout(() => {
                set({ is_click_on_validate: true })
            }, 500);
        } else {
            set({ stops: get().stops.filter((stop, i) => i !== index) });
        }

    },
    removeAllStops: () => {
        set({ stops: [] });
        set({ invalid_count: 0 });
    },
    pasteStopInfo: (data, property) => {
        data.forEach((info, i) => {
            set(get().stops[i][property] = info);
        })
        Toastr('Info Pasted', 'success');
    },
    pasteData: [],
    setPasteData: (data) => set({ pasteData: data }),
    generateAdditionalStopsAndPasteInfo: (additionStopCount, property) => {
        for (let i = 0; i < additionStopCount; i++) addStop();
        get().pasteStopInfo(get().pasteData, property);
    },
    invalid_count: 0,
    setInvalidCount: (data) => set({ invalid_count: data }),
    first_invalid_index: -1,
    setFirstInvalidIndex: (data) => set({ first_invalid_index: data }),

    validateStops: async (req_date, req_time) => {
        get().setFirstInvalidIndex(-1);
        let all_valid = 1;
        let invalid = 0;
        let stops = get().stops;
        let x = stops.map((stop, i) => {
            stop.stop_reference = stop.title ?? stop.stop_reference;
            if (stop.stop_reference === null || stop.stop_reference?.length === 0) stop.stop_reference = get().request_title;

            // date time validation
            let is_date_time_valid = true;
            const pickup_date_time = new Date(req_date + " " + req_time);
            const stop_date_time = new Date(stop.date + " " + stop.start_time);

            if (stop_date_time < pickup_date_time) is_date_time_valid = false;

            if (!is_date_time_valid) {
                stop.is_date_time_valid = false;
                all_valid *= 0;
                invalid++;
                if (invalid === 1) get().setFirstInvalidIndex(i);
            } else {
                stop.is_date_time_valid = true;
                all_valid *= 1;
            }

            if (stop.product.length === 0) {
                stop.is_product_valid = false;
                all_valid *= 0;
                invalid++;
                if (invalid === 1) get().setFirstInvalidIndex(i);
                // console.log('here', i);
            }
            else {
                stop.is_product_valid = true;
                all_valid *= 1;
            }

            // zip code validation
            if (stop?.zip !== null && stop?.zip !== undefined && stop?.zip !== '') {
                stop.zip = stop.zip.toString();
                stop.zip.trim();
                parseInt(stop.zip);
            }
            if (stop?.zip?.length === 4 && (stop?.zip >= 1000 && stop?.zip <= 9990)) {
                stop.is_zip_valid = true;
                all_valid *= 1;
            } else {
                stop.is_zip_valid = false;
                all_valid *= 0;
                invalid++;
                if (invalid === 1) get().setFirstInvalidIndex(i);
            }
            if (stop?.zip === 0 || stop?.zip === '0') stop.zip = '';

            return stop;
        });
        console.log('validateStops', x);
        set({ stops: x });

        get().setInvalidCount(invalid);
        all_valid *= await get().addressValidation();
        set({ is_every_thing_valid: all_valid === 1 ? true : false });
        all_valid ? Toastr('All stops are valid!', 'success') : Toastr('Please check the required fields!', 'warning');
        get().setIsAddressFieldDisable(true);
        setTimeout(() => { get().setIsAddressFieldDisable(false); }, 1);
    },

    addressValidation: async () => {
        let stops = get().stops;
        let all_valid = 1;
        const { setLoading } = useGeneralStore.getState();
        setLoading(true);
        let all_address = [];

        // getting location lat lan from address using here api
        for (let i = 0; i < stops.length; i++) {
            if (!stops[i].address || !stops[i].zip) {
                all_address.push({ address: stops[i]?.address ?? '', lat: null, lng: null });
                continue;
            }

            // check postal code before hitting api
            let is_postal_code = checkIsPostalCode(stops[i].address + ',' + stops[i].zip);
            console.log('is_postal_code', is_postal_code);
            if (!is_postal_code) {
                all_address.push({ address: stops[i].address, lat: null, lng: null });
                continue;
            }

            // also set invalid only type postal code
            let is_only_postal_code = checkIsOnlyPostalCode(stops[i].address + ',' + stops[i].zip);
            console.log('is_only_postal_code', is_only_postal_code);
            if (is_only_postal_code) {
                all_address.push({ address: stops[i].address, lat: null, lng: null });
                continue;
            }

            let address = await setAddressPickupPoints(stops[i].address.trim() + ',' + stops[i].zip);
            // check search address is in the suggestion result
            // console.log('address', address);
            // console.log('abc', returnAddressWithoutPostalCode(stops[i].address).toLocaleLowerCase());
            let is_included = address?.suggestion?.toLowerCase().includes(returnAddressWithoutPostalCode(address.address).toLocaleLowerCase());
            let is_zip_included = address?.suggestion?.toLowerCase().includes(stops[i].zip);
            if (!is_included || !is_zip_included) {
                all_address.push({ address: stops[i].address, lat: null, lng: null });
                continue;
            }

            all_address.push({ ...address, address: returnAddressWithoutPostalCode(address.address) });
        }
        // console.log('stops', all_address);
        setLoading(false);

        // validating
        let invalid = 0;
        let x = stops.map((stop, i) => {
            stop.address = all_address[i].address;
            stop.address_lat = all_address[i].lat;
            stop.address_lng = all_address[i].lng;
            if (stop.address?.length === 0 || stop.address_lat === null || stop.address.lng === null || stop.address_lat === undefined || stop.address_lng === undefined) {
                stop.is_address_valid = false
                all_valid *= 0;
                invalid++;
                if (invalid === 1) {
                    if (get().first_invalid_index === -1) get().setFirstInvalidIndex(i);
                    else if (get().first_invalid_index > i) get().setFirstInvalidIndex(i);
                    // console.log('here2', i);
                }
            }
            else {
                stop.is_address_valid = true;
                all_valid *= 1;
            }
            return stop;
        });

        set({ stops: x });
        get().setInvalidCount(invalid + get().invalid_count);
        return all_valid;
    },

    is_address_field_disable: false,
    setIsAddressFieldDisable: (data) => set({ is_address_field_disable: data }),
    request_title: '',
    setRequestTitle: (data) => set({ request_title: data }),
    is_every_thing_valid: false,
    setIsEveryThingValid: (data) => set({ is_every_thing_valid: data }),
    is_click_on_validate: false,
    setIsClickOnValidate: (data) => set({ is_click_on_validate: data }),
}))

const { setLoading } = useGeneralStore.getState()



/*
|--------------------------------------------------------------------------
| Api Related functions 
|--------------------------------------------------------------------------
|
|
*/
export const getShiftPlannerList = async (start_date, start_time, reqData) => {
    setLoading(true)
    try {
        const res = await axios.get(kuShiftPlannerList + `?start_time=${start_time}&start_date=${start_date}&is_maintenance=${0}&request_get_shift=${1}`);
        console.log("getShiftPlannerList: ", res.data);

        if (res.data.success) {
            useCreateRequestStore.getState().setShits({ shiftList: res.data.data, reqData: reqData });
        } else {
            Toastr(res.data.message);
        }
        setLoading(false)
    } catch (err) {
        console.log(err);
        Toastr(t("An error occurred!"));
        setLoading(false)
    }
};

export const getShiftDetails = async (id, user_id) => {
    try {
        const { setShiftDetails } = useCreateRequestStore.getState();
        const { setIsShiftDetailsLoading } = useCreateReqPatchStore.getState();

        setIsShiftDetailsLoading(true);
        console.log("getShiftDetails BODY::::::", id, user_id);
        const res = await axios.get(kuShiftPlannerDetails + `?id=${id}&user_id=${user_id}`);
        console.log("getShiftDetails:", res.data);

        if (res.data.success) {
            setShiftDetails(res.data.data);
            setIsShiftDetailsLoading(false);
        } else {
            Toastr(res.data.message);
        }
    } catch (err) {
        console.log('getShiftDetails: ', err);
        Toastr(t("An error occurred!"));
    }
};

export const getFastCalculationData = async (request_id) => {
    console.log('request_id', request_id);
    try {
        // const { setFastCalculationData } = useCreateRequestStore.getState();
        const { setIsCalculateLoading, setFastCalculationData } = useCreateReqPatchStore.getState();
        setIsCalculateLoading(true);
        const res = await axios.get(kuFastCalculation + `?req_id=${request_id}`);
        console.log("getFastCalculationData:", res.data);
        if (res.data.success) {
            setFastCalculationData(res.data.data);
            setIsCalculateLoading(false);
        } else {
            Toastr(res.data.message);
        }
    } catch (err) {
        console.log(err);
        Toastr(t("An error occurred!"));
    }
}

export const getAdvanceCalculationData = async (request_id, shift_id) => {
    console.log('getAdvanceCalculationData: ', request_id, shift_id);

    const { setAdvanceCalculationData } = useCreateRequestStore.getState();
    const { setIsCalculateLoading } = useCreateReqPatchStore.getState();
    try {
        setIsCalculateLoading(true);
        const res = await axios.get(kuAdvanceCalculation + `?req_id=${request_id}&shift_id=${shift_id}`);
        console.log("getAdvanceCalculationData:", res.data);
        if (res.data.success) {
            setAdvanceCalculationData(res.data.data);
            setIsCalculateLoading(false);
        } else {
            Toastr(res.data.message);
            setIsCalculateLoading(false);
        }
    } catch (err) {
        setIsCalculateLoading(false);
        console.log(err);
        Toastr(t("An error occurred!"));
    }
}




/*
|--------------------------------------------------------------------------
| Helper Functions
|--------------------------------------------------------------------------
*/

export const searchShift = (key) => {
    const { shiftsCopy, setShiftsSearchResult } = useCreateRequestStore.getState();

    const result = shiftsCopy.filter((item) => {
        if (
            item.driver_user.name.toLowerCase().includes(key) ||
            item.car.car_license_plate_number.toLowerCase().includes(key)
        ) {
            return item;
        }
    });
    console.log("result: ", key);
    setShiftsSearchResult(result);

}

export const requestFormValidation = (title, transport_type, pickup_date, pickup_start_time) => {
    if (title.length < 1) {
        Toastr('Please enter title', 'warning');
        return false;
    }
    if (transport_type.length < 1) {
        Toastr('Please select transport type', 'warning');
        return false;
    }
    if (pickup_date.length < 1) {
        Toastr('Please select pickup date', 'warning');
        return false;
    }
    if (pickup_start_time.length < 1) {
        Toastr('Please select pickup start time', 'warning');
        return false;
    }
    return true;

}

export const createSimpleRequestAddressValidation = (pickup_lat, pickup_lng, delivery_lat, delivery_lng) => {
    if (!pickup_lat || !pickup_lng) {
        Toastr('Invalid pickup address!', "warning")
        return false
    }
    if (!delivery_lat || !delivery_lng) {
        Toastr('Invalid delivery address!', "warning")
        return false
    } else {
        return true;
    }
}

export const createAdvanceRequestAddressValidation = (pickup_lat, pickup_lng) => {
    if (!pickup_lat || !pickup_lng) {
        Toastr('Invalid pickup address!', "warning")
        return false
    } else {
        return true;
    }
}

export const pickupDateTimeValidation = (pickup_date, pickup_time) => {
    const pickup_date_time = new Date(pickup_date + " " + pickup_time);
    const current_date_time = new Date();
    if (pickup_date_time < current_date_time) {
        Toastr('Invalid pickup date time! You must select a future date time!', "warning")
        return false
    } else return true;
}

export const stopAddValidation = (stops = [], can_add_stop = true, pickup_date, pickup_time, is_from_csv = false) => {
    stops.forEach((stop) => {
        if (stop.title === null || stop.date === null || stop.start_time === null || (!is_from_csv && stop.products.length === 0))
            can_add_stop = false;

        if (!is_from_csv && (stop.address_lat === null || stop.address_lng === null)) can_add_stop = false;

        // date time validation : stop date time must be greater than pickup date time
        if (stop.date && stop.start_time) {
            const pickup_date_time = new Date(pickup_date + " " + pickup_time);
            const stop_date_time = new Date(stop.date + " " + stop.start_time);

            if (stop_date_time < pickup_date_time) can_add_stop = false;
        }

    });

    !can_add_stop && Toastr(`Please check the required fields!`, "warning")

    return can_add_stop;
}

export const setAddressPickupPoints = async (address) => {
    const res = await fetch(`https://geocode.search.hereapi.com/v1/geocode?apiKey=${here_api_key}&q=` + address + "&in=countryCode:DNK")
        .then((response) => response.json())
        .then((json) => {
            console.log('geo-code: ', json);
            try {
                const position = json?.items[0]?.position ?? null;
                // console.log('position: ', position);
                return { address: address, lat: position?.lat, lng: position?.lng, suggestion: suggestionFormat(json?.items[0]?.address) };
            } catch (error) {
                console.log(error);
                return null;
            }
        });
    return res;
}

export const stopInfoValidation = (stops, pickup_date, pickup_time) => {
    let x = [];
    if (stops.length === 1 && checkIfStopAllFieldsAreEmpty(stops[0])) return x;

    // generating validation object for each stop
    stops.forEach((stop) => {
        let y = {};
        (stop.title === null || stop.title === '') ? y.is_title_valid = false : y.is_title_valid = true;

        (stop.date === null || stop.date === '') ? y.is_date_valid = false : y.is_date_valid = true;
        (stop.start_time === null || stop.start_time === null) ? y.is_time_valid = false : y.is_time_valid = true;

        if (stop.date && stop.start_time) {
            const pickup_date_time = new Date(pickup_date + " " + pickup_time);
            const stop_date_time = new Date(stop.date + " " + stop.start_time);

            if (stop_date_time < pickup_date_time) {
                y.is_date_valid = false;
                y.is_time_valid = false
            } else {
                y.is_date_valid = true;
                y.is_time_valid = true;
            }
        } else {
            y.is_date_valid = false;
            y.is_time_valid = false
        }

        (stop.address === '' || stop.address === null || stop.address_lat === null || stop.address_lng === null) ? y.is_address_valid = false : y.is_address_valid = true;

        stop.products.length === 0 ? y.is_products_valid = false : y.is_products_valid = true;

        (!y.is_address_valid || !y.is_date_valid || !y.is_time_valid || !y.is_title_valid || !y.is_products_valid) ? y.is_stop_valid = false : y.is_stop_valid = true;

        x.push(y);
    });

    console.log('stops validation: ', x);
    return x;
}

export const checkIfStopAllFieldsAreEmpty = (stop) => {
    if ((stop.title === null || stop.title === '' || stop.title === undefined) &&
        (stop.products.length === 0) &&
        (stop.date === null || stop.date === '' || stop.date === undefined) &&
        (stop.start_time === null || stop.start_time === '' || stop.start_time === undefined) &&
        (stop.address === null || stop.address === '' || stop.address === undefined) &&
        (stop.comment === null || stop.comment === '' || stop.comment === undefined) &&
        (stop.attachment === null || stop.attachment === '' || stop.attachment === undefined))
        return true;
    else return false;
}

export const allStopsValidation = async (stops) => {
    const { stop_fields_validate, setStopFieldsValidate, z_stops } = useCreateRequestStore.getState();
    let x = stop_fields_validate;
    if (x.length > z_stops.length) {
        x = x.pop();
        setStopFieldsValidate(x);
    }
    await stops.forEach((s, i) => selectedStopValidation(s, i))
    return 0;
};

export const selectedStopValidation = (stop, index) => {
    const { stop_fields_validate, setStopFieldsValidate, z_stops } = useCreateRequestStore.getState();
    let x = stop_fields_validate;
    if (x[index] === undefined) {
        x = []
        console.log('here z_stops', z_stops);
        z_stops.forEach((s, i) => {
            x.push({
                is_stop_valid: true,
                is_title_valid: true,
                is_product_valid: true,
                is_address_valid: true,
            })
        })
        setStopFieldsValidate(x);
    }



    (stop?.title === null || stop?.title === '') ? x[index].is_title_valid = false : x[index].is_title_valid = true;

    (stop?.products?.length === 0) ? x[index].is_product_valid = false : x[index].is_product_valid = true;

    (
        stop?.address === '' ||
        stop?.address === null ||
        stop?.address === undefined ||
        stop?.address_lat === null ||
        stop?.address_lng === null ||
        stop?.address_lat === undefined ||
        stop?.address_lng === undefined ||
        stop?.address_lat === '' ||
        stop?.address_lng === ''
    ) ? x[index].is_address_valid = false : x[index].is_address_valid = true;

    (!x[index]?.is_title_valid || !x[index]?.is_product_valid || !x[index]?.is_address_valid) ? x[index].is_stop_valid = false : x[index].is_stop_valid = true;

    setStopFieldsValidate(x);
}

export const addStop = () => {
    const { stops, setStops } = useCreateRequestStore.getState();
    const newStop = {
        stop_reference: '',
        is_stop_reference_valid: true,
        address: '',
        address_lat: null,
        address_lng: null,
        is_address_valid: true,
        zip: '',
        is_zip_valid: true,
        product: '',
        is_product_valid: true,
        start_time: '',
        end_time: '',
        date: '',
        is_date_time_valid: true,
        comment: '',
    };
    setStops([...stops, newStop]);
}

export const formatPasteData = (data = []) => {
    let index = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].length !== 0) index = i
    }
    return data.slice(0, index + 1);
}

const checkIsPostalCode = (address) => {
    let y = address.split(',');
    let z = y[y.length - 1].trim()
    z = parseInt(z.toString())
    if (z >= 1000 && z <= 9990) { // denmark postal code range is 1000-9990
        return true;
    } else {
        return false;
    }
}

const checkIsOnlyPostalCode = (x) => {
    let y = x.split(',');
    if (y.length < 2) return false;
    let address = '';
    // join all the address except the last one
    for (let i = 0; i < y.length - 1; i++) {
        address += y[i];
    }

    console.log('checkIsOnlyPostalCode: ', address);
    if (address === '') return true;
    else return false;
}

const returnAddressWithoutPostalCode = (x) => {
    let index = x.lastIndexOf(',');
    return x.substring(0, index);
}

export const useCreateReqPatchStore = create((set) => ({

    isShiftModalOpen: false,
    setIsShiftModalOpen: (value) => set({ isShiftModalOpen: value }),

    isFastCalculate: true,
    setIsFastCalculate: (value) => set({ isFastCalculate: value }),

    isCalculateLoading: false,
    setIsCalculateLoading: (value) => set({ isCalculateLoading: value }),

    fastCalculationData: { distance: 0, duration: 0 },
    setFastCalculationData: (value) => set({ fastCalculationData: value }),

    isShiftDetailsLoading: false,
    setIsShiftDetailsLoading: (value) => set({ isShiftDetailsLoading: value }),

}));

export default useCreateRequestStore

