import SwitchToggle from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

function CommonSwitch({
  onClick,
  checked,
  onChange,
  colorHexCode = "#F89818"
}) {
  const PrimarySwitch = styled(SwitchToggle)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colorHexCode,
      "&:hover": {
        backgroundColor: alpha(colorHexCode, theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colorHexCode,
    },
  }));

  return (
    <PrimarySwitch
      onClick={onClick}
      size="small"
      checked={checked}
      onChange={onChange}
    />
  );
}

export default CommonSwitch;
