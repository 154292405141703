import React from 'react'
import { formatDate } from '../../../Utility/UtilityFunctions';
import useTransportationTypesStore from '../../../App/stores/TransportationTypesStore';

const TransportationTypeTableRow = ({ data }) => {
    const { setEditTransportationTypeMode, setShowAddTransportationTypeModal } = useTransportationTypesStore();
    return (
        <tr
            className={`border-b-[1px] border-collapse hover:bg-cGridView select-none border-cNmSelect "font-semibold"`} >
            <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
                <div className='flex items-center'>
                    {data?.title}
                </div>
            </td>

            <td className='p-s10 border-r-[1px] text-center truncate max-w-[200px] min-w-[200px]'>
                {
                    data?.duration ?? 0
                }
            </td>

            <td className={`p-s10 border-r-[1px] text-center truncate max-w-[130px] min-w-[130px] ${data?.status ? "text-cSuccess" : "text-cRed"}`}>
                {
                    data?.status ? "Active" : "Inactive"
                }
            </td>

            <td className={` truncate max-w-[90px] min-w-[80px] p-s10 border-r-[1px] text-center text-fs14 font-fw600 text-cBrand`}>
                <span
                    onClick={() => {
                        setEditTransportationTypeMode(data);
                        setShowAddTransportationTypeModal(true);
                    }}
                    className='cursor-pointer'>
                    EDIT
                </span>
            </td>
        </tr >
    )
}

export default TransportationTypeTableRow