import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUpdateLogger from '../../../../App/Hooks/useUpdateLogger';
import useCompanyStore, { getCompanyDetails } from '../../../../App/stores/CompanyStore';
import useShiftPlannerStore from '../../../../App/stores/ShiftPlannerStore';
import BackLinks from '../../../../Components/Layout/BackLinks';
import { BaseUrlSrc } from '../../../../Utility/url';
import CommonDetailsPanel from '../../CommonDetailsPanel';
import MiniCard from '../../MiniCard';
import CloudRequest from './CloudRequest/CloudRequest';
import RequestSection from '../../Common/RequestSection/RequestSection';
import Content from '../../Common/RequestSection/Content';
import useRequestStore from '../../../../App/stores/RequestStore';
import useGlobalRequestStore from '../../../../App/stores/GlobalRequestStore';
import { PageTitle } from '../../../../Utility/UtilityFunctions';
import { ShiftPlannerContext } from '../../../../Context/ShiftPlannerContext';

export default function CompanyDetails() {

  const { setShowDeactivateCompanyModal, setShowResetPassCompanyModal, setShowDeleteCompanyModal, setShowEditCompanyModal, companyDetails, setCompanyDeleteID } = useCompanyStore();

  useUpdateLogger("companyDetails", companyDetails);

  const {setIsShiftDetailsData}=useContext(ShiftPlannerContext);

  // const { companyTrackOnlineDrive, setCompanyTrackOnlineDrive } = useShiftPlannerStore();

  const { setRequestDetails } = useRequestStore.getState();
  const { setGlobalRequestIndex } = useGlobalRequestStore.getState();

  useEffect(() => {
    getCompanyDetails(parseInt(window.location.pathname.slice(25)));
    PageTitle("Limadi | Company Details");
    window.scrollTo(0, 0);
  }, [])

  const { company_id } = useParams();

  const navigateTo = useNavigate();

  // console.log("companyDetails",companyDetails)

  return (
    <div>
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "" },
        ]}
      />

      <CommonDetailsPanel
        customDeactivateFn={() => { setShowDeactivateCompanyModal(true) }}
        name={companyDetails?.name}
        email={companyDetails?.email}
        image={companyDetails?.image ? companyDetails?.image : ""}
        phone={companyDetails?.phone}
        address={companyDetails?.address}
        memberSince={companyDetails?.joined}
        totalReq={companyDetails?.total_request}
        completedReq={companyDetails?.total_complete_request}
        badge={companyDetails?.cvr}
        totalRating={ parseFloat(companyDetails?.rate?.toFixed(1))}
        is_active={companyDetails?.is_active}
        id={companyDetails?.id}
        userId={company_id}
        userData="company"
        cvr={companyDetails?.cvr}
        social_media={companyDetails?.social_media}
        website={companyDetails?.website}
        admin_access={companyDetails?.admin_access}

        showSecondaryMenu={true}
        secondaryMenuOptions={[
          { title: "Edit Account", action: () => { setShowEditCompanyModal(true); } },
          { inActive: false, title: "Reset Password", action: () => { setShowResetPassCompanyModal(true); } },
          { title: "Delete Account", action: () => { setShowDeleteCompanyModal(true); setCompanyDeleteID(company_id) } },
        ]}

        optionsArray={[
          { inActive: false, title: "Global Request", action: () => {
            setRequestDetails({});
            setGlobalRequestIndex("");
            navigateTo("/users/companies/details/" + company_id + "/global-request"); 
          } },

          // todo: for version 2.0    
          // {
          //   inActive: true,
          //   title: "Track Online Driver", action: () => {
          //     navigateTo("/users/companies/details/" + company_id + "/track-online")
          //     setCompanyTrackOnlineDrive("")
          //   }
          // },
          { inActive: false, title: "Shift Planner", action: () =>
           { setIsShiftDetailsData({});navigateTo("/users/companies/details/" + company_id + "/shift-planner"); } },
          { title: "Driver Management", action: () => { navigateTo("/users/companies/details/" + company_id + "/driver-management"); } },
          { title: "Car Management", action: () => { navigateTo("/users/companies/details/" + company_id + "/car-management"); } },
          { title: "Favorite Address", action: () => { navigateTo("/users/companies/details/" + company_id + "/fav-address"); } },
        ]}
      />

      {/* todo: when company request section unlocks, they will be visible here */}
      {/* {detailsState === "request" ?
        <RequestState />
        : ""} */}
      <div className="mt-s20"></div>
      <RequestSection />
      <Content />

    </div>
  )
}

const RequestState = () => {
  const { setCompanyReqState } = useCompanyStore();
  return (
    <div>
      <div className="flex items-center justify-between pt-5 space-x-5">
        <MiniCard onClick={() => setCompanyReqState("in-bidding")} title='in bidding' number='120' />
        <MiniCard onClick={() => setCompanyReqState("awarded")} title='awarded' number='120' />
        <MiniCard onClick={() => setCompanyReqState("ongoing")} title='ongoing' number='120' />
        <MiniCard onClick={() => setCompanyReqState("completed")} title='completed' number='120' />
      </div>
      {/* <div className="pt-5">
        <AllRequestArea />
      </div> */}

    </div>
  )
}


