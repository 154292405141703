import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { kuGetAllCustomers, kuGetLicense, kuLicenseAction } from '../../Utility/url';
import { ToastrLoading } from '../../Utility/UtilityFunctions';

const initialState = {
    isLoading: true,
    customers: [],
    tempCustomers: [],
    licenseData: { id: null, status: '', license_start_date: '', license_end_date: '' }
}

/*
|--------------------------------------------------------------------------
| managing state of license application
|--------------------------------------------------------------------------
|
*/
export const ManageCustomersSlice = createSlice({
    name: 'manageCustomers',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log('setLoading: ' + action.payload);
            state.isLoading = action.payload
        },
        setAllCustomers: (state, action) => {
            state.customers = action.payload
            state.tempCustomers = action.payload
        },
        changeValue: (state, action) => {
            // const { name, value } = action.payload;
            // state.licenseData[name] = value;
        },
    },
})

/*
|--------------------------------------------------------------------------
| api related functions
|--------------------------------------------------------------------------
|
*/

export const handleSetAllCustomers = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetAllCustomers + '?take=1000');
        console.log('handleSetAllCustomers: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setAllCustomers(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// export const handleUpdateLicenseStatus = (state, type) => async (dispatch) => {
//     let toastr = ToastrLoading()

//     try {
//         const body = state.licenseData;
//         console.log(body);
//         // return;

//         const res = await axios.post(kuLicenseAction, body);
//         console.log(res.data);

//         if (res.data.success) {
//             ToastrLoading(res.data.message, 'stop', 'success', toastr);
//             await dispatch(handleSetAllCustomers(state))
//         } else {
//             ToastrLoading(res.data.message, 'stop', 'error', toastr);
//         }
//     } catch (err) {
//         console.log(err);
//         ToastrLoading('An error occurred!', 'stop', 'success', toastr);
//     }
// }

export const { setLoading, setAllCustomers, changeValue } = ManageCustomersSlice.actions
export default ManageCustomersSlice.reducer