import { t } from 'i18next';
import CommonModal from '../../Components/Modal/CommonModal';

function ProductListModal({ showModal, setShowModal, products = [] }) {

    return (
        <div>
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={t("Product Lists")}
                mainContent={
                    <div className='max-h-[40vh] overflow-y-auto'>
                        {
                            products.map((product, index) =>
                                <div key={index} className='min-h-[40px]  bg-cPress text-sm font-normal rounded-[5px] py-1 my-2 px-5 flex flex-col justify-center'>
                                    <div className=' '>{product.text}</div>
                                </div>
                            )
                        }
                    </div>
                }
            />
        </div>
    )
}

export default ProductListModal