/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect } from "react";
import { useParams } from 'react-router-dom';
import CommonButtonOutlined from '../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../Components/CircularProgressBar';
import SearchInput from '../../../../../Components/Input/SearchInput';
import BackLinks from '../../../../../Components/Layout/BackLinks';
import ListSkeleton01 from '../../../../../Components/Skeletons/ListSkeleton01';
import { DriverManagementContext } from '../../../../../Context/DriverManagementContext';
import { iRefresh } from '../../../../../Utility/Sources';
import { PageTitle, Toastr } from '../../../../../Utility/UtilityFunctions';
import AddDriver from "./AddDriver";
import Driver from "./Driver";
import UpdateDriver from "./UpdateDriver";
import LimadiSearchBox from '../../../../../Components/Input/LimadiSearchBox';

function DriverManagement() {
  const {
    detailsState,
    updateDetailsState,
    getDriverInvitation,
    driver,
    selectedIndex,
    selectDriver,
    isLoading,
    searchKey,
    searchDriver,
    setSearchKey,
    setSelectedDriverUID,
  } = useContext(DriverManagementContext);

  const { company_id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Driver Management");
    getDriverInvitation(company_id);
    setSearchKey("")
  }, []);

  useEffect(() => {
    selectedDriversData()
  }, [driver?.length > 0]);

  const selectedDriversData = async () => {
    await selectDriver(null)
  }


  useEffect(() => {
    setSelectedDriverUID(company_id);
  }, [company_id])

  const refreshFoo = async () => {
    // setIsRefreshing(true);
    setSearchKey("");
    selectedDriversData();
    await getDriverInvitation(company_id);
    //Toastr(t("Data Fetch Completed!"), "success");
    // setIsRefreshing(false);
  };

  return (
    <>
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "/users/companies/details/" + company_id },
          { label: "driver management", linkTo: "" },
        ]}
      />
      {/* title */}
      <div className="flex flex-row justify-between align-middle card">
        <div className="flex items-center w-2/3">
          <div className="text-2xl font-bold mt-[0px] mr-5">
            {t("Driver Management")} ({driver?.length})
          </div>
          {/* refresh icon button */}
          {!isLoading ? (
            <img
              onClick={() => {
                refreshFoo();
              }}
              src={iRefresh}
              alt="refresh-icon"
              className="h-8 cursor-pointer"
            />
          ) : (
            <CircularProgressBar />
          )}
        </div>

        {/* <CommonButtonOutlined
          onClick={() => updateDetailsState("add")}
          btnLabel={t("Add Driver")}
        /> */}
      </div>

      {/* content */}
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        {/* favorite address list */}
        <div className="col-span-12 mt-5 lg:col-span-5">
          <div className="flex-col justify-start bg-white shadow rounded-xl">
            {/* search-bar */}
            <div className="px-5 py-5">
              <LimadiSearchBox
                onSearchClear={() => {
                  setSearchKey("");
                  searchDriver("");
                }}
                fullWidth={true}
                withClearSearch={true}
                placeholder={t("search")}
                type="search"
                onChange={(e) => { searchDriver(e.target.value) }}
                name="searchKey"
                value={searchKey}
              />
            </div>

            <ul className="overflow-y-auto h-screen scroll-smooth">
              {isLoading ? (
                <ListSkeleton01 />
              ) : (
                driver?.map((address, index) => {
                  return (
                    <Driver
                      key={index}
                      data={address}
                      isSelected={selectedIndex === index ? true : false}
                      onSelect={() => selectDriver(index)}
                    />
                  );
                })
              )}

              <div className="mb-3"></div>
            </ul>
          </div>
        </div>

        {/* favorite address details */}
        <div className="col-span-12 mt-5 lg:col-span-7">
          {/* {detailsState === "add" ? <AddDriver /> : <UpdateDriver />} */}
          <UpdateDriver />
        </div>
      </div>
    </>
  );
}

export default DriverManagement;
