/* eslint-disable react-hooks/exhaustive-deps */

import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useCloudRequestStore from "../../../../../App/stores/CloudRequestStore";
import useGeneralStore from "../../../../../App/stores/GeneralStore";
import { addMarker } from "../../../../../App/stores/MapStore";
import CommonButton from "../../../../../Components/Button/CommonButton";
import CommonButtonOutlined from "../../../../../Components/Button/CommonButtonOutlined";
import CircularProgressBar from "../../../../../Components/CircularProgressBar";
import EmptyMessage from "../../../../../Components/EmptyMessage";
import SearchInput from "../../../../../Components/Input/SearchInput";
import BackLinks from "../../../../../Components/Layout/BackLinks";
import EmptyMapDiv from "../../../../../Components/Map/EmptyMapDiv";
import LimadiMap from "../../../../../Components/Map/LimadiMap";
import ListSkeleton01 from "../../../../../Components/Skeletons/ListSkeleton01";
import { CloudRequestContext } from "../../../../../Context/CloudRequestContext";
import FilterIcon from "../../../../../Images/icons/filter.svg";
import iRefresh from "../../../../../Images/icons/refresh.svg";
import { PageTitle } from "../../../../../Utility/UtilityFunctions";
import Filter from "./Filter";
import CloudFilterModal from "./Modal/CloudFilterModal";


function CloudRequest() {
  const {
    isLoading,
    setStartSearch,
    filterSelected,
    addFilter,
    applyFilter,
    manageFilter,
    currentSelection,
    setCurrentSelection,
    setChipShow,
    getFilterList,
    getFilterSearchListByFreeText,
    filter,
    searchKey,
    setSearchKey,
    searchFilter,
    setFilterToUpdate,
    setFilterReqTypeUpdate,
    setFilterTransportID,
    clearFilterPopup,
    chip,
    setChip,
    setIsExpressType,
    setIsAdvancedType,
    setIsAddFilter,
    setIsEditingNow,
    setShowFilterModal,
    filterPopupID,
    setIsClrPopupReqType,
    setFilterPopupReqType,
    setTempTransportID,
    setIsclrPopupTransportationType,
    setFilterPopupTransportation,
    setIsClrPopupCitySelector,
    setTmpFreeText
  } = useContext(CloudRequestContext);

  const { setCloudReqCompanyID, pickup_points } = useCloudRequestStore();
  const { isOnline } = useGeneralStore();

  const { company_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isRefreshing] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Global Request");
    setCloudReqCompanyID(company_id);
    refreshFoo();
  }, []);

  useEffect(() => {
    const selectedPopFilter = filter.find((item) => item.id === filterPopupID);

    // set other values
    if (selectedPopFilter) {
      setTempTransportID(selectedPopFilter.typeoftransportations_id - 1);
      setFilterPopupReqType(selectedPopFilter.request_type);
      setFilterPopupTransportation(selectedPopFilter.transportation_type);

      // set states for the other options
      if (selectedPopFilter.request_type.toLowerCase() === "simple_and_advance") {
        setIsExpressType(true);
        setIsAdvancedType(true);
      } else {
        if (selectedPopFilter.request_type.toLowerCase() === "simple") {
          setIsExpressType(true);
          setIsAdvancedType(false);
        } else if (selectedPopFilter.request_type.toLowerCase() === "advance") {
          setIsAdvancedType(true);
          setIsExpressType(false);
        }
      }

      // remove clear flags from the options' states
      setIsClrPopupReqType(false);
      setIsclrPopupTransportationType(false);
      setIsClrPopupCitySelector(false);
    }
  }, [filterPopupID]);

  useEffect(() => { addMarker(pickup_points) }, [pickup_points]);

  const refreshFoo = async () => {
    setSearchKey("")
    await setChip({});
    window.scrollTo(0, 0);
    PageTitle("Limadi | Global Request");
    await getFilterList(company_id, false);
    setSearchKey("");
    setCurrentSelection(null);
    setIsAddFilter(false);
    setIsEditingNow(false);
    setStartSearch(false);
    setFilterToUpdate("");
    setTmpFreeText("");

    // fetchCloudReqIndex();
  }

  return (
    <>
      <BackLinks
        linksArray={[
          { label: "companies", linkTo: "/users/companies" },
          { label: "company Details", linkTo: "/users/companies/details/" + company_id },
          { label: "global request", linkTo: "" },
        ]}
      />

      {/*r     Map */}
      {
        (isOnline && pickup_points?.length > 0 && (location.pathname === "/users/companies/details/" + company_id + "/global-request")) ?
          <LimadiMap />
          :
          location.pathname === "/users/companies/details/" + company_id + "/global-request" &&
          <EmptyMapDiv content={isOnline ? t('No Pickup Points Available!') : t('You are not connected to internet!')} />
      }


      {/*  title bar  */}
      <div
        onClick={() => {
          console.log("isOnline, pickup_points", isOnline, pickup_points);
        }}
        className="flex flex-row justify-between align-middle card text-cBodyText">
        <div className="flex items-center w-2/3">
          {(location.pathname === "/users/companies/details/" + company_id + "/global-request/manage-filter" ||
            location.pathname === "/users/companies/details/" + company_id + "/global-request/add-filter") &&
            <div className="text-2xl font-bold mt-[5px] mr-5">
              {t("Filter Management")} ({filter.length && filter.length})
            </div>
          }

          {location.pathname === "/users/companies/details/" + company_id + "/global-request" &&
            <div className="text-2xl font-bold mt-[5px] mr-5">
              {t("Global Request")} ({filter.length && filter.length})
            </div>
          }

          {/* refresh icon button */}
          {!isRefreshing ?
            <img
              onClick={refreshFoo}
              src={iRefresh}
              alt="refresh-icon"
              className="h-8 cursor-pointer"
            />
            :
            <CircularProgressBar />
          }

        </div>

        {/* filter management and add filter button */}
        {
          (location.pathname === "/users/companies/details/" + company_id + "/global-request") &&
          <div className="flex flex-row items-center justify-end space-x-5">
            <Link onClick={() => {
              setFilterToUpdate("");
              setCurrentSelection("");
              // setFilterSearchList({});
              setStartSearch(false);
            }} to={"/users/companies/details/" + company_id + "/global-request/add-filter"}>
              <CommonButtonOutlined
                btnLabel={t("Filter Management")}
                onClick={manageFilter}
                type="submit"
                width={"w-[180px]"}
              />
            </Link>
          </div>

        }

        {/*p       back button          */}
        {(location.pathname === "/users/companies/details/" + company_id + "/global-request/manage-filter" ||
          location.pathname === "/users/companies/details/" + company_id + "/global-request/add-filter") &&
          <div className="flex flex-row items-center justify-end space-x-5">
            <div onClick={() => {
              navigate("/users/companies/details/" + company_id + "/global-request");
              // setFilterSearchList({});
              setStartSearch(false);
            }}>
              <CommonButtonOutlined
                btnLabel={t("Back")}
                onClick={applyFilter}
                type="submit"
              />
            </div>

            {/* <Link
              onClick={() => {
                // setFilterSearchList({});
                setStartSearch(false);
              }} to={"/users/companies/details/" + company_id + "/global-request/add-filter"}>
              <CommonButtonOutlined
                btnLabel={t("Add Filter")}
                onClick={addFilter}
              />
            </Link> */}
          </div>
        }
      </div>


      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        {/* //! filter list */}
        <div className="col-span-12 pb-5 mt-5 overflow-hidden bg-white shadow lg:col-span-5 rounded-xl">
          <div className="bg-white flex-col justify-start overflow-y-auto max-h-[60vh]">

            {/*             search bar             */}
            <div className="sticky top-0 flex items-center justify-between px-5 py-5 bg-white">
              <div className="w-full pr-5">
                <SearchInput
                  placeholder={t("search filter")}
                  type="search"
                  onChange={(e) => {
                    searchFilter(e);
                    setStartSearch(false);
                  }}
                  name="searchKey"
                  value={searchKey}
                />
              </div>

              {location.pathname === "/users/companies/details/" + company_id + "/global-request/manage-filter" ||
                location.pathname === "/users/companies/details/" + company_id + "/global-request" ? <img
                className="cursor-pointer select-none"
                onClick={() => {
                  setStartSearch(true);
                  setShowFilterModal(true);
                  clearFilterPopup();

                }}
                src={FilterIcon}
                alt=""
              /> : ''}

            </div>

            {/* //! list items */}
            <div className="max-h-[60vh]">
              {isLoading ? (
                <ListSkeleton01 />
              ) : filter.length ? (
                filter.map((item, index) =>
                  location.pathname === "/users/companies/details/" + company_id + "/global-request" ? (
                    <Link key={index} to={"/users/companies/details/" + company_id + "/global-request"} className="hover:text-cMainBlack text-cMainBlack" >
                      <Filter
                        data={item}
                        isSelected={currentSelection === item.id}
                        onSelect={() => {
                          setStartSearch(true);
                          console.log("filter info: ", item);
                          setCurrentSelection(item.id);
                          setChipShow(true);
                          filterSelected(item.id);
                          setFilterToUpdate(item.name);
                          setFilterReqTypeUpdate(item.request_type);
                          setFilterTransportID(
                            item.typeoftransportations_id - 1
                          );
                          // setSelectedCitiesUpdate(item.city);
                          setIsAddFilter(false);

                          // fetchCloudReqIndex();
                        }}
                      />
                    </Link>
                  ) : (
                    <Link key={index} to={"/users/companies/details/" + company_id + "/global-request/manage-filter"} className="hover:text-cMainBlack text-cMainBlack" >
                      <Filter
                        key={index}
                        data={item}
                        isSelected={currentSelection === item.id}
                        onSelect={() => {
                          setStartSearch(true);
                          setCurrentSelection(item.id);
                          setChipShow(true);
                          filterSelected(item.id);
                          setFilterToUpdate(item.name);
                          setFilterReqTypeUpdate(item.request_type);
                          setFilterTransportID(item.typeoftransportations_id - 1);
                          // setSelectedCitiesUpdate(item.city);
                          setIsAddFilter(false);
                        }}
                      />
                    </Link>
                  )
                )
              ) : <div>
                <EmptyMessage message={t("No Data Available!")} />

                <div className="flex justify-center"><CommonButton onClick={() => navigate(`/users/companies/details/${company_id}/global-request/add-filter`)} btnLabel="Add Filter" /></div>

              </div>
              }
            </div>
          </div>
        </div>

        {/* //! details area:: filter result, add, update filter */}
        <div className="col-span-12 mt-5 lg:col-span-7">
          <Outlet />
        </div>

      </div>

      {/* modal */}
      <CloudFilterModal />

    </>
  );
}

export default CloudRequest;
