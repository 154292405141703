import React from 'react'

function OutlineButton({ label, onSubmit, canSubmit = true, width = 'w-36', className }) {
  return (
    <div>
      <button
        disabled={canSubmit ? false : true}
        onClick={onSubmit} type="button"
        className={`${className} font-semibold px-5 py-1.5 rounded-br10 border-[2px] border-cBrand text-cBrand bg-white  hover:text-white hover:bg-cBrand`}> {label}
      </button>
    </div>
  )
}

export default OutlineButton