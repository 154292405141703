/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import useUpdateLogger from '../../../../../App/Hooks/useUpdateLogger';
import useCloudRequestStore from '../../../../../App/stores/CloudRequestStore';
import CommonButton from '../../../../../Components/Button/CommonButton';
import Input02 from '../../../../../Components/Input/Input02';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { CloudRequestContext } from '../../../../../Context/CloudRequestContext';

const CitySelector = ({
  data_id = null,
  cleared = false,
  setCleared,
  finalValue,
}) => {
  const {
    selectedCitiesUpdate,
    filterCities,
    filterCityArray,
    setSelectedCities,
    currentSelection,
  } = useContext(CloudRequestContext);
  const [show_transportation_modal, setTransportationModal] = useState(false);

  const { cities, setCities, selectedTitles, setSelectedTitles } = useCloudRequestStore();

  // r    update cities                               
  useUpdateLogger("cities", cities);

  let selectedTitlesString = "";

  var selectedTitlesArray = [];

  const submit_req = () => {
    setCleared(false);
    setTransportationModal(false);
    console.log("filterCityArray: ", filterCityArray);
    cities.length &&
      // eslint-disable-next-line array-callback-return
      cities.map((item) => {
        if (item.checked) {
          selectedTitlesArray.push(item.title);
          selectedTitlesString.concat(", ", item.title);
        } else return null;
      });
    setSelectedTitles(selectedTitlesArray);
    setSelectedCities(selectedTitlesArray);
    finalValue(selectedTitlesArray);
    console.log("UPDATED selectedTitlesArray: ", selectedTitlesArray);
  };

  useEffect(() => {
    console.log("selectedCitiesUpdate::::", selectedCitiesUpdate);
    if (selectedCitiesUpdate) {
      setSelectedTitles(selectedCitiesUpdate);
      cities.forEach((element) => {
        if (selectedCitiesUpdate.includes(element.title)) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelection, selectedCitiesUpdate]);

  useEffect(() => {
    var tempID = 0;
    if (filterCities.length) {
      filterCities.map((filterName) => {
        filterCityArray[tempID] = {
          id: tempID,
          checked: false,
          title: filterName,
        };
        tempID++;
      });
      setCities(filterCityArray);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCities]);

  useEffect(() => {
    let t_array = [];
    cities?.length && cities?.map((item) => {
      if (item?.checked) t_array.push(item?.title);
    });
    finalValue(t_array);
    console.log("cities t_array (final value):::", t_array);

  }, [cities]);

  return (
    <div>
      <Input02
        onClick={() => setTransportationModal(true)}
        name="request_type"
        value={cleared ? "" : selectedTitles?.map(item => " " + item)}
        className="cursor-pointer capitalize"
        label={t("Select City")}
        type="text"
        placeholder={t("Select Select City")}
        icon={<IoIosArrowForward className="h-[70%] cursor-pointer" />}
        is_readonly={true}
      />
      <CommonModal
        showModal={show_transportation_modal}
        setShowModal={setTransportationModal}
        modalTitle={t("Select Cities")}
        mainContent={
          <div>
            <div>
              <div className="pt-5 max-h-[30vh] overflow-y-auto space-y-s10">
                {cities.length ?
                  cities.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        const newCities = cities.map((city) => {
                          if (city.id === item.id) {
                            const updatedCity = {
                              ...city,
                              checked: !city.checked,
                            };
                            return updatedCity;
                          }
                          return city;
                        });
                        setCities(newCities);
                        console.log("newCities: ", cities);
                      }}
                      className={`${item.checked && "border-emerald-600"
                        } border border-transparent flex justify-between items-center py-s10 px-4 cursor-pointer rounded-lg bg-cListItem text-gray-600 font-bold select-none capitalize`}
                    >
                      {item.title}
                      {/* ---Uid: {`${id}`} */}
                      <input
                        className="w-4 h-4 mr-5"
                        type={"checkbox"}
                        checked={item.checked}
                        onChange={() => {
                          const newCities = cities.map((city) => {
                            if (city.id === item.id) {
                              const updatedCity = {
                                ...city,
                                checked: !city.checked,
                              };
                              return updatedCity;
                            }
                            return city;
                          });
                          setCities(newCities);
                        }}
                      />
                    </div>
                  )) : ""}
              </div>
            </div>

            <div className="mt-5 w-full flex justify-center">
              <CommonButton
                btnLabel={t("Submit")}
                onClick={submit_req}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default CitySelector;
