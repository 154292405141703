import { t } from 'i18next';
import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iRefresh } from '../../../../../../Utility/Sources';

const StopListBar = ({
  // backLink 
}) => {
  const { isShiftDetailsData, getShiftPlannerDetails } =
    useContext(ShiftPlannerContext);
  const { stopList, setSearchStoptKey } = useContext(TrackOnlineContext);

  const { company_id, driver_id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  //fix: refresh with an api call to getShiftPlannerDetails
  const refreshFoo = async () => {
    setSearchStoptKey("");
    setIsLoading(true);
    if (company_id) await getShiftPlannerDetails(isShiftDetailsData.id, parseInt(company_id));
    if (driver_id) await getShiftPlannerDetails(isShiftDetailsData?.id, parseInt(company_id), true, isShiftDetailsData?.company_user_id);

    setIsLoading(false);
  };

  const navigate = useNavigate();

  return (
    <div className="card flex flex-row justify-between items-center align-middle">
      <div className="flex items-center w-2/3">
        <div className="text-2xl font-bold mt-[5px] mr-5">
          {t("Stop List")} ({stopList ? stopList.length : "0"})
        </div>

        {/* refresh icon button */}
        {!isLoading ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}
      </div>
      <div className="">
        <div onClick={() => navigate(-1)}>
          <CommonButtonOutlined
            btnLabel={t("Back")}
          />
        </div>
      </div>
    </div>
  );
};

export default StopListBar;
