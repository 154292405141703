/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useTransportationTypesStore, { addTransportationType, updateTransportationType } from '../../../App/stores/TransportationTypesStore'
import CommonModal from '../../../Components/Modal/CommonModal';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonButton from '../../../Components/Button/CommonButton';
import SelectInput from '../../../Components/Select/SelectInput';
import { Toastr } from '../../../Utility/UtilityFunctions';

const AddEditTransportationTypeModal = () => {
    const {
        showAddTransportationTypeModal,
        setShowAddTransportationTypeModal,
        editTransportationTypeMode,
        setEditTransportationTypeMode
    } = useTransportationTypesStore();
    const [formData, setFormData] = useState({
        title: "",
        duration: "",
        status: ""
    });

    const resetFormData = () => {
        setFormData({
            title: "",
            duration: "",
            status: ""
        })
    }

    useEffect(() => {
        if (editTransportationTypeMode?.title) {
            setFormData({
                title: editTransportationTypeMode?.title,
                duration: editTransportationTypeMode?.duration,
                status: editTransportationTypeMode?.status ? 1 : 0
            })
        }

        if (!editTransportationTypeMode) {
            resetFormData();
        }
    }, [editTransportationTypeMode]);

    return (
        <>
            <CommonModal
                showModal={showAddTransportationTypeModal}
                setShowModal={setShowAddTransportationTypeModal}
                modalTitle={editTransportationTypeMode ? "Edit Transportation Type" : 'Add Transportation Type'}
                widthClass='w-[550px]'
                mainContent={
                    <form onSubmit={(e) => { e.preventDefault(); }} className='space-y-5'>
                        <CommonInput
                            required
                            label={"Title"}
                            placeholder={"Enter a title"}
                            value={formData.title}
                            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        />
                        <CommonInput
                            required
                            label={"Duration (min)"}
                            placeholder={"Enter duration"}
                            type='number'
                            value={formData.duration}
                            max_number={50000}
                            max_input={5}
                            onChange={(e) => setFormData({ ...formData, duration: e.target.value })}
                        />

                        {editTransportationTypeMode &&
                            <SelectInput
                                required={true}
                                label={"Status"}
                                placeholder={"Select status"}
                                dataArray={[
                                    {
                                        title: "Active",
                                        value: 1,
                                        selected: formData.status ? true : false
                                    },
                                    {
                                        title: "Inactive",
                                        value: 0,
                                        selected: !formData.status ? true : false
                                    }
                                ]}
                                value={formData.status}
                                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                            />}

                        <div className="flex justify-center">
                            <CommonButton
                                type='submit'
                                btnLabel={editTransportationTypeMode ? "Update" : 'Save'}
                                isDisabled={!formData.title || !formData.duration || (editTransportationTypeMode && formData.status === "")}
                                onClick={async () => {
                                    if(formData?.duration && formData?.duration > 50000) return;
                                    if (editTransportationTypeMode) {
                                        const updateSuccess = await updateTransportationType(formData, editTransportationTypeMode?.id);
                                        if (updateSuccess) {
                                            resetFormData();
                                            setEditTransportationTypeMode(null);
                                            setShowAddTransportationTypeModal(false);
                                        }
                                    } else {
                                        const addSuccess = await addTransportationType(formData);
                                        if (addSuccess) {
                                            resetFormData();
                                            setShowAddTransportationTypeModal(false);
                                        }
                                    }
                                }}
                            />
                        </div>
                    </form>
                }
            />
        </>
    )
}

export default AddEditTransportationTypeModal