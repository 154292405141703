import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand';
import { kuAddUser, kuGetAllDrivers, kuGetCommonDetails } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';


const useDriverStore = create((set, get) => ({
  is_profile_open: true,
  setProfileOpen: (value) => set({ is_profile_open: value }),
  drivers: {},
  setDrivers: (value) => {
    console.log('all_drivers', value.data);
    set({ drivers: value });
    set({ all_drivers: value?.data });
    get().setTempAllDrivers(value?.data);
  },

  all_drivers: [],
  setAllDrivers: (value) => set({ all_drivers: value }),

  temp_all_drivers: [],
  setTempAllDrivers: (value) => set({ temp_all_drivers: value }),

  is_show_driver_delete_modal: false,
  setIsShowCompanyDeleteModal: (value) => set({ is_show_driver_delete_modal: value }),

  driverDetails: {},
  setDriverDetails: (value) => set({ driverDetails: value }),

  driverDeleteID: 0,
  setDriverDeleteID: (value) => set({ driverDeleteID: value }),

  driverTakeAmount: 10,
  setDriverTakeAmount: (value) => set({ driverTakeAmount: value }),

  showAddDriverModal: false,
  setShowAddDriverModal: (value) => set({ showAddDriverModal: value }),

  showEditDriverModal: false,
  setShowEditDriverModal: (value) => set({ showEditDriverModal: value }),

  showDeactivateDriverModal: false,
  setShowDeactivateDriverModal: (value) => set({ showDeactivateDriverModal: value }),

  showResetPassDriverModal: false,
  setShowResetPassDriverModal: (value) => set({ showResetPassDriverModal: value }),

  showDeleteDriverModal: false,
  setShowDeleteDriverModal: (value) => set({ showDeleteDriverModal: value }),

  driverPageUrl: "",
  setDriverPageUrl: (value) => set({ driverPageUrl: value }),

  driverSearchValue: "",
  setDriverSearchValue: (value) => set({ driverSearchValue: value }),

  showAssignedCompaniesModal: false,
  setShowAssignedCompaniesModal: (value) => set({ showAssignedCompaniesModal: value }),

  showAssignedCompaniesDeclineModal: false,
  setShowAssignedCompaniesDeclineModal: (value) => set({ showAssignedCompaniesDeclineModal: value }),

  driverFilterValue: "",
  setDriverFilterValue: (value) => set({ driverFilterValue: value }),

}));


// add new driver 
export const addNewDriver = async (addNewDriverFormData) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    // return 
    console.log("addNewDriverFormData", addNewDriverFormData);
    setLoading(true);

    const res = await axios.post(kuAddUser, addNewDriverFormData);
    console.log('addNewDriver: ', res.data);

    if (res.data.success) {
      Toastr(res.data.message, 'success');
      await getDrivers();
      return true;
    }
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('addNewDriver: ', e);
  }
}



// get all driver 
export const getDrivers = async (paginationUrl = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setDrivers, driverTakeAmount } = useDriverStore.getState();

  try {
    setLoading(true);
    let targetUrl = "";
    console.log('### getDrivers: paginationUrl = ', paginationUrl);
    if (paginationUrl !== "") {
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = kuGetAllDrivers + targetUrl;
    } else {
      targetUrl = kuGetAllDrivers;
    }

    console.log("take: ", driverTakeAmount);
    const res = await axios.get(targetUrl, { params: { take: driverTakeAmount } });
    console.log('getDrivers: ', res.data);

    if (res.data.success) {
      setDrivers(res.data.data);
      setLoading(false);
      return true
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false
    }
  } catch (e) {
    Toastr(t("An error occurred!"));
    console.log('getDrivers: ', e);
    setLoading(false);
    return false
  }
}



// get customer details
export const getDriverDetails = async (id = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setDriverDetails } = useDriverStore.getState();

  try {
    setLoading(true);

    const res = await axios.get(kuGetCommonDetails, { params: { user_id: id } });
    console.log('getDriverDetails: ', res.data);

    if (res.data.success) setDriverDetails(res.data.data);
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('getDriverDetails: ', e);
  }
}


export default useDriverStore;
