import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useContactStore, { advancedFilterMessage, HandleGetMessages } from '../../App/stores/ContactStore'
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined'
import TitleBar from '../../Components/Common/TitleBar'
import CommonTable from '../../Components/Table/CommonTable'
import NoDataRow from '../../Components/Table/NoDataRow'
import { iFilterBtn, iFilterBtnWhite } from '../../Utility/Sources'
import { PageTitle } from '../../Utility/UtilityFunctions'
import MessageItem from './MessageItem'

export default function Message() {
  const { contactMessageList, setShowFilterMessage, filterMode, setFilterMode, filterForm, setCustomDateShow, setFilterForm, setPaginationUrl } = useContactStore();
  const { t } = useTranslation();

  const messageTableHeaders = [
    { index: 1, name: t("name") },
    { index: 2, name: t("subject") },
    { index: 3, name: t("message") },
    { index: 4, name: t("user type") },
    { index: 5, name: t("date") },
    // { index: 6, name: t("action") },
  ];

  useEffect(() => {
    PageTitle('Limadi | Contact-App');
    window.scrollTo(0, 0);
    if (filterMode) setFilterMode(false);
    HandleGetMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {/* <MessageTitleBar type={'submit'} title={t("contact us")} label={'Send Message'} /> */}
        <TitleBar
          label={t("contact us")}
          count={contactMessageList?.total}
          onClick={() => {

            if (filterMode) setFilterMode(false);

            setFilterForm({
              status: "",
              is_reply: null,
              is_seen: null,
              date: "",
              custom_date: "",
              start_date: "",
              end_date: "",
            });
            setCustomDateShow(false);

            HandleGetMessages();
          }}
          rightCornerComponent={
            <CommonButtonOutlined
              onClick={() => {
                setShowFilterMessage(true);
              }}
              leftIcon={<img src={iFilterBtn} alt="filter" />}
              leftIconHover={<img src={iFilterBtnWhite} alt="filter-alter" />}
              btnLabel="Filter"
            />
          }
        />
        <div className="justify-between space-x-0 w-full md:flex md:space-x-5">

          {/* green: message table... */}
          <CommonTable
            headers={messageTableHeaders}
            showingFrom={contactMessageList?.from}
            showingTo={contactMessageList?.to}
            totalResult={contactMessageList?.total}
            items={contactMessageList?.data?.length > 0 ? contactMessageList?.data?.map((message, index) => (<MessageItem key={index} data={message} />)) : <NoDataRow />}
            shoSearchBox={false}
            paginationObject={contactMessageList}
            showPagination={
              contactMessageList?.last_page !== 1 ? true : false
            }
            paginationOnClick={(url) => {
              console.log(url);
              setPaginationUrl(url);
              console.log("filterMode::::", filterMode);
              if (filterMode === true) advancedFilterMessage(filterForm, url);
              else HandleGetMessages(url);
            }}
          />
        </div>
      </div>
    </>
  )
}


const FilterDropDown = () => {

  return (
    <div>
      <select onChange={(e) => { console.log(e.target.value); }} id="cars" className='pl-5 font-semibold w-s200 h-s40 rounded-br10 select-style space-y-s5'>
        {/* <option className='py-s10 text-cMainBlack' value="">Select Filter..</option> */}
        <option className='py-s10 text-cMainBlack' value="all">All</option>
        <option className='py-s10 text-cMainBlack' value="customer">Customer</option>
        <option className='py-s10 text-cMainBlack' value="company">Company</option>
        <option className='py-s10 text-cMainBlack' value="driver">Driver</option>
      </select>

    </div>
  )
}