import { configureStore } from '@reduxjs/toolkit'
import bannerSliceReducer from './Slice/BannerSlice'
import packageSliceReducer from './Slice/PackageSlice'
import messageSliceReducer from './Slice/MessageSlice'
import licenseApplicationReducer from './Slice/LicenseApplicationSlice'
import dashboardSliceReducer from './Slice/DashboardSlice'
import manageCustomersReducer from './Slice/ManageCustomersSlice'
import manageCompaniesReducer from './Slice/ManageCompaniesSlice'
import manageDriversReducer from './Slice/ManageDriversSlice'


export const store = configureStore({
        reducer: {
                licenseApplication: licenseApplicationReducer,
                manageCustomers: manageCustomersReducer,
                manageCompanies: manageCompaniesReducer,
                manageDrivers: manageDriversReducer,
                banners: bannerSliceReducer,
                packages: packageSliceReducer,
                messages: messageSliceReducer,
                dashboard: dashboardSliceReducer,
        },
        //A non-serializable value was detected in the state" issue fix
        middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, }),
})