import { t } from 'i18next';
import axios from "axios";
import { createContext, useReducer, useState } from "react";
import {
  kuAddCar,
  kuAllCar,
  kuDeleteCar,
  kuGetCarLicense,
  kuRenewCarLicense,
  kuUpdateCar
} from "../Utility/url";
import { Toastr, ToastrLoading } from "../Utility/UtilityFunctions";
import useGeneralStore from '../App/stores/GeneralStore';
import { carManagementReducer } from './CarManagementReducer';

export const CarManagementContext = createContext();

const CarManagementContextProvider = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedCar, setSelectedCar] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [openLicenseModal, setOpenLicenseModal] = useState(false);
  const [updatingDetails, setUpdatingDetails] = useState(true);
  const [imgUpdated, setImgUpdated] = useState(false);
  const [targetCarId, setTargetCarId] = useState(null);
  const [targetUserId, setTargetUserId] = useState(0);
  const [licenseStartDate, setLicenseStartDate] = useState("");
  const [doRenew, setDoRenew] = useState(false);

  const [popWarning, setPopWarning] = useState(false);
  const [popDelWarning, setPopDelWarning] = useState(false);

  const initialState = {
    allCar: [],
    tempAllCar: [],
    detailsState: "add",
    selectedLicenseIndex: 0,
    addCar: { name: "", licenseNumber: "", comment: "", image: "" },
    licenseForm: { licenseID: "", startDate: "", comment2: "" },
    updateCar: {
      id: null,
      name: "",
      licenseNumber: "",
      comment: "",
      image: "",
      licenseStatus: "",
    },
    addTypeUpdate: {
      id: null,
      licenseID: null,
      licenseStart: "",
      purchaseLicenseComment: "",
    },
    allCarLicense: [],
    selectedLicenseID: null,
    newCarID: null,
  };

  const [state, dispatch] = useReducer(carManagementReducer, initialState);
  const { setLoading } = useGeneralStore()

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */

  const getAllCar = async (u_id, withLoading = true) => {
    try {
      if (!u_id) {
        Toastr('Invalid User', 'warning');
        return;
      }

      if (withLoading) setLoading(true);
      const res = await axios.get(kuAllCar, { params: { user_id: u_id, take: 500 } });
      console.log('getAllCar: ', res.data);

      if (res.data.success) {
        await dispatch({ type: "SET_ALL_CAR", payload: res.data.data.data });
        if (res.data.data.data?.length > 0) {
          // selectCarFoo(0);
          // setUpdatingDetails(true);
          selectCarFoo(null);
        }else{
          selectCarFoo(null);
        }
        if (withLoading) setLoading(false);
        return true;
      } else {
        Toastr(res.data.message);
        if (withLoading) setLoading(false);
        return false;
      }
    } catch (err) {
      console.log('getAllCar: ', err);
      Toastr(t("An error occurred!"));
      if (withLoading) setLoading(false);
      return false;
    }
  };

  // get license list
  const getAllLicenseList = async () => {
    try {
      setLoading(true);
      const res = await axios.get(kuGetCarLicense);
      console.log('getAllLicenseList: ', res.data);
      if (res.data.success) {
        dispatch({ type: "SET_LICENSE_DATA", payload: res.data.data });
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('getAllLicenseList: ', err);
      Toastr(t("An error occurred!"));
      setIsLoading(false);
    }
  };

  const addCarFoo = async (u_id) => {
    try {
      if (state.addCar.licenseNumber.length < 6) {
        Toastr("License number must be Less-than 6 digits", "warning");
        return;
      }

      if (!u_id) {
        Toastr('Invalid User', 'warning');
        return;
      }

      setLoading(true);
      const body = {
        user_id: u_id,
        name: state.addCar.name,
        car_license_plate_number: state.addCar.licenseNumber,
        comment: state.addCar.comment,
        image: state.addCar.image,
      };
      const res = await axios.post(kuAddCar, body);
      console.log('addCarFoo: ', res.data);

      if (res.data.success) {
        await getAllCar(u_id);
        // resetting add car form
        dispatch({
          type: "RESET_ADD_CAR",
          payload: { name: "", licenseNumber: "", comment: "" },
        });
        // for modal license cards and selectedLicenseID set
        dispatch({ type: "SET_LICENSE_DATA", payload: res.data.data.license });
        dispatch({ type: "SET_NEW_CAR_ID", payload: res.data.data.car.id });

        Toastr(res.data.message, 'success');
        setOpenLicenseModal(true);
        setLoading(false);
        return true;
      } else {
        Toastr(res.data.message);
        setLoading(false);
        return false;
      }
    } catch (err) {
      console.log('addCarFoo: ', err);
      ToastrLoading(t("An error occurred!"));
      setLoading(false);
    }
  };

  // both adds and updates the car information
  const updateCarFoo = async (type = "update", u_id) => {
    console.log("UPDATE CAR TYPE: ", type);
    try {
      setLoading(true);
      let body = {};
      if (type === "add") {
        body = {
          user_id: u_id,
          id: state.addTypeUpdate.id,
          license_id: state.addTypeUpdate.licenseID,
          license_start: licenseStartDate,
          purchase_license_comment: state.addTypeUpdate.purchaseLicenseComment,
        };
      } else if (type === "update") {

        if (state.updateCar.licenseNumber.length < 6) {
          Toastr("License number must be Less-than 6 digits", "warning");
          setLoading(false);
          return;
        }

        if (imgUpdated) {
          body = {
            user_id: u_id,
            id: selectedCar.id,
            name: state.updateCar.name,
            car_license_plate_number: state.updateCar.licenseNumber,
            image: state.updateCar.image,
            comment: state.updateCar.comment,
            // license_id: state.selectedLicenseID,
            // license_start: licenseStartDate,
          };
        } else {
          body = {
            user_id: u_id,
            id: selectedCar.id,
            name: state.updateCar.name,
            car_license_plate_number: state.updateCar.licenseNumber,
            comment: state.updateCar.comment,
            license_id: state.selectedLicenseID,
            license_start: licenseStartDate,
          };
        }
      } else {
        body = {
          user_id: u_id,
          id: state.newCarID,
          comment: state.addTypeUpdate.purchaseLicenseComment,
          license_id: state.selectedLicenseID,
          license_start: licenseStartDate,
        };
      }

      console.log("LICENSE Body before axios:::", body);

      const res = await axios.post(kuUpdateCar, body);
      console.log('updateCarFoo: ', res.data);

      if (res.data.success) {
        await getAllCar(u_id);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('updateCarFoo: ', err);
      ToastrLoading(t("An error occurred!"));
      setLoading(false);
    }
  };

  // update / apply license or update
  const UpdateApplyForLicense = async (u_id) => {
    // console.log('doRenew: ', doRenew, u_id);
    if (!u_id) {
      Toastr('Invalid User', 'warning');
      return;
    }

    try {
      let body = {};
      if (doRenew) {
        body = {
          user_id: u_id,
          id: targetCarId,
          license_id: state.selectedLicenseID,
          renew_license_start: licenseStartDate,
          purchase_license_comment: state.addTypeUpdate.purchaseLicenseComment,
        };
      } else {
        body = {
          user_id: u_id,
          id: targetCarId,
          license_id: state.selectedLicenseID,
          license_start: licenseStartDate,
          purchase_license_comment: state.addTypeUpdate.purchaseLicenseComment,
        };
      }

      setLoading(true)
      console.log("LICENSE APPLY / UPDATE Body before axios:::", body);
      let res = {};
      if (doRenew) {
        res = await axios.post(kuRenewCarLicense, body);
      } else {
        res = await axios.post(kuUpdateCar, body);
      }
      console.log('UpdateApplyForLicense: ', res.data);

      if (res.data.success) {
        await getAllCar(u_id);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log('UpdateApplyForLicense: ', err);
      ToastrLoading(t("An error occurred!"));
      setIsLoading(false);
    }
  };

  // delete car data
  const deleteCar = async (u_id) => {

    if (!u_id) {
      Toastr('Invalid User', 'warning');
      return;
    }

    try {
      console.log("DEL CAR UID::: ", targetUserId);
      setLoading(true);

      const body = { id: selectedCar.id, user_id: u_id };
      // console.log(body);
      const res = await axios.post(kuDeleteCar, body);
      console.log('deleteCar', res.data);

      if (res.data.success) {
        await getAllCar(targetUserId);
        updateDetailsState("add");
        setLoading(false);
        return true;
      } else {
        Toastr(res.data.message);
        setLoading(false);
        return false;

      }
    } catch (err) {
      console.log('deleteCar', err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Helper Functions 
  |--------------------------------------------------------------------------
  */

  const setChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    dispatch({ type: "SET_ADD_CAR_CHANGE", payload: { name, value } });
  };

  const setUpdateChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    dispatch({ type: "SET_UPDATE_CAR_CHANGE", payload: { name, value } });
    // console.log("update ::", value);
  };

  const setLicenseFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    dispatch({ type: "SET_LICENSE_FORM_CHANGE", payload: { name, value } });
  };

  const setAddTypeUpdateFormChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.value !== " ") {
      console.log(name, value);
      dispatch({ type: "SET_ADD_TYPE_UPDATE", payload: { name, value } });
    }

  };

  const updateDetailsState = (s) => {
    dispatch({ type: "SET_DETAILS_STATE", payload: s });
  };

  const selectCarFoo = (index) => {
    if(index=== null){
      dispatch({
        type: "SELECT_CAR",
        payload: {
          id: "",
          name: "",
          licenseNumber: "",
          comment: "",
          image: "",
          licenseStatus: "",
          licenseEnd: "",
          renewLicenseStart: "",
          license_start: "",
          license_end: "",
        },
      });
    }
    setSelectedIndex(index);
    setSelectedCar(state.allCar[index]);
    updateDetailsState("update");
    updateDetailsState("state", state);

    dispatch({
      type: "SELECT_CAR",
      payload: {
        id: state?.allCar[index]?.id,
        name: state?.allCar[index]?.name,
        licenseNumber: state?.allCar[index]?.car_license_plate_number,
        comment: state?.allCar[index]?.comment,
        image: state?.allCar[index]?.image,
        licenseStatus: state?.allCar[index]?.license_status,
        licenseEnd: state?.allCar[index]?.license_end,
        renewLicenseStart: state?.allCar[index]?.renew_license_start,
        license_start: state?.allCar[index]?.license_start,
        license_end: state?.allCar[index]?.license_end,

      },
    });
  };

  const cancelAddCar = () => {
    dispatch({
      type: "RESET_ADD_CAR",
      payload: { name: "", licenseNumber: "", comment: "" },
    });
    selectCarFoo(0);
    updateDetailsState("update");
  };

  const searchCar = (search) => {
    updateDetailsState("add");
    setSearchKey(search);
    setSelectedIndex(null);
    // console.log(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = state.tempAllCar.filter((item) => {
      if (
        item?.name.toLowerCase().includes(search.toLowerCase()) ||
        item?.license_status.toLowerCase().includes(search.toLowerCase()) ||
        item?.car_license_plate_number
          .toLowerCase()
          .includes(search.toLowerCase())
      ) {
        return item;
      }
    });

    dispatch({ type: "SET_ONLY_ALL_CAR", payload: result });
  };

  const handleApplyForLicense = async (u_id) => {
    // closing modal
    setOpenLicenseModal(false);
    console.log(state.addTypeUpdate);
    console.log(state.selectedLicenseID);
    updateCarFoo("purchaseLicense", u_id);
  };

  // sharing states
  return (
    <CarManagementContext.Provider
      value={{
        // states
        detailsState: state.detailsState,
        allCar: state.allCar,
        addCar: state.addCar,
        updateCar: state.updateCar,
        selectedIndex,
        selectedCar,
        isLoading,
        canSubmit,
        searchKey,
        openLicenseModal,
        licenseForm: state.licenseForm,
        selectedLicenseIndex: state.selectedLicenseIndex,
        addTypeUpdate: state.addTypeUpdate,
        allCarLicense: state.allCarLicense,
        selectedLicenseID: state.selectedLicenseID,
        updatingDetails,
        imgUpdated,
        targetCarId,
        licenseStartDate,
        doRenew,
        popWarning,
        popDelWarning,
        targetUserId,

        // actions
        setTargetUserId,
        dispatch,
        setChange,
        updateDetailsState,
        getAllCar,
        setSelectedIndex,
        selectCarFoo,
        setUpdateChange,
        addCarFoo,
        setIsLoading,
        cancelAddCar,
        setCanSubmit,
        updateCarFoo,
        deleteCar,
        searchCar,
        setOpenLicenseModal,
        setLicenseFormChange,
        handleApplyForLicense,
        setAddTypeUpdateFormChange,
        setUpdatingDetails,
        setImgUpdated,
        setTargetCarId,
        setSearchKey,
        setLicenseStartDate,
        setDoRenew,

        // api calls
        getAllLicenseList,
        UpdateApplyForLicense,

        setPopWarning,
        setPopDelWarning,
      }}
    >
      {props.children}
    </CarManagementContext.Provider>
  );
};

export default CarManagementContextProvider;
