/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useChatStore, { getChatCounter, getChatList, getMessageList } from '../../../App/stores/chatStore'
import LimadiSearchBox from '../../../Components/Input/LimadiSearchBox';
import { useDebounce } from 'use-debounce';
import { ThreeDots } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import CommonChatBox from './CommonChatBox';
import { convertATime, formatDate, initializeFirebase } from '../../../Utility/UtilityFunctions';
import { role } from '../../../App/Utility/const';
import firebase from "../../../App/firebase/firebase";

const ChatLeftBar = () => {

    const { setFirebaseNewMessage, firebase_new_message, chat_search, setChatSearch, chat_list, selectChat, setTextFieldCount, setSelectChat, setMessage_scroll_bottom } = useChatStore();

    const [scroll, setScroll] = useState(false);

    const [searchValue] = useDebounce(chat_search, 500);

    const currentDateTime = new Date();

    useEffect(() => {
        getChatList(chat_search);
        initializeFirebase(true, firebase);
        getChatCounter();
    }, [])

    // chat search
    useEffect(() => {
        getChatList(searchValue)
    }, [searchValue])

    const incrementData = () => {
         getChatList(chat_search, false, false, true);
    }

    useEffect(() => {
        notificationFetch();
    }, [firebase_new_message])

    // get message notification then refresh chat list
    const notificationFetch = async () => {
        if (firebase_new_message) {
            await getChatList(chat_search, false, false);
            setFirebaseNewMessage(false);
        }
    }

    return (
        <div className='rounded-br4'>
            <div className='ml-4'>
                <LimadiSearchBox
                    value={chat_search}
                    onChange={(e) => { setChatSearch(e.target.value) }}
                    fullWidth={true}
                    onSearchClear={() => { setChatSearch("") }}
                    withClearSearch={true}
                    roundedFull={false}
                />
            </div>


            <div className='mt-s12'>
                {
                    chat_list?.data?.length ?
                        <div
                            className='overflow-y-auto' id="scrollableDiv">
                            <InfiniteScroll
                                height={window.innerHeight - 276}
                                dataLength={chat_list?.data?.length ?? 0} //This is important field to render the next data
                                next={() => {
                                    if (!scroll && (chat_list?.current_page < chat_list?.last_page)) {
                                        incrementData();
                                    }
                                }}
                                hasMore={!scroll && chat_list?.current_page < chat_list?.last_page}
                                scrollableTarget="scrollableDiv"
                                loader={<div className='flex justify-center w-full'>
                                    {
                                        <ThreeDots
                                            height="40"
                                            width="40"
                                            radius="9"
                                            color="'#F89818"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    }
                                </div>}
                                scrollThreshold={0.9}
                            >
                                <div className='pr-3 space-y-4'>
                                    {
                                        chat_list?.data?.map((item, index) => (
                                            (item?.id &&
                                                <div key={index} className='flex w-full h-full space-x-3'>
                                                    {selectChat?.id === item?.id ? <div className='min-w-[4px] max-w-[4px] h-[44px] bg-cBrand'></div> : <div className='min-w-[4px] max-w-[4px]'></div>}
                                                    <CommonChatBox
                                                        image={item?.image}
                                                        user_type={item?.user_type}
                                                        isSeen={item?.last_message?.from_type === role.admin ? true : item?.last_message?.to_is_read ? true : false}
                                                        name={item?.name}
                                                        shortMessage={item?.last_message?.message}
                                                        onClick={async () => {
                                                            setTextFieldCount(40);
                                                            if (selectChat?.id !== item?.id) {
                                                                const success = await getMessageList({ "chat_id": item?.id, take:  10 })
                                                                if (success?.success) {
                                                                    await setSelectChat(item);
                                                                    await getChatList(searchValue, false, false, false, chat_list?.data?.length);
                                                                    await getChatCounter();
                                                                }
                                                            } else {
                                                                setMessage_scroll_bottom(true);
                                                            }
                                                        }}
                                                        date_time={
                                                            item?.created_at ?
                                                                new Date(item?.last_message?.created_at)?.toDateString() === currentDateTime.toDateString() ?
                                                                    convertATime(item?.last_message?.created_at) :
                                                                    `${formatDate(item?.last_message?.created_at)}, ${convertATime(item?.last_message?.created_at)}` : null

                                                        }
                                                    />

                                                </div>)
                                        ))
                                    }
                                </div>

                                {
                                    scroll && <div className='flex justify-center w-full'>
                                        {<ThreeDots
                                            height="40"
                                            width="40"
                                            radius="9"
                                            color="#F89818"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />}
                                    </div>
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='h-[calc(100vh-276px)]  space-y-2 mx-14'>
                            <div className='text-center text-fs20 font-fw600 text-cNero'>No chat history</div>
                            <div className='text-center text-fs14 font-fw400 text-cLesson'>To create a chat please click on the function icon at the top right corner</div>
                        </div>
                }
            </div>

        </div>
    )
}

export default ChatLeftBar