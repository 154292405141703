import React, { useLayoutEffect, useState } from 'react'
import Logo from '../../../Components/Image/Logo'

export default function OptionNested({
    options = [],
    onClick, mainLabel, source, forceClose = false
}) {
    const [expanded, setExpanded] = useState(false);
    useLayoutEffect(() => {
        if (forceClose) {
            setExpanded(false);
        }
    }, [forceClose])
    return (
        <div className={`${expanded ? "h-[200px]" : "h-10"} select-none transition-height duration-500 overflow-hidden bg-gray-100 rounded-md`}>
            <div
                className={`text-sm flex justify-between cursor-pointer h-10`}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                <div className="pt-[10px] pl-3">
                    {mainLabel}
                </div>
                <div className="pt-[10px] pr-3">
                    <Logo source={source} className={`${expanded ? "rotate-90" : "rotate-0"}  transition-all duration-300`} />{" "}
                </div>
            </div>
            <div className="flex flex-col pt-s5 pr-s10 pl-s10">
                {options.map((item, index) =>
                    <div
                        onClick={item.onClick}
                        className={`
                                text-sm rounded-md flex justify-between cursor-pointer py-s10 px-s10 ring-[1px] ring-cPlaceholder my-s5 capitalize
                                ${item.isActive === item.name && "text-cBrand"}
                            `}
                        key={index}
                    >
                        {item.name}
                    </div>
                )}
            </div>
        </div>
    );
}
