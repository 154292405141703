import { Tooltip } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Section = ({
  isSidebarOpen = true,
  url = "/",
  icon,
  title,
  is_active = false,
  is_image = false,
  active_image = null,
  total_unread_message = 0,
  onClick=()=>{}
}) => {

  const [inHoverState, setInHoverState] = useState(false);

  return (
    <Link
      onMouseEnter={() => setInHoverState(true)}
      onMouseLeave={() => setInHoverState(false)}
      title={title}
      onClick={onClick}
      to={url}
      className={`flex justify-start items-center text-sm px-7 py-2 mb-4 text-cIcon cursor-pointer hover:font-semibold hover:text-limadi ${is_active && "bg-cListItem text-limadi font-semibold hover:no-underline"}`} >

      <Tooltip title={isSidebarOpen ? '' : title}>
        <div className="flex ">
        <div className={`mr-5 flex justify-start items-center transition-all ${isSidebarOpen ? "text-xl mr-2" : "text-2xl"}`}>
          <img className={`max-w-[25px] min-w-[25px] h-5`} src={inHoverState ? active_image : icon} alt="" />
        </div>
        <div className="">
        {
            (title === "Chat" && total_unread_message !== 0 && !isSidebarOpen) &&
            <div className={`flex items-center justify-center ${total_unread_message > 99 ? "w-7 h-6" : "w-5 h-5"} text-white rounded-full text-fs12 bg-cBrand`}>
              {total_unread_message > 99 ? "99+" : total_unread_message}
            </div>
          }
          </div>
          </div>
      </Tooltip>
      {
        isSidebarOpen &&
        <div className="flex justify-between w-full">
          <div>{title}</div>
          {
            (title === "Chat" && total_unread_message !== 0) &&
            <div className={`flex items-center justify-center ${total_unread_message > 99 ? "w-6 h-6" : "w-5 h-5"} text-white rounded-full text-fs12 bg-cBrand`}>
              {total_unread_message > 99 ? "99+" : total_unread_message}
            </div>
          }
        </div>
      }
    </Link>
  );
};

export default Section;
