import CommonImage from "../../../Components/Image/CommonImage"
import { formatDate } from "../../../Utility/UtilityFunctions"

function OnGoingRow({ data, num }) {
  return (
    <tr className='hover:bg-cGridView'>
      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center px-s10'>
        {num}
      </td>

      <td className='border-r-[1px] border-b p-s10 text-left max-w-[150px] min-w-[150px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.creator?.image} alt="" />
          </div>
          <div className='capitalize items-center pl-s10 truncate'>{data?.creator?.name ? data?.creator?.name : ""}</div>
        </div>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left'>
        <span className="pl-s10">{data?.title ? data?.title : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-left capitalize'>
        <span className="pl-s10">{data?.type ? data?.type : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
        <span className="pl-s10">{data?.awarded_company?.bidding_company?.name ? data?.awarded_company?.bidding_company?.name : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
        <span className="pl-s10">{data?.awarded_company?.shift_plan?.shift?.driver_user?.name ? data?.awarded_company?.shift_plan?.shift?.driver_user?.name : ""}</span>
      </td>

      <td className='border-collapse border-[1px] border-cNmSelect py-s10 text-center'>
        <span className="pl-s10">{data?.pickup_starts_at ? formatDate(data?.pickup_starts_at) : ""}</span>
      </td>

    </tr>
  )
}

export default OnGoingRow