export const companyReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_VALUE":
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      case "SET_DATA":
        return {
          ...state,
          company_details: action.payload,
        };
      default:
        return state;
    }
}