/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { PageTitle } from '../../../Utility/UtilityFunctions';
import { useTranslation } from 'react-i18next';
import CircularProgressBar from '../../../Components/CircularProgressBar';
import { iRefresh } from '../../../Utility/Sources';
import useGeneralStore from '../../../App/stores/GeneralStore';
import CommonTable from '../../../Components/Table/CommonTable';
import { getCompanies } from '../../../App/stores/CompanyStore';
import useCustomerStore, { searchUsers } from '../../../App/stores/CustomerStore';
import useDriverStore, { getDrivers } from '../../../App/stores/DriverStore';
import DriverTableRow from './DriverTableRow';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import NoDataRow from '../../../Components/Table/NoDataRow';


const ManageDrivers = () => {
    const { drivers, all_drivers, driverSearchValue, setDriverSearchValue, driverTakeAmount, setDriverTakeAmount, setDriverPageUrl, driverPageUrl } = useDriverStore();
    const { is_searching } = useCustomerStore();
    const { t } = useTranslation();
    //const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        PageTitle('Limadi | Drivers');
        // getDrivers();
    }, []);

    useEffect(() => {
        // ! Using debounce
        const timer = setTimeout(async () => {
            if (driverSearchValue) {
                // if (!driverPageUrl) { setDriverTakeAmount(10) }
                searchUsers(driverSearchValue, 'driver', driverTakeAmount, driverPageUrl)
            } else {
                setDriverSearchValue("")
                if (!driverPageUrl) {
                    // setDriverTakeAmount(10)
                    await getDrivers("")
                } else await getDrivers(driverPageUrl)
            }

        }, driverSearchValue === '' ? 0 : 500);
        return () => clearTimeout(timer);
    }, [driverSearchValue])


    const header = [
        { index: 1, name: t("name") },
        { index: 2, name: t("email") },
        { index: 3, name: t("Phone") },
        { index: 4, name: t("status") },
        // { index: 5, name: t("status") },
        // { index: 6, name: t("actions") },
    ];


    return (
        <>
            <TitleBar count={drivers?.total} />

            <div className="justify-between w-full space-x-0 md:flex md:space-x-5">

                <CommonTable
                    filterFunction={false}
                    filterComponent={<FilterDropDown />}
                    headers={header}
                    topRightComponent={<TakeItem />}
                    showingFrom={drivers?.from}
                    showingTo={drivers?.to}
                    totalResult={drivers?.total}
                    items={all_drivers?.length > 0 ? all_drivers?.map((message, index) => <DriverTableRow key={index} data={message} />) : <NoDataRow />}
                    shoSearchBox={true}
                    searchOnChange={(e) => {
                        setDriverSearchValue(e.target.value)
                        setDriverPageUrl("")
                    }
                    }
                    withClearSearch={true}
                    onSearchClear={() => {
                        setDriverSearchValue("");
                        setDriverPageUrl("");
                    }}
                    searchValue={driverSearchValue}
                    paginationObject={drivers}
                    showPagination={drivers?.last_page !== 1 ? true : false}
                    search_loading={is_searching}
                    paginationOnClick={async (url) => {
                        setDriverPageUrl(url)
                        // console.log(url);
                        driverSearchValue !== "" ? searchUsers(driverSearchValue, 'driver', driverTakeAmount, url) : await getDrivers(url);
                    }}
                />
            </div>
        </>
    )
}

export default ManageDrivers


const TitleBar = ({ count }) => {

    const { isLoading } = useGeneralStore();
    const { setShowAddDriverModal, setDriverSearchValue, driverPageUrl } = useDriverStore();

    return (
        <>
            <div className="flex flex-row justify-between w-full p-5 mb-5 bg-white shadow-sm rounded-2xl">
                <div className="flex items-center text-fs28 font-fw600">
                    <div className="capitalize">{'Drivers'}</div>
                    <div className="pr-5 pl-s5">({count ?? 0})</div>
                    {
                        isLoading ?
                            <CircularProgressBar />
                            :
                            <img
                                onClick={async () => { await getCompanies(driverPageUrl); await setDriverSearchValue('') }}
                                src={iRefresh}
                                alt="refresh-icon"
                                className='cursor-pointer'
                            />
                    }
                </div>

                {/* <CommonButtonOutlined btnLabel={'Add Driver'} onClick={() => setShowAddDriverModal(true)} /> */}
            </div>
        </>
    );
}

const TakeItem = () => {
    const { setDriverTakeAmount, driverTakeAmount, driverSearchValue, setDriverPageUrl } = useDriverStore();
    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                value={driverTakeAmount ? driverTakeAmount : 10}
                onChange={async (e) => {
                    console.log(e.target.value);
                    setDriverTakeAmount(e.target.value);
                    setDriverPageUrl()
                    console.log(e.target.value);
                    if (driverSearchValue) {
                        searchUsers(driverSearchValue, 'driver', e.target.value)
                    } else await getDrivers();
                }}
                className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
            >
                <option
                    className='py-s10 text-cMainBlack'
                    selected={driverTakeAmount === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cMainBlack'
                    selected={driverTakeAmount === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={driverTakeAmount === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={driverTakeAmount === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}


const FilterDropDown = () => {

    const { driverFilterValue, setCustomerSearchValue, setDriverFilterValue } = useDriverStore();

    return (
        <div>
            <select onChange={async (e) => {
                // await setLicenseTakeAmount(10);
                await setCustomerSearchValue('')
                // console.log(e.target.value);
                setDriverFilterValue(e.target.value)
                // searchLicenseApplication('status=' + e.target.value);

            }} id="cars" className='pl-5 font-semibold w-s200 h-s37 rounded-br5 select-style space-y-s5'>
                <option className='py-s10 text-cMainBlack' value="">Choose Status</option>
                <option className='py-s10 text-cMainBlack' selected={driverFilterValue === 1 || driverFilterValue === "1"} value="1">Active</option>
                <option className='py-s10 text-cMainBlack' selected={driverFilterValue === 0 || driverFilterValue === "0"} value="0">Inactive</option>
            </select>
        </div>
    )
}