import axios from 'axios';
import React, { createContext, useReducer } from 'react'
import { auth_user_url, login_url, register_url } from '../Utility/url';
import { authReducer } from "./AuthReducer";
import AxiosHeader from "../Utility/AxiosHeader";
import { Toastr, initializeFirebase } from '../Utility/UtilityFunctions';
import useGeneralStore from '../App/stores/GeneralStore';
import i18next, { t } from 'i18next';
import { useNavigate } from "react-router-dom";
import firebase from "../App/firebase/firebase";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {

  const initialState = {
    token: localStorage.getItem('limadi_token'),
    isAuth: false,
    auth_loading: false,
    loading: false,
    user: null,
    active_route: localStorage.getItem('limadi_active_route') ?? '/',
  }

  const [state, dispatch] = useReducer(authReducer, initialState)
  const { setLoading, setLoggedOut, setUserLoading } = useGeneralStore();
  const navigate = useNavigate();

  const SetActiveRoute = (curr_route) => {
    if (state.isAuth) localStorage.setItem('limadi_active_route', curr_route)
    dispatch({ type: "SET_ACTIVE_ROUTE", payload: curr_route });
  }

  const LoadUser = async () => {
    if (localStorage.limadi_token) AxiosHeader(localStorage.limadi_token);

    try {
      setUserLoading(true);
      const res = await axios.get(auth_user_url)
      console.log('LoadUser: ', res.data)
      if (res.data.success) {
        localStorage.setItem("user_id", res.data.data.id);
        localStorage.setItem("lang_code", res.data.data.lang_code);
        useGeneralStore.getState().setAppLangCode(res.data.data.lang_code);
        i18next.changeLanguage(res.data.data.lang_code)

        localStorage.setItem("numOfUnreadNotification", res.data.data.unread_notification);
        useGeneralStore.getState().setHasUnseenNotification();
        await initializeFirebase(true, firebase);
        dispatch({ type: "LOAD_USER", payload: res.data.data })
      }
      else {
        dispatch({ type: "USER_LOADING", payload: false });
      }
      setUserLoading(false);
    } catch (err) {
      console.log(err)
      setUserLoading(false);
    }
  }

  const Login = async (formData) => {
    try {
      setLoading(true);
      const res = await axios.post(login_url, formData);
      console.log('Login: ', res.data);
      if (res.data.success) {
        dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
       await initializeFirebase(true, firebase);
        LoadUser();
        setLoading(false);
      } else {
        setLoading(false);
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      Toastr(t('An error occurred!'));
      console.log(err);
      setLoading(false);
    }
  };

  const Register = async (formData) => {
    try {
      setLoading(true);
      const res = await axios.post(register_url, formData);
      console.log(res.data);
      if (res.data.success) {
        dispatch({ type: "REGISTER_SUCCESS", payload: res.data });
        setLoading(false);
        LoadUser();
      } else {
        setLoading(false);
        console.log(res.data.message);
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      Toastr(err);
    }
  };

  const Logout = async () => {
    if (localStorage.limadi_token) AxiosHeader(localStorage.limadi_token);

    try {
      setLoading(true);
      const res = await axios.post("/auth/logout", { device_token: localStorage.limadi_admin_device_token });
      console.log('Logout: ', res.data);

      if (res.data.success) {
        localStorage.setItem("limadi_admin_device_token", null);
        setLoading(true);
        setLoggedOut(true); // disable showing splash screen after logged out
        dispatch({ type: "LOGOUT" });
        Toastr(res.data.message, "success");
        navigate("/login");
      }
      else Toastr(res.data.message);

      setLoading(false);
    } catch (err) {
      console.log('Logout: ', err);
      Toastr(t('An error occurred!'));
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuth: state.isAuth,
        user: state.user,
        loading: state.loading,
        auth_loading: state.auth_loading,
        active_route: state.active_route,
        SetActiveRoute,
        Login,
        Register,
        LoadUser,
        Logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
