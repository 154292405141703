import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { AiFillCloseCircle } from "react-icons/ai";


const CommonModal = ({
    showModal=false,
    setShowModal,
    mainContent,
    modalTitle,
    subTitle = "",
    singleButton,
    primaryActionButton,
    secondaryActionButton,
    scrollModal = true,
    useAutoClose = true,
    widthClass = "w-[90vw] sm:w-[70vw] md:w-[60vw] lg:w-[55vw] xl:w-[45vw] 2xl:w-[35vw]",
    padding = "p-5",
    customClose
}) => {
    return (
        <div>
            <Transition appear show={showModal} as={Fragment}>
                <Dialog
                    as="div"
                    className={`${scrollModal ? "overflow-y-auto" : "overflow-y-hidden"}  fixed inset-0 z-50 py-10 opacity-100 bg-cModalDropBg backdrop-blur-sm`}
                    onClose={() => setShowModal(false)}
                >
                    <div className="min-h-screen px-4 text-center font-poppins">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-100"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={`inline-block ${padding} text-left align-middle bg-white rounded-lg shadow-xl opacity-100 transition-all transform gs-text-main-black ${widthClass}`}
                            >
                                <div className="relative flex flex-col items-center justify-center">
                                    <div
                                        onClick={() => setShowModal(false)}
                                        className="absolute top-0 right-0 cursor-pointer"
                                    >
                                        {
                                            customClose ? 
                                            <img src={customClose} alt="" className="z-50 pr-s10 pt-s10" />
                                            :
                                            <AiFillCloseCircle
                                                className="text-gray-600 cursor-pointer text-fs28"
                                            />
                                        }
                                        {/* <img src={iCloseModal} alt="close-modal-icon" /> */}
                                    </div>
                                    {modalTitle ? <div className="capitalize text-fs18 font-fw600">
                                        {modalTitle}
                                    </div> : ""}
                                    <div>{subTitle}</div>
                                </div>
                                {mainContent}
                                {singleButton ? (
                                    <div
                                        onClick={() => {
                                            if (useAutoClose) {
                                                setShowModal(false);
                                            }
                                        }}
                                        className="flex items-center justify-center mx-auto w-fit"
                                    >
                                        {singleButton}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="flex items-center justify-between">
                                    <div onClick={() => setShowModal(false)}>
                                        {secondaryActionButton}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (useAutoClose) {
                                                setShowModal(false);
                                            }
                                        }}
                                    >
                                        {primaryActionButton}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default CommonModal;
