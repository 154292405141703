import React from 'react'
import CommonButton from '../../Components/Button/CommonButton'
import { iAddChatIcon } from '../../Utility/Sources'
import ChatLeftBar from './components/ChatLeftBar'
import ChatRightBar from './components/ChatRightBar'
import useChatStore from '../../App/stores/chatStore'

const Chat = () => {

    const { setShowAddChatModal } = useChatStore();

    return (
        <div>
            <div className='flex justify-between'>
                <div className='font-fw600 text-fs28 text-[#333333]'>Chat</div>
                <CommonButton
                    onClick={() => {
                        setShowAddChatModal(true)
                    }}
                    width='w-[100px]'
                    leftIconPadding="pr-s10"
                    btnLabel='Chat'
                    leftIcon={
                        <img src={iAddChatIcon} alt="" />
                    }
                />
            </div>

            <div className='grid grid-cols-12 ring-1 rounded-md ring-[#D1D5DB] mt-4'>
                <div className='col-span-4 pr-4 border-r-[1px] border-[#D1D5DB] py-4'>  <ChatLeftBar /></div>
                <div className='col-span-8'>
                    <ChatRightBar />
                </div>
            </div>

        </div>
    )
}

export default Chat