import { Tooltip } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const SubSection = ({ isSidebarOpen, url = '/', icon, title, is_active = false, onClick, active_image = null }) => {

  const [inHoverState, setInHoverState] = useState(false);

  return (
    <Link
      onMouseEnter={() => setInHoverState(true)}
      onMouseLeave={() => setInHoverState(false)}
      onClick={onClick}
      to={url}
      className={`flex justify-start items-center text-sm ${isSidebarOpen ? 'px-7' : ''} py-2 mb-1 text-cIcon cursor-pointer hover:font-semibold hover:text-limadi ${is_active && 'bg-cListItem text-cPrimary font-semibold'}`}
    >

      <Tooltip title={isSidebarOpen ? '' : title}>
        <div className={`flex justify-start items-center transition-all ${isSidebarOpen ? 'text-lg mr-2' : 'text-lg'}`}>
          <img className={`w-5 h-5`} src={inHoverState ? active_image : icon} alt="" />
          {/* <img className={`w-5 h-5`} src={icon} alt="" /> */}
        </div>
      </Tooltip>

      {isSidebarOpen && title}
    </Link>
  )
}

export default SubSection