import React from 'react'
import { useTranslation } from 'react-i18next';
import { iLockIcon } from '../../../App/Utility/ImageImports';

const NoSelectChat = () => {

    


    return (
        <div className='flex items-center justify-center h-[calc(100vh-248px)] space-x-1'>
            <img alt='' src={iLockIcon} className='' />
            <div className='text-fs14 font-fw400 text-cGrayishBlue text-center flex items-start justify-start'>
                Chat with customer, company and driver
            </div>
        </div>
    )
}

export default NoSelectChat