import React from 'react'

const CommonListItem = ({title="",value="",email=false}) => {
    return (
        <div className="flex justify-between">
            <div className={`min-w-[200px] text-fs14 font-fw400 text-[#828282] ${email ? "": "capitalize"}`}>{title ?? ""}</div>
            <div className='text-fs14 font-fw400 text-[#595959] break-all w-[400px] flex justify-end items-end'>{value ?? "" }</div>
        </div>
    )
}

export default CommonListItem