export const requestReducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_TYPE":
        return {
          ...state,
          type: action.payload,
        };
      case "SET_INIT_DATA":
        return {
          ...state,
          favorite_companies: action.payload.favorite_companies,
          transportation_types: action.payload.transportation_types,
          favorite_addresses: action.payload.favorite_addresses,
        };
      case "CHANGE_VALUE":
        return {
          ...state,
          [action.payload.key]: action.payload.value
        };
      case "SET_DATA":
        return {
          ...state,
          id: action.payload.id,
          type: action.payload.type,
          status: action.payload.status,
          title: action.payload.title,
          transport_type: action.payload.transport_type,
          pickup_date: action.payload.pickup_date,
          pickup_start_time: action.payload.pickup_start_time,
          pickup_end_time: action.payload.pickup_end_time,
          delivery_start_time: action.payload.delivery_start_time,
          delivery_end_time: action.payload.delivery_end_time,
          pickup_address: action.payload.pickup_address,
          delivery_address: action.payload.delivery_address,
          pickup_comment: action.payload.pickup_comment,
          pickup_attachment_url: action.payload.pickup_attachment,
          is_global: action.payload.is_global,
          invitation_ids: action.payload.invitation_ids ? action.payload.invitation_ids : [],
          global_companies: action.payload.global_companies ? action.payload.global_companies : [],
        };
      case "CLEAR":
        return {
          ...state,
          id: null,
          // type: action.payload.type,
          status: state.type === 'simple' ? 'init' : 'pickup1',
          title: null,
          transport_type: null,
          pickup_date: null,
          pickup_start_time: null,
          pickup_end_time: null,
          delivery_start_time: null,
          delivery_end_time: null,
          pickup_address: null,
          delivery_address: null,
          pickup_comment: null,
          pickup_attachment_url: null,
          is_global: false,
          invitation_ids: [],
        };
      case "SEARCH_COMPANY":
        return {
          ...state,
          search_companies: action.payload,
        };
      default:
        return state;
    }
}