import { t } from 'i18next';
import React from 'react'
import Image from '../../../../../../Components/Image/Image';
import { formatDate } from '../../../../../../Utility/UtilityFunctions';
import DeliveryInfoItem from './DeliveryInfoItem';


export default function DeliveryInfo({ cloudReqData }) {

  const { 
    title,
    transport_type,
    pickup_start_time,
    pickup_date,
    pickup_address,
    delivery_address,
    pickup_attachment,
    type, pickup_end_time,
    pickup_comment
  } = cloudReqData;
  return (
    <>
      <div className={`rounded-br5 duration-500 ease-in-out overflow-hidden text-base text-cBodyText capitalize max-h-[400px] overflow-y-auto`}>

        <div className="bg-cListItem p-5 rounded-xl">
          <DeliveryInfoItem title={t("Title")} value={title} />
          <DeliveryInfoItem title={t("Transportation Type")} value={transport_type ?? null} />
          <DeliveryInfoItem title={t("Pickup Date")} value={pickup_date ? formatDate(pickup_date) : null} />
          <DeliveryInfoItem title={t("Pickup Time")} value={pickup_start_time ? `${pickup_start_time} (End Time ${pickup_end_time})` : null} />

          <DeliveryInfoItem title={t("Pickup Address")} value={pickup_address ? pickup_address : null} />

          {type === 'simple' && <DeliveryInfoItem title={t("Delivery Address")} value={delivery_address ? delivery_address : null} />}

          <DeliveryInfoItem title={t("Comment")} value={pickup_comment ? pickup_comment : null} alignUnified={true} />

          <div className="flex justify-between mt-5">
            <div className="text-fs16 font-fw400 text-cIcon">{t("Attachment")}</div>
            <div className="rounded-lg overflow-hidden w-36">
              <Image src={pickup_attachment ? pickup_attachment : null} className="cursor-pointer" />
            </div>
          </div>

        </div>

      </div>
    </>
  )
}
