import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonImage from "../../../Components/Image/CommonImage";

const DriverTableRow = ({ data }) => {
  const navigateTo = useNavigate();
  return (
    <tr onClick={() => navigateTo("/users/drivers/details/" + data?.id)} className={`border-b-[1px] border-collapse border-cNmSelect  cursor-pointer hover:bg-cGridView select-none "font-semibold"`} >

      <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
          </div>
          {data?.name ? <Tooltip title={data?.name}><div className='capitalize items-center pl-s10 truncate'>{data?.name}</div></Tooltip> : ''}
        </div>
      </td>

      <td className='p-s10 border-r-[1px] text-center max-w-[150px] min-w-[150px] truncate'>
      {data?.email ? <Tooltip title={data?.email}><div className='truncate'>{data?.email}</div></Tooltip> : ''}
      </td>

      <td className='py-s10 border-r-[1px] text-center max-w-[120px] min-w-[120px] truncate'>
      {data?.phone ? <Tooltip title={data?.phone}><div className='truncate's>{data?.phone}</div></Tooltip> : ''}
      </td>

      <td className='p-s10 border-r-[1px] text-center text-fs14 font-fw600 max-w-[70px] min-w-[70px] truncate'>
        {data?.is_active ?
          <span className='text-cSecondary'>Active</span>
          :
          <span className='text-cRed'>Inactive</span>
        }
      </td>
    </tr>
  )
}


export default DriverTableRow;