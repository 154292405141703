import React from 'react'
import useCompanyStore from '../../../../App/stores/CompanyStore';
import { userToggleStatus } from '../../../../App/stores/CustomerStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal';

export default function DeactivateCompany() {
    const { showDeactivateCompanyModal, setShowDeactivateCompanyModal } = useCompanyStore();
    
    const {companyDetails} = useCompanyStore();

    let status = companyDetails?.is_active ? 0 : 1;
    const body = {
      user_id: companyDetails?.id,
      is_active: status
    } 

    return (
        <>
            <CommonModal
                showModal={showDeactivateCompanyModal}
                setShowModal={setShowDeactivateCompanyModal}
                modalTitle="deactivate confirmation"
                mainContent={
                    <div className='py-5 text-base text-center'>
                        Are you sure you want to deactivate this Company Account ?
                    </div>
                }

                primaryActionButton={
                    <CommonButton colorType='danger' btnLabel='Yes' onClick={()=>userToggleStatus(companyDetails?.id,body,"company")} />
                }

                secondaryActionButton={
                    <CommonButton btnLabel='no' />
                }
            />
        </>
    )
}
