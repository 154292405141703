/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDashboardStore, { getDashboardCards, getDashboardLicense, getDashboardMessage, searchLicenseTable } from '../../App/stores/DashboardStore';
import TitleBar from '../../Components/Common/TitleBar';
import DashboardCard from '../../Components/Dashboard/DashboardCard';
import LicenseTableRow from '../../Components/Table/LicenseTableRow';
import NoDataRow from '../../Components/Table/NoDataRow';
import { PageTitle } from '../../Utility/UtilityFunctions';
import CommonTable from './../../Components/Table/CommonTable';
import NewMessageTableRow from './../../Components/Table/NewMessageTableRow';


export default function Dashboard() {
    // expire_warning, pending
    const [licenseType, setLicenseType] = useState("pending");
    const [messageType, setMessageType] = useState("Company");

    const navigateTo = useNavigate();

    const { setIsSearching, is_searching, dashboardCardData, dashboardLicenseList, searchLicenseKey, setSearchLicenseKey, dashboardMessageList } = useDashboardStore();

    const header = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Car Name") },
        { index: 3, name: ("License") },
        { index: 3, name: ("Company Name") },
        { index: 4, name: ("Duration") },
        // { index: 5, name: ("Date") },
        { index: 6, name: ("Last Action") },
    ];

    const header2 = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Name") },
        { index: 3, name: ("Subject") },
        { index: 4, name: ("Message") },
        { index: 5, name: ("Date & Time") },
        { index: 6, name: ("Last Action") },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle("Limadi | Dashboard");

        getDashboardCards();
        getDashboardLicense("pending");
        getDashboardMessage("company");
        setSearchLicenseKey("")
        searchLicenseTable("")
    }, [])

    return (
        <>
            <TitleBar label={"Dashboard"} showLoadingBtn={false} hideCounter={true} />
            <div className="overflow-hidden bg-white rounded-br10">

                <div className="w-full px-5 py-5 lg:flex lg:items-center">
                    <div className="w-full p-5 border lg:m-5 border-cChipBorder rounded-br10">
                        <div className="flex flex-col w-full">
                            <div className="flex mb-5 space-x-5">
                                <DashboardCard
                                    onClick={() => navigateTo("/users/companies")}
                                    brandTitle={"Total Companies"}
                                    subTitle={"Active (" + dashboardCardData?.company?.active_company + ") Inactive(" + dashboardCardData?.company?.inactive_company + ")"}
                                    number={dashboardCardData?.company?.total_company}
                                />

                                <DashboardCard
                                    onClick={() => navigateTo("/users/customers")}
                                    brandTitle={"Total Customers"}
                                    subTitle={"Active (" + dashboardCardData?.customer?.active_customer + ") Inactive(" + dashboardCardData?.customer?.inactive_customer + ")"}
                                    number={dashboardCardData?.customer?.total_customer}
                                />
                            </div>

                            <DashboardCard
                                onClick={() => navigateTo("/license/application")}
                                brandTitle={'Total Sold Licenses'}
                                subTitle={"About to expire in 30 days (" + dashboardCardData?.license?.total_expired_warning_license + ")"}
                                number={dashboardCardData?.license?.total_sold_license}
                            />
                        </div>
                    </div>
                    <div className="pt-5 lg:pt-0"></div>
                    <div className="w-full p-5 mr-5 border lg:my-5 border-cChipBorder rounded-br10">
                        <div className="flex flex-col w-full">
                            <div className="flex mb-5 space-x-5">
                                <DashboardCard
                                    onClick={() => navigateTo("/dash-request-details/customer_low_activity")}
                                    isCompact={true}
                                    subTitle={'Customer with low activity'}
                                    number={dashboardCardData?.customer?.low_profile_customer}
                                />
                                <DashboardCard
                                    onClick={() => navigateTo("/dash-request-details/company_low_activity")}
                                    isCompact={true}
                                    subTitle={'Companies with low activity'}
                                    number={dashboardCardData?.company?.low_profile_company}
                                />
                            </div>

                            <DashboardCard
                                onClick={() => navigateTo("/dash-request-details/req_no_bid")}
                                isCompact={true}
                                subTitle={'Request with no bid'}
                                number={dashboardCardData?.request?.request_no_bid}
                            />
                            <div className="flex mt-5 space-x-5">
                                <DashboardCard
                                    onClick={() => navigateTo("/dash-request-details/customer_no_response")}
                                    isCompact={true}
                                    subTitle={'Customer is not responding'}
                                    number={dashboardCardData?.request?.request_no_bid_action}
                                />
                                <DashboardCard
                                    onClick={() => navigateTo("/dash-request-details/req_ongoing")}
                                    isCompact={true}
                                    subTitle={'More than 2 days in Ongoing'}
                                    number={dashboardCardData?.request?.request_ongoing}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-5 pt-10 pb-5">
                    <hr color='#E9E9E9' />
                </div>

                <div>
                    <CommonTable
                        headers={header}
                        withClearSearch={true}
                        shoSearchBox={true}
                        searchValue={searchLicenseKey}
                        onSearchClear={() => {
                            setSearchLicenseKey("")
                            searchLicenseTable("")
                        }}
                        searchOnChange={(e) => {
                            // console.log(e.target.value);
                            setSearchLicenseKey(e.target.value)
                            searchLicenseTable(e.target.value)
                        }}
                        search_loading={is_searching}
                        showPagination={false}
                        showPageCountText={false}
                        showChip={true}
                        chipArray={[
                            {
                                title: ("New License Applications (" + dashboardCardData?.license?.pending + ")"),
                                selected: licenseType === "pending" ? true : false,
                                action: () => {
                                    console.log("New License Applications");
                                    getDashboardLicense("pending");
                                    setLicenseType("pending");
                                    setSearchLicenseKey("")
                                },
                            }, {
                                title: ("About To Expire License (" + dashboardCardData?.license?.expire_warning + ")"),
                                selected: licenseType === "expire_warning" ? true : false,
                                action: () => {
                                    console.log("About To Expire License");
                                    getDashboardLicense("expire_warning");
                                    setLicenseType("expire_warning");
                                    setSearchLicenseKey("")
                                },
                            },
                        ]}
                        tableTitle={"New License Request (" + (dashboardCardData?.license?.pending + dashboardCardData?.license?.expire_warning) + ")"}
                        items={
                            <>
                                {dashboardLicenseList?.length > 0 ? dashboardLicenseList.map((item, index) =>
                                    <LicenseTableRow key={index} data={item} index={index} />
                                )
                                    : <NoDataRow columnNumber={6} />
                                }
                            </>
                        }
                        ite
                    />
                </div>

                {/* <div className="px-5 pt-10 pb-5">
                    <hr color='#E9E9E9' />
                </div>

                <div >
                    <CommonTable
                        headers={header2}
                        shoSearchBox={false}
                        showPagination={false}
                        showPageCountText={false}

                        showChip={true}
                        chipArray={[
                            {
                                title: ("Company (" + dashboardCardData?.message?.company + ")"),
                                selected: messageType === "Company" ? true : false,
                                action: () => {
                                    console.log("Company");
                                    setMessageType("Company");
                                    getDashboardMessage("company");
                                },
                            },
                            {
                                title: ("Customer (" + dashboardCardData?.message?.customer + ")"),
                                selected: messageType === "Customer" ? true : false,
                                action: () => {
                                    console.log("Customer");
                                    setMessageType("Customer");
                                    getDashboardMessage("customer");
                                },
                            },
                            {
                                title: ("Driver (" + dashboardCardData?.message?.driver + ")"),
                                selected: messageType === "Driver" ? true : false,
                                action: () => {
                                    console.log("Driver");
                                    setMessageType("Driver");
                                    getDashboardMessage("driver");
                                },
                            },
                        ]}

                        tableTitle={"New Messages"}

                        seeAllText="See All"
                        seeAllLink='/contact-us'

                        items={
                            dashboardMessageList?.length > 0 ? dashboardMessageList?.map((item, index) =>
                                <NewMessageTableRow key={index} data={item} index={index} />
                            )
                                : <NoDataRow columnNumber={6} />
                        }
                    />
                </div> */}

            </div>
        </>
    )
}
