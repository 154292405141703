import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useContactStore, { HandleReplyMessages } from '../../App/stores/ContactStore';
import useDashboardStore, { getDashboardCards, getDashboardMessage } from '../../App/stores/DashboardStore';
import { NoUserImage } from '../../App/Utility/ImageImports';
import CommonButton from '../../Components/Button/CommonButton';
import CommonShowMoreLess from '../../Components/CommonShowMoreLess';
import CommonInput from '../../Components/Input/CommonInput';
import CommonLabel from '../../Components/Label/CommonLabel';
import CommonModal from '../../Components/Modal/CommonModal';
import { BaseUrlSrc } from '../../Utility/url';
import { formatDate } from '../../Utility/UtilityFunctions';

function NewMessageReply() {

    const { showNewMsgReply, setShowNewMsgReply, message } = useDashboardStore();

    return (
        <>
            <CommonModal
                showModal={showNewMsgReply}
                setShowModal={setShowNewMsgReply}
                modalTitle={t("Message Details")}
                // subtitle="Change License Status"
                mainContent={
                    <ReplyForm targetMsg={message} />
                }
            />
        </>
    )
}

export default NewMessageReply


function ReplyForm({ targetMsg }) {
    const { t } = useTranslation();

    const { contactMessageReply, setContactMessageReply, setShowDeleteMessage } = useContactStore();
    const { setShowNewMsgReply } = useDashboardStore();

    const navigateTo = useNavigate();

    const handleSubmitReply = async () => {
        if (contactMessageReply) {
            let replySuccess = HandleReplyMessages();
            if (replySuccess) {
                await getDashboardCards();
                await getDashboardMessage("company");
                setShowNewMsgReply(false);
            }
        }
    }


    return (
        <div className='pt-5 w-full bg-white rounded-br10'>
            <div className='flex justify-between items-center w-full bg-cMessageTitleBG p-s10 rounded-br10'>
                <div className='flex items-center space-x-5'>

                    <div className=''>
                        <div className="relative">
                            <img className='object-cover w-11 h-11 rounded-full border-4 border-white' src={targetMsg?.user?.image ? (BaseUrlSrc + targetMsg?.user?.image) : NoUserImage} alt="user-img" />
                            {targetMsg?.is_seen === 0 ?
                                <div className='text-white bg-cRed px-s5 rounded-full text-[10px] w-fit absolute -top-1 -right-4'>New</div>
                                : ""}
                        </div>
                    </div>
                    <div>
                        <div className='text-fs14 font-fw600'>{targetMsg?.user?.name}</div>
                        <div className='text-fs14 text-cBodyText'>{targetMsg?.user?.email}</div>
                    </div>
                </div>
                <CommonButton
                    onClick={() => {
                        if (targetMsg?.user?.role === 'driver') {
                            setShowNewMsgReply(false)
                            navigateTo(`/users/drivers/details/${targetMsg?.user?.id}`)
                        }
                        else if (targetMsg?.user?.role === 'company') {
                            setShowNewMsgReply(false)
                            navigateTo(`/users/companies/details/${targetMsg?.user?.id}`)
                        }
                        else if (targetMsg?.user?.role === 'private') {
                            setShowNewMsgReply(false)
                            navigateTo(`/users/customers/details/${targetMsg?.user?.id}`)
                        }
                    }}
                    fullRounded={true}
                    btnLabel="View Profile"
                    width='w-[110px]' />
            </div>

            <div className='pt-5'>
                <div >
                    <div className='flex pb-5'>
                        <div className='text-sm font-semibold capitalize text-fs16 text-cChipText'>Subject:</div>
                        <span className="pl-s5 text-cMessageReplyBody text-fs14">
                            {targetMsg?.subject}
                        </span>
                    </div>
                    <div className="flex justify-between">
                        <CommonLabel labelText={t("Message Details")} />
                        <div className='text-cIcon'>
                            {formatDate(targetMsg?.created_at)}
                        </div>
                    </div>
                    <div className='rounded-br10 mt-s10 '>
                        <div className='text-fs14 max-w-[600px] overflow-hidden'>
                            {<CommonShowMoreLess totalLetters="90" data={targetMsg?.message} />}
                        </div>
                        {/* <div className='flex justify-end items-center mt-3'>
                            <small className='text-fs12 font-fw600 text-cBodyText'>
                                <div className='flex items-center space-x-1'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <div>
                                    </div>
                                </div>
                            </small>
                        </div> */}
                    </div>
                </div>

                <div className="pt-5">
                    <hr />
                </div>

                {targetMsg?.reply ?
                    <div className='pt-5'>
                        <CommonLabel labelText={t("Reply")} />
                        <div className='w-full bg-cMessageReplyBG rounded-br10 p-s20 mt-s10'>
                            <p className='break-all'>
                                {targetMsg?.reply}
                            </p>
                            <div className='flex justify-end items-center mt-3'>
                                <small className='text-fs12 font-fw600 text-cBodyText'>
                                    <div className='flex items-center space-x-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                        </svg>
                                        <div>
                                            {formatDate(targetMsg?.updated_at)}
                                        </div>
                                    </div>
                                </small>
                            </div>
                        </div>
                    </div>
                    : ""
                }

                {targetMsg?.reply ?
                    (
                        <div className="pt-5">
                            <CommonButton btnLabel={t("Delete")} colorType="danger" onClick={() => setShowDeleteMessage(true)} />
                        </div>
                    ) :
                    <form onSubmit={(e) => e.preventDefault()} className='pt-5'>
                        <CommonInput
                            required={true}
                            textarea={true}
                            max_char_limit={500}
                            is_show_char_limit={true}
                            name={'body'}
                            label={t("Reply Message")}
                            placeholder={t("Write Message")}
                            value={contactMessageReply}
                            onChange={(e) => setContactMessageReply(e.target.value)}
                        />
                        <div className='flex justify-between pt-5'>
                            <CommonButton btnLabel={t("Delete")} colorType="danger" onClick={() => setShowDeleteMessage(true)} />
                            <CommonButton btnLabel={t("Send Reply")} type="submit" onClick={handleSubmitReply} />
                        </div>
                    </form>}
            </div>
        </div>
    )
}