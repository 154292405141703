import React from 'react'
import { useTranslation } from 'react-i18next';
import useContactStore from '../../App/stores/ContactStore'
import CommonButton from '../../Components/Button/CommonButton';
import CommonModal from '../../Components/Modal/CommonModal'

export default function DeleteMessage() {
    const { showDeleteMessage, setShowDeleteMessage } = useContactStore();
    const { t } = useTranslation();
    return (
        <CommonModal
            showModal={showDeleteMessage}
            setShowModal={setShowDeleteMessage}
            modalTitle={t("Confirmation")}
            // subtitle="Change License Status"
            mainContent={
                <div className='py-5 text-center'>
                    {t("Are you sure you want to delete this message ?")}
                </div>
            }
            singleButton={
                
                <CommonButton onClick={()=>{}} colorType='danger' btnLabel={t("delete")} />
            }
        />
    )
}
