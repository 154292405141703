/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import useProfileStore, { getProfileInfo, updateProfileInfo } from '../../App/stores/ProfileStore';
import { NoUserImage } from '../../App/Utility/ImageImports';
import CommonButton from '../../Components/Button/CommonButton';
import ProfileImageUploader from '../../Components/Image/ProfileImageUploader';
import CommonInput from '../../Components/Input/CommonInput';
import { AuthContext } from '../../Context/AuthContext';
import { BaseUrlSrc } from '../../Utility/url';
import CommonImage from '../../Components/Image/CommonImage';
import { iDemoUser } from '../../Utility/Sources';

export default function AccountSettings() {
    const { user_info } = useProfileStore();
    const [is_old_image, setIsOldImage] = useState(true);

    const { LoadUser } = useContext(AuthContext);
    const [editMode, setEditMode] = useState(false);
    const [tempUser, setTempUser] = useState({
        name: "",
        email: "",
        image: null,
    });

    const handleSubmitProfile = async (e) => {
        console.log("T_User", tempUser);
        if (tempUser.name && tempUser.email) {
            setEditMode(false);
        }
        await updateProfileInfo(tempUser.name, tempUser.image, is_old_image);
        !is_old_image && LoadUser()
    }

    useEffect(() => {
        getProfileInfo()
    }, []);

    useEffect(() => { setTempUser(user_info); }, [user_info]);

    useEffect(() => {
        // console.log("tempUser:::", tempUser);
    }, [tempUser]);

    return (
        <div className='bg-white w-[70vw] xl:w-[50vw] p-5 rounded-br10'>

            {/* top */}
            <div className="flex w-full text-fs28 font-bold">
                {editMode ? "Edit Profile" : "User Profile"}
            </div>
            <div className="flex items-start justify-between">
                {/* left */}
                <div>

                    <div className="pt-5">
                        {
                            editMode ? <ProfileImageUploader value={user_info?.image} onChange={(e) => console.log("Profile image upload:::", e)} setTempUser={setTempUser} user_info={user_info} setIsOldImage={setIsOldImage} />
                                :
                                <CommonImage
                                    className='h-s100 w-s100 object-cover rounded-full'
                                    src={user_info?.image}
                                    alt=""
                                    dummyImage={iDemoUser}
                                />
                        }
                        {
                            !editMode ? <div className="flex justify-center text-center pt-5">{user_info?.name}</div> : ""
                        }
                    </div>
                </div>
                {/* right */}
                <form onSubmit={(e) => e.preventDefault()} className='w-[40vw] xl:w-[30vw] space-y-5'>
                    <CommonInput withStar={false} required={true} disabled={!editMode} label="name" name="name" value={tempUser?.name} onChange={(e) => setTempUser({ ...tempUser, "name": e.target.value })} />
                    <CommonInput withStar={false} tooltip={editMode ? "You cannot edit this info" : ""} disabled={true} label="email" name="email" value={tempUser?.email} onChange={(e) => setTempUser({ ...tempUser, "email": e.target.value })} />
                    {
                        editMode ? <div className="flex w-full justify-between items-center">
                            <CommonButton onClick={() => setEditMode(false)} btnLabel='cancel' colorType='danger' />
                            <CommonButton type='submit' onClick={handleSubmitProfile} btnLabel='save changes' />
                        </div>
                            : ""
                    }
                </form>
            </div>

            {/* bottom */}
            {!editMode ? <div className="flex w-full justify-end pt-5">
                <CommonButton onClick={() => {
                    setEditMode(true);
                    setIsOldImage(true);
                }} btnLabel='edit' />
            </div> :
                ""
            }
        </div>
    )
}
