import { t } from 'i18next';
import React, { useEffect } from 'react';
import useLicenseStore, { getAllApplication, searchLicenseApplication } from '../../../App/stores/LicenseStore';
import TitleBar from '../../../Components/Common/TitleBar';
import CommonTable from '../../../Components/Table/CommonTable';
import NoDataRow from '../../../Components/Table/NoDataRow';
import { PageTitle } from '../../../Utility/UtilityFunctions';
import ApplicationItem from './ApplicationItem';

function UserList() {

    const {
        applicationSearchValue,
        setApplicationSearchValue,
        setApplicationFilter,
        applicationFilter,
        allApplicationList,
        is_license_application_searching,
        licenseTakeAmount,
        setLicenseTakeAmount,
        applicationPageUrl,
        setApplicationPageUrl
    } = useLicenseStore();

    //const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle("Limadi | Application");
        // getAllApplication();
    }, []);

    useEffect(() => {
        const timer = setTimeout(async () => {
            if (applicationSearchValue !== "") {
                searchLicenseApplication('key=' + applicationSearchValue, applicationPageUrl);
                // if (!applicationPageUrl) {await setLicenseTakeAmount(10);}
                setApplicationFilter("")
            } else if (applicationFilter !== "") {
                searchLicenseApplication('status=' + applicationFilter, applicationPageUrl);
            }
            else {
                setApplicationSearchValue("")
                // setApplicationPageUrl("")
                // applicationPageUrl && getAllApplication(applicationPageUrl);
                if (!applicationPageUrl) {
                    // setLicenseTakeAmount(10);
                    getAllApplication()
                } else {
                    // setLicenseTakeAmount(10);
                    getAllApplication(applicationPageUrl);
                }
                // setApplicationFilter("")
            }
        }, applicationSearchValue === "" ? 0 : 500);
        return () => clearTimeout(timer);
    }, [applicationSearchValue])

    const header = [
        { index: 1, name: t("car name") },
        { index: 2, name: t("license") },
        { index: 3, name: t("duration") },
        { index: 4, name: t("Company Name") },
        { index: 5, name: t("license status") },
        { index: 6, name: t("last action") },
    ];

    return (
        <>

            <TitleBar label={'Car License Application'}
                count={allApplicationList?.total}
                onClick={() => {
                    getAllApplication();
                    setApplicationSearchValue('')
                    // setLicenseTakeAmount(10);
                    setApplicationFilter("")
                }}
                rightCornerComponent={<FilterDropDown />}
            />

            <div className="justify-between w-full space-x-0 md:flex md:space-x-5">

                <CommonTable
                    headers={header}
                    showingFrom={allApplicationList?.from}
                    topRightComponent={<TakeItem />}
                    showingTo={allApplicationList?.to}
                    totalResult={allApplicationList?.total}
                    items={allApplicationList?.data?.length > 0 ?
                        allApplicationList?.data?.map((item, index) => <ApplicationItem key={index} data={item} />)
                        : <NoDataRow />
                    }
                    withClearSearch={true}
                    onSearchClear={()=>{
                        setApplicationSearchValue("")
                        setApplicationPageUrl();
                    }}
                    shoSearchBox={true}
                    searchOnChange={(e) => {
                        setApplicationSearchValue(e.target.value)
                        setApplicationPageUrl()
                    }}
                    searchValue={applicationFilter ? "" : applicationSearchValue}
                    paginationObject={allApplicationList}
                    showPagination={allApplicationList?.last_page !== 1 ? true : false}
                    search_loading={is_license_application_searching}
                    paginationOnClick={(url) => {
                        setApplicationPageUrl(url)
                        if (applicationFilter) {
                            searchLicenseApplication('status=' + applicationFilter, url);
                        } else applicationSearchValue !== "" ? searchLicenseApplication('key=' + applicationSearchValue, url) : getAllApplication(url);
                    }}
                />
            </div>

        </>
    )
}

export default UserList

const FilterDropDown = () => {
    const { setApplicationFilter, applicationFilter, setApplicationSearchValue, setLicenseTakeAmount } = useLicenseStore();
    return (
        <div>
            <select onChange={async (e) => {
                // await setLicenseTakeAmount(10);
                // await setApplicationSearchValue('')
                // console.log(e.target.value);
                setApplicationFilter(e.target.value)
                searchLicenseApplication('status=' + e.target.value);

            }} id="cars" className='pl-5 font-semibold w-s200 h-s40 rounded-br10 select-style space-y-s5'>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === "" ? true : false} value="">All license</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'active'} value="active">Active License</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'pending'} value="pending">New License</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'expire_warning'} value="expire_warning">Expire soon</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'processing'} value="processing">Processing</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'expired'} value="expired">Expired</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'rejected'} value="rejected">Rejected</option>
                <option className='py-s10 text-cMainBlack' selected={applicationFilter === 'no_license'} value="no_license">No License</option>
            </select>

        </div>
    )
}


const TakeItem = () => {
    const { applicationSearchValue, setLicenseTakeAmount, licenseTakeAmount, applicationFilter } = useLicenseStore();
    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                value={licenseTakeAmount ? licenseTakeAmount : 10}
                onChange={(e) => {
                    // console.log(e.target.value);
                    setLicenseTakeAmount(e.target.value);
                    if (applicationFilter) {
                        searchLicenseApplication('status=' + applicationFilter);
                    } else if (applicationSearchValue) {
                        searchLicenseApplication('key=' + applicationSearchValue);
                    } else getAllApplication();
                }}
                className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
            >
                <option
                    className='py-s10 text-cMainBlack'
                    selected={licenseTakeAmount === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cMainBlack'
                    selected={licenseTakeAmount === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={licenseTakeAmount === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={licenseTakeAmount === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}
