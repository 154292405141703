import React from 'react'
import useBannerStore from '../../App/stores/BannerStore'
import BannerItem from './BannerItem'

function BannerGrid() {
  const { allBannerList } = useBannerStore();

  return (
    <>
      <div className='w-full'>
        <div className='grid 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8'>
          {allBannerList?.map((banner, index) => (
            <BannerItem key={index} data={banner} />
          ))}
        </div>
      </div>
    </>
  )
}

export default BannerGrid