import React, { useContext, useEffect } from 'react'
import useShiftStore, { getShiftRouteList } from '../../../../../../App/stores/ShiftStore'
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import CommonStopsList from '../../../../../../Components/CommonStopsList';
import { calculateDistance } from '../../../../../../Utility/UtilityFunctions';
import SecondaryTitle from '../../../../../../Components/SecondaryTitle';
import { useTranslation } from 'react-i18next';

function RouteOverview() {
    const { isShiftDetailsData } = useContext(ShiftPlannerContext);
    const { shiftRouteList } = useShiftStore();
    useEffect(() => {
        if (isShiftDetailsData?.id) {
            getShiftRouteList(isShiftDetailsData?.id, false)
        }
    }, [isShiftDetailsData])

    const { t } = useTranslation();

    // console.log("shiftRouteList", shiftRouteList)

    return (
        <div>
            {(shiftRouteList?.length > 0 && (isShiftDetailsData?.status === 'ongoing' || isShiftDetailsData?.status === 'break')) ?
                <>
                    <div className='pt-5'>
                        <SecondaryTitle title={`${t("Route Overview")} (` + (shiftRouteList?.length ?? 0) + ')'} />
                    </div>
                    <div className='w-full pb-10 space-y-5'>
                        {
                            shiftRouteList?.length > 0 ? shiftRouteList?.map((item, index) =>
                                <div className="">
                                    <CommonStopsList
                                        key={index}
                                        totalKm={item?.status === 'hold' ? "0 KM" : calculateDistance(item?.distance)?.distance +
                                            ' ' + calculateDistance(item?.distance)?.unit}
                                        time={item?.status === 'hold' ? "00:00" : item?.approx_time ?? "00:00"}
                                        count={item?.q_index ?? "0"}
                                        routeType={item?.stop_type}
                                        title={item?.type === 'default' ? `Request ID - ${item?.request_id}` : item?.title}
                                        subTitleOne={(item?.stop_details?.products?.length ?? 0) + ' Packages'}
                                        subTitleTwo={item?.address ?? "No address"}
                                        title_max_width='300'

                                        accentType={item?.status === 'hold' ? 'on_hold' : 'transparent'}

                                        accentBorderType={
                                            item?.status === 'hold' ? 'on_hold'
                                                : item?.status === 'on_going' ? 'warning'
                                                    : item?.status === 'init' ? 'warning'
                                                        : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                            : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                                : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                    : 'transparent'
                                        }

                                        circleColorType={
                                            item?.status === 'hold' || item?.status === 'on_going' ? 'warning'
                                                : item?.status === 'init' ? 'warning'
                                                    : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                        : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                            : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                : 'transparent'
                                        }

                                        topRightComponent={
                                            item?.q_index && item?.status === 'un_optimized' ? 'Not optimized'
                                                : item?.status === 'init' ? 'Not optimized'
                                                    : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'Completed'
                                                        : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'Completed'
                                                            : item?.status === 'on_going' ? 'Ongoing'
                                                                : item?.status === 'hold' ? 'On Hold'
                                                                    : item?.status
                                        }

                                        topRightComponentType={
                                            item?.status === 'hold' ? 'on_hold'
                                                : item?.status === 'on_going' ? 'warning'
                                                    : item?.status === 'init' ? 'warning'
                                                        : item?.q_index && item?.status === 'un_optimized' ? 'danger'
                                                            : item?.status === 'complete' && item?.stop_status === 'not_delivered' ? 'base'
                                                                : item?.status === 'complete' && item?.stop_status === 'delivered' ? 'base'
                                                                    : 'transparent'
                                        }
                                    />
                                </div>
                            )
                                : ""
                        }

                    </div>
                </>
                : ""
            }
        </div>
    )
}

export default RouteOverview
