import React from 'react'
import { iLockIcon } from '../../../App/Utility/ImageImports';

const NoMessage = ({textFieldCount="342px"}) => {

    return (
        <div 
        style={{ height: textFieldCount }}
        className="flex items-center justify-center bg-cLightBlue">
            <div className='flex items-start justify-start px-5 space-x-1'>
                <img alt='' src={iLockIcon} className='' />
                <div className='flex items-center justify-center text-center text-fs14 font-fw400 text-cGrayishBlue'>
                    {`Chat with customer, company and driver`}
                </div>
            </div>
        </div>
    )
}

export default NoMessage