import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { kuGetLicense, kuLicenseAction } from '../../Utility/url';
import { ToastrLoading } from '../../Utility/UtilityFunctions';

const initialState = {
    isLoading: true,
    licenseApplication: [],
    tempLicenseApplication: [],
    licenseData: { id: null, status: '', license_start_date: '', license_end_date: '' }
}

/*
|--------------------------------------------------------------------------
| managing state of license application
|--------------------------------------------------------------------------
|
*/
export const licenseApplicationSlice = createSlice({
    name: 'licenseApplication',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log('setLoading: ' + action.payload);
            state.isLoading = action.payload
        },
        setLicenseApplication: (state, action) => {
            state.licenseApplication = action.payload
            state.tempLicenseApplication = action.payload
        },
        changeValue: (state, action) => {
            const { name, value } = action.payload;
            console.log('name: ', name);
            console.log('value: ', value);
            state.licenseData[name] = value;
        },
    },
})

/*
|--------------------------------------------------------------------------
| api related functions
|--------------------------------------------------------------------------
|
*/

export const handleSetLicenseApplication = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetLicense);
        console.log('handleSetLicenseApplication: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setLicenseApplication(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

export const handleUpdateLicenseStatus = (state, type) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
        const body = state.licenseData;
        console.log(body);
        // return;

        const res = await axios.post(kuLicenseAction, body);
        console.log(res.data);

        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            await dispatch(handleSetLicenseApplication(state))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'success', toastr);
    }
}

export const { setLoading, setLicenseApplication, changeValue } = licenseApplicationSlice.actions
export default licenseApplicationSlice.reducer