/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import CommonButton from '../../../../../Components/Button/CommonButton';
import CommonInput from '../../../../../Components/Input/CommonInput';
import CommonModal from '../../../../../Components/Modal/CommonModal';
import { CloudRequestContext } from '../../../../../Context/CloudRequestContext';
import CitySelector from "./CitySelector";
import MultiTransportSelector from "./MultiTransportSelector";
import RequestTypeSelector from "./RequestTypeSelector";

function UpdateFilter() {
  const { company_id } = useParams();

  const {
    deleteShiftData,
    updateShiftData,
    filter,
    currentSelection,
    setFilterToUpdate,
    setFilterNameUpdate,
    filterToUpdate,
    isEditingNow,
    filterReqTypeUpdate,
    setIsExpressType,
    setIsAdvancedType,
    filterTransportID,
    manageFilter,
  } = useContext(CloudRequestContext);


  const [showDelModal, setShowDelModal] = useState(false);
  const [isClrReq, setIsClrReq] = useState(false);
  const [isClrTransport, setIsClrTransport] = useState(false);
  const [isClrCity, setIsClrCity] = useState(false);

  useEffect(() => {
    if (isEditingNow) {
      setIsExpressType(false);
      setIsAdvancedType(false);
      if (filterReqTypeUpdate === "simple_and_advance") {
        setIsAdvancedType(true);
        setIsExpressType(true);
      } else {
        if (filterReqTypeUpdate === "simple") {
          setIsExpressType(true);
        } else if (filterReqTypeUpdate === "advance") {
          setIsAdvancedType(true);
        }
      }
    }
  }, [filterReqTypeUpdate, currentSelection, isEditingNow]);

  useEffect(() => {
    currentSelection === null && setFilterToUpdate("");
    window.scrollTo(0, 0);
    manageFilter();
    if (isEditingNow) {
      setFilterNameUpdate(
        filter.find(function (item) {
          return item.id === currentSelection;
        })?.name
      );
    }
  }, []);

  return (
    <>
      {/* Delete Modal */}
      <CommonModal
        showModal={showDelModal}
        setShowModal={setShowDelModal}
        modalTitle={t("Delete Item")}
        mainContent={
          <div>
            <div>
              <div className="py-5 text-center text-fs16">
                {t("Are you sure you want to delete this item ?")}
              </div>

              <div className="flex flex-row justify-center">
                <CommonButton
                  btnLabel={t("Delete")}
                  colorType="danger"
                  onClick={async () => {
                    await deleteShiftData("", parseInt(company_id));
                    setFilterToUpdate("");
                    setShowDelModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />

      {!filterToUpdate ? (
        <div className="card h-[50vh] flex justify-center items-center">
          <div className="font-semibold text-gray-500 italic">
            {t("Please Select a Filter")}
          </div>
        </div>
      ) : (
        <div className="card">
          <div className="text-xl font-bold mb-5 capitalize">
            {t("Update")} {filterToUpdate}
          </div>

          <CommonInput
            label={t("Title*")}
            placeholder={t("Title for the filter")}
            name="title"
            type="text"
            value={filterToUpdate}
            onChange={(e) => {
              setFilterToUpdate(e.target.value);
            }}
          />
          <div className="pb-5"></div>

          <RequestTypeSelector
            request_type={filterReqTypeUpdate}
            cleared={isClrReq}
            setCleared={setIsClrReq}
            finalValue={() => { }}
          />
          <div className="pt-5"></div>

          <MultiTransportSelector
            data_id={filterTransportID}
            cleared={isClrTransport}
            setCleared={setIsClrTransport}
            finalValue={() => { }}
          />
          <div className="pt-5"></div>

          <CitySelector
            cleared={isClrCity}
            setCleared={setIsClrCity}
            finalValue={() => { }}
          />

          <div className="flex flex-row justify-between pt-5">

            <div>
              <CommonButton
                btnLabel={t("Delete")}
                colorType="danger"
                onClick={() => {
                  setShowDelModal(true);
                }}
              />
            </div>

            <div>
              <CommonButton
                btnLabel={t("Save")}
                type="submit"
                onClick={() => {
                  updateShiftData(company_id);
                }}
                canSubmit={() => { }}
              />
            </div>

          </div>
        </div>
      )}
    </>
  );
}

export default UpdateFilter;
