import React, { useEffect, useState } from "react";

const SmLinearProgressBar = ({
  percentage = 90,
  textLabel = "Almost there..",
  type = "basic",
}) => {
  const [leftBar, setLeftBar] = useState();
  const [rightBar, setRightBar] = useState();

  useEffect(() => {
    var tempLeft = percentage * 2;
    if (tempLeft >= 100) {
      tempLeft = 100;
    }

    setLeftBar(tempLeft);
    if (percentage > 50) {
      var tempRight = percentage;
      tempRight = 100 - tempRight;

      if (tempRight < 0) {
        tempRight *= -1;
      }

      setRightBar(tempRight);
    } else {
      setRightBar(100);
    }
  }, [percentage, leftBar, rightBar]);
  return (
    <div className="flex justify-between items-center text-[12px] font-semibold">
      <div className="relative w-1/2">
        {/* down layer */}
        <div className="absolute rounded-l-md w-full h-[18px] bg-white">
          <div className="pl-[4px]  w-[350px] z-50">{textLabel}</div>
        </div>
        {/* upper layer */}
        <div
          className={`absolute rounded-l-md h-[18px] text-cWhite overflow-hidden ${type === "warning" ? "bg-cPrimary" : "bg-cListIcon"
            }`}
          style={{ width: `${leftBar}%` }}
        >
          <div className="pl-[4px] w-[350px] z-50">{textLabel}</div>
        </div>
      </div>

      <div className="relative flex w-1/2 justify-end">
        {/* down layer */}
        <div
          className={`absolute rounded-r-md w-full text-cWhite ${type === "warning" ? "bg-cPrimary" : "bg-cListIcon"
            } text-right`}
        >
          <div className="pr-3">{percentage + "%"}</div>
        </div>
        {/* upper layer */}

        <div
          className={`absolute rounded-r-md text-black flex flex-row-reverse overflow-hidden bg-white`}
          style={{ width: `${rightBar}%` }}
        >
          <div className="pr-3">{percentage + "%"}</div>
        </div>
      </div>
    </div>
  );
};

export default SmLinearProgressBar;
