import React, { useEffect, useState } from 'react';

function SelectInput({
    label,
    placeholder,
    required,
    onChange,
    warningBorder = false,
    className,
    value,
    dataArray,
    disabled
}) {
    const [selectArray, setSelectArray] = useState([]);

    useEffect(() => {
        if (dataArray) setSelectArray(dataArray);
        else setSelectArray([
            {
                title: "Option 01",
                value: 10,
                selected: false
            }, {
                title: "Option 02",
                value: 20,
                selected: false,
            },
        ]);
    }, [dataArray]);

    return (
        <div className='capitalize'>
            <div className={`text-gray-600 text-left text-sm font-bold mb-2 w-full ${required === true ? "req-field" : ""}`}>{label}</div>

            <select
                required={required}
                onChange={(e) => {
                    onChange(e);
                }}
                defaultValue={value}
                disabled={disabled}
                value={value}
                className={`bg-cMoreLiteGrey text-gray-600 ${warningBorder ? "border border-cRed" : "border-cNmSelect"} bg-cMoreLiteGrey px-5 py-s10 w-full rounded-md text-cTextBlack ${className} }
                ${disabled ? "cursor-not-allowed" : "cursor-text"}
              `}>
                <option className='text-gray-600' selected value='' >{placeholder}</option>
                {
                    selectArray?.map((item, index) =>
                        item?.title ?
                            <option
                                key={index}
                                className='text-gray-600 capitalize cursor-pointer py-s10'
                                value={item?.value}
                                selected={item?.selected === true ? true : false}
                            >{item?.title}</option>
                            : ""
                    )
                }
            </select>
        </div>
    )
}

export default SelectInput