/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import firebase from "./App/firebase/firebase";
import useGeneralStore, { setFirebaseDeviceTokenFoo } from "./App/stores/GeneralStore";
import Layout from "./Components/Layout/Layout";
import SplashScreen from "./Components/Layout/SplashScreen";
import CommonModalArea from "./Components/Modal/CommonModalArea";
import Notification from "./Components/Notification/Notification";
import { AuthContext } from "./Context/AuthContext";
import { TrackOnlineContext } from "./Context/TrackOnlineContext";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";
import Login from "./Pages/Auth/Login";
import Banner from "./Pages/Banner/Banner";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Error404 from "./Pages/Errors/Error404";
import CustomDatePicker from "./Pages/ExamplePages/CustomDatePicker";
import DependentAccordionExample from "./Pages/ExamplePages/DependentAccordionExample";
import IndependentAccordionExample from "./Pages/ExamplePages/IndependentAccordionExample";
import UserList from "./Pages/License/Application/Application";
import LicenseDetails from "./Pages/License/Application/LicenseDetails";
import Package from "./Pages/License/Package/Package";
import Message from "./Pages/Message/Message";
import RandomPage from "./Pages/RandomPage/RandomPage";
import Settings from "./Pages/Settings/Settings";
import LayoutTest from "./Pages/Test/LayoutTest";
import LayoutTest02 from "./Pages/Test/LayoutTest02";
import LayoutTest3 from "./Pages/Test/LayoutTest3";
import PlayGround from "./Pages/Test/PlayGround";
import FavoriteAddress from "./Pages/Users/Common/FavoriteAddress/FavoriteAddress";
import FavoriteCompanies from "./Pages/Users/Common/FavoriteCompanies/FavoriteCompanies";
import RatingReview from "./Pages/Users/Common/FavoriteCompanies/RatingReview";
import CarManagement from "./Pages/Users/Companies/CompanyDetails/CarManagement/CarManagement";
import AddFilter from "./Pages/Users/Companies/CompanyDetails/CloudRequest/AddFilter";
import FilterResult from "./Pages/Users/Companies/CompanyDetails/CloudRequest/FilterResult";
import StopDetails from "./Pages/Users/Companies/CompanyDetails/CloudRequest/StopDetails";
import UpdateFilter from "./Pages/Users/Companies/CompanyDetails/CloudRequest/UpdateFilter";
import CompanyDetails from "./Pages/Users/Companies/CompanyDetails/CompanyDetails";
import DriverManagement from "./Pages/Users/Companies/CompanyDetails/DriverManagement/DriverManagement";
import CreateShift from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/CreateShift";
import HistoryDetails from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/HistoryDetails";
import CompanyPackageDetailsSp from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/PackageDetailsSp";
import CompanyRequestDetailsSp from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/RequestDetailsSp";
import CompanyStopDetailsSp from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/StopDetailsSp";
import EditShift from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/EditShift";
import ShiftPlannerManagement from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/ShiftPlannerManagement";
import DriverHistoryDetails from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/Details/HistoryDetails";
import DriverShiftDetails from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/Details/ShiftDetails";
import DriverShiftPlannerManagement from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/ShiftPlannerManagement";
import DriverRequestDetailsSp from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/Details/RequestDetailsSp";
import DriverStopDetailsSp from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/Details/StopDetailsSp";
import DriverPackageDetailsSp from "./Pages/Users/Drivers/DriverDetails/ShiftPlanner/Details/PackageDetailsSp";

import AddStop from "./Pages/Users/Companies/CompanyDetails/TrackOnline/Details/AddStop";
import PackageListDetails from "./Pages/Users/Companies/CompanyDetails/TrackOnline/Details/PackageListDetails";
import RoutePlannerDetails from "./Pages/Users/Companies/CompanyDetails/TrackOnline/Details/RoutePlannerDetails";
import StopDetailsFromShiftDt from "./Pages/Users/Companies/CompanyDetails/TrackOnline/Details/StopDetailsFromShiftDt";
import TrackShiftDetails from "./Pages/Users/Companies/CompanyDetails/TrackOnline/Details/TrackShiftDetails";
import TrackOnlineManagement from "./Pages/Users/Companies/CompanyDetails/TrackOnline/TrackOnlineManagement";
import ManageCompanies from "./Pages/Users/Companies/ManageCompanies";
import CustomerDetailsOutlet from "./Pages/Users/Customers/customer_details/CustomerDetailsOutlet.jsx";
import ManageCustomers from "./Pages/Users/Customers/ManageCustomers";
import DriverDetails from "./Pages/Users/Drivers/DriverDetails/DriverDetails";
import ManageDrivers from "./Pages/Users/Drivers/ManageDrivers";
import AxiosHeader from "./Utility//AxiosHeader";
import ProtectedRoute from "./Utility/ProtectedRoute";
import DashRequestDetails from "./Pages/Dashboard/DashRequestDetails";
import { Offline, Online } from "react-detect-offline";
import AssignedCompanies from "./Pages/Users/Drivers/AssignedCompanies/AssignedCompanies";
import WebMessage from "./Pages/Message/WebMessage/WebMessage";
import GlobalRequest from "./Pages/Users/Companies/CompanyDetails/Global Request/GlobalRequest";
import CommonShiftShiftDetails from "./Pages/Users/Companies/CompanyDetails/ShiftPlanner/Details/CommonShiftShiftDetails";
import Chat from "./Pages/chat/Chat";
import { initializeFirebase } from "./Utility/UtilityFunctions";
import ManageTransportationType from "./Pages/ManageTransportationType/ManageTransportationType";



if (localStorage.limadi_token) {
  AxiosHeader(localStorage.limadi_token);
} else {
  AxiosHeader();
}

function App(props) {
  const { isAuth, LoadUser } = useContext(AuthContext);
  const { stopId } = useContext(TrackOnlineContext);

  useEffect(() => { if (!isAuth) LoadUser() }, []);

  useEffect(() => {
    if (isAuth) {
      // initialize firebase
      let messaging = null;
      if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
      }
      messaging && messaging.requestPermission().then(() => {
        return messaging.getToken()
      }).then(token => {
        setFirebaseDeviceTokenFoo(token)
        // console.log('Token : ', token)
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [isAuth]);

  useEffect(() => { initializeFirebase(isAuth, firebase); }, [isAuth]);

  const { setAppInitialized } = useGeneralStore();

  useEffect(() => {
    if (!isAuth) LoadUser();
    setTimeout(() => {
      setAppInitialized(true);
    }, 3000);
  }, []);


  return (
    <div className="App font-poppins">

      <CommonModalArea />

      <Online onChange={(state) => useGeneralStore.getState().setIsOnline(state)}> <X /> </Online>
      <Offline onChange={(state) => useGeneralStore.getState().setIsOnline(state)}> <Y /> </Offline>

      <Routes>
        {/* ========================== 404 Error =========================== */}
        {/* <Route path='*' element={<Error404/>} /> */}
        <Route exact path="/*" element={<ProtectedRoute />}>
          <Route
            path="*"
            element={
              <Layout {...props}>
                <Error404 />
              </Layout>
            }
          />
        </Route>
        {/* ========================== 404 Error =========================== */}

        <Route path="/login" element={<Login />} />

        <Route exact path="/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/"
            element={
              <Layout {...props}>
                <Dashboard />
              </Layout>
            }
          />
        </Route>



        <Route exact path="/dash-request-details/:details_type" element={<ProtectedRoute />}>
          <Route
            exact
            path="/dash-request-details/:details_type"
            element={
              <Layout {...props}>
                <DashRequestDetails />
              </Layout>
            }
          />
        </Route>


        <Route path="/login" element={<Login />} />
        <Route exact path="/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/massage/user"
            element={
              <Layout {...props}>
                <LayoutTest3 />
              </Layout>
            }
          />
        </Route>


        {/* Users */}
        <Route exact path="/users/customers" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/customers"
            element={
              <Layout {...props}>
                <ManageCustomers />
              </Layout>
            }
          />
        </Route>


        <Route exact path="/users/customers/details/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/customers/details/:customer_id"
            element={
              <Layout {...props}>
                <CustomerDetailsOutlet />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/customers/details/:customer_id/favorite-company/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/customers/details/:customer_id/favorite-company/"
            element={
              <Layout {...props}>
                <FavoriteCompanies userType={"customer"} />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/customers/details/:customer_id/favorite-company/reviews" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/customers/details/:customer_id/favorite-company/reviews"
            element={
              <Layout {...props}>
                <RatingReview />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/customers/details/:customer_id/favorite-address/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/customers/details/:customer_id/favorite-address/"
            element={
              <Layout {...props}>
                <FavoriteAddress userType={"customer"} />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/companies/details/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies/details/:company_id"
            element={
              <Layout {...props}>
                <CompanyDetails />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/companies/details/:company_id/shift-planner" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies/details/:company_id/shift-planner"
            element={
              <Layout {...props}>
                <ShiftPlannerManagement />
              </Layout>
            }
          >
          </Route>
        </Route>


        {/********************** Global Request ***********************/}
        <Route
          exact
          path="/users/companies/details/:company_id/global-request"
          element={
            <Layout {...props}>
              <GlobalRequest />
            </Layout>
          }
        >
          <Route index element={<FilterResult />} />
          <Route path="manage-filter" element={<UpdateFilter />} />
          <Route path="add-filter" element={<AddFilter />} />
        </Route>

        {/**********************b Company => Shift planner ***********************/}
        <Route
          exact
          path="/users/companies/details/:company_id/shift-planner"
          element={
            <Layout {...props}>
              <ShiftPlannerManagement />
            </Layout>
          }
        >
          <Route index element={<CommonShiftShiftDetails />} />
          <Route path="create-new-shift" element={<CreateShift />} />
          <Route path="edit-shift" element={<EditShift />} />
          <Route path="shift-history" element={<HistoryDetails />} />

          <Route path="shift-history/request-details" element={<CompanyRequestDetailsSp />} />
          <Route path="request-details" element={<CompanyRequestDetailsSp />} />

          <Route path="stop-details" element={<CompanyStopDetailsSp />} />
          <Route path="shift-history/stop-details" element={<CompanyStopDetailsSp />} />

          <Route path="package-details" element={<CompanyPackageDetailsSp />} />
          <Route path="shift-history/package-details" element={<CompanyPackageDetailsSp />} />

        </Route>


        {/**********************b Track Online Driver ***********************/}
        <Route
          exact
          path="/users/companies/details/:company_id/track-online"
          element={
            <Layout {...props}>
              <TrackOnlineManagement />
            </Layout>
          }
        >
          <Route index element={<TrackShiftDetails />} />

          {/* stop details from the button of shift details if the shift is ongoing or has logical info */}
          <Route path={`stop-details`} element={<StopDetails id={stopId} />} />

          {/* stop details from the button of shift details if the shift is ongoing or has logical info */}
          <Route path={`stop-details-of-shift`} element={<StopDetailsFromShiftDt id={stopId} />} />

          <Route path={`stop-details-of-shift-tracked`} element={<StopDetailsFromShiftDt id={stopId} />} />
          <Route path="request-list" element={<CompanyRequestDetailsSp />} />
          <Route path="package-list" element={<PackageListDetails />} />
          <Route path="route-planner/:shift_id" element={<RoutePlannerDetails />} />
          <Route path="route-planner/:shift_id/add-stop" element={<AddStop />} />
        </Route>


        <Route exact path="/users/companies/details/:company_id/car-management" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies/details/:company_id/car-management"
            element={
              <Layout {...props}>
                <CarManagement />
              </Layout>
            }
          >
          </Route>
        </Route>



        <Route exact path="/users/companies/details/:company_id/driver-management" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies/details/:company_id/driver-management"
            element={
              <Layout {...props}>
                <DriverManagement />
              </Layout>
            }
          >
          </Route>
        </Route>



        <Route exact path="/users/drivers/details/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers/details/:driver_id"
            element={
              <Layout {...props}>
                <DriverDetails />
              </Layout>
            }
          >
          </Route>
        </Route>


        {/**********************e Driver => Shift planner ***********************/}
        <Route
          exact
          path="/users/drivers/details/:driver_id/shift-planner"
          element={
            <Layout {...props}>
              <DriverShiftPlannerManagement />
            </Layout>
          }
        >
          <Route index element={<DriverShiftDetails />} />
          {/* <Route path="create-new-shift" element={<CreateShift />} /> */}
          {/* <Route path="edit-shift" element={<EditShift />} /> */}
          <Route path="shift-history" element={<DriverHistoryDetails />} />

          <Route path="request-details" element={<DriverRequestDetailsSp />} />
          <Route path="shift-history/request-details" element={<DriverRequestDetailsSp />} />

          <Route path="stop-details" element={<DriverStopDetailsSp />} />
          <Route path="shift-history/stop-details" element={<DriverStopDetailsSp />} />

          <Route path="package-details" element={<DriverPackageDetailsSp />} />
          <Route path="shift-history/package-details" element={<DriverPackageDetailsSp />} />
        </Route>


        <Route exact path="/users/companies/details/:company_id/fav-address" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies/details/:company_id/fav-address"
            element={
              <Layout {...props}>
                <FavoriteAddress userType={"company"} />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/drivers/details/:driver_id/fav-address" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers/details/:driver_id/fav-address"
            element={
              <Layout {...props}>
                <FavoriteAddress userType={"driver"} />
              </Layout>
            }
          >
          </Route>
        </Route>

        <Route exact path="/users/drivers/details/:driver_id/assigned-companies" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers/details/:driver_id/assigned-companies"
            element={
              <Layout {...props}>
                <AssignedCompanies />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/drivers/details/:driver_id/fav-company" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers/details/:driver_id/fav-company"
            element={
              <Layout {...props}>
                <FavoriteCompanies userType={"driver"} />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/drivers/details/:driver_id/favorite-company/reviews" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers/details/:driver_id/favorite-company/reviews"
            element={
              <Layout {...props}>
                <RatingReview />
              </Layout>
            }
          >
          </Route>
        </Route>


        <Route exact path="/users/drivers" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/drivers"
            element={
              <Layout {...props}>
                <ManageDrivers />
              </Layout>
            }
          />
        </Route>
        <Route exact path="/users/companies" element={<ProtectedRoute />}>
          <Route
            exact
            path="/users/companies"
            element={
              <Layout {...props}>
                <ManageCompanies />
              </Layout>
            }
          />
        </Route>

        {/* Banner */}
        <Route exact path="/banner" element={<ProtectedRoute />}>
          <Route
            exact
            path="/banner"
            element={
              <Layout {...props}>
                <Banner />
              </Layout>
            }
          />
        </Route>

        <Route exact path="/chat" element={<ProtectedRoute />}>
          <Route
            exact
            path="/chat"
            element={
              <Layout {...props}>
                <Chat />
              </Layout>
            }
          />
        </Route>

        <Route exact path="/transportation-types" element={<ProtectedRoute />}>
          <Route
            exact
            path="/transportation-types"
            element={
              <Layout {...props}>
                <ManageTransportationType />
              </Layout>
            }
          />
        </Route>

        {/* blue: License */}
        <Route exact path="/license/application" element={<ProtectedRoute />}>
          <Route
            exact
            path="/license/application"
            element={
              <Layout {...props}>
                <UserList />
              </Layout>
            }
          />
        </Route>

        <Route exact path="/license/application/:application_id/details" element={<ProtectedRoute />}>
          <Route
            exact
            path="/license/application/:application_id/details"
            element={
              <Layout {...props}>
                <LicenseDetails />
              </Layout>
            }
          >
          </Route>
        </Route>

        <Route exact path="/license/package" element={<ProtectedRoute />}>
          <Route
            exact
            path="/license/package"
            element={
              <Layout {...props}>
                <Package />
              </Layout>
            }
          />
        </Route>

        {/* Message */}
        <Route exact path="/contact-us" element={<ProtectedRoute />}>
          <Route
            exact
            path="/contact-us"
            element={
              <Layout {...props}>
                <Message />
              </Layout>
            }
          />
        </Route>

        <Route exact path="/contact-us/web" element={<ProtectedRoute />}>
          <Route
            exact
            path="/contact-us/web"
            element={
              <Layout {...props}>
                <WebMessage />
              </Layout>
            }
          />
        </Route>


        {/* account-settings / profile */}
        <Route exact path="/profile" element={<ProtectedRoute />}>
          <Route
            index
            element={
              <Layout {...props}>
                <AccountSettings />
              </Layout>
            }
          />
        </Route>

        {/********************** Settings ***********************/}
        <Route exact path="/settings" element={<ProtectedRoute />}>
          <Route
            exact
            path="/settings"
            element={
              <Layout {...props}>
                <Settings />
              </Layout>
            }
          />
        </Route>

        {/********************** Testing Only ***********************/}
        <Route exact path="/play" element={<ProtectedRoute />}>
          <Route
            exact
            path="/play"
            element={
              <Layout {...props}>
                <PlayGround />
              </Layout>
            }
          />
        </Route>

        <Route exact path="/random-page" element={<ProtectedRoute />}>
          <Route
            exact
            path="/random-page"
            element={
              <Layout {...props}>
                <RandomPage />
              </Layout>
            }
          />
        </Route>

        <Route
          exact
          path="/monkey"
          element={
            <Layout {...props}>
              <CustomDatePicker />
            </Layout>
          }
        ></Route>
        <Route
          exact
          path="/pirate"
          element={
            <Layout {...props}>
              <DependentAccordionExample />
            </Layout>
          }
        ></Route>
        <Route
          exact
          path="/ninja"
          element={
            <Layout {...props}>
              <IndependentAccordionExample />
            </Layout>
          }
        ></Route>

        {/********************** Notification ***********************/}
        <Route exact path="/notifications/" element={<ProtectedRoute />}>
          <Route
            exact
            path="/notifications/"
            element={
              <Layout {...props}>
                <Notification />
              </Layout>
            }
          />
        </Route>

        <Route path="/layout-test-1" element={<LayoutTest />} />
        <Route path="/layout-test-2" element={<LayoutTest02 />} />

        <Route path="/test" element={<SplashScreen />} />
        {/********************** Testing Only ***********************/}
      </Routes>
    </div>
  );
}

export default App;


const X = () => {
  useEffect(() => useGeneralStore.getState().setIsOnline(true), [])
  return <></>
}

const Y = () => {
  useEffect(() => useGeneralStore.getState().setIsOnline(false), [])
  return <></>
}
