import { t } from 'i18next';
import { useEffect, useState } from "react";

const XsLinearProgressBar = ({
  percentage = 75,
  textLabel = t("Completed"),
  type = "basic",
}) => {
  const [leftBar, setLeftBar] = useState(0);
  const [rightBar, setRightBar] = useState(100);

  useEffect(() => {
    var tempLeft;
    var tempRight = percentage - 50;
    if (percentage > 50) {
      // console.log("RIGHT CALC");
      tempRight = 50 - tempRight;
      tempRight = (tempRight / 50) * 100;
      setRightBar(tempRight);
      setLeftBar(100);
    } else if (percentage <= 50) {
      // console.log("LEFT CALC");
      tempLeft = (percentage / 50) * 100;
      setLeftBar(tempLeft);
      setRightBar(100);
    }
  }, [percentage, leftBar, rightBar]);
  return (
    <div className="flex justify-between items-center text-[10px] font-semibold">
      <div className="relative w-1/2">
        {/*hg down layer */}
        <div className="absolute rounded-l-md w-full h-[14px] bg-white">
          <div className="pl-[4px]">{textLabel}</div>
        </div>
        {/*hb upper layer */}
        <div
          className={`absolute rounded-l-md h-[14px] text-cWhite overflow-hidden ${type === "warning" ? "bg-cPrimary" : "bg-cListIcon"
            }`}
          style={{ width: `${leftBar}%` }}
        >
          <div className="pl-[4px]">{textLabel}</div>
        </div>
      </div>

      <div className="relative flex w-1/2 justify-end">
        {/*hg down layer */}
        <div
          className={`absolute rounded-r-md w-full h-[14px] text-cWhite ${type === "warning" ? "bg-cPrimary" : "bg-cListIcon"
            } text-right`}
        >
          <div className="pr-3">{percentage + "%"}</div>
        </div>
        {/*hb upper layer */}

        <div
          className={`absolute rounded-r-md text-black flex flex-row-reverse overflow-hidden bg-white`}
          style={{ width: `${rightBar}%` }}
        >
          <div className="pr-3">{percentage + "%"}</div>
        </div>
      </div>
    </div>
  );
};

export default XsLinearProgressBar;
