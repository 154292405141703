/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

function TimePicker({ init_time = null, init_avg = 3, selectAction }) {

    const [hour, setHour] = useState('12')
    const [minute, setMinute] = useState('00')
    const [avg, setAvg] = useState(3)
    const [time, setTime] = useState(null)
    const [avg_time, setAvgTime] = useState(null)

    const hourScroll = (e, action = 'up', is_scrolling = true) => {
        if (is_scrolling) {
            if (e.deltaY < 0) action = 'up'
            else action = 'down'
        }

        let curr_hour = parseInt(hour);

        let new_hour = curr_hour

        if (action === 'up') {
            if (curr_hour === 23) {
                new_hour = 0
            } else {
                new_hour = curr_hour + 1
            }
        } else {
            if (curr_hour === 0) {
                new_hour = 23
            } else {
                new_hour = curr_hour - 1
            }
        }

        if (new_hour.toString().length < 2) new_hour = '0' + new_hour

        setHour(new_hour)
    }

    const minuteScroll = (e, action = 'up', is_scrolling = true) => {
        let curr_min = parseInt(minute);
        let new_min = curr_min

        if (action === 'up') {
            if (new_min >= 59) {
                new_min = new_min - 60
                // hourChange('up')
            } else {
                new_min = new_min + 15
                if (new_min >= 59) {
                    new_min = new_min - 60
                    // hourChange('up')
                }
            }
        } else {
            if (new_min < 0) {
                new_min = new_min + 60
                // hourChange('down')
            } else {
                new_min = new_min - 15
                if (new_min < 0) {
                    new_min = new_min + 60
                    // hourChange('down')
                }
            }
        }

        if (new_min.toString().length < 2) new_min = '0' + new_min
        setMinute(new_min)
    }



    useEffect(() => {
        setTime(`${hour}:${minute}`)
    }, [hour, minute])
    useEffect(() => {
        if (selectAction) selectAction(time, avg_time, avg)
    }, [avg, time, avg_time])

    useEffect(() => {
        let avg_hour = parseInt(hour) + avg
        if (avg_hour > 23) avg_hour = avg_hour - 24
        if (avg_hour.toString().length < 2) avg_hour = '0' + avg_hour
        let avg_min = minute
        setAvgTime(`${avg_hour}:${avg_min}`)
    }, [avg, time])

    useEffect(() => {
        let curr_hour = (new Date()).getHours()
        let curr_min = '00'

        console.log("Initial Time", init_time);

        if (init_time) {
            const time_array = init_time.split(":");

            curr_hour = time_array[0] || (new Date()).getHours()
            curr_min = time_array[1] || (new Date()).getMinutes()
        }

        if (curr_hour.toString().length < 2) curr_hour = '0' + curr_hour
        if (curr_min.toString().length < 2) curr_min = '0' + curr_min

        setHour(curr_hour)
        setMinute(curr_min)
        setAvg(init_avg)
    }, [])


    return (
        <div className='bg-white px-5 rounded-lg inline-block text-gray-700'>
            <div className='flex justify-between items-center'>
                <div className='text-4xl rounded-lg cursor-n-resize select-none flex flex-col items-center justify-between px-2'>
                    <IoIosArrowUp className='cursor-pointer p-2 m-1 hover:bg-cListItem rounded-br5' onClick={(e) => hourScroll(e, 'up', false)} />
                    <div>{hour}</div>
                    <IoIosArrowDown className='cursor-pointer p-2 m-1 hover:bg-cListItem rounded-br5' onClick={(e) => hourScroll(e, 'down', false)} />
                </div>
                <div className='text-4xl px-2'>:</div>
                <div className='text-4xl rounded-lg cursor-n-resize select-none flex flex-col items-center justify-between px-2'>
                    <IoIosArrowUp className='cursor-pointer p-2 m-1 hover:bg-cListItem rounded-br5' onClick={(e) => minuteScroll(e, 'up', false)} />
                    <div>{minute}</div>
                    <IoIosArrowDown className='cursor-pointer p-2 m-1 hover:bg-cListItem rounded-br5' onClick={(e) => minuteScroll(e, 'down', false)} />
                </div>
            </div>

        </div>
    )
}

export default TimePicker