import { Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import CommonButton from "../../../../../Components/Button/CommonButton";
import Calendar from "../../../../../Components/Calendar";
import Dropdown from "../../../../../Components/Dropdown";
import CommonInput from "../../../../../Components/Input/CommonInput";
import Input02 from "../../../../../Components/Input/Input02";
import TimePicker from "../../../../../Components/Input/TimePicker";
import { ShiftPlannerContext } from "../../../../../Context/ShiftPlannerContext";
import { iInfo } from "../../../../../Utility/Sources";
import { formatDate } from "../../../../../Utility/UtilityFunctions";


import ShiftPlannerBar from "./Bars/ShiftPlannerBar";
import SelectDriver from "./SelectDriver";
import SelectLicensePlate from "./SelectLicensePlate";
import ShiftPlannerList from "./ShiftLists/ShiftPlannerList";

const EditShift = ({
  start_date,
  end_date,
  start_time,
  end_time,
  car_number_plate,
  driver,
  comment,
}) => {

  const {
    setIsEditingShift,
    setStartTimeUpdateValue,
    setEndTimeUpdateValue,
    updateShiftData,
    setUpdateShift,
    updateShift,
    setStartDate,
    setEndDate,
    setStartTimeValue,
    setEndTimeValue,
    getShiftPlannerList,
    setClearDetailsPanel,
    clearEditPanel,
    maintenanceState,
    setMaintenanceState,
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const [tempMaintenance, setTempMaintenance] = useState(false);

  const onPickStartDateSelect = (time) => {
    setStartTimeUpdateValue(time);
    changeUpdateShiftHandler(time, "start_time");
  };

  const onPickEndDateSelect = (time) => {
    changeUpdateShiftHandler(time, "end_time");
    setEndTimeUpdateValue(time);
  };

  const changeUpdateShiftHandler = (value, type) => {
    switch (type) {
      case "comment":
        setUpdateShift({ ...updateShift, comment: value });
        break;

      case "start_time":
        setUpdateShift({ ...updateShift, start_time: value });
        break;

      case "end_time":
        setUpdateShift({ ...updateShift, end_time: value });
        break;

      case "start_date":
        setUpdateShift({ ...updateShift, start_date: value });
        break;

      case "end_date":
        setUpdateShift({ ...updateShift, end_date: value });
        break;

      case "is_maintenance":
        setUpdateShift({ ...updateShift, is_maintenance: value });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setStartDate(updateShift?.start_date);
    setEndDate(updateShift?.end_date);
    setStartTimeValue(updateShift?.start_time);
    setEndTimeValue(updateShift?.end_time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShift]);

  useEffect(() => {
    console.log("maintenanceState:::", maintenanceState);
    if (maintenanceState) {
      setUpdateShift({ ...updateShift, is_maintenance: 1, comment: "" });
    } else {
      setUpdateShift({ ...updateShift, is_maintenance: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maintenanceState]);


  useEffect(() => {
    setTempMaintenance(maintenanceState);
    if (clearEditPanel) {
      console.log("cleared by clearEditPanel:::", clearEditPanel);
      getShiftPlannerList(company_id);
      setUpdateShift({});
    }
    setClearDetailsPanel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCanSubmit = () => {
    if (maintenanceState) {
      if (updateShift.start_date && updateShift.end_date && updateShift.start_time && updateShift.end_time && updateShift.car_id) return true
      else return false
    } else {
      if (updateShift.start_date && updateShift.start_time && updateShift.end_time && updateShift.car_id &&
        updateShift.driver_user_id && updateShift.comment) return true
      else return false
    }
  }

  return (
    <>
      <ShiftPlannerBar />

      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">

        <ShiftPlannerList />

        <div className="col-span-12 lg:col-span-8 mt-5">
          {!updateShift.id ? (
            <div className="card">
              <div className="h-[30rem] pt-[15rem] flex justify-center text-center gs-text-placeholder font-semibold italic text-[18px]">
                Please select a shift to edit
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="p-[4px]">
                <div className="font-semibold text-lg">Edit Shift</div>

                {/* in maintenance */}
                <div className="mt-5 mb-2 flex flex-row items-center">
                  <input
                    className="gs-green-checkbox"
                    type="checkbox"
                    checked={maintenanceState ? true : false}
                    onChange={(e) => setMaintenanceState(!maintenanceState)}
                    id="maintenance"
                  />
                  <label htmlFor="maintenance" className="pl-2 font-fw500 text-fs14 text-cBodyText select-none cursor-pointer mr-2" >
                    In Maintenance
                  </label>
                  <Tooltip title={"When a shift is in maintenance stage, company can't assign this shift to any request.\n Maintenance stage will start and end it certain period of time."}>
                    <img className="cursor-pointer" src={iInfo} alt="" />
                  </Tooltip>
                </div>

                {/* start date, end date */}
                <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] ">
                  <Dropdown
                    width={null}
                    button={
                      <Input02
                        onChange={(date) => {
                          changeUpdateShiftHandler(date, "start_date");
                        }}
                        className="cursor-pointer"
                        className2="w-full"
                        // name="pickup_date"
                        value={
                          updateShift?.start_date
                            ? formatDate(Date.parse(updateShift?.start_date))
                            : ""
                        }
                        label="Start Date"
                        type="text"
                        placeholder="Start Date"
                        is_readonly={true}
                        icon={<BsCalendar2WeekFill className="h-[70%]" />}
                      />
                    }
                    body={
                      <Calendar
                        selectAction={(date) => {
                          changeUpdateShiftHandler(date, "start_date");
                        }}
                        init_date={
                          updateShift?.start_date
                            ? Date.parse(updateShift?.start_date)
                            : ""
                        }
                      />
                    }
                  />


                  {maintenanceState ? <Dropdown
                    // className={`${maintenanceState ? "" : "invisible"}`}
                    width={null}
                    button={
                      <Input02
                        onChange={(date) => {
                          changeUpdateShiftHandler(date, "end_date");
                        }}
                        className="cursor-pointer"
                        className2="w-full"
                        name="end_date"
                        value={formatDate(Date.parse(updateShift.end_date))}
                        label="End Date"
                        type="text"
                        placeholder="End Date"
                        is_readonly={true}
                        icon={<BsCalendar2WeekFill className="h-[70%]" />}
                      />
                    }
                    body={
                      <Calendar
                        selectAction={(date) => {
                          changeUpdateShiftHandler(date, "end_date");
                        }}
                        init_date={Date.parse(updateShift.end_date)}
                      />
                    }
                  /> : ""}

                </div>

                {/* start time, end time */}
                <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] mt-0">
                  <Dropdown
                    width={null}
                    button={
                      <Input02
                        value={updateShift.start_time}
                        onChange={(e) => { }}
                        onClick={() => { }}
                        className="cursor-pointer"
                        className2="w-full"
                        name="start_time"
                        label="Start Time"
                        type="text"
                        icon={
                          <BsClockFill className="h-[70%] cursor-pointer" />
                        }
                      />
                    }
                    body={
                      <TimePicker
                        selectAction={onPickStartDateSelect}
                        init_time={updateShift.start_time}
                      />
                    }
                  />

                  <Dropdown
                    width={null}
                    button={
                      <Input02
                        value={updateShift.end_time}
                        onChange={(e) => { }}
                        onClick={() => { }}
                        className="cursor-pointer"
                        className2="w-full"
                        name="end_time"
                        label="End Time"
                        type="text"
                        icon={
                          <BsClockFill className="h-[70%] cursor-pointer" />
                        }
                      />
                    }
                    body={
                      <TimePicker
                        selectAction={onPickEndDateSelect}
                        init_time={updateShift.end_time}
                      />
                    }
                  />
                </div>

                <SelectLicensePlate
                  lisensenNumberUpdate={updateShift.license_plate}
                  tempName={updateShift?.car_name}
                />

                <SelectDriver
                  tempName={updateShift?.driver_name}
                  isUnderMaintanance={maintenanceState ? true : false}
                />

                {/* Shift Instructions */}
                <div className={`text-sm text-left font-fw600 mb-2 text-cBodyText mt-5 ${maintenanceState ? "opacity-50" : ""}`} >
                  {"Shift Instructions*"}
                </div>
                <div className={`mb-5 mt-2 ${maintenanceState ? "opacity-50" : ""}`} >
                  <CommonInput
                    disabled={maintenanceState ? true : false}
                    textarea={true}
                    max_char_limit={500}
                    is_show_char_limit={true}
                    value={updateShift?.comment}
                    name="comment"
                    onChange={(e) => {
                      changeUpdateShiftHandler(e.target.value, "comment");
                    }}
                    placeholder="Write instructions for the driver"
                  />
                </div>

                {/* action buttons */}
                <div className="flex flex-row justify-between pt-3">
                  <Link to={"/users/companies/details/" + company_id + "/shift-planner"}>
                    <CommonButton
                      btnLabel={"Cancel"}
                      colorType="danger"
                      onClick={() => {
                        setIsEditingShift(false);
                        setMaintenanceState(tempMaintenance);
                      }}
                    />
                  </Link>

                  <div>
                    <CommonButton
                      btnLabel={"Save Changes"}
                      onClick={() => {
                        // setUpdateShift({ ...updateShift, user_id: parseInt(company_id) });
                        updateShiftData(parseInt(company_id))
                      }}
                      isDisabled={!setCanSubmit()}
                      colorType={setCanSubmit() ? "primary" : "base"}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

    </>
  );
};

export default EditShift;
