import { t } from 'i18next';
import { useEffect, useState } from 'react';
import useCloudRequestStore from '../../../../../../App/stores/CloudRequestStore';
import Accordion from '../../../../../../Components/Accordion';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import LoadingModal from '../../../../../../Components/Modal/LoadingModal';
import AnalyzeShift from '../request_details/AnalyzeShift';
import Bidding from '../request_details/Bidding';
// import AnalyzeShift from '../request_details/AnalyzeShift';
// import Bidding from '../request_details/Bidding';
import DeliveryInfo from '../request_details/DeliveryInfo';
import StopDetails from '../StopDetails';

export default function GlobalRequestDetailsModal() {

  const [toggled_accordion, setToggledAccordion] = useState('delivery_info')
  const { cloudReqData, isShowCloudRequestModal, setShowCloudRequestModal } = useCloudRequestStore()
  useEffect(() => { setToggledAccordion('delivery_info') }, [])

  return (
    <>
      <CommonModal
        // widthClass="w-[2xl:60vw] lg:w-[65vw] w-[75vw]"
        scrollModal={false}
        showModal={isShowCloudRequestModal}
        setShowModal={setShowCloudRequestModal}
        modalTitle={t("Request Details")}
        mainContent={
          !cloudReqData ? <LoadingModal />
            :
            <>
              <div className="">
                {/* delivery info */}
                <Accordion
                  withBottomBar={false}
                  accordion_type='dependent'
                  isInitOpen={toggled_accordion === 'delivery_info' ? true : false}
                  customOnClick={() => setToggledAccordion('delivery_info')}
                  header={<div className='py-s10 text-fs18 font-semibold' >{t("Delivery Info")}</div>}
                  body={<DeliveryInfo cloudReqData={cloudReqData} />}
                />

                {/* stops details for advance request */}
                {cloudReqData.type === 'advance' &&
                  <Accordion
                    withBottomBar={false}
                    accordion_type='dependent'
                    isInitOpen={toggled_accordion === 'stop_details' ? true : false}
                    customOnClick={() => setToggledAccordion('stop_details')}
                    header={<div className='text-fs16 font-fw600 text-cListTitle' >{t("Stop Details")}</div>}
                    body={<StopDetails stops={cloudReqData?.stops} />}
                  />}

                <AnalyzeShift toggled_accordion={toggled_accordion} setToggledAccordion={setToggledAccordion} reqData={cloudReqData} />

                {/* bid description */}
                <Accordion
                  withBottomBar={false}
                  accordion_type='dependent'
                  isInitOpen={toggled_accordion === 'bid_description' ? true : false}
                  customOnClick={() => setToggledAccordion('bid_description')}
                  header={<div className='text-fs16 font-fw600 text-cListTitle' >{t("Bidding")}</div>}
                  body={<Bidding setShowCloudRequestModal={setShowCloudRequestModal} request_id={cloudReqData?.id} />}
                />

              </div>
            </>
        } />
    </>
  )
}
