import { t } from 'i18next';
import { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iRefresh } from '../../../../../../Utility/Sources';

const TrackListTitleBar = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const {
    setCurrentFilterSelection,
    getTrackOnlineList,
    trackList,
    setSearchKey,
    setCurrentSelection,
  } = useContext(TrackOnlineContext);

  const { company_id } = useParams();

  const refreshFoo = async () => {
    setIsRefreshing(true);
    setCurrentFilterSelection(0);
    setSearchKey("");
    await getTrackOnlineList(true, company_id);
    setIsRefreshing(false);
    setCurrentSelection(null)
  };

  return (
    <div className="card flex flex-row justify-between items-center align-middle">
      <div className="flex items-center w-2/3">

        <div className="text-2xl font-bold mt-[5px] mr-5">
          {t("Track Online Driver")} ( {trackList.length} )
        </div>

        {/* refresh icon button */}
        {!isRefreshing ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}
      </div>
    </div>
  );
};

export default TrackListTitleBar;
