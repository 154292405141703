import React from 'react'
import { BsChevronRight } from 'react-icons/bs'
import { NoImage } from '../../Utility/Sources'
import { BaseUrlSrc } from '../../Utility/url'
import { formatDate, formatTime } from '../../Utility/UtilityFunctions'


export default function SelectedShift({ onClick, shift, driver = null, license_num = '' }) {
    return (
        <div onClick={onClick} className={`h-16 bg-cListItem rounded-br5 my-2 cursor-pointer flex flex-row justify-start items-center`}>


            <div className='flex flex-row justify-start items-center w-full'>
                <img className='h-10 w-10 rounded-full ml-2' src={driver?.image ? (BaseUrlSrc + driver?.image) : NoImage} alt="img" />


                <div className='flex flex-row justify-between items-center w-full'>
                    <div className='flex flex-col justify-center items-left ml-2 w-5/8'>
                        <div className='text-fs14 text-cListTitle  font-semibold'>{driver?.name}</div>
                        <div className='text-fs12 text-cBodyText font-semibold'>{license_num}</div>
                    </div>

                    <div className='flex flex-row justify-end items-center w-3/8 space-x-2 mr-2'>
                        <div className='flex flex-col justify-center items-end ml-2'>
                            <div className='text-fs12 text-cBodyText font-fw400'>{formatDate(shift?.start_date)}</div>
                            <div className='text-fs12 text-cBodyText font-fw400'>{formatTime(shift?.start_time)}</div>
                        </div>

                        <BsChevronRight />
                    </div>
                </div>
            </div>

        </div>
    )
}
