/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import React, { useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io';
import StopDetailsModal from './Modal/StopDetailsModal';

function StopDetails({ stops = [] }) {

  const [show_details_modal, setShowDetailsModal] = useState(false)
  const [details_stop_index, setDetailsStopIndex] = useState(null)
  const [products, setProducts] = useState([]);

  const showDetails = (index) => {
    setProducts(stops[index]?.products);
    setDetailsStopIndex(index);
    setShowDetailsModal(true);
  }


  return (
    <div>
      {
        stops?.length === 0 ?
          <div className="text-[18px] text-[#bdbdbd] italic font-semibold text-center my-10">{t("Stop Not Available")}</div>
          :
          <div>
            {
              stops?.map((stop, index) =>
                <div key={index} className='h[70px] bg-cListItem flex flex-row justify-between items-center rounded-[5px] p-2 my-2 cursor-pointer' onClick={() => { showDetails(index) }}>
                  <div className="flex flex-row justify-start items-center space-x-1">
                    <div className='flex flex-col justify-around'>
                      <div className='text-sm font-semibold text-[#333333]'>{stop?.title ?? 'No title'} </div>
                      <div className='text-sm font-semibold text-cBodyText'>{stop?.address ?? 'No address'}</div>
                    </div>
                  </div>


                  <div className='flex flex-row justify-end'>
                    <div className='pr-2 text-[12px] font-normal text-cBodyText'>
                      {stop?.products?.length} {t("Products")}</div>
                    <IoIosArrowForward className='mt-1' />
                  </div>
                </div>
              )
            }
          </div>

      }

      {/* Modals:: stop details, products list */}
      {<StopDetailsModal
        index={details_stop_index}
        show_modal={show_details_modal}
        setShowModal={setShowDetailsModal}
        products={products}
        stop={stops[details_stop_index]}
      />
      }
    </div>
  )
}

export default StopDetails