import { t } from 'i18next';
import axios from 'axios'
import create from 'zustand'
import useGeneralStore from './GeneralStore'
import { kuGlobalRequestIndex, kuSubmitRequestInvitationBidding, request_show } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';

const { setLoading } = useGeneralStore.getState()

const useCloudRequestStore = create((set) => ({
    pickup_points: [],
    setPickupPoints: (data) => set((state) => state.pickup_points = data),

    isStartFreeTextSearch: false,
    setStartFreeTextSearch: (status) => set((state) => (state.isStartFreeTextSearch = status)),

    cloudReqIndex: {},
    setCloudReqIndex: (value) => set({ cloudReqIndex: value }),

    cloudReqCompanyID: {},
    setCloudReqCompanyID: (value) => set({ cloudReqCompanyID: value }),

    cloudReqData: [],
    setCloudReqData: (value) => set({ cloudReqData: value }),

    isShowCloudRequestModal: false,
    setShowCloudRequestModal: (value) => set({ isShowCloudRequestModal: value }),

    bidding_form: { budget: '', description: '' },
    setBiddingForm: (name, value) => set((state) => (state.bidding_form[name] = value)),

    cities: [],
    setCities: (data) => set((state) => (state.cities = data)),

    selectedTitles: [],
    setSelectedTitles: (data) => set((state) => (state.selectedTitles = data)),
    /* 
    const [cities, setCities] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
     */
}))

export default useCloudRequestStore;

const { setCloudReqData } = useCloudRequestStore.getState();


// get request index list  
export const getCloudReqIndex = async (user_id, filterForm, searchKey) => {

    const { setCloudReqIndex } = useCloudRequestStore.getState();

    // return console.log("filterForm", filterForm);

    if (user_id === null) {
        Toastr(t("Invalid request"), "warning")
        return;
    }

    let body = {};

    body = {
        user_id: parseInt(user_id),
    };
    // body = {...body, searchKey: search}

    setLoading(true);


    try {
        const res = await axios.post(kuGlobalRequestIndex, body);
        console.log('getCloudReqIndex: ', res.data);

        if (res.data.success) {
            // setCloudReqIndex(res.data);
        } else {
            Toastr(res.data.message);
        }

        setLoading(false);
    } catch (err) {
        console.log('getCloudReqIndex: ', err);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}

// get request details  
export const getCloudReqDetails = async ({ request_id }) => {

    // const {setCloudReqData}=useCloudRequestStore.getState();

    if (request_id === null) {
        Toastr(t("Invalid request"), "warning")
        return
    }

    setLoading(true);
    const data_set = []

    try {
        const res = await axios.get(request_show, { params: { id: request_id, data_set: data_set } });
        console.log('getCloudReqDetails: ', res.data.data);

        if (res.data.success) {
            setCloudReqData(res.data.data)
        } else {
            Toastr(res.data.message)
        }

        setLoading(false);
    } catch (err) {
        console.log('getCloudReqDetails: ', err);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}

export const submitGlobalReqBid = async (request_id, biddingForm = {}, user_id) => {
    // const { bidding_form } = useCloudRequestStore.getState();

    if (request_id === null) {
        Toastr(t("Invalid Request!"), 'warning');
        return;
    } else if (biddingForm.budget <= 0) {
        Toastr(t("Please enter a valid budget!"), 'warning');
        return;
    } else if (biddingForm.description === '') {
        Toastr(t("Please enter a valid description!"), 'warning');
        return;
    }

    console.log('requestID::::', request_id, ' user_id::', user_id, ' biddingForm:::', biddingForm);
    // return

    try {
        setLoading(true);
        const res = await axios.post(kuSubmitRequestInvitationBidding, { id: request_id, budget: biddingForm.budget, details: biddingForm.description, user_id: user_id })

        console.log("submitGlobalReqBid: ", res.data);

        if (res.data.success) {
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (err) {
        console.log('submitGlobalReqBid: ', err);
        Toastr(t("An error occurred!"));
        setLoading(false);
        return false
    }
}
