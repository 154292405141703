import React from 'react'
import useLicenseStore from '../../../../App/stores/LicenseStore'
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal'

function ConfirmDeactivate() {
    const { showDeactivateLicense, setShowDeactivateLicense } = useLicenseStore();
    return (
        <>
            <CommonModal
                showModal={showDeactivateLicense}
                setShowModal={setShowDeactivateLicense}
                modalTitle="Confirm Deactivate"
                widthClass='w-[550px]'
                mainContent={
                    <div className='flex flex-col items-center'>
                        <div className="pt-5">
                            Are you sure you want to Deactivate this license?
                        </div>

                        <div className="pt-5">
                            <CommonButton btnLabel='Deactivate' colorType='danger' onClick={() => setShowDeactivateLicense(false)} />
                        </div>
                    </div>
                }
            />
        </>
    )
}

export default ConfirmDeactivate