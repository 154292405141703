import create from 'zustand';
import { kuGetCommonDetails, kuGetCommonUpdate, kuGetAllCompanies, kuCommonDeleteUser, kuAddUser } from '../../Utility/url';
import useGeneralStore from './GeneralStore';
import axios from 'axios';
import { Toastr } from '../../Utility/UtilityFunctions';
import { t } from 'i18next';
import { getCustomerDetails } from './CustomerStore';
import { getDriverDetails } from './DriverStore';



const useCompanyStore = create((set, get) => ({
  is_profile_open: true,
  setProfileOpen: (value) => set({ is_profile_open: value }),
  companies: {},
  setCompanies: (value) => {
    console.log('all_companies', value.data);
    set({ companies: value });
    set({ all_companies: value?.data });
    get().setTempAllCompanies(value?.data);
  },

  all_companies: [],
  setAllCompanies: (value) => set({ all_companies: value }),

  temp_all_companies: [],
  setTempAllCompanies: (value) => set({ temp_all_companies: value }),

  is_show_company_delete_modal: false,
  setIsShowCompanyDeleteModal: (value) => set({ is_show_company_delete_modal: value }),

  companyDetails: {},
  setCompanyDetails: (value) => set({ companyDetails: value }),

  companyID: 0,
  setCompanyID: (value) => set({ companyID: value }),

  companyDeleteID: 0,
  setCompanyDeleteID: (value) => set({ companyDeleteID: value }),

  companyTakeAmount: 10,
  setCompanyTakeAmount: (value) => set({ companyTakeAmount: value }),

  companyReqState: "in-bidding",
  setCompanyReqState: (value) => set({ companyReqState: value }),

  showEditCompanyModal: false,
  setShowEditCompanyModal: (value) => set({ showEditCompanyModal: value }),

  showDeactivateCompanyModal: false,
  setShowDeactivateCompanyModal: (value) => set({ showDeactivateCompanyModal: value }),

  showResetPassCompanyModal: false,
  setShowResetPassCompanyModal: (value) => set({ showResetPassCompanyModal: value }),

  showAddCompanyModal: false,
  setShowAddCompanyModal: (value) => set({ showAddCompanyModal: value }),

  showDeleteCompanyModal: false,
  setShowDeleteCompanyModal: (value) => set({ showDeleteCompanyModal: value }),

  companySearchValue: "",
  setCompanySearchValue: (value) => set({ companySearchValue: value }),

  companyPageUrl: "",
  setCompanyPageUrl: (value) => set({ companyPageUrl: value }),

  companyFilterValue: "",
  setCompanyFilterValue: (value) => set({ companyFilterValue: value }),
}));

export default useCompanyStore;


// add new company  
export const addNewCompany = async (addFormData) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    // return 
    console.log("addNewCompany", addFormData);

    setLoading(true);

    const res = await axios.post(kuAddUser, addFormData);
    console.log('addNewCompany: ', res.data);

    if (res.data.success) {
      Toastr(res.data.message, 'success');
      await getCompanies();
      return true;
    }
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('addNewCompany: ', e);
  }
}


// get all companies list
export const getCompanies = async (paginationUrl = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setCompanies, companyTakeAmount } = useCompanyStore.getState();

  try {
    setLoading(true);
    let targetUrl = "";
    if (paginationUrl !== "") {
      paginationUrl = JSON.stringify(paginationUrl);
      targetUrl = paginationUrl.slice(
        paginationUrl.indexOf("?"),
        paginationUrl.length - 1
      );
      targetUrl = kuGetAllCompanies + targetUrl;
    } else {
      targetUrl = kuGetAllCompanies;
    }

    const res = await axios.get(targetUrl, { params: { take: companyTakeAmount } });
    console.log('getCompanies: ', res.data);

    if (res.data.success) {
      setCompanies(res.data.data);
      setLoading(false);
      return true
    }
    else{ Toastr(res.data.message);
      setLoading(false);
      return false
  }
  } catch (e) {
    Toastr(t("An error occurred!"));
    console.log('getCompanies: ', e);
    setLoading(false);
    return false
  }
}



// get company details
export const getCompanyDetails = async (id = "") => {
  const { setLoading } = useGeneralStore.getState();
  const { setCompanyDetails } = useCompanyStore.getState();

  try {
    setLoading(true);

    const res = await axios.get(kuGetCommonDetails, { params: { user_id: id } });
    console.log('getCompanyDetails: ', res.data);

    if (res.data.success) setCompanyDetails(res.data.data);
    else Toastr(res.data.message);

    setLoading(false);
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('getCompanyDetails: ', e);
  }
}


// update ALL USER details
export const updateCommonDetails = async (updateForm, type = 0) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true);

    const body = {
      user_id: updateForm?.id,
      email: updateForm?.email,
      name: updateForm?.name,
      phone: updateForm?.phone,
      address: updateForm?.address,
      image: updateForm?.image,
      lat: updateForm?.lat,
      lng: updateForm?.lng,
      website: updateForm?.website,
    }
    console.log('UPDATE-BODY: ', body);

    const res = await axios.post(kuGetCommonUpdate, body);
    console.log('updateCommonUserDetails: ', res.data);

    if (res.data.success) {
      Toastr(res.data.message,"success");
      if (type === 1) {
        await getCustomerDetails(updateForm?.id);
      }
      if (type === 2) {
        await getCompanyDetails(updateForm?.id);
      }
      if (type === 3) {
        await getDriverDetails(updateForm?.id);
      }
      setLoading(false);
      return true;
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (e) {
    Toastr("An error occurred!");
    setLoading(false);
    console.log('updateCommonUserDetails: ', e);
    return false;
  }
}




// delete ALL USER account
export const commonUserDelete = async (id = 0) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true);

    const body = { user_id: id };
    console.log('UPDATE-BODY: ', body);

    const res = await axios.post(kuCommonDeleteUser, body);
    console.log('commonUserDelete: ', res.data);

    if (res.data.success) {
      Toastr(res?.data?.message,"success");
      setLoading(false);
      return true;
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (e) {
    Toastr("An error occurred!");
    setLoading(false);
    console.log('commonUserDelete: ', e);
  }
}



// add ALL USER account
export const commonUserAdd = async (form) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    return console.log("ADD FORM", form);

    setLoading(true);

    console.log('UPDATE-BODY: ', form);

    const res = await axios.post(kuCommonDeleteUser, form);
    console.log('commonUserAdd: ', res.data);

    if (res.data.success) {
      setLoading(false);
      return true;
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (e) {
    Toastr(t("An error occurred!"));
    setLoading(false);
    console.log('commonUserAdd: ', e);
  }
}