import React from 'react'
import useLicenseStore, { updateLicenseStatus } from '../../../../App/stores/LicenseStore'
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonModal from '../../../../Components/Modal/CommonModal'

function ConfirmReject() {
    const { showRejectModal, setShowRejectModal, licenseDetails } = useLicenseStore();
    let submitForm = {
        id: licenseDetails?.id,
        status: "rejected",
        license_start_date: licenseDetails?.license_start,
        license_end_date: licenseDetails?.license_end
    }
    return (
        <>
            <CommonModal
                showModal={showRejectModal}
                setShowModal={setShowRejectModal}
                modalTitle="information"
                mainContent={
                    <>
                        <div className='py-5 text-center'>
                            Are you sure you want to Reject this application?

                        </div>
                        <div className="flex justify-center">
                            <CommonButton btnLabel='Reject' colorType='danger' onClick={() => {
                                let rejectSuccess = updateLicenseStatus(submitForm)
                                if (rejectSuccess) setShowRejectModal(false);
                            }} />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default ConfirmReject