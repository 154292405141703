import React from 'react'

export default function DeliveryInfoItem({ title, value, alignUnified=false }) {
    return (
        <div className="flex justify-between items-start mb-5 space-x-2">
            <div className="text-fs16 font-fw400 text-cIcon">{title}</div>
            <div className={`text-fs16 font-fw600 text-cMainBlack text-right max-w-[500px] overflow-clip ${alignUnified ? "text-justify" : ""}`}>{value ? value : <span></span>}</div>
        </div>
    )
}


