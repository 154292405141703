import { useEffect } from "react";
import useBannerStore, { getAllBanners } from "../../App/stores/BannerStore";
import CommonButtonOutlined from "../../Components/Button/CommonButtonOutlined";
import TitleBar from "../../Components/Common/TitleBar";
import { PageTitle } from "../../Utility/UtilityFunctions";
import AddBanner from "./AddBanner";
import BannerGrid from "./BannerGrid";
import UpdateBanner from "./UpdateBanner";

function Banner({ data }) {

  const { showUpdateBanner, setShowUpdateBanner, allBannerList } = useBannerStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle("Limadi | Banner");
    getAllBanners();
  }, [])

  return (
    <div>
      <TitleBar
        label={"Banner"}
        count={allBannerList?.length}
        onClick={() => getAllBanners()}
        rightCornerComponent={<CommonButtonOutlined btnLabel="Add New Banner" onClick={() => setShowUpdateBanner(false)} />}
      />

      {/* <ComponentTitleBar
        type={'submit'}
        title={'Banner'}
        label={'Add New Banner'}
        totalItems={allBannerList?.length}

        btnOnClick={() => {
          setShowUpdateBanner(false);
        }}
        onClick={() => {
          getAllBanners();
        }}
      /> */}

      <div className="justify-between items-start space-x-0 md:flex md:space-x-10">
        {/* All Banners  */}
        <BannerGrid />
        {/* Add New Banner */}
        {showUpdateBanner ? <UpdateBanner /> : <AddBanner />}
      </div>
    </div>
  );
}

export default Banner;
