/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCloudRequestStore, { submitGlobalReqBid } from '../../../../../../App/stores/CloudRequestStore';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonInput from '../../../../../../Components/Input/CommonInput';
import { CloudRequestContext } from '../../../../../../Context/CloudRequestContext';
import { Toastr } from '../../../../../../Utility/UtilityFunctions';

export default function Bidding({ setShowCloudRequestModal, request_id }) {

  const { cloudReqCompanyID } = useCloudRequestStore();
  const [bidding_form, setBiddingForm] = useState({ budget: "", description: '' });
  const { setCurrentSelection, getFilterSearchListByFreeText, setChip } = useContext(CloudRequestContext);
  const navigate = useNavigate()

  const onSubmit = async (e) => {
    e.preventDefault()

    const isSuccess = await submitGlobalReqBid(request_id, bidding_form, cloudReqCompanyID);

    // return
    // console.log('bidding_form', bidding_form);

    if (isSuccess) {
      await getFilterSearchListByFreeText("", cloudReqCompanyID);

      setCurrentSelection(null);
      setChip({});
      setBiddingForm({ budget: "", description: '' });

      Toastr('Bid submitted successfully', 'success')
      setShowCloudRequestModal(false);
      // navigate('/request/in-bidding')
    }
  }


  return (
    <>
      <form onSubmit={onSubmit}>

        <div className="py-5">
          <CommonInput
            label={t("Budget")}
            placeholder={t("Enter your budget")}
            name={'budget'}
            value={bidding_form?.budget}
            type={'number'}
            max_input={12}
            min_input={1}
            required={true}
            min_number={1}
            onChange={(e) => { setBiddingForm({ ...bidding_form, budget: parseInt(e.target.value) }) }}
          />
        </div>

        <CommonInput
          textarea={true}
          max_char_limit={500}
          is_show_char_limit={true}
          label={t("Bid Description")}
          name={'description'}
          type={'text'}
          value={bidding_form?.description}
          placeholder={t("Enter your bid description")}
          required={true}
          onChange={(e) => { setBiddingForm({ ...bidding_form, description: e.target.value }) }}
        />


        <div className="flex flex-row justify-between items-center pt-5">

          <CommonButton btnLabel={t("Cancel")} colorType="danger" onClick={() => setShowCloudRequestModal(false)} />

          <CommonButton type={'submit'} btnLabel={t("Submit")} />

        </div>

      </form>
    </>
  )
}
