import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useGeneralStore from '../../App/stores/GeneralStore';
import { CloseIcon, UploadIcon } from '../../App/Utility/ImageImports';
import { BaseUrlSrc } from '../../Utility/url';
import { getBase64 } from '../../Utility/UtilityFunctions';
import CommonButtonOutlined from '../Button/CommonButtonOutlined';

export function DropzoneCustom({ onUpload, value = "", clearImage = false, showClearBtn = false }) {
    const [files, setFiles] = useState([]);
    const [imageValue, setImageValue] = useState("");

    const { trackingUpload, setTrackingUpload } = useGeneralStore();


    const onDrop = useCallback(acceptedFiles => {
        setTrackingUpload(true);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        onUpload(acceptedFiles[0]);
        // console.log("HIIII");
        getBase64(acceptedFiles[0], (res) => {
            setImageValue(res);
        })
        // console.log("UPLOAD !!!!!", acceptedFiles[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const accept = {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/gif': ['.gif'],
        'image/svg': ['.svg'],
    }

    // todo: detect OS and remove accept from validation for linux only
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        accept: accept,
    });

    useEffect(() => {
        // console.log("api value FOUND !!!");
        setImageValue("");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (files[0]) {
            // console.log("FILES[0] FOUND !!!", files[0].preview);
            setImageValue("");
            setImageValue(files[0].preview);
        }
    }, [files]);

    useEffect(() => {
        console.log("clearImage", clearImage);
        if (clearImage) {
            setFiles({});
            setImageValue("");
            setTrackingUpload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearImage]);

    return (
        <div className="bg-[#F8F8F8] border-cNmSelect border-dashed border-[1px] rounded-br10 p-s16">
            <aside className="flex justify-center cursor-pointer" >
                <div className="flex relative justify-center w-full">
                    {
                        imageValue || value ?
                            showClearBtn ? <img
                                onClick={(e) => {
                                    e.preventDefault();
                                    setFiles({});
                                    setImageValue("");
                                    setTrackingUpload(false);
                                }}
                                className='absolute top-0 right-0 p-s5'
                                src={CloseIcon}
                                alt=""
                            /> : ""
                            : ""
                    }
                    {trackingUpload ?
                        <img onClick={open} src={imageValue} className="rounded-lg w-full h-s150 xl:h-[180px] 2xl:h-s200 object-cover" alt="img selected" />
                        : value ? <img onClick={open} src={BaseUrlSrc + value} className="rounded-lg w-full h-s150 xl:h-[180px] 2xl:h-s200 object-cover" alt="img not loaded" />
                            : <img onClick={open} src={UploadIcon} className="object-cover rounded-lg drop-shadow-md w-s150 h-s150" alt="upload icon" />
                    }
                </div>
            </aside>

            <div {...getRootProps({ className: 'dropzone' })} className="flex flex-col justify-center items-center pt-s16">
                <input {...getInputProps()} />
                <div className='pb-2'>Only png,jpg,psd Image upload</div>
                <div className='pb-2'>Upload 820x320 Size Image</div>
                <div className='pb-s16'>Drag and Drop Image or</div>
                <CommonButtonOutlined width={"w-s100"} onClick={open} btnLabel='Browse' />

            </div>

        </div>
    );
}