/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import CircularProgressBar from "../../Components/CircularProgressBar";
import { NotificationContext } from "../../Context/NotificationContext";
import iRefresh from "../../Images/icons/refresh.svg";
import { iInfo } from "../../Utility/Sources";
import SearchInput from "../Input/SearchInput";
import NA from "../NA";
import ListSkeleton01 from "../Skeletons/ListSkeleton01";
import NotificationDetails from "./NotificationDetails";
import NotificationItem from "./NotificationItem";
import { Tooltip } from "antd";
import { PageTitle } from "../../Utility/UtilityFunctions";

function Notification() {
  const {
    isLoading,
    selectedNotification,
    notification,
    selectNotification,
    getNotification,
    selectedIndex,
    searchKey,
    searchNotifications,
  } = useContext(NotificationContext);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const refreshFoo = async () => {
    setIsRefreshing(true);
    await getNotification();
    setIsRefreshing(false);
  };


  useEffect(() => {
    getNotification()
    PageTitle("Limadi | Notification");
  }, []);

  return (
    <>
      {/* Header Part */}
      <div className="flex items-center bg-white text-2xl font-bold mt-[5px] my-5 px-5 py-3 rounded-lg shadow-sm">
        <span className="mr-5">{t("Notification")}</span>

        {/* refresh icon button */}
        {!isRefreshing ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}

        <Tooltip title={"The notification you have already read, will be removed after 48 hours"}>
          <img className="absolute right-14 h-6 text-right" src={iInfo} alt="info" srcSet="" />
        </Tooltip>

      </div>


      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <div className="col-span-12 lg:col-span-4">
          {/* settings options[push notification, email, change password, lang, terms and condition, faq] */}
          <div className="bg-white  shadow rounded-xl flex-col">

            {/* search field */}
            <div className="pt-[10px] px-5 mx-auto">
              <SearchInput
                placeholder={"search"}
                type="search"
                onChange={searchNotifications}
                name="searchKey"
                value={searchKey}
              />
            </div>

            {/* notification list */}
            <ul className="overflow-y-auto max-h-screen scroll-smooth">
              {isLoading ? (
                <ListSkeleton01 />
              ) : notification.length === 0 ? (
                <NA />
              ) : (
                notification.map((item, index) => {
                  return (
                    <NotificationItem
                      isSelected={selectedIndex === index}
                      onSelect={() => selectNotification(index, item.is_seen)}
                      data={item}
                      key={item.id}
                    />
                  );
                })
              )}

              <div className="mb-3"></div>
            </ul>
          </div>
        </div>

        {/* Notification Details */}
        <div className="col-span-12 lg:col-span-8">
          {/* notification details */}
          {isLoading ?
            <ListSkeleton01 />
            :
            <NotificationDetails
              notification={notification}
              selectedNotification={selectedNotification}
            />
          }
        </div>

      </div>
    </>
  );
}

export default Notification;
