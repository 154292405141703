/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { t } from 'i18next';
import { createContext, useEffect, useState } from "react";
import useGeneralStore from '../App/stores/GeneralStore';
import useShiftPlannerStore from "../App/stores/ShiftPlannerStore";
import {
  kuAddShiftPlanner,
  kuDeleteShiftPlanner,
  kuDriverShiftPlannerDetails,
  kuDriverShiftPlannerList,
  kuGetAllCarsList,
  kuShiftCarsDriversList,
  kuShiftPlannerDetails,
  kuUpdateShiftPlanner
} from "../Utility/url";
import { Toastr, ToastrLoading, checkForFutureDate, checkForFutureDateTime, checkForFutureTime, checkForPastDate, checkForPastDateTime, checkForPastTime } from "../Utility/UtilityFunctions";

export const ShiftPlannerContext = createContext();

const ShiftPlannerContextProvider = (props) => {
  const [createShift, setCreateShift] = useState({
    user_id: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    license_plate: "",
    is_under_maintainance: "",
    selected_driver: "",
    comment: "",
  });

  const { setIsLoadingSearch } = useGeneralStore();

  const [clearOnSubmitShift, setClearOnSubmitShift] = useState(false);

  const [addRequest, setAddRequest] = useState({
    title: "",
    req_type: "",
    transport_type: "",
    city: "",
  });
  const [updateShift, setUpdateShift] = useState({
    user_id: 0,
    id: "",
    driver_user_id: "",
    car_id: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    license_plate: "",
    is_maintainance: "",
    comment: "",
  });

  const [companyUID, setCompanyUID] = useState(0);

  const [shift, setShift] = useState([]);
  const [shiftFilterCar,setShiftFilterCar] = useState([]);
  const [shiftTemp, setShiftTemp] = useState([]);

  const [shiftHistory, setShiftHistory] = useState([]);
  const [shiftHistoryTemp, setShiftHistoryTemp] = useState([]);

  // create new shift states
  const [driverUserId, setDriverUserId] = useState();
  const [driverShiftSearch, setDriverShiftSearch] = useState("");
  const [carId, setCarId] = useState("");
  const [isUnderMaintanance, setIsUnderMaintanance] = useState(false);

  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");

  const [startTimeUpdateValue, setStartTimeUpdateValue] = useState("");
  const [endTimeUpdateValue, setEndTimeUpdateValue] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [commentValue, setCommentValue] = useState("");

  const [shiftCars, setShiftCars] = useState([]);
  const [shiftDrivers, setShiftDrivers] = useState([]);

  const [shortMonth, setShortMonth] = useState("");
  const [shortDateNo, setShortDateNo] = useState(0);

  const [driverType, setDriverType] = useState("");
  const [licensePlateValue, setLicensePlateValue] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");

  const [isShiftHistory, setIsShiftHistory] = useState(false);
  const [isCreatingShift, setIsCreatingShift] = useState(false);
  const [isEditingShift, setIsEditingShift] = useState(false);
  const [isEditingShiftID, setIsEditingShiftID] = useState(0);

  const [isShiftDetails, setIsShiftDetails] = useState(false);
  const [isShiftDetailsData, setIsShiftDetailsData] = useState(null);
  const [isShiftDetailsID, setIsShiftDetailsID] = useState(0);

  const [isManageFilter, setIsManageFilter] = useState(false);
  const [isEditingNow, setIsEditingNow] = useState(false);

  const [requestType, setRequestType] = useState("");
  const [multiTransportType, setMultiTransportType] = useState("");
  const [selectedCity, setSelectedCity] = useState();
  const [currentSelection, setCurrentSelection] = useState(null);

  const [chipShow, setChipShow] = useState(true);

  const [isExpressType, setIsExpressType] = useState(false);
  const [isAdvancedType, setIsAdvancedType] = useState(false);

  const [detailsState, setDetailsState] = useState("add");
  const [request, setRequest] = useState([]);
  const [tempRequest, setTempRequest] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [canUpdateSubmit, setCanUpdateSubmit] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // hb modal state
  const [showFilterModal, setShowFilterModal] = useState(false);

  // hr global clear/reload details states
  const [clearDetailsPanel, setClearDetailsPanel] = useState(true);
  const [clearHistoryDetailsPanel, setClearHistoryDetailsPanel] =
    useState(true);
  const [clearEditPanel, setClearEditPanel] = useState(true);
  const [clearRequestDetailsPanel, setClearRequestDetailsPanel] =
    useState(false);

  // hg share shift
  const [shareShiftEmail, SetShareShiftEmail] = useState("");

  // hb filter history list
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  // hy tracker states
  const [maintenanceState, setMaintenanceState] = useState(0);
  const [filterState, setFilterState] = useState(false);

  // hb only for filter popup
  const [tempCarName, setTempCarName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterStartTime, setFilterStartTime] = useState("");
  const [filterEndTime, setFilterEndTime] = useState("");
  const [currentCar, setCurrentCar] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [is_maintenance, setIs_maintenance] = useState(0);

  // ! Filter list api
  const [filterParams, setFilterParams] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    plate_number: "",
    driver_name: "",
  });

  const [chipData, setChipData] = useState({})

  const { setLoading } = useGeneralStore()

  /*
  |--------------------------------------------------------------------------
  | Helper Functions
  |--------------------------------------------------------------------------
  */

  const setChangeDataValue = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddRequest({ ...createShift, [name]: value });
  };

  const filterSelected = (data) => {
    setSelectedIndex(data);
    if (isManageFilter) {
      setIsEditingNow(true);
      // setUpdateShift(DummyData[data]);
    }
  };

  var ShiftDriver = [];

  const searchShift = (search) => {
    Object.keys(chipData).length > 0 && setChipData({});
    console.log("shiftTemp", shiftTemp)
    setSearchKey(search);

    const result = shiftTemp?.filter((item) => {
      if (item.driver_name) {
        let name = "";
        name = item?.driver_name ?? "";
        if (
          name?.toLowerCase().includes(search.toLowerCase()) ||
          item?.car_name.toLowerCase().includes(search.toLowerCase()) ||
          item?.car_number.toLowerCase().includes(search.toLowerCase())
        ) {
          return item;
        }
      }else {
        if (
           item?.car_name?.toLowerCase().includes(search.toLowerCase()) ||
           item?.number_plate.toLowerCase().includes(search.toLowerCase()) || 
           item?.status?.car_name?.toLowerCase().includes(search.toLowerCase()) 
        ) {
          return item;
        }
      }
    });
    console.log("result: ", result);
    setShift(result);
  };



  // blue:  API Related Functions  

  const getShiftPlannerList = async (u_id, isDriver = false,company_user_id) => {
    if (isDriver) {
      if (driverShiftSearch) setIsLoadingSearch(true); else setLoading(true)
    } else {
      setLoading(true)
    }
    console.log("driverShiftSearch :::",driverShiftSearch)
    try {
      setShift({});
      setShiftTemp({});
      let res = {};
      if (isDriver) {
        res = await axios.get(kuDriverShiftPlannerList, { params: { driver_user_id: u_id,company_user_id:company_user_id  } });
      } else {
        res = await axios.get("/shift/web/index", { params: { user_id: u_id } });
      }
      console.log("getShiftPlannerList:", res.data);
      if (res.data.success) {
        if (isDriver) {
          setShift(res?.data?.data?.result);
          setShiftFilterCar(res?.data?.data?.car_list);
          setShiftTemp(res?.data?.data?.result);
        } else {
          // const valuesArray1 = Object.values(res.data.data);
          // const valuesArray = valuesArray1.reduce((acc, currentArray) => acc.concat(currentArray), []);

          // console.log("get shift list valuesArray: ", valuesArray);
          setShift(res.data.data.data);
          setShiftTemp(res.data.data.data);
        }
      } else {
        Toastr(res.data.message,);
      }
      if (isDriver) {
        if (driverShiftSearch) setIsLoadingSearch(false); else setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      if (isDriver) {
        if (driverShiftSearch) setIsLoadingSearch(false); else setLoading(false)
      } else {
        setLoading(false)
      }
    }
  };

  const generateFilterData = (filter) => {
    let x = {};

    if (filter?.user_id) x.user_id = filter?.user_id;
    if (filter?.driver_user_id) x.driver_user_id = filter?.driver_user_id;
    if (filter?.start_date || filter?.start_date !== '') x.start_date = filter?.start_date;
    if (filter?.end_date) x.end_date = filter?.end_date;
    if (filter?.start_time) x.start_time = filter?.start_time;
    if (filter?.end_time) x.end_time = filter?.end_time;
    if (filter?.plate_number) x.plate_number = filter?.plate_number;
    if (filter?.driver_id) x.driver_id = filter?.driver_id;
    if (filter?.status) x.status = filter?.status;
    if (filter?.is_maintenance === 1) x.is_maintenance = filter?.is_maintenance;
    if (filter?.take) x.take = filter?.take; else x.take = 1000
    x.is_asc = 1;

    if (filter?.status?.length) {
      if (filter?.status[0] === "upcoming") {
        x.status = ["init"];
        x.start_date = checkForFutureDate(filter?.start_date);
        if (filter?.start_time && checkForFutureDateTime(filter?.start_date, filter?.start_time)) {
          x.start_time = filter?.start_time;
        }
        else x.start_time = checkForFutureTime(filter?.start_time)
      }
      if(filter?.status[0] === "init") {
        x.end_date = checkForPastDate(filter?.end_date)
        if (filter?.end_time && checkForPastDateTime(filter?.end_date, filter?.end_time)) {
          x.end_time = filter?.end_time;
        }
        else x.end_time = checkForPastTime(filter?.end_time)
      }
    }
    return x;
  }

  const filterShiftPlannerList = async (paramsBody, isDriver = false) => {
    try {
      setLoading(true)
      // console.log("paramsBody:::", paramsBody);
      const body = generateFilterData(paramsBody);
      console.log("body:::", body);
      let res = {};
      if (isDriver) {
        res = await axios.get(kuDriverShiftPlannerList, { params: paramsBody });
      } else {
        res = await axios.get("/shift/web/index", { params: body });
      }

      console.log("filterShiftPlannerList: ", res);
      if (res.data.success) {
        if (isDriver) {
          setShift(res.data.data.result);
          setShiftTemp(res.data.data.result);
        } else {
          setShift(res.data.data?.data);
          setShiftTemp(res.data.data?.data);
        }
      } else {
        console.log(res.data.message);
        Toastr(res.data.message);
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };

  const getShiftHistoryList = async (u_id, isDriver = false) => {
    setLoading(true)
    try {
      setShift({});
      setShiftTemp({});
      let res = {};
      console.log("driver_user_id => ", u_id);
      if (isDriver) {
        res = await axios.get(kuDriverShiftPlannerList, { params: { type: "history", driver_user_id: u_id } });
      } else {
        res = await axios.get("/shift/web/index", { params: { type: "history", user_id: u_id } });
      }

      console.log("getShiftHistoryList: ", res.data);

      if (res.data.success) {
        if (isDriver) {
          setShift(res.data.data.result);
          setShiftTemp(res.data.data.result);
        } else {
          const valuesArray1 = Object.values(res.data.data);
          const valuesArray = valuesArray1.reduce((acc, currentArray) => acc.concat(currentArray), []);

          console.log("get shift HISTORY valuesArray: ", valuesArray);
          setShift(valuesArray);
          setShiftTemp(valuesArray);
        }
        setIsShiftHistory(true);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };

  const onResetFilterHandler = () => {
    setDriverUserId("");
    setLicensePlateValue("")
    setClearOnSubmitShift(true);
    setIs_maintenance(0);
    setFilterStatus("")

    setFilterStartDate("");
    setFilterEndDate("");

    setFilterStartTime("");
    setFilterEndTime("");

    setLicensePlateValue("");
    setSelectedDriver("");
    setTempCarName("");
    setCarId("");

    setIsDeleted(false);
    setIsCompleted(false);
  };

  const { companyTrackOnlineDrive, setCompanyTrackOnlineDrive } = useShiftPlannerStore();

  const getShiftPlannerDetails = async (shift_id, u_id = companyTrackOnlineDrive, isDriver = false, company_user_id) => {

    try {
      if (!shift_id) {
        Toastr(t("Invalid Shift!"))
        return;
      }

      setLoading(true);
      let res = {};
      if (isDriver) {
        console.log("driver shift details loading...", shift_id, " ", company_user_id);
        res = await axios.get(kuDriverShiftPlannerDetails, { params: { id: shift_id, company_user_id: company_user_id } })
      } else {
        res = await axios.get(kuShiftPlannerDetails, { params: { id: shift_id, user_id: u_id } })
      }
      // const res = await axios.get(kuShiftPlannerDetails + "?id=" + shift_id)
      console.log("getShiftPlannerDetails:", res.data)

      if (res.data.success) {

        setIsShiftDetailsData(res.data.data);
        // console.log("UpdateShift::::: ", updateShift);
        setCurrentCar(res.data.data.car ?? null)
        setLoading(false);
      } else {
        Toastr(res.data.message);
        setLoading(false);
      }

      setLoading(false)
    } catch (err) {
      console.log(err)
      ToastrLoading(t("An error occurred!"))
      setLoading(false)
    }
  };

  const getShiftCarsDriversList = async (isInMaintenance, is_edit_shift = false, u_id) => {
    try {
      // console.log("getShiftCarsDriversList: ", startDate, endDate, startTimeValue, endTimeValue)
      setLoading(true)
      let res = {};

      if (filterState) {
        res = await axios.get(kuGetAllCarsList, { params: { user_id: u_id } });
      } else if (startDate && endDate && startTimeValue && endTimeValue && isInMaintenance) {
        res = await axios.get(kuShiftCarsDriversList, {
          params: {
            user_id: u_id,
            start_date: startDate,
            end_date: endDate,
            start_time: startTimeValue,
            end_time: endTimeValue,
          },
        });
      } else if (startDate && startTimeValue && endTimeValue && !isInMaintenance) {

        // validate endDate
        const result = validateEndTime(startDate, startTimeValue, endTimeValue);
        let is_end_time_smaller = result.is_end_time_smaller;
        let new_end_date = result.new_end_date;

        res = await axios.get(kuShiftCarsDriversList, {
          params: {
            user_id: u_id,
            start_date: startDate,
            end_date: is_end_time_smaller ? new_end_date : startDate,
            start_time: startTimeValue,
            end_time: endTimeValue,
          },
        });
      } else {
        isInMaintenance ? Toastr(t("Start time and date, End time and date required")) : Toastr(t("Start time and date, End time required"));
        setLoading(false)
        return
      }


      console.log("getShiftCarsDriversList::::: ", res.data);

      if (res.data.success) {
        if (is_edit_shift) {
          let cars = res.data.data.cars
          let carExist = false;
          cars.forEach(car => { if (car.id === currentCar.id) carExist = true })
          if (!carExist && currentCar) {
            cars.push(currentCar);
            setCarId(currentCar.id);
          }
          setShiftCars(cars);
        } else {
          setShiftCars(res.data.data.cars);
        }
        setShiftDrivers(res.data.data.drivers);
        setIsLoading(false);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };

  // add / save new shift 
  const saveNewlyCreatedShift = async () => {
    try {
      setLoading(true)

      // validate end date   
      const result = validateEndTime(startDate, startTimeValue, endTimeValue);
      let is_end_time_smaller = result.is_end_time_smaller;
      let new_end_date = result.new_end_date;

      const body = createShift.is_under_maintainance
        ? {
          user_id: createShift.user_id,
          driver_user_id: driverUserId,
          car_id: carId,
          start_date: createShift.start_date,
          end_date: createShift.end_date,
          start_time: createShift.start_time,
          end_time: createShift.end_time,
          license_plate: createShift.license_plate,
          is_maintenance: createShift.is_under_maintainance,
          selected_driver: createShift.selected_driver,
          // comment: createShift.comment,
        }
        : {
          user_id: createShift.user_id,
          driver_user_id: driverUserId,
          car_id: carId,
          start_date: createShift.start_date,
          end_date: is_end_time_smaller ? new_end_date : createShift.end_date,
          start_time: createShift.start_time,
          end_time: createShift.end_time,
          license_plate: createShift.license_plate,
          is_maintenance: createShift.is_under_maintainance,
          selected_driver: createShift.selected_driver,
          comment: createShift.comment,
        };

      console.log("ADD BODY::: ", body);

      const res = await axios.post(kuAddShiftPlanner, body);

      console.log("saveNewlyCreatedShift: ", res.data);

      if (res.data.success) {
        setClearOnSubmitShift(true);
        setCreateShift({
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
          license_plate: "",
          is_under_maintainance: "",
          selected_driver: "",
          comment: "",
        });
        setIsUnderMaintanance(false)
        setStartDate("");
        setEndDate("");
        setStartTimeValue("");
        setEndTimeUpdateValue("");
        getShiftPlannerList(createShift.user_id);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };


  const updateShiftData = async (u_id) => {
    try {

      // validate end time and end date for update shift   
      const result = validateEndTime(startDate, startTimeValue, endTimeValue);
      let is_end_time_smaller = result.is_end_time_smaller;
      let new_end_date = result.new_end_date;

      const body = updateShift.is_maintenance
        ? {
          user_id: u_id,
          id: updateShift.id,
          driver_user_id: updateShift.driver_user_id,
          car_id: updateShift.car_id,
          start_date: updateShift.start_date,
          end_date: updateShift.end_date,
          start_time: updateShift.start_time,
          end_time: updateShift.end_time,
          is_maintenance: updateShift.is_maintenance,
        }
        : {
          user_id: u_id,
          id: updateShift.id,
          driver_user_id: updateShift.driver_user_id,
          car_id: updateShift.car_id,
          start_date: updateShift.start_date,
          end_date: is_end_time_smaller ? new_end_date : updateShift.start_date,
          start_time: updateShift.start_time,
          end_time: updateShift.end_time,
          comment: updateShift.comment ?? "",
          is_maintenance: updateShift.is_maintenance,
        };

      console.log("updateShiftData body: ", body);

      // return;

      setLoading(true);

      const res = await axios.post(kuUpdateShiftPlanner, body);

      console.log("updateShiftData: ", res.data);

      if (res.data.success) {
        setUpdateShift({
          id: "",
          driver_user_id: "",
          car_id: "",
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
          license_plate: "",
          is_maintenance: "",
          comment: "",
        });

        getShiftPlannerList(u_id);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };

  const deleteShiftData = async (shift_id, u_id) => {
    if (!shift_id) {
      Toastr(t("Invalid shift id"));
      return;
    }

    setLoading(true)
    try {
      const res = await axios.post(kuDeleteShiftPlanner, { id: shift_id, user_id: u_id });
      console.log('deleteShiftData: ', res.data);

      if (res.data.success) {
        setIsShiftDetailsID("");
        setIsShiftDetailsID(null);
        setIsShiftDetailsData({});
        getShiftPlannerList(u_id);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  };

  // e  check if the end time is smaller than the start time, then update the end date to next day
  const validateEndTime = (start_date, start_time, end_time) => {
    let temp_start_date = new Date(start_date + " " + start_time);
    let temp_end_date = new Date(start_date + " " + end_time);
    let is_end_time_smaller = temp_end_date <= temp_start_date ? true : false;
    let new_end_date = startDate;

    if (is_end_time_smaller) {
      let date = new Date(temp_end_date);
      date.setDate(date.getDate() + 1)
      new_end_date = date;
      new_end_date = new_end_date.toISOString().split('T')[0]; // format new_end_date to yyyy-mm-dd
    }

    return { is_end_time_smaller: is_end_time_smaller, new_end_date: new_end_date };
  }


  /*
   |--------------------------------------------------------------------------
   | Refresh global functions
   |--------------------------------------------------------------------------
   */

  const megaRefreshShiftLists = async () => {
    if (isCreatingShift) {
      setStartDate("");
      setEndDate("");
      setStartTimeValue("");
      setEndTimeValue("");
      setCommentValue("");
      setClearOnSubmitShift(true);
    }
    setIsShiftDetailsID(null);
    setIsShiftDetailsData({});
    // getShiftHistoryList();
    getShiftPlannerList();
  };

  /*
  |--------------------------------------------------------------------------
  | UseEffects..
  |--------------------------------------------------------------------------
  */

  let targetDataIndex = shift?.length
    ? shift.findIndex((item) => item?.id === isShiftDetailsID)
    : "";

  useEffect(() => {
    if (shift[targetDataIndex]) {
      setUpdateShift({
        id: shift[targetDataIndex]?.id,
        driver_user_id: shift[targetDataIndex]?.driver_user?.id,
        car_id: shift[targetDataIndex]?.car_id,
        start_date: shift[targetDataIndex]?.start_date,
        end_date: shift[targetDataIndex]?.end_date,

        start_time: shift[targetDataIndex]?.start_time?.substring(
          0,
          shift[targetDataIndex]?.start_time.length - 3
        ),
        end_time: shift[targetDataIndex]?.end_time?.substring(
          0,
          shift[targetDataIndex]?.end_time.length - 3
        ),

        license_plate: shift[targetDataIndex]?.car?.car_license_plate_number,
        car_name: shift[targetDataIndex]?.car?.name,
        selected_driver: shift[targetDataIndex]?.driver_user?.id,
        driver_name: shift[targetDataIndex]?.driver_user?.name,
        comment: shift[targetDataIndex]?.comment,
        is_under_maintainance: shift[targetDataIndex]?.is_maintenance,
      });
      setMaintenanceState(shift[targetDataIndex]?.is_maintenance);

      setStartDate(shift[targetDataIndex].start_date);
      setEndDate(shift[targetDataIndex].end_date);

      setStartTimeValue(
        shift[targetDataIndex]?.start_time?.substring(
          0,
          shift[targetDataIndex]?.start_time.length - 3
        )
      );
      setEndTimeValue(
        shift[targetDataIndex]?.end_time?.substring(
          0,
          shift[targetDataIndex]?.end_time.length - 3
        )
      );
    }
  }, [targetDataIndex]);

  useEffect(() => {
    if (createShift.start_date) {
      saveNewlyCreatedShift();
    }
  }, [createShift]);





  return (
    <ShiftPlannerContext.Provider
      value={{
        // states
        shiftFilterCar,
        addRequest,
        updateShift,
        detailsState,
        request,
        tempRequest,
        selectedIndex,
        selectedRequest,
        isLoading,
        canSubmit,
        canUpdateSubmit,
        searchKey,
        requestType,
        multiTransportType,
        selectedCity,
        isManageFilter,
        isEditingNow,
        selectedRow,
        isExpressType,
        isAdvancedType,
        currentSelection,
        chipShow,
        isCreatingShift,
        isShiftDetails,
        isShiftDetailsID,
        isEditingShift,
        isEditingShiftID,
        isShiftHistory,
        driverShiftSearch, setDriverShiftSearch,
        driverType,
        createShift,
        licensePlateValue,
        selectedDriver,
        shift,
        shiftTemp,
        isShiftDetailsData,
        shortMonth,
        shortDateNo,
        isUnderMaintanance,
        startTimeValue,
        endTimeValue,
        commentValue,
        startDate,
        endDate,
        shiftCars,
        shiftDrivers,
        driverUserId,
        carId,
        clearOnSubmitShift,
        startTimeUpdateValue,
        endTimeUpdateValue,
        shiftHistory,
        shiftHistoryTemp,
        filterParams,
        shareShiftEmail,
        isCompleted,
        isDeleted,
        clearDetailsPanel,
        clearEditPanel,
        clearHistoryDetailsPanel,
        maintenanceState,
        filterState,
        showFilterModal,
        clearRequestDetailsPanel,
        tempCarName,
        filterStartDate,
        setFilterStartDate,
        filterEndDate,
        setFilterEndDate,
        filterStartTime,
        setFilterStartTime,
        filterEndTime,
        setFilterEndTime,
        chipData,
        companyUID,

        // actions
        setCompanyUID,
        setAddRequest,
        setUpdateShift,
        setDetailsState,
        setRequest,
        setTempRequest,
        setSelectedIndex,
        setSelectedRequest,
        setIsLoading,
        setCanSubmit,
        setCanUpdateSubmit,
        setSearchKey,
        setRequestType,
        setMultiTransportType,
        setSelectedCity,
        setChangeDataValue,
        setIsShiftDetails,
        setIsShiftDetailsID,
        setIsEditingShift,
        setIsEditingShiftID,
        setIsShiftHistory,
        setDriverType,
        setCreateShift,
        setLicensePlateValue,
        setSelectedDriver,
        setShift,
        setShiftTemp,
        setIsShiftDetailsData,
        setShortMonth,
        setShortDateNo,
        setIsUnderMaintanance,
        setStartTimeValue,
        setEndTimeValue,
        setCommentValue,
        setStartDate,
        setEndDate,
        setShiftCars,
        setShiftDrivers,
        setDriverUserId,
        setCarId,
        setClearOnSubmitShift,
        setStartTimeUpdateValue,
        setEndTimeUpdateValue,
        setShiftHistory,
        setShiftHistoryTemp,
        SetShareShiftEmail,
        setClearEditPanel,
        setIsManageFilter,
        filterSelected,
        setIsEditingNow,
        setSelectedRow,
        setClearHistoryDetailsPanel,
        setIsExpressType,
        setIsAdvancedType,
        setCurrentSelection,
        setChipShow,
        setClearDetailsPanel,
        setIsCreatingShift,
        setFilterParams,
        setIsCompleted,
        setIsDeleted,
        setMaintenanceState,
        setFilterState,
        setShowFilterModal,
        setClearRequestDetailsPanel,
        setTempCarName,

        // helper actions
        searchShift,
        megaRefreshShiftLists,
        // API functions
        getShiftPlannerList,
        saveNewlyCreatedShift,
        getShiftPlannerDetails,
        onResetFilterHandler,
        getShiftCarsDriversList,
        updateShiftData,
        deleteShiftData,
        getShiftHistoryList,
        filterShiftPlannerList,

        filterStatus, setFilterStatus, is_maintenance, setIs_maintenance,

        // custom array data
        ShiftDriver,
        setChipData
      }}
    >
      {props.children}
    </ShiftPlannerContext.Provider>
  );
};

export default ShiftPlannerContextProvider;
