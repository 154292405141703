import { t } from 'i18next';
import { useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import { ShiftPlannerContext } from '../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../Context/TrackOnlineContext';
import { iDemoUser, iRightArrow } from '../../../../../Utility/Sources';
import { BaseUrlSrc } from '../../../../../Utility/url';
import useShiftPlannerStore from '../../../../../App/stores/ShiftPlannerStore';


const Track = ({ data, isSelected, onSelect }) => {

  const { company_id } = useParams();

  const { setIsTrackShiftDetails, setCurrentSelection, currentSelection } = useContext(TrackOnlineContext);
  const { getShiftPlannerDetails } = useContext(ShiftPlannerContext);

  const { companyTrackOnlineDrive, setCompanyTrackOnlineDrive } = useShiftPlannerStore();

  //console.log('ytfytft',company_id)

  return (
    <div
      className="flex item-center justify-between w-full"
      onClick={() => {
        setCurrentSelection(data.shift_id);     //to indicate selection in the list also to show empty message in the details panel.
        setCompanyTrackOnlineDrive(company_id)    // to set data of the details panel
        setIsTrackShiftDetails(true);
        if (currentSelection === data?.shift_id) {

          company_id && getShiftPlannerDetails(data?.shift_id, companyTrackOnlineDrive)
        }

      }}
    >
      <div className={`w-[5px] my-[5px] ${isSelected ? "bg-cListIcon" : "bg-[#ffffff]"} mr-[10px]`}></div>


      <div className=" flex flex-row h-[90px] w-full justify-between items-center bg-cListItem my-[5px] mr-[15px] rounded-[5px] cursor-pointer select-none">
        <div className="flex flex-row justify-between items-center">
          <div className="flex justify-center items-center">
            <div className="flex justify-start items-center rounded-full gs-list-icon-color w-[50px] h-[50px] overflow-hidden ml-4">
              <img src={data?.image === null ? iDemoUser : (BaseUrlSrc + data?.image)} alt="" className="w-[70px] h-[70px] object-cover" />
            </div>
            <div className="flex flex-col pl-[10px]">
              <span className="font-semibold text-sm max-w-[14vw] truncate">{data.car_name}</span>
              <span className="text-xs text-cBodyText font-normal capitalize max-w-[14vw] truncate">
                {data.car_license_plate_number}
              </span>
              {data.title === null ? (
                <p className="font-normal text-[10px] text-[#B2B2B2] ">
                  {t("Not Accepted Yet")}
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="pr-2 flex items-center gs-text-body">
          {data.is_maintenance === 1 && (
            <span className="text-[10px]  drop-shadow-sm">{t("In Maintenance")}</span>
          )}
          {data.status === "init" && data.is_maintenance === 0 && (
            <span className="text-[10px] gs-text-red drop-shadow-sm">
              {t("Not Started")}
            </span>
          )}
          {(data.status === "break" || data.status === "ongoing") &&
            data.is_maintenance === 0 && (
              <span className="text-[10px]  drop-shadow-sm">{t("Started")}</span>
            )}

          <img src={iRightArrow} alt="" className="pl-2" />
        </div>
      </div>
    </div>
  );
};

export default Track;
