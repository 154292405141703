// import { AUTH_ACTION } from "../utils/actions.util";

export const carManagementReducer = (state, action) => {
  switch (action.type) {
    case "SET_ALL_CAR":
      return { ...state, allCar: action.payload, tempAllCar: action.payload };
    case "SET_ONLY_ALL_CAR":
      return { ...state, allCar: action.payload };
    case "SET_DETAILS_STATE":
      return { ...state, detailsState: action.payload };
    case "SET_ADD_CAR_CHANGE":
      return {
        ...state,
        addCar: {
          ...state.addCar,
          [action.payload.name]: action.payload.value,
        },
      };
    case "RESET_ADD_CAR":
      return { ...state, addCar: action.payload };
    case "SET_ADD_CAR_IMAGE":
      return { ...state, addCar: { ...state.addCar, image: action.payload } };
    case "SET_UPDATE_CAR_IMAGE":
      console.log('Updating image:::', action.payload);
      return { ...state, updateCar: { ...state.updateCar, image: action.payload } };
    case "SET_UPDATE_CAR_CHANGE":
      return {
        ...state,
        updateCar: {
          ...state.updateCar,
          [action.payload.name]: action.payload.value,
        },
      };
    case "RESET_UPDATE_CAR":
      return { ...state, addCar: action.payload };
    case "SELECT_CAR":
      return { ...state, updateCar: action.payload };
    case "SET_LICENSE_FORM_CHANGE":
      return {
        ...state,
        licenseForm: {
          ...state.licenseForm,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_SELECTED_LICENSE_INDEX":
      return {
        ...state,
        selectedLicenseIndex: action.payload.index,
        selectedLicenseID: action.payload.id,
      };
    case "SET_ADD_TYPE_UPDATE":
      return {
        ...state,
        addTypeUpdate: {
          ...state.addTypeUpdate,
          [action.payload.name]: action.payload.value,
        },
      };
    case "SET_LICENSE_DATA":
      return {
        ...state,
        allCarLicense: action.payload,
        selectedLicenseID:
          action.payload.length > 0 ? action.payload[0].id : null,
      };
    case "SET_NEW_CAR_ID":
      return { ...state, newCarID: action.payload };

    default:
      return state;
  }
};
