import { t } from 'i18next';
import { iDemoUser, iRightArrow } from '../../../../../Utility/Sources';

function Driver({ data, isSelected, onSelect }) {
  return (
    <div className="flex flex-row justify-start items-center" onClick={onSelect} >
      <div className={` w-[5px] h-[50px] my-[5px] ${isSelected ? "bg-cSelected" : "bg-[#ffffff]"}`}></div>

      <div className=" flex flex-row h-[70px] w-full justify-between items-center bg-cLiteGrey my-[5px] ml-[15px] mr-[20px] rounded-br5 cursor-pointer">
        <div className="flex flex-row justify-start items-center">
          <img src={iDemoUser} className="ml-s10 h-[50px] w-[50px] rounded-full" alt="" />
          <div className="flex flex-col pl-[10px] py-s10">
            <p className="font-semibold text-sm max-w-[14vw] truncate capitalize">
              {data.name ?? data.email}
            </p>
            {data.name === null ? (
              <p className="font-normal text-[10px] text-cTextBody ">
                {t("Not Accepted Yet")}
              </p>
            ) : null}
          </div>
        </div>

        <img src={iRightArrow} alt="" className='pr-s10' />
      </div>
    </div>
  );
}

export default Driver;
