import useContactStore, { advancedFilterMessage, contactFilterClear } from '../../../App/stores/ContactStore';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import CommonInput from '../../../Components/Input/CommonInput';
import CommonLabel from '../../../Components/Label/CommonLabel';
import CommonModal from '../../../Components/Modal/CommonModal';
import { iCloseRed } from '../../../Utility/Sources';

function MessageFilterModal() {
    const { showFilterMessage, setShowFilterMessage, filterMode, setFilterMode, filterForm, setFilterForm, customDateShow, setCustomDateShow } = useContactStore();

    return (
        <>
            <CommonModal
                showModal={showFilterMessage}
                setShowModal={setShowFilterMessage}
                modalTitle="Filter"
                widthClass='w-[700px]'

                mainContent={
                    <form onSubmit={(e) => e.preventDefault()} className='text-cChipText'>

                        {/* g: user type */}
                        <div className="pt-5 font-semibold text-fs14">Select User Type</div>
                        <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    console.log("all")
                                    setFilterForm({ ...filterForm, status: "" });
                                }}
                                label={"all"}
                                active={filterForm.status === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "company" });
                                    console.log("company");
                                }}
                                label={"company"}
                                active={filterForm.status === "company" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "customer" });
                                    console.log("customer")
                                }}
                                label={"customer"}
                                active={filterForm.status === "customer" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, status: "driver" });
                                    console.log("driver")
                                }}
                                label={"driver"}
                                active={filterForm.status === "driver" ? true : false}
                            />

                            {/* <FilterChip
                                onclick={() => {
                                    console.log("guest")
                                }}
                                label={"guest"}
                            /> */}
                        </div>

                        {/* g: message type */}
                        <div className="pt-5 font-semibold text-fs14">Select Message Type</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.is_seen === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: 1 });
                                    console.log("read");
                                }}
                                label={"read"}
                                active={filterForm.is_seen === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_seen: 0 });
                                    console.log("unread");
                                }}
                                label={"unread"}
                                active={filterForm.is_seen === 0 ? true : false}
                            />
                        </div>

                        {/* g: reply status */}
                        <div className="pt-5 font-semibold text-fs14">Select Reply Status</div>
                        <div className="flex pt-s10 space-x-s10">

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: null });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.is_reply === null ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: 1 });
                                    console.log("replied");
                                }}
                                label={"replied"}
                                active={filterForm.is_reply === 1 ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, is_reply: 0 });
                                    console.log("not_replied");
                                }}
                                label={"not replied"}
                                active={filterForm.is_reply === 0 ? true : false}
                            />
                        </div>

                        {/* g: select date */}
                        <div className="pt-5 font-semibold text-fs14 pb-s5">Select Date</div>
                        {!customDateShow ? <div className="flex pt-s10 space-x-s10">
                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "" });
                                    console.log("all");
                                }}
                                label={"all"}
                                active={filterForm.date === "" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "weekly" });
                                    console.log("weekly");
                                }}
                                label={"weekly"}
                                active={filterForm.date === "weekly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "monthly" });
                                    console.log("monthly");
                                }}
                                label={"monthly"}
                                active={filterForm.date === "monthly" ? true : false}
                            />

                            <FilterChip
                                onclick={() => {
                                    setFilterForm({ ...filterForm, date: "custom" });
                                    setCustomDateShow(true);
                                }}
                                label={"custom date"}
                            />
                        </div>
                            :
                            <div className='flex justify-between items-center w-full bg-cLine rounded-br10'>
                                <div className='p-5'>
                                    <CommonLabel labelText='start date' />
                                    <CommonInput
                                        required={filterForm.date === "custom" ? true : false}
                                        type='date'
                                        value={filterForm.start_date}
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            console.log((e.target.value));

                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterForm.end_date);

                                            console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterForm({ ...filterForm, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterForm({ ...filterForm, start_date: e.target.value });
                                            }
                                        }}
                                    />
                                </div>
                                <div className='flex items-center'>
                                    <div className='pr-s15'>
                                        <CommonLabel labelText='end date' />
                                        <CommonInput
                                            required={filterForm.date === "custom" ? true : false}
                                            type='date'
                                            value={filterForm.end_date}
                                            disabled={!filterForm.start_date}
                                            startDate={filterForm.start_date}
                                            onChange={(e) => {
                                                console.log(e.target.value);

                                                setFilterForm({ ...filterForm, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                    <img
                                        onClick={() => {
                                            setCustomDateShow(false);
                                            setFilterForm({ ...filterForm, date: "" });
                                        }}
                                        src={iCloseRed}
                                        alt=""
                                        className='cursor-pointer pr-s15'
                                    />
                                </div>
                            </div>
                        }

                        <div className="flex justify-between pt-5">
                            <CommonButtonOutlined
                                fullRounded={true}
                                btnLabel='clear'
                                colorType='danger'
                                onClick={() => {
                                    contactFilterClear()
                                    setCustomDateShow(false);
                                }}
                            />
                            <CommonButton
                                type={filterForm.date === "custom" ? "submit" : ""}
                                fullRounded={true}
                                btnLabel='apply'
                                onClick={async () => {
                                    if (!filterMode) setFilterMode(true);
                                    if (filterForm.date === "custom") {
                                        if (filterForm.start_date && filterForm.end_date) {
                                            let filterSuccess = await advancedFilterMessage(filterForm);
                                            if (filterSuccess) setShowFilterMessage(false);
                                        }
                                    } else {
                                        let filterSuccess = await advancedFilterMessage(filterForm);
                                        if (filterSuccess) setShowFilterMessage(false);
                                    }
                                }}
                            />
                        </div>

                    </form>
                }
            />
        </>
    )
}

export default MessageFilterModal

const FilterChip = ({ label, active = false, onclick }) => {

    return (
        <div
            onClick={onclick}
            className={`
                capitalize rounded-full border border-cChipBorder hover:border-cBrand py-s5 px-5 text-medium hover:text-white hover:bg-cBrand select-none cursor-pointer
                ${active ? "text-white bg-cBrand border-cBrand" : "bg-white text-cChipText border-cChipBorder"}
            `}
        >
            {label}
        </div>
    )
}