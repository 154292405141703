import React from "react";
import Search from "../../Images/icons/search.svg";

/**
 * custom text input
 */
const SearchBoxSolid = ({
  onClick,
  onChange,
  name,
  value,
  label,
  placeholder,
  type = "text",
}) => {
  return (
    <div className="">
      <div className="absolute mt-[8px] ml-2">
        <img src={Search} alt="" />
      </div>
      <input
        onClick={onClick}
        onChange={onChange}
        type={type}
        name={name}
        value={value}
        className="bg-cListItem px-4 py-2 w-full text-sm rounded-br5 pl-9"
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchBoxSolid;
