/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { t } from "i18next";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  kuAddFilter,
  kuDeleteFilter,
  kuGetFilterList,
  kuSearchFilterList,
  kuShiftSummeryData,
  kuUpdateFilter
} from "../Utility/url";
import { LogWarning, Toastr } from "../Utility/UtilityFunctions";

import useCloudRequestStore from "../App/stores/CloudRequestStore";
import useGeneralStore from "../App/stores/GeneralStore";

export const CloudRequestContext = createContext();

const CloudRequestContextProvider = (props) => {
  const [addRequest, setAddRequest] = useState({
    title: "",
    req_type: "",
    transport_type: "",
    city: "",
  });

  const [updateRequest, setUpdateRequest] = useState({
    title: "",
    req_type: "",
    transport_type: "",
    city: "",
  });

  const [addFilterName, setAddFilterName] = useState("");
  const [addFilterReqType, setAddFilterReqType] = useState("");
  const [addFilterTransportId, setAddFilterTransportId] = useState(0);
  const [addFilterCities, setAddFilterCities] = useState(["c1", "c3"]);

  const [filter, setFilter] = useState([]);
  const [filterTemp, setFilterTemp] = useState([]);
  const [filterBK, setFilterBK] = useState([]);
  const [filterTransportType, setFilterTransportType] = useState([]);

  const [filterCities, setFilterCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCitiesUpdate, setSelectedCitiesUpdate] = useState([]);

  var filterCityArray = [];

  // ! filter cloud-req :: pop up
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isClearedFilterPopup, setIsClearedFilterPopup] = useState(false);
  const [filterPopData, setFilterPopData] = useState({}); //!one object with all parameters to send as body durning api calls
  const [chip, setChip] = useState({});

  // ! add cases
  const [isAddFilter, setIsAddFilter] = useState(false);
  const [isManageFilter, setIsManageFilter] = useState(false);
  const [isEditingNow, setIsEditingNow] = useState(false);

  // ! update cases
  const [filterNameUpdate, setFilterNameUpdate] = useState("");
  const [filterToUpdate, setFilterToUpdate] = useState("");
  const [filterReqTypeUpdate, setFilterReqTypeUpdate] = useState("");
  const [filterTransportID, setFilterTransportID] = useState();
  const [filterTransportTypeUpdate, setFilterTransportTypeUpdate] = useState();

  // ! map-section states
  const [isShowPointsOnMap, setIsShowPointsOnMap] = useState(false);
  const [dataPoints, setDataPoints] = useState([
    { pickup_lat: 55.66745, pickup_lng: 12.37078 },
    { pickup_lat: 55.68591, pickup_lng: 12.38872 },
    { pickup_lat: 55.64591, pickup_lng: 12.39565 },
    { pickup_lat: 55.65591, pickup_lng: 12.39565 },
  ]);

  // ! filter-in filter list
  const [filterSearchList, setFilterSearchList] = useState([]);
  const [searchFilterName, setSearchFilterName] = useState();
  const [searchFilterByChip, setSearchFilterByChip] = useState({});

  const [requestType, setRequestType] = useState();
  const [multiTransportType, setMultiTransportType] = useState();
  const [currentSelection, setCurrentSelection] = useState(null);

  const [chipShow, setChipShow] = useState(true);

  const [isExpressType, setIsExpressType] = useState(false);
  const [isAdvancedType, setIsAdvancedType] = useState(false);

  const [detailsState, setDetailsState] = useState("add");
  const [request, setRequest] = useState([]);
  const [tempRequest, setTempRequest] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedRow, setSelectedRow] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [canUpdateSubmit, setCanUpdateSubmit] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchKeyFreeText, setSearchKeyFreeText] = useState("");

  // red Filter result popup data
  const [shiftSummeryData, setShiftSummeryData] = useState({});
  const [isClrPopupReqType, setIsClrPopupReqType] = useState(false);
  const [filterPopupID, setFilterPopupID] = useState();
  const [filterPopupReqType, setFilterPopupReqType] = useState();
  const [tempTransportID, setTempTransportID] = useState(null);
  const [isclrPopupTransportationType, setIsclrPopupTransportationType] = useState(false);
  const [filterPopupTransportation, setFilterPopupTransportation] = useState("");
  const [isClrPopupCitySelector, setIsClrPopupCitySelector] = useState(false);
  const [filterPopupCities, setFilterPopupCities] = useState([]);
  const [filterPopupStartDate, setFilterPopupStartDate] = useState();
  const [filterPopupEndDate, setFilterPopupEndDate] = useState();
  const [filterPopupStartTime, setFilterPopupStartTime] = useState();
  const [filterPopupEndTime, setFilterPopupEndDaTime] = useState();
  const [dataChk, setDataChk] = useState(null);
  const [selected, setSelected] = useState();

  // more state tracking
  const [startSearch, setStartSearch] = useState(false);
  const [saveSuccessAdd, setSaveSuccessAdd] = useState(false);
  const [tmpFreeText, setTmpFreeText] = useState("");
  const [is_global_req_free_text_searching, setGlobalReqFreeTextSearching] = useState(false);

  const { setLoading } = useGeneralStore()
  const { setPickupPoints } = useCloudRequestStore();

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */

  const getFilterList = async (user_id, withLoading = true) => {
    try {
      if (withLoading) setLoading(true);
      const res = await axios.get(kuGetFilterList, { params: { user_id: user_id } });
      console.log('getFilterList', res.data)

      if (res.data.success) {
        setFilterCities(res.data.data.cities);
        setFilter(res.data.data.filters);
        setFilterTemp(res.data.data.filters);
        setFilterBK(res.data.data.filters);
        setFilterTransportType(res.data.data.transportation_types);
      } else {
        Toastr(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      console.log('getFilterList', err);
      setLoading(false)
      Toastr(t("An error occurred!"))
    }
  }

  const getFilterSearchList = async (user_id, withLoading = true, searchValue = "") => {
    try {
      if (withLoading) setLoading(true);
      if (searchValue) setGlobalReqFreeTextSearching(true);

      let body = { ...chip }
      if (body.city?.length === 0) delete body.city
      if (body === {}) {
        // getFilterSearchListByFreeText('')
        return
      }
      body.is_web = 1;
      if (body.type === "simple_and_advance") body.type = ''

      body = { ...body, user_id: user_id };

      if (searchValue) body = { ...body, search_type: "search", search_text: searchValue };

      console.log('getFilterSearchList BODY ==>', body);

      const res = await axios.post(kuSearchFilterList, body);

      console.log("getFilterSearchList: ", res.data);

      if (res.data.success) {
        if (startSearch) {
          setFilterSearchList(res.data.data);
          setPickupPoints(generatePickupPoints(res.data.data));
        }
      } else {
        Toastr(res.data.message)
      }

      setLoading(false);
      setGlobalReqFreeTextSearching(false);
    } catch (err) {
      console.log('getFilterList', err)
      setLoading(false);
      setGlobalReqFreeTextSearching(false);
      Toastr(t("An error occurred!"))
    }
  }

  const generatePickupPoints = (requests) => {
    let x = []
    requests.forEach(item => {
      if (item.pickup_lat && item.pickup_lng) {
        const point = {
          pickup_lat: item.pickup_lat,
          pickup_lng: item.pickup_lng,
        };
        x.push(point)
      }
    })
    // console.log('x: ', x);
    return x;
  }

  const getShiftSummeryData = async (id = 0, u_id = 0) => {
    try {
      if (!id) {
        Toastr(t("Invalid shift"))
        return
      }

      setLoading(true)
      console.log("getShiftSummeryData: BODY:: ", id, u_id);
      const res = await axios.post(kuShiftSummeryData, { id: id, user_id: u_id });
      console.log("getShiftSummeryData: ", res.data);

      if (res.data.success) {
        setShiftSummeryData(res.data.data);
      } else {
        Toastr(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      console.log('getShiftSummeryData: ', err);
      setLoading(false)
      Toastr(t("An error occurred!"))
    }
  }

  const getFilterSearchListByFreeText = async (value, id = "") => {
    setGlobalReqFreeTextSearching(true)
    setChip();
    let inputText = value;
    console.log("inputText: ", inputText, id);
    setSearchKeyFreeText(inputText);
    try {
      const body = { search_type: "search", search_text: inputText, user_id: id };
      const res = await axios.post(kuSearchFilterList, body);
      console.log("getFilterSearchListByFreeText ", res.data);

      if (res.data.success) {
        setFilterSearchList(res.data.data);
        setPickupPoints(generatePickupPoints(res.data.data));
      } else {
        Toastr(res.data.message)
      }

      setGlobalReqFreeTextSearching(false)
    } catch (err) {
      console.log('getFilterSearchListByFreeText: ', err);
      setGlobalReqFreeTextSearching(false)
      Toastr(t("An error occurred!"))
    }
  }

  const saveNewlyCreatedFilter = async (user_id) => {
    try {
      setLoading(true);
      var req_typ = "";
      if (isAdvancedType && isExpressType) {
        req_typ = "simple_and_advance";
      } else {
        if (isAdvancedType) {
          req_typ = "advance";
        } else if (isExpressType) {
          req_typ = "simple";
        }
      }
      const body = {
        name: addFilterName,
        transportation_type_id: addFilterTransportId,
        request_type: req_typ,
        city: JSON.stringify(selectedCities),
        company_user_id: user_id
      };

      const res = await axios.post(kuAddFilter, body);
      console.log("saveNewlyCreatedFilter: ", res.data);

      if (res.data.success) {
        getFilterList(user_id);
        setSaveSuccessAdd(true);
      } else {
        Toastr(res.data.message)
      }
      setLoading(false)
    } catch (err) {
      console.log('saveNewlyCreatedFilter', err);
      Toastr(t("An error occurred!"))
      setLoading(false)
    }
  }

  const updateShiftData = async (user_id) => {
    try {
      setLoading(true)
      var req_typ = "";
      if (isAdvancedType && isExpressType) {
        req_typ = "simple_and_advance";
      } else {
        if (isAdvancedType) {
          req_typ = "advance";
        } else if (isExpressType) {
          req_typ = "simple";
        }
      }
      let body = {};
      if (selectedCities.length) {
        body = {
          id: currentSelection,
          name: filterToUpdate,
          request_type: req_typ,
          transportation_type_id: filterTransportTypeUpdate,
          company_user_id: user_id,
          city: JSON.stringify(selectedCities),
        };
      } else {
        body = {
          id: currentSelection,
          name: filterToUpdate,
          request_type: req_typ,
          transportation_type_id: filterTransportTypeUpdate,
          company_user_id: user_id,
        };
      }

      const res = await axios.post(kuUpdateFilter, body)
      console.log("updateShiftData: ", res.data);

      if (res.data.success) {
        getFilterList(user_id);
      } else {
        Toastr(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      console.log('updateShiftData', err)
      Toastr(t("An error occurred!"))
      setLoading(false)
    }
  }

  const deleteShiftData = async () => {
    const { cloudReqCompanyID } = useCloudRequestStore.getState();
    try {
      setLoading(true)
      const body = { id: currentSelection };
      const res = await axios.post(kuDeleteFilter, body);
      console.log('deleteShiftData', res.data)

      if (res.data.success) {
        getFilterList(cloudReqCompanyID);
        setIsManageFilter(false);
        setIsEditingNow(false);
        setCurrentSelection(null);
      } else {
        Toastr(res.data.message)
      }
      setLoading(false)
    } catch (err) {
      console.log('deleteShiftData', err);
      setLoading(false)
      Toastr(t("An error occurred!"))
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Helper Functions
  |--------------------------------------------------------------------------
  */

  const searchFilter = (event) => {
    setSearchKey(event.target.value);
    const sKey = event.target.value;
    // eslint-disable-next-line array-callback-return
    const result = filterTemp.filter((item) => {
      if (
        item?.name?.toLowerCase().includes(sKey?.toLowerCase()) ||
        item?.transportation_type?.toLowerCase().includes(sKey?.toLowerCase())
      ) {
        return item;
      } else {
        return null;
      }
    });
    setCurrentSelection(null);
    setFilter(result);
  };

  const addFilter = () => {
    setIsAddFilter(true);
    setIsManageFilter(false);
  };

  const applyFilter = () => {
    setIsManageFilter(false);
    setIsAddFilter(false);
  };

  const manageFilter = () => {
    setIsManageFilter(true);
    setIsAddFilter(false);
  };

  const setChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddRequest({ ...addRequest, [name]: value });
  };

  const filterSelected = (data) => {
    setSelectedIndex(data);
    if (isManageFilter) {
      setIsEditingNow(true);
    }
  };

  const setUpdateFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUpdateRequest({ ...updateRequest, [name]: value });
  };

  const clearFilterPopup = () => {
    setIsClearedFilterPopup(true);
    setIsClrPopupReqType(true);
    setIsclrPopupTransportationType(true);
    setIsClrPopupCitySelector(true);
    setTempTransportID(null)
    // ? clear values
    setFilterPopupID(null);

    setFilterPopupReqType(null);
    setFilterPopupTransportation(null);
    setFilterPopupCities([]);

    setFilterPopupStartDate();
    setFilterPopupEndDate();
    setFilterPopupStartTime();
    setFilterPopupEndDaTime();
  };

  const resetAddFilterForm = () => {
    setAddFilterName("");
    setAddFilterTransportId(null);
    setIsAdvancedType(false);
    setIsExpressType(false);
    setAddFilterReqType(null);
    setAddFilterCities([]);
  }

  /*
  |--------------------------------------------------------------------------
  | use Effects
  |--------------------------------------------------------------------------
  */
  const location = useLocation();

  useEffect(() => {
    setSelectedCitiesUpdate("");
  }, [selectedCitiesUpdate]);

  useEffect(() => {
    setChip(filterPopData);
  }, [filterPopData]);

  useEffect(() => {
    
    filterBK.map((item) => {
      if (item.id === currentSelection) {
        console.log("Selected Filter Item::::", item);
        setSelectedCitiesUpdate(item.city);

        // hb set chip data on click
        let tempChip = {};
        if (item.request_type != null) {
          tempChip.type = item.request_type;
        }
        if (item.transportation_type != null) {
          tempChip.transport_type = item.transportation_type;
        }
        if (item?.city?.length) {
          tempChip.city = item?.city;
        }
        setChip(tempChip);
        console.log("setChip::::: ", tempChip);
      }
    });

    let tLat = dataPoints[dataPoints.length - 1].pickup_lat + 0.015;
    tLat = Math.round(tLat * 100000) / 100000;
    let tLng = dataPoints[dataPoints.length - 1].pickup_lng - 0.035;
    tLng = Math.round(tLng * 100000) / 100000;
    setDataPoints([
      ...dataPoints,
      {
        pickup_lat: tLat,
        pickup_lng: tLng,
      },
    ]);
  }, [currentSelection]);

  // useEffect(() => {
  //   if (!currentSelection) {
  //     setFilterSearchList(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentSelection]);

  return (
    <CloudRequestContext.Provider
      value={{
        //green states
        selected,
        setSelected,
        dataChk,
        setDataChk,
        addRequest,
        updateRequest,
        detailsState,
        request,
        tempRequest,
        selectedIndex,
        selectedRequest,
        isLoading,
        canSubmit,
        canUpdateSubmit,
        searchKey,
        requestType,
        multiTransportType,
        selectedCities,
        isAddFilter,
        isManageFilter,
        isEditingNow,
        selectedRow,
        isExpressType,
        isAdvancedType,
        currentSelection,
        chipShow,
        filter,
        filterTemp,
        addFilterName,
        addFilterReqType,
        addFilterTransportId,
        addFilterCities,
        filterTransportType,
        filterNameUpdate,
        filterTransportTypeUpdate,
        searchFilterName,
        filterSearchList,
        filterToUpdate,
        filterReqTypeUpdate,
        filterTransportID,
        filterCities,
        selectedCitiesUpdate,
        showFilterModal,
        isClearedFilterPopup,
        searchFilterByChip,
        chip,
        filterPopData,
        searchKeyFreeText,
        isShowPointsOnMap,
        dataPoints,
        shiftSummeryData,
        filterPopupID,
        isClrPopupReqType,
        filterPopupReqType,
        tempTransportID,
        isclrPopupTransportationType,
        filterPopupTransportation,
        isClrPopupCitySelector,
        filterPopupCities,
        filterPopupStartDate,
        filterPopupEndDate,
        filterPopupStartTime,
        filterPopupEndTime,
        startSearch,
        saveSuccessAdd,
        setSaveSuccessAdd,
        filterBK,
        setFilterBK,
        tmpFreeText,
        is_global_req_free_text_searching,

        //blue actions
        setAddRequest,
        setUpdateRequest,
        setDetailsState,
        setRequest,
        setTempRequest,
        setSelectedIndex,
        setSelectedRequest,
        setIsLoading,
        setCanSubmit,
        setCanUpdateSubmit,
        setSearchKey,
        setRequestType,
        setMultiTransportType,
        setSelectedCities,
        setChange,
        setIsAddFilter,
        setIsManageFilter,
        filterSelected,
        setIsEditingNow,
        setSelectedRow,
        setUpdateFilter,
        setIsExpressType,
        setIsAdvancedType,
        setCurrentSelection,
        setChipShow,
        addFilter,
        applyFilter,
        manageFilter,
        setFilter,
        setFilterTemp,
        searchFilter,
        setAddFilterName,
        setAddFilterReqType,
        setAddFilterTransportId,
        setAddFilterCities,
        setFilterTransportType,
        setFilterNameUpdate,
        setFilterTransportTypeUpdate,
        setSearchFilterName,
        setFilterSearchList,
        setFilterToUpdate,
        setFilterReqTypeUpdate,
        setFilterTransportID,
        setFilterCities,
        setSelectedCitiesUpdate,
        setShowFilterModal,
        setIsClearedFilterPopup,
        setSearchFilterByChip,
        setChip,
        setFilterPopData,
        setSearchKeyFreeText,
        setIsShowPointsOnMap,
        setDataPoints,
        setShiftSummeryData,
        setFilterPopupID,
        setIsClrPopupReqType,
        setFilterPopupReqType,
        setTempTransportID,
        setIsclrPopupTransportationType,
        setFilterPopupTransportation,
        setIsClrPopupCitySelector,
        setFilterPopupCities,
        setFilterPopupStartDate,
        setFilterPopupEndDate,
        setFilterPopupStartTime,
        setFilterPopupEndDaTime,
        setStartSearch,
        setTmpFreeText,

        // api functions
        getFilterList,
        saveNewlyCreatedFilter,
        updateShiftData,
        deleteShiftData,
        getFilterSearchList,
        getFilterSearchListByFreeText,
        getShiftSummeryData,

        //hb helper functions
        clearFilterPopup,
        resetAddFilterForm,

        // dummy data
        // DummyData,
        filterCityArray,
        setGlobalReqFreeTextSearching,
      }}
    >
      {props.children}
    </CloudRequestContext.Provider>
  );
};

export default CloudRequestContextProvider;
