/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { createContext, useState } from "react";
import { Toastr } from "../Utility/UtilityFunctions";
import {
  kuGetCompanyDetails,
  kuGetCompanyReviewInfo,
  kuGetFavoriteCompany,
  kuGetNotFavoriteCompany,
  kuToggleFavCompany,
} from "../Utility/url";
import { t } from "i18next";
import useGeneralStore from "../App/stores/GeneralStore";

export const FavoriteCompanyContext = createContext();

const FavoriteCompanyContextProvider = (props) => {
  const [updateAddress, setUpdateAddress] = useState({
    name: "",
    addressValue: "",
  });
  const [detailsState, setDetailsState] = useState("add");

  const [favoriteCompany, setFavoriteCompany] = useState([]);
  const [tempFavoriteCompany, setTempFavoriteCompany] = useState([]);

  var favChipArray = [false, false, false, false, false, false];
  var notFavChipArray = [false, false, false, false, false, false];

  const [isOneStar, setIsOneStar] = useState(false);
  const [isTwoStar, setIsTwoStar] = useState(false);
  const [isThreeStar, setIsThreeStar] = useState(false);
  const [isFourStar, setIsFourStar] = useState(false);
  const [isFiveStar, setIsFiveStar] = useState(false);

  const [isOneNStar, setIsOneNStar] = useState(false);
  const [isTwoNStar, setIsTwoNStar] = useState(false);
  const [isThreeNStar, setIsThreeNStar] = useState(false);
  const [isFourNStar, setIsFourNStar] = useState(false);
  const [isFiveNStar, setIsFiveNStar] = useState(false);

  const [isFavorable, setIsFavorable] = useState(true);

  const [notFavoriteCompanyUID, setNotFavoriteCompanyUID] = useState([]);

  const [notFavoriteCompany, setNotFavoriteCompany] = useState([]);
  const [notFavoriteCompanyTmp, setNotFavoriteCompanyTmp] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedFavIndex, setSelectedFavIndex] = useState(0);

  const [favCompanyDetails, setFavCompanyDetails] = useState({});
  const [favCompanyRatingInfo, setFavCompanyRatingInfo] = useState({});

  const [selectedFavCompany, setSelectedFavCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingFavCompany, setIsAddingFavCompany] = useState(false);

  const [searchKey, setSearchKey] = useState("");
  const [searchKeyNotFav, setSearchKeyNotFav] = useState("");
  const [isCompanySearching, setCompanySearching] = useState(false);

  const { setLoading } = useGeneralStore()

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */
  const getFavoriteCompany = async (u_id) => {
    try {
      setLoading(true);
      const body = { user_id: u_id };
      console.log("GET FC BODY : ", body);
      const res = await axios.get(kuGetFavoriteCompany, { params: body });
      console.log("getFavoriteCompany: ", res.data);
      if (res.data.success) {
        setFavoriteCompany(res.data.data);
        setTempFavoriteCompany(res.data.data);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('getFavoriteCompany: ', err);
      Toastr(t('An error occurred!'));
      setLoading(false);
    }
  };

  const getCompanyDetails = async (id="") => {
    try {
      setLoading(true);

      const res = await axios.get(kuGetCompanyDetails, {
        params: { id: id ? id :selectedIndex },
      });

      console.log('company details: ', res.data);

      const favReviews = await axios.get(kuGetCompanyReviewInfo, {
        params: { company_user_id: id  ? id :selectedIndex },
      });

      console.log('review details: ', favReviews.data);

      if (res.data.success) {
        setFavCompanyDetails(res.data.data);
      } else {
        Toastr(res.data.message);
      }

      if (favReviews.data.success) {
        setFavCompanyRatingInfo(favReviews.data.data);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log('getCompanyDetails: ', err);
      Toastr(t('An error occurred!'));
      setLoading(false);
    }
  };

  const getNotFavoriteCompany = async (key = '', stars = [], u_id) => {
    try {
      console.log("UID::::", notFavoriteCompanyUID);

      setCompanySearching(true)
      const res = await axios.post(kuGetNotFavoriteCompany,
        {
          user_id: notFavoriteCompanyUID,
          search: key,
          rate: JSON.stringify(stars)
        });
      console.log("getNotFavoriteCompany: ", res.data);
      if (res.data.success) {
        setNotFavoriteCompany(res.data.data);
        setNotFavoriteCompanyTmp(res.data.data);
      } else {
        Toastr(res.data.message);
      }
      setCompanySearching(false);
    } catch (err) {
      console.log('getNotFavoriteCompany: ', err);
      Toastr(t('An error occurred!'));
      setCompanySearching(false);
    }
  };

  // add / remove favorite company
  const addFavoriteCompany = async (u_id = 0) => {
    try {
      setLoading(true);
      const body = { id: selectedIndex, user_id: u_id };
      console.log("ADD FC BODY:::", body);
      const res = await axios.post(kuToggleFavCompany, body);
      console.log('addFavoriteCompany(toggle): ', res.data);

      if (res.data.success) {
        getFavoriteCompany(u_id);
        getNotFavoriteCompany("", [], u_id);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('addFavoriteCompany(toggle): ', err);
      Toastr(t('An error occurred!'));
      setLoading(false);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Helper functions
  |--------------------------------------------------------------------------
  */

  const filterFavCompany = () => {
    const result = tempFavoriteCompany.filter((item) => {
      if (favChipArray[1] && item.rate <= 1) return item;
      else if (favChipArray[2] && item.rate <= 2) return item;
      else if (favChipArray[3] && item.rate <= 3) return item;
      else if (favChipArray[4] && item.rate <= 4) return item;
      else if (favChipArray[5] && item.rate <= 5) return item;
      else return null;
    });
    setFavoriteCompany(result);
  };

  const filterNotFavCompany = () => {
    const result = notFavoriteCompanyTmp.filter((item) => {
      if (notFavChipArray[1] && item.rate <= 1) return item;
      else if (notFavChipArray[2] && item.rate <= 2) return item;
      else if (notFavChipArray[3] && item.rate <= 3) return item;
      else if (notFavChipArray[4] && item.rate <= 4) return item;
      else if (notFavChipArray[5] && item.rate <= 5) return item;
      else return null;
    });
    setNotFavoriteCompany(result);
  };

  // const searchFavCompany = (search) => {
  //   console.log("tempFavoriteCompany",tempFavoriteCompany)
  //   console.log("search",search)
  //   setSearchKey(search);
  //   // eslint-disable-next-line array-callback-return
  //   const result = tempFavoriteCompany?.filter((item) => {
  //     const name = item?.name ?? "";
  //     if (
  //       name?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //       item?.rate?.toLowerCase()?.includes(search?.toLowerCase()) ||
  //       item?.address?.toLowerCase().includes(search?.toLowerCase())
  //     ) {
  //       console.log("dfdf",item)
  //       return item;
  //     }
  //   });
  //   console.log("result",result)
  //   setFavoriteCompany(result);
  // };

  const searchFavCompany = async (search) => {
    setSearchKey(search);

    await setFavoriteCompany(tempFavoriteCompany?.filter((item) =>
      item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      //parseInt(item?.rate) == search ||
      item?.rate?.toString()?.includes(search.toString()) ||
      item?.address?.toLowerCase().includes(search.toLowerCase())));
      console.log("")
  };

  const searchNotFavCompany = (event) => {
    setSearchKeyNotFav(event.target.value);
    const result = notFavoriteCompanyTmp.filter((item) => {
      if (
        item.name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        item.name?.toLowerCase().includes(event.target.value.toLowerCase())
      ) return item
      else return null
    });
    setNotFavoriteCompany(result);
  };


  return (
    <FavoriteCompanyContext.Provider
      value={{
        // states
        updateAddress,
        detailsState,
        favoriteCompany,
        tempFavoriteCompany,
        selectedIndex,
        selectedFavCompany,
        isLoading,
        searchKey,
        isAddingFavCompany,
        notFavoriteCompany,
        notFavoriteCompanyTmp,
        selectedFavIndex,
        favCompanyDetails,
        favCompanyRatingInfo,
        searchKeyNotFav,
        isOneStar,
        isTwoStar,
        isThreeStar,
        isFourStar,
        isFiveStar,
        isOneNStar,
        isTwoNStar,
        isThreeNStar,
        isFourNStar,
        isFiveNStar,
        isCompanySearching,
        isFavorable,
        notFavoriteCompanyUID,

        //action
        setNotFavoriteCompanyUID,
        setIsFavorable,
        setUpdateAddress,
        setDetailsState,
        setFavoriteCompany,
        setTempFavoriteCompany,
        setSelectedIndex,
        setSelectedFavCompany,
        setIsLoading,
        setSearchKey,
        setIsAddingFavCompany,
        setNotFavoriteCompany,
        setNotFavoriteCompanyTmp,
        setSelectedFavIndex,
        setFavCompanyDetails,
        setFavCompanyRatingInfo,
        setSearchKeyNotFav,

        setIsOneStar,
        setIsTwoStar,
        setIsThreeStar,
        setIsFourStar,
        setIsFiveStar,
        setIsOneNStar,
        setIsTwoNStar,
        setIsThreeNStar,
        setIsFourNStar,
        setIsFiveNStar,

        // helper functions
        searchFavCompany,
        searchNotFavCompany,
        filterFavCompany,
        filterNotFavCompany,

        //api functions
        getFavoriteCompany,
        addFavoriteCompany,
        getNotFavoriteCompany,
        getCompanyDetails,

        // Data
        favChipArray,
        notFavChipArray,
        setCompanySearching,
      }}
    >
      {props.children}
    </FavoriteCompanyContext.Provider>
  );
};

export default FavoriteCompanyContextProvider;
