import { useTranslation } from 'react-i18next';
import useGeneralStore, { setAppLangCodeFoo } from '../../../App/stores/GeneralStore';
import useSettingsStore from '../../../App/stores/SettingsStore';
import LanguageItem from './LanguageItem';


function LanguageSetup() {
    const { appLanguage, setActiveLanguage } = useSettingsStore();
    const { t } = useTranslation()
    const { app_lang_code } = useGeneralStore()

    const setLanguage = (lang) => {
        setActiveLanguage(lang);
        setAppLangCodeFoo(lang);
    }



    return (
        <div>
            <div className="text-2xl font-bold mb-5">{t('Language Setup')}</div>
            <ul className="list-none">
                {
                    appLanguage.map((lang, index) => {

                        return <LanguageItem
                            key={index}
                            onClick={() => {
                                setLanguage(lang.code)
                                // console.log(lang.code);
                            }}
                            label={lang.name}
                            isActive={appLanguage[index].code === app_lang_code ? true : false}
                        />

                    })
                }

            </ul>


        </div>
    )
}

export default LanguageSetup