/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { useTrackOnlineDriverPatchStore } from '../../../../../../App/stores/TrackOnlineDriverStore';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import UploadButton from '../../../../../../Components/Button/UploadButton';
import Calendar from '../../../../../../Components/Calendar';
import Dropdown from '../../../../../../Components/Dropdown';
import Dropzone from '../../../../../../Components/Dropzone';
import CommonInput from '../../../../../../Components/Input/CommonInput';
import Input02 from '../../../../../../Components/Input/Input02';
import InputAutoCompleteType2 from '../../../../../../Components/Input/InputAutocompleteType2';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import TimePickerNew from '../../../../../../Components/TimePickerNew';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iInfoLocationIcon } from '../../../../../../Utility/Sources';
import { formatDate, getBase64 } from '../../../../../../Utility/UtilityFunctions';
import RoutePlannerList from '../Lists/RoutePlannerList';
import ChooseFavAddress from '../Popup/ChooseFavAddress';
import AddStopBar from '../TitleBars/AddStopBar';


const AddStop = () => {
  const {
    addStopAddress,
    setAddStopAddress,
    setAddStopLat,
    setAddStopLng,
    showFavAddress,
    setShowFavAddress,
    addStopAttachment,
    setAddStopAttachment,
    addStopName,
    setAddStopName,
    addStopStartDate,
    setAddStopStartDate,
    addStopStartTime,
    setAddStopStartTime,
    addStopLat,
    addStopLng,
    addStopComment,
    setAddStopComment,
    addStopEndTime,
    setAddStopEndTime,
    addCustomStop,
    clearOnSubmit,
    setClearOnSubmit,
    getRoutePlannerList,
  } = useContext(TrackOnlineContext);
  const { isShiftDetailsData, getShiftPlannerDetails } = useContext(ShiftPlannerContext);

  const { setIsCustomStopDateValid, setShiftDateTimeRange } = useTrackOnlineDriverPatchStore();

  const [doSearch, setDoSearch] = useState(false);
  const { shift_id, company_id } = useParams()

  //   hb process the image after upload triggered
  const onPickImageUpAttachment = (file) => getBase64(file, (result) => setAddStopAttachment(result));

  //   hg helper fn to handle changes to the address suggestion input
  const handleChange = (name, value) => {
    if (name === "location") {
      setAddStopAddress(value);
    } else if (name === "lat") {
      setAddStopLat(value);
    } else if (name === "lng") {
      setAddStopLng(value);
    }
  };

  //hg helper function to refresh the list
  const refreshFoo = async () => {
    // the api function to execute from context
    if (isShiftDetailsData) {
      getRoutePlannerList(isShiftDetailsData.id);
    }
  };

  // hy handle time  picker functionality
  const onDeliveryStartDateSelect = (time, avg_time, avg) => {
    console.log(time, "Selected Start Time");
    console.log(avg_time, "Selected End Time");
    // console.log(avg, "Average");
    if (time !== addStopStartTime) setAddStopStartTime(time);
    if (avg_time !== addStopEndTime) setAddStopEndTime(avg_time);
    // if (avg !== delivery_avg) ChangeValue('delivery_avg', avg);
  };

  // hb handle date picker function
  const ChangeEndDateValue = async (name, value) => {
    setAddStopStartDate(value);
  };

  // hy clear form data
  useEffect(() => {
    if (clearOnSubmit) {
      // hg clear things
      setAddStopName("");

      setAddStopStartDate(null);
      setAddStopStartTime("");
      setAddStopEndTime("");

      setAddStopAddress("");
      setAddStopLat(null);
      setAddStopLng(null);

      setAddStopComment("");
      setAddStopAttachment("");

      // hb remove clear command
      setClearOnSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOnSubmit]);

  const [isInAddStop, setIsInAddStop] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setClearOnSubmit(true);
    !isShiftDetailsData && getShiftPlannerDetails(shift_id, company_id);
    setIsInAddStop(true);
    return (() => { setIsInAddStop(false) })
  }, []);

  useEffect(() => {
    if (!addStopStartDate) return;
    let a = addStopStartDate ?? "1970-01-01"
    let b = addStopStartTime ?? "00:00:00"
    let selected_date = new Date(a + " " + b)
    let shift_start_date = new Date(isShiftDetailsData?.start_date + " " + isShiftDetailsData?.start_time);
    let shift_end_date = new Date(isShiftDetailsData?.end_date + " " + isShiftDetailsData?.end_time);
    if (selected_date < shift_start_date || selected_date > shift_end_date);
    setShiftDateTimeRange(" from " + shift_start_date + " to " + shift_end_date);


    if ((selected_date >= shift_start_date) && (selected_date <= shift_end_date)) {
      setIsCustomStopDateValid(true)
      console.log('valid');
    } else {
      setIsCustomStopDateValid(false)
      console.log('invalid');
    }

  }, [addStopStartDate, addStopStartTime, addStopEndTime]);



  return (
    <div className="gs-text-main-black">
      {/* hg favorite address modal to choose */}

      <CommonModal
        showModal={showFavAddress}
        setShowModal={setShowFavAddress}
        modalTitle={t("Select Favorite Address")}
        mainContent={
          <div>
            <ChooseFavAddress
              fullAddressTitle={(e) => handleChange("location", e)}
              addressLat={(lat) => handleChange("lat", lat)}
              addressLng={(lng) => handleChange("lng", lng)}
              company_id={company_id}
            />
          </div>
        }
      />

      <AddStopBar />
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <RoutePlannerList isInAddStop={isInAddStop} />

        <div className="col-span-12 lg:col-span-7 mt-5 gs-text-main-black">
          {/* hb:  main form starts */}
          <div className="card p-5 gs-text-main-black text-sm relative">
            <div className="text-2xl font-semibold">{t("Add Stop")}</div>

            {/* hg name input */}
            <div className="py-5">
              <CommonInput

                label={"Name"}
                placeholder={t("Write Name")}
                value={addStopName}
                onChange={(e) => {
                  setAddStopName(e.target.value);
                }}
              />
            </div>

            <div className="pb-6 flex justify-between space-x-2">
              {/* hg date picker */}
              <Dropdown
                width={null}
                button={
                  <Input02
                    onChange={(date) => {
                      ChangeEndDateValue("end_date", date);
                    }}
                    className="cursor-pointer"
                    className2="w-full"
                    name="end_date"
                    value={!clearOnSubmit ? formatDate(addStopStartDate) : ""}
                    label={t("Start Date")}
                    type="text"
                    placeholder={t("Start Date")}
                    is_readonly={true}
                    icon={<BsCalendar2WeekFill className="h-[20px] w-[20px]" />}
                  />
                }
                body={
                  <Calendar
                    selectAction={(date) => {
                      ChangeEndDateValue("end_date", date);
                    }}
                    init_date={addStopStartDate}
                  />
                }
              />

              {/* hp new time picker */}
              {/* <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 mb-12"> */}
              <Dropdown
                width={null}
                button={
                  <Input02
                    onChange={(e) => {
                      console.log(e.target.name + ",  " + e.target.value);
                    }}
                    // onClick={onClick}
                    className="cursor-pointer"
                    className2="mb-0"
                    name="delivery_start_time"
                    value={addStopStartTime}
                    label={t("Start Time")}
                    type="text"
                    placeholder={t("Start Time")}
                    icon={
                      <BsClockFill className="h-[20px] w-[20px] cursor-pointer" />
                    }
                  />
                }
                body={
                  <TimePickerNew
                    selectAction={onDeliveryStartDateSelect}
                    init_time={addStopStartTime}
                    endTime={addStopEndTime}
                  />
                }
              />
            </div>

            {/* hg auto complete address box */}
            <div className="relative pt-[20px]">
              <InputAutoCompleteType2
                label={t("Address")}
                placeholder={t("Type Address..")}
                name={"location"}
                address={addStopAddress}
                latName={"lat"}
                lngName={"lng"}
                changeValue={handleChange}
                doSearch={doSearch}
                setDoSearch={setDoSearch}
                classNameInp="pr-12"
              />
              <div className="flex justify-between items-center absolute top-[51px] right-[5px] py-1 px-2 ">
                <div className="h-[20px] w-[1px] bg-cIcon mr-[7px]"></div>
                <img
                  onClick={() => setShowFavAddress(true)}
                  className="cursor-pointer w-[21px] h-[21px]"
                  src={iInfoLocationIcon}
                  alt="pin-location"
                />
              </div>
            </div>

            {/* hy comment box */}
            <div>
              <div className="pb-[10px] text-sm font-bold">{t("Comment")}</div>
              <CommonInput
                textarea={true}
                max_char_limit={500}
                is_show_char_limit={true}
                value={addStopComment}
                onChange={(e) => setAddStopComment(e.target.value)}
                placeholder={t("Write comment..")}
              />
            </div>

            {/* hg attachment button */}
            <div className="pt-[15px]">
              {!addStopAttachment ? (
                <Dropzone
                  onUpload={onPickImageUpAttachment}
                  content={
                    <>
                      <UploadButton
                        className={"mx-0"}
                        title={t("Attachment")}
                        subTitle={t("Only 1 signature image at a time. Maximum 10 MB.")}
                      />
                    </>
                  }
                />
              ) : (
                // hb attachment preview image
                <div className="mx-auto w-full h-[150px] rounded-lg overflow-hidden shadow-lg relative">
                  <AiFillCloseCircle
                    onClick={() => {
                      setAddStopAttachment(null);
                    }}
                    className="absolute top-0 right-0 text-2xl cursor-pointer gs-text-red z-30"
                  />
                  <div className="absolute top-0 left-0 w-full h-full object-cover blur-xl">
                    <img src={addStopAttachment} alt="" />
                  </div>
                  <img
                    className="absolute top-0 left-[50%] translate-x-[-50%] h-[150px]"
                    src={addStopAttachment}
                    alt=""
                  />
                </div>
              )}
            </div>

            {/* hg submit data */}
            <div className="pt-[20px] flex justify-center">
              <CommonButton
                btnLabel={t("Submit")}
                isDisabled={
                  addStopName &&
                    addStopStartDate &&
                    addStopStartTime &&
                    addStopAddress &&
                    addStopLat &&
                    addStopLng ? false : true
                }
                colorType={
                  addStopName &&
                    addStopStartDate &&
                    addStopStartTime &&
                    addStopAddress &&
                    addStopLat &&
                    addStopLng ? "primary" : "base"
                }
                onClick={async () => {
                  const success = await addCustomStop(shift_id);
                  success && refreshFoo();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStop;
