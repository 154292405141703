/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import Company from "./Company";
import { ImSpinner2 } from 'react-icons/im'
import { FavoriteCompanyContext } from "../../../../Context/FavoriteCompanyContext";
import SearchInput from "../../../../Components/Input/SearchInput";
import ChipWithStar from "../../../../Components/Chip/ChipWithStar";
import EmptyMessage from "../../../../Components/EmptyMessage";
import CommonButton from "../../../../Components/Button/CommonButton";
import { useParams } from "react-router-dom";

const AddFavCompany = ({ userType, UID }) => {
  const {
    setIsAddingFavCompany,
    notFavoriteCompany,
    addFavoriteCompany,
    setFavCompanyDetails,
    setSelectedIndex,
    setSelectedFavIndex,
    selectedFavIndex,
    searchKeyNotFav,
    getNotFavoriteCompany,
    isCompanySearching,
    isFavorable,
    isOneNStar,
    isTwoNStar,
    isThreeNStar,
    isFourNStar,
    isFiveNStar,

    setIsOneNStar,
    setIsTwoNStar,
    setIsThreeNStar,
    setIsFourNStar,
    setIsFiveNStar,
    setSearchKeyNotFav
  } = useContext(FavoriteCompanyContext);


  const { customer_id } = useParams();
  const { driver_id } = useParams();

  const [userID, setUserID] = useState(0);

  useEffect(() => {
    if (userType === "customer") {
      setUserID(parseInt(customer_id))
    } else if (userType === "driver") {
      setUserID(parseInt(driver_id));
    }
  }, [userType])

  // reset start selection
  useEffect(() => {
    setIsOneNStar(false)
    setIsTwoNStar(false)
    setIsThreeNStar(false)
    setIsFourNStar(false)
    setIsFiveNStar(false)
  }, [])

  useEffect(() => {
    let stars = [];
    isOneNStar && stars.push(1);
    isTwoNStar && stars.push(2);
    isThreeNStar && stars.push(3);
    isFourNStar && stars.push(4);
    isFiveNStar && stars.push(5);
    console.log('text, star: ', searchKeyNotFav, stars);



    const timer = setTimeout(() => getNotFavoriteCompany(searchKeyNotFav, stars, userID), 500)

    return () => clearTimeout(timer)
  }, [searchKeyNotFav, isOneNStar, isTwoNStar, isThreeNStar, isFourNStar, isFiveNStar])



  return (
    <>
      <div>

        <div className="pb-5 font-bold text-center text-gray-700 text-fs24"> {t("Add Company")} </div>
        <div>
          <div className="relative px-5">
            <SearchInput
              placeholder={t("Search Company")}
              type="search"
              onChange={(e) => setSearchKeyNotFav(e.target.value)}
              name="searchKey"
              value={searchKeyNotFav}
            />
            {isCompanySearching ? <div className="absolute top-2 right-8 pl-3 border-l">
              <ImSpinner2 className="animate-spin duration-150 text-gray-500 border-gray-400 w-5 h-[60%]" />
            </div> : <></>}
          </div>

          {/* start chips */}
          <div className="flex justify-around items-center pt-5">
            <ChipWithStar
              rate={1}
              onClick={() => setIsOneNStar(!isOneNStar)}
              selected={isOneNStar}
            />

            <ChipWithStar
              rate={2}
              onClick={() => setIsTwoNStar(!isTwoNStar)}
              selected={isTwoNStar}
            />

            <ChipWithStar
              rate={3}
              onClick={() => setIsThreeNStar(!isThreeNStar)}
              selected={isThreeNStar}
            />

            <ChipWithStar
              rate={4}
              onClick={() => setIsFourNStar(!isFourNStar)}
              selected={isFourNStar}
            />

            <ChipWithStar
              rate={5}
              onClick={() => setIsFiveNStar(!isFiveNStar)}
              selected={isFiveNStar}
            />

          </div>

          {/* company list */}
          <div className="max-h-[45vh] overflow-y-auto mt-5">
            {notFavoriteCompany.length ? (
              notFavoriteCompany.map((item) => (
                <Company data={item} key={item.id} isFav={false} />
              ))
            ) : (
              <EmptyMessage />
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-center pt-5 w-full">
        <CommonButton
          btnLabel={isFavorable ? t("Add") : "Remove"}
          onClick={() => {
            console.log("UID :::::: ", UID);
            addFavoriteCompany(UID);
            setFavCompanyDetails({});
            setIsAddingFavCompany(false);
            setSelectedIndex(0);
            setSelectedFavIndex(0);
          }}
          isDisabled={!selectedFavIndex}
          colorType={selectedFavIndex ? "primary" : "base"}
        />
      </div>

    </>
  );
};

export default AddFavCompany;



