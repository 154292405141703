import React from "react";
import StarIcon from "../../Images/icons/rating_star.svg";
import StarIconAlt from "../../Images/icons/rating_star2.svg";
import TikIcon from "../../Images/icons/tik-icon.svg";

const ChipWithStar = ({ rate = 1, selected = false, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={` ${
        selected ? "bg-[#F89818] w-[100px]" : "bg-white w-[85px]"
      } flex items-center justify-center rounded-full border-[1px] border-[#E9E9E9] h-[30px] cursor-pointer select-none`}
    >
      {selected ? (
        <img className="w-[16px] h-[16px]" src={StarIconAlt} alt="" />
      ) : (
        <img className="w-[16px] h-[16px]" src={StarIcon} alt="" />
      )}

      <div
        className={`${
          selected ? "text-white px-2" : "text-[#828282] pl-2"
        } text-xs font-normal`}
      >
        {rate + " Star"}
      </div>
      {selected && <img className="w-[16px] h-[16px]" src={TikIcon} alt="" />}
    </div>
  );
};

export default ChipWithStar;
