import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLicenseStore, { getAllLicense } from "../../../App/stores/LicenseStore";
import CommonButtonOutlined from "../../../Components/Button/CommonButtonOutlined";
import TitleBar from "../../../Components/Common/TitleBar";
import { PageTitle } from "../../../Utility/UtilityFunctions";
import AddPackage from "./AddPackage";
import PackageGrid from "./PackageGrid";
import UpdatePackage from "./UpdatePackage";

function Package({ data }) {
    const { allLicenseList, showUpdateLicense, setShowUpdateLicense } = useLicenseStore();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle("Limadi | Packages");

        getAllLicense();
    }, []);
    return (
        <div>
            <TitleBar
                label={t("License Package")}
                count={allLicenseList?.length}
                onClick={() => getAllLicense()}
                rightCornerComponent={
                    <>
                        <CommonButtonOutlined btnLabel={t("Add License")} onClick={() => setShowUpdateLicense(false)} />
                    </>
                }
            />

            <div className="justify-between items-start space-x-0 md:flex md:space-x-10">
                {/* All package  */}
                <PackageGrid />
                {/* Add New Banner */}
                {showUpdateLicense ? <UpdatePackage /> : <AddPackage />}
            </div>
        </div>
    );
}
export default Package