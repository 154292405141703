import React, { } from 'react'
import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux'
// import { handleSetPackages } from '../../../App/Slice/PackageSlice'
import useLicenseStore from '../../../App/stores/LicenseStore'
import PackageItem from './PackageItem'

function PackageGrid() {
  const { allLicenseList } = useLicenseStore();
  const { t } = useTranslation();

  return (
    <>
      <div className='w-full'>
        <div className='grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 gap-8'>
          {allLicenseList?.length > 0 ?
            allLicenseList.map((pack, index) => (
              <PackageItem key={index} data={pack} />
            ))
            : t("No data found !")
          }
        </div>
      </div>
    </>
  )
}

export default PackageGrid