import React from "react";
import { useTranslation } from "react-i18next";
import { deleteFAQ } from "../../../App/stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonModal from "../../../Components/Modal/CommonModal";

export const DeleteFAQModal = ({
  show_delete_faq_modal = false,
  setShowDeleteFaqModal,
  item,
}) => {
  const { t } = useTranslation();

  const modalClose = () => {
    setShowDeleteFaqModal(false);
    // setForm({ qus: "", ans: "", type: "" });
  };
  const handleSubmit = () => {
    console.log(item);
    deleteFAQ(item);
    setShowDeleteFaqModal(false);
  }
  return (
    <CommonModal
      showModal={show_delete_faq_modal}
      setShowModal={setShowDeleteFaqModal}
      modalTitle={t("confirmation")}
      mainContent={
        <div className="bg-white">

          <div className="h-auto">
            <div className="py-5 text-center">
              {t("Are you sure you want to delete this FAQ?")}
            </div>
            <div className="flex flex-row justify-between">
              <CommonButton btnLabel={t("cancel")} onClick={() => modalClose()} />

              <CommonButton colorType="danger" type="submit" btnLabel={t("delete")} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      }
    />
  );
};
