import { Tooltip } from 'antd'
import React from 'react'

import useLicenseStore from '../../App/stores/LicenseStore'
import DeleteIcon from "../../Images/icons/Delete.png"
import EditIcon from "../../Images/icons/Edit.png"

const PackageItemButton = ({ onClick, type, onChange, name, label, deletePackage, id }) => {
  const { setShowDeleteLicense, setLicensePackID, setShowUpdateLicense } = useLicenseStore();

  return (
    <div className="flex items-center space-x-5">
      <span>
        <button onClick={() => {
          setShowUpdateLicense(true);
          setLicensePackID(id);
        }}>
          <Tooltip title="Edit this package" >
            <img src={EditIcon} alt="" />
          </Tooltip>
        </button>
      </span>

      <span>
        <button onClick={() => {
          setShowDeleteLicense(true);
          setLicensePackID(id);
        }}>
          <Tooltip title="Delete this package" >
            <img src={DeleteIcon} alt="" />
          </Tooltip>
        </button>
      </span>
    </div>
  )
}

export default PackageItemButton
