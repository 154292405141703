import { t } from 'i18next';
import { useContext } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

const ConfirmPauseShift = ({company_id}) => {
  const { setShowPauseShiftModal, takeShiftAction } = useContext(TrackOnlineContext);

  return (
    <div className="">
      <div className="relative">
        <AiFillCloseCircle
          onClick={() => setShowPauseShiftModal(false)}
          className="absolute top-0 right-0 text-2xl cursor-pointer text-gray-600"
        />
        <div className="p-5 text-center font-bold text-xl">{t("Confirmation")}</div>
        <div className="pb-5 text-center">
          {t("Are you sure you want to pause the shift?")}
        </div>

        <div className="flex flex-row justify-center">
          <CommonButton
            btnLabel={t("Yes")}            
            onClick={() => {
              takeShiftAction("break", company_id);
              setShowPauseShiftModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPauseShift;
