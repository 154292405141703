import { t } from 'i18next';
import axios from 'axios';
import create from 'zustand'
import { Toastr,  } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';


const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useCarStore = create((set, get) => ({

    shiftRouteList: [],
    setShiftRouteList: (value) => set({ shiftRouteList: value }),

    carIndex: "",
    setCarIndex: (value) => set({ carIndex: value }),

    showAcceptModal: false,
    setShowAcceptModal: (value) => set({ showAcceptModal: value }),

    customStopModalData: {},
    setCustomStopModalData: (value) => set({ customStopModalData: value }),

    showCarRejectModal: false,
    setShowCarRejectModal: (value) => set({ showCarRejectModal: value }),

}))

//! api related functions


// export const getShiftRouteList = async (shift_id, withLoading = true) => {
//     const { setShiftRouteList } = useShiftStore.getState();
//     if (withLoading) setLoading(true);
//     try {
//         const res = await axios.get(kuGetShiftRouteList, { params: { shift_id: shift_id } });
//         console.log('getShiftRouteList: ', res.data);

//         if (res.data.success) {
//             setShiftRouteList(res?.data?.data);
//         }
//         else {
//             Toastr({ message: res?.data?.message });
//         }
//         if (withLoading) setLoading(false)
//         return true;
//     } catch (err) {
//         Toastr(({ message: t("An error occurred!") }))
//         if (withLoading) setLoading(false)
//         console.log('getShiftRouteList: ', err)
//         return false;
//     }
// }


export default useCarStore;