import { createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios'
import { kuAddBanner, kuDeleteBanner, kuGetBanners, kuUpdateBanner } from '../../Utility/url'
import { Toastr, ToastrLoading } from '../../Utility/UtilityFunctions'


const initialState = {
    banners: [],
    form: {
        id: null,
        image: '',
        title: '',
        start_date: null,
        end_date: null,
        app_type: ''
    },
    isLoading: false,
    isUpdate: false,
    isImageChanged: false
}
export const bannerSlice = createSlice({
    name: 'banners',
    initialState,
    reducers: {
        changeValue:(state, action) => {
            const {name, value} = action.payload
            // console.log('name: ', name);
            // console.log('value: ', value);      
            state.form[name] = value
        },
        setBanners:(state, action) => {
            state.banners = action.payload            
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        deleteBanner: (state, action) => {
            state.banners = action.payload
        },
        setIsUpdate: (state, action) => {
            const {bannerId, isUpdate} = action.payload
            console.log('bannerID: ', bannerId);
            console.log('isUpdate: ', isUpdate);

            // state update
            state.isUpdate = isUpdate;

            if (bannerId) {
                const tempBanner = state.banners.find((banner) => banner.id === bannerId)
                console.log(current(tempBanner));

                state.form.id = tempBanner.id
                state.form.title = tempBanner.title
                state.form.start_date = tempBanner.start_date
                state.form.end_date = tempBanner.end_date
                state.form.app_type = tempBanner.app_type
                state.form.image = tempBanner.image
            } else {
                state.form.id = null
                state.form.title = ''
                state.form.start_date = null
                state.form.end_date = null
                state.form.app_type = '' 
                state.form.image = '' 
            }            
        },
        resetForm: (state) => {
            state.form.id = null
            state.form.title = ''
            state.form.start_date = null
            state.form.end_date = null
            state.form.app_type = '' 
            state.form.image = ''
        },
        setIsImageChanged: (state, action) => {
            state.isImageChanged = action.payload
        }
        
    },
})

// Get Banners
export const handleSetBanners = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetBanners)
        console.log('handleSetBanners: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setBanners(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Delete Banner
export const handleDeleteBanner = (state, id) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
        const body = { id: id }
        dispatch(setLoading(true))
        const res = await axios.post(kuDeleteBanner, body)

        console.log('handleDeleteBanner: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetBanners())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Add Banner
export const handleAddBanner = (state) => async (dispatch) => {
    if (state.form.title.length === 0) {
        Toastr('Title is required')
        return
    }

    if (state.form.start_date === null) {
        Toastr('Start date is required')
        return
    }

    if (state.form.end_date === null) {
        Toastr('End date is required')
        return
    }

    if (state.form.app_type === '') {
        Toastr('Select for is required')
        return
    }

    let toastr = ToastrLoading()

    try {        
        const body = { title: state.form.title, start_date: state.form.start_date, end_date: state.form.end_date, app_type: state.form.app_type, image: state.form.image }
        dispatch(setLoading(true))
        const res = await axios.post(kuAddBanner, body)

        console.log('handleAddBanner: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetBanners())
            dispatch(resetForm())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Update Banner
export const handleUpdateBanner = (state) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
        if (!state.isImageChanged) {       
            const body = { id: state.form.id, title: state.form.title, start_date: state.form.start_date, end_date: state.form.end_date, app_type: state.form.app_type}

            dispatch(setLoading(true))
            const res = await axios.post(kuUpdateBanner, body)

            console.log('handleUpdateBanner: ', res.data);
            if (res.data.success) {
                ToastrLoading(res.data.message, 'stop', 'success', toastr);
                dispatch(handleSetBanners())
            } else {
                ToastrLoading(res.data.message, 'stop', 'error', toastr);
            }
            dispatch(setLoading(false))
        } else {          
            const body = { id: state.form.id, title: state.form.title, start_date: state.form.start_date, end_date: state.form.end_date, app_type: state.form.app_type, image: state.form.image,}

            dispatch(setLoading(true))
            const res = await axios.post(kuUpdateBanner, body)

            console.log('handleUpdateBanner: ', res.data);
            if (res.data.success) {
                ToastrLoading(res.data.message, 'stop', 'success', toastr);
                dispatch(handleSetBanners())
            } else {
                ToastrLoading(res.data.message, 'stop', 'error', toastr);
            }
            dispatch(setLoading(false))
        }
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}


export const { changeValue, setBanners, setLoading, deleteBanner, setIsUpdate, resetForm, setIsImageChanged } = bannerSlice.actions;
export default bannerSlice.reducer