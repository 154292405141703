import axios from "axios";
import { t } from "i18next";
import { createContext, useState } from "react";
import useGeneralStore from "../App/stores/GeneralStore";
import { kuGetNotification, kuNotificationSeen } from "../Utility/url";
import { Toastr } from "../Utility/UtilityFunctions";
// import { faDetailsType } from "../Utility/action";

export const NotificationContext = createContext();

const NotificationContextProvider = (props) => {
  const [notification, setNotification] = useState([]);
  const [notificationTmp, setNotificationTmp] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [isLoadingNotification, setIsLoadingNotification] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const { setLoading } = useGeneralStore()

  /*
    |--------------------------------------------------------------------------
    | API Related Functions
    |--------------------------------------------------------------------------
    */
  const getNotification = async (withLoading = true) => {
    try {
      if (withLoading) setLoading(true);
      setIsLoadingNotification(true);
      const res = await axios.get(kuGetNotification);
      console.log("getNotification: ", res.data);

      if (res.data.success) {
        setNotification(res.data.data);
        setNotificationTmp(res.data.data);
        if (res.data.data.length > 0) {
          setSelectedNotification(res.data.data[0]);
          checkUnseenNotification(res.data.data);
        }
      } else {
        Toastr(res.data.message);
      }
      setIsLoadingNotification(false);
      setLoading(false);
    } catch (err) {
      console.log("getNotification: ", err);
      Toastr(t("An error occurred!"));
      setIsLoadingNotification(false);
      setLoading(false);
    }
  };

  const notificationSeenFoo = async (id, index) => {
    try {
      const body = { id: id };
      console.log("notificationSeenFoo:", body);
      const res = await axios.post(kuNotificationSeen, body);
      console.log('notificationSeenFoo: ', res.data);
      if (res.data.success) {
        let x = localStorage.getItem('numOfUnreadNotification').toString();
        let y = parseInt(x);
        localStorage.setItem("numOfUnreadNotification", --y);
        useGeneralStore.getState().setHasUnseenNotification();
      } else {
        Toastr(res.data.message);
        notification[index].is_seen = 0;
      }
    } catch (err) {
      console.log("notificationSeenFoo:", err);
      Toastr(t("An error occurred!"));
    }
  };

  /*
    |--------------------------------------------------------------------------
    | Helper Functions 
    |--------------------------------------------------------------------------
    */

  const searchNotifications = (event) => {
    setSearchKey(event.target.value);
    // eslint-disable-next-line array-callback-return
    const result = notificationTmp.filter((item) => {
      if (item.title) {
        let title = "";
        title = item.title ?? "";
        if (
          title.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.description.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          return item;
        }
      }
    });
    console.log("result: ", result);
    setNotification(result);
  };

  const selectNotification = (index, is_seen) => {
    setSelectedIndex(index);
    setSelectedNotification(notification[index]);
    notification[index].is_seen = 1;
    (is_seen === 0) && notificationSeenFoo(notification[index].id, index);
  };

  const checkUnseenNotification = (notifications) => {
    let count = 0;
    for (let index = 0; index < notifications.length; index++) {
      if (notifications[index].is_seen === 0) count++;
    }
    localStorage.setItem("numOfUnreadNotification", count);
    useGeneralStore.getState().setHasUnseenNotification();
  }

  // sharing states
  return (
    <NotificationContext.Provider
      value={{
        // states
        selectedIndex,
        isLoadingNotification,
        selectedNotification,
        notification,
        searchKey,
        notificationTmp,

        // actions
        selectNotification,
        getNotification,
        setSearchKey,
        setNotificationTmp,
        setIsLoadingNotification,

        // helper functions
        searchNotifications,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
