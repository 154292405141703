import { t } from 'i18next';
import { useContext } from "react";
import SearchBoxSolid from '../../../../../../Components/Input/SearchBoxSolid';
import ListSkeleton01 from '../../../../../../Components/Skeletons/ListSkeleton01';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import PackageItem from "./PackageItem";

const PackageList = () => {
  const { isLoading } = useContext(ShiftPlannerContext);
  const {
    PackageList,
    searchPackage,
    searchPackageKey,
    setSelectedPackage,
    setSelectedPackageRequest,
  } = useContext(TrackOnlineContext);

  return (
    <div className="col-span-12 lg:col-span-4 mt-5">
      <div className="bg-white shadow rounded-xl flex-col justify-start pb-5">
        {/* search - bar */}
        <div className="flex justify-between items-center px-5 pt-5 pb-s10">
          <div className="w-full ">
            <SearchBoxSolid
              placeholder={t("search")}
              type="search"
              onChange={(e) => {
                searchPackage(e);
                setSelectedPackage(null);
                setSelectedPackageRequest(null);
              }}
              name="searchKey"
              value={searchPackageKey}
            />
          </div>
        </div>
        <ul className="overflow-y-auto max-h-[60vh] scroll-smooth">
          {isLoading ? (
            <ListSkeleton01 />
          ) : PackageList?.length ? (
            PackageList.map((item, index) => (
              <div
                key={index + 99}
                className="mt-[10px] pr-5 cursor-pointer select-none"
              >
                <PackageItem item={item} />
              </div>
            ))
          ) : (
            <div className="text-center mx-auto font-semibold gs-text-placeholder italic">
              {t("No Results Available")}
            </div>
          )}

          {/* <div className="mb-3"></div> */}
        </ul>
      </div>
    </div>
  );
};

export default PackageList;
