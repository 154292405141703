import { t } from 'i18next';
import { useContext } from "react";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import SearchBoxSolid from '../../../../../../Components/Input/SearchBoxSolid';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import ListSkeleton01 from '../../../../../../Components/Skeletons/ListSkeleton01';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iFilter } from '../../../../../../Utility/Sources';
import Track from "../Track";

const TrackList = () => {
  const {
    searchTrack,
    searchKey,
    showFilterModal,
    setShowFilterModal,
    currentFilterSelection,
    setCurrentFilterSelection,
    filterTrack,
    trackList,
    isLoading,
    currentSelection,
    setCurrentSelection,
  } = useContext(TrackOnlineContext);

  const submit_req = () => {
    filterTrack();
    setShowFilterModal(false);
  };



  return (
    <div className="col-span-12 lg:col-span-4 mt-5 ">
      <div className="bg-white shadow rounded-xl flex-col justify-start">
        {/* search-bar */}
        <div className="flex justify-between items-center px-5 pt-5 pb-s10">
          <div className="w-full pr-2">
            <SearchBoxSolid
              placeholder={t("search")}
              type="search"
              onChange={(e) => {
                searchTrack(e);
                setCurrentSelection(null);
                // setIsShiftDetailsData(null);
              }}
              name="searchKey"
              value={searchKey}
            />
          </div>
          <img
            className="select-none cursor-pointer"
            onClick={() => {
              // console.log("Filter clicked !");
              setShowFilterModal(true);
            }}
            src={iFilter}
            alt=""
          />
        </div>

        <CommonModal
          showModal={showFilterModal}
          setShowModal={setShowFilterModal}
          mainContent={
            <div>
              <div className="text-center pb-5 text-xl font-bold">
                {t("Select Status")}
              </div>
              <div>
                <div
                  key={1}
                  onClick={() => {
                    setCurrentFilterSelection(-1);
                  }}
                  className={`${currentFilterSelection === -1 && "ring-1 ring-cSecondary"
                    } border border-transparent flex justify-between items-center m-[2px] py-3 px-4 mb-[20px] cursor-pointer rounded-lg bg-cListItem text-gray-600 font-medium`}
                >
                  {t("Not Started")}
                  <input
                    id="1"
                    className="w-4 h-4 mr-5 gs-green-checkbox"
                    type={"radio"}
                    checked={currentFilterSelection === -1}
                    onChange={() => {
                      setCurrentFilterSelection(-1);
                    }}
                  />
                  {/* {isExpressType ? (<Checkbox checked /> ): (<Checkbox />)} */}
                </div>
                <div
                  key={2}
                  onClick={() => {
                    setCurrentFilterSelection(1);
                  }}
                  className={`${currentFilterSelection === 1 && "ring-1 ring-cSecondary"
                    } border border-transparent flex justify-between items-center m-[2px] py-3 px-4 mb-[20px] cursor-pointer rounded-lg bg-cListItem text-gray-600 font-medium`}
                >
                  {t("Started")}
                  <input
                    id="2"
                    className="w-4 h-4 mr-5 gs-green-checkbox"
                    type={"radio"}
                    checked={currentFilterSelection === 1}
                    onChange={() => {
                      setCurrentFilterSelection(1);
                    }}
                  />
                </div>

                <div
                  key={3}
                  onClick={() => {
                    setCurrentFilterSelection(-2);
                  }}
                  className={`${currentFilterSelection === -2 && "ring-1 ring-cSecondary"
                    } border border-transparent flex justify-between items-center m-[2px] py-3 px-4 mb-[20px] cursor-pointer rounded-lg bg-cListItem text-gray-600 font-medium`}
                >
                  {t("In Maintenance")}
                  <input
                    id="2"
                    className="w-4 h-4 mr-5 gs-green-checkbox"
                    type={"radio"}
                    checked={currentFilterSelection === -2}
                    onChange={() => {
                      setCurrentFilterSelection(-2);
                    }}
                  />
                </div>
              </div>

              <div className="w-full flex justify-center">
                <CommonButton
                  btnLabel={t("Submit")}
                  onClick={submit_req}
                  isDisabled={currentFilterSelection  ? false : true}
                  colorType={currentFilterSelection ? "primary" : "base"}
                />
              </div>
              {/* </div> */}
            </div>
          }
        />
        <ul className="overflow-y-auto max-h-[60vh] scroll-smooth">
          {isLoading ? (
            <ListSkeleton01 />
          ) : trackList.length ? (
            trackList.map((item, index) => (
              <Track
                key={index}
                data={item}
                isSelected={currentSelection === item.shift_id}
              />
            ))
          ) : (
            <div className="text-center mx-auto font-semibold gs-text-placeholder italic ">
              {t("No Results Available")}
            </div>
          )}

          <div className="mb-3"></div>
        </ul>
      </div>
    </div>
  );
};

export default TrackList;
