import { useEffect, useState } from 'react';
import useCompanyStore, { addNewCompany } from '../../../../App/stores/CompanyStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { CheckEmailValidity, checkValidPassword, FullFormDataValidation, Toastr } from '../../../../Utility/UtilityFunctions';

const AddCompany = () => {

    const { showAddCompanyModal, setShowAddCompanyModal } = useCompanyStore();

    const [addCompanyFormData, setAddCompanyFormData] = useState({
        cvr: '',
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'company'     //company role is always: company
    });


    const handleAddCustomer = async (e) => {
        e.preventDefault();
        if (addCompanyFormData?.password.length < 8 && addCompanyFormData.password_confirmation.length < 8)
            return Toastr('Password must be 8 character long', "warning");
        if (addCompanyFormData?.cvr?.toString().length < 8) return Toastr('CVR must be 8 digits!', "warning");
        if (!CheckEmailValidity(addCompanyFormData?.email)) return Toastr('Enter a valid email address!', "warning");
        if (addCompanyFormData?.password !== addCompanyFormData.password_confirmation) return Toastr('Both password fields should be same !', "warning");
        if (checkValidPassword(addCompanyFormData?.password).status === false) return Toastr(checkValidPassword(addCompanyFormData?.password).message, "warning");
        if (FullFormDataValidation(addCompanyFormData)) {
            let addSuccess = await addNewCompany(addCompanyFormData);
            if (addSuccess) {
                setShowAddCompanyModal(false);
                setAddCompanyFormData({
                    cvr: '',
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    role: 'company'
                });
            }
        }
        else Toastr('Need to fill up all required fields with valid data!', "warning");
    }

    return (
        <CommonModal
            showModal={showAddCompanyModal}
            setShowModal={setShowAddCompanyModal}
            modalTitle="Add Company"
            mainContent={
                <form onSubmit={handleAddCustomer}>

                    <div className="pt-5"></div>
                    <CommonInput
                        unnecessaryCharacters={true}
                        withStar={false}
                        value={addCompanyFormData?.cvr}
                        required={true}
                        onChange={(e) => {
                            if(e.target.value !== "+"){
                                setAddCompanyFormData({ ...addCompanyFormData, cvr: e.target.value })
                            }
                        }}
                        min_number={0}
                        label={"CVR"}
                        max_input={8}
                        type="number"
                        placeholder="Enter company CVR"
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        withStar={false}
                        value={addCompanyFormData?.name}
                        required={true}
                        onChange={(e) => setAddCompanyFormData({ ...addCompanyFormData, name: e.target.value })}
                        label={"Name"}
                        min_input={5}
                        placeholder="Enter company name"
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        withStar={false}
                        value={addCompanyFormData?.email}
                        onChange={(e) => setAddCompanyFormData({ ...addCompanyFormData, email: e.target.value })}
                        required={true}
                        label={"Email"}
                        type="email"
                        placeholder="Enter company email"
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        withStar={false}
                        type='password'
                        togglePasswordBtn={true}
                        value={addCompanyFormData?.password}
                        onChange={(e) => setAddCompanyFormData({ ...addCompanyFormData, password: e.target.value })}
                        required={true}
                        placeholder="Enter company password"
                        label={"password"}
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        withStar={false}
                        type='password'
                        togglePasswordBtn={true}
                        value={addCompanyFormData?.password_confirmation}
                        onChange={(e) => setAddCompanyFormData({ ...addCompanyFormData, password_confirmation: e.target.value })}
                        required={true}
                        placeholder="Re-enter company password"
                        label={"confirm password"}
                    />

                    <div className='flex justify-center mt-s20'><CommonButton type='submit' btnLabel='Save' /></div>

                </form>
            }
        />
    )
}

export default AddCompany