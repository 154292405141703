import { t } from 'i18next';
import { useContext } from "react";
import CommonButton from '../../../../../../Components/Button/CommonButton';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

const ConfirmStopShift = ({company_id}) => {
  const { setShowStopShiftModal, takeShiftAction } =
    useContext(TrackOnlineContext);
  return (
    <div className="">
      <div className="relative">

        <div className="pb-5 text-center font-bold text-xl">{t("Confirmation")}</div>
        
        <div className="pb-5 text-center text-fs16">
          {t("Are you sure you want to stop the shift?")}
        </div>

        <div className="flex flex-row justify-center">
          <CommonButton
            btnLabel={t("Yes")}
            onClick={() => {
              // setShiftAction('complete');
              takeShiftAction('complete', company_id);
              setShowStopShiftModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmStopShift;
