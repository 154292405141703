import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

function CircularProgressBar() {    

    return (
        <div><CircularProgress
            color='inherit'
            size={20}
            sx={{
                color: (theme) => (theme.palette.mode === 'light' ? '#F89818' : '#F89818'),
            }}
        /></div>
    )
}

export default CircularProgressBar