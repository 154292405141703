import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { kuGetAllCompanies, } from '../../Utility/url';
import { ToastrLoading } from '../../Utility/UtilityFunctions';

const initialState = {
    isLoading: true,
    companies: [],
    tempCompanies: [],
    licenseData: { id: null, status: '', license_start_date: '', license_end_date: '' }
}

/*
|--------------------------------------------------------------------------
| managing state of license application
|--------------------------------------------------------------------------
|
*/
export const ManageCompaniesSlice = createSlice({
    name: 'manageCompanies',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            console.log('setLoading: ' + action.payload);
            state.isLoading = action.payload
        },
        setAllCompanies: (state, action) => {
            state.companies = action.payload
            state.tempCompanies = action.payload
        },
        changeValue: (state, action) => {
            // const { name, value } = action.payload;
            // state.licenseData[name] = value;
        },
    },
})

/*
|--------------------------------------------------------------------------
| api related functions
|--------------------------------------------------------------------------
|
*/

export const handleSetAllCompanies = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    try {
        dispatch(setLoading(true))
        const res = await axios.get(kuGetAllCompanies + '?take=1000');
        console.log('handleSetAllCompanies: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setAllCompanies(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (err) {
        console.log(err);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// export const handleUpdateLicenseStatus = (state, type) => async (dispatch) => {
//     let toastr = ToastrLoading()

//     try {
//         const body = state.licenseData;
//         console.log(body);
//         // return;

//         const res = await axios.post(kuLicenseAction, body);
//         console.log(res.data);

//         if (res.data.success) {
//             ToastrLoading(res.data.message, 'stop', 'success', toastr);
//             await dispatch(handleSetAllCompanies(state))
//         } else {
//             ToastrLoading(res.data.message, 'stop', 'error', toastr);
//         }
//     } catch (err) {
//         console.log(err);
//         ToastrLoading('An error occurred!', 'stop', 'success', toastr);
//     }
// }

export const { setLoading, setAllCompanies, changeValue } = ManageCompaniesSlice.actions
export default ManageCompaniesSlice.reducer