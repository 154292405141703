import { t } from 'i18next';
import { iMaintenanceRed } from '../../../../../../Utility/Sources';

export default function ShiftInMaintenanceInfo() {
  return (
    <>
      <div className="flex justify-center items-center pt-5">
        <img src={iMaintenanceRed} alt="" />
        <span className="pl-2 font-bold italic text-sm text-cRed">
          {t("In Maintenance")}
        </span>
      </div>
    </>
  )
}
