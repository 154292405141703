
export const k_data_set = {
    all_requests: 'all',
    saved: 'saved',
    invitation: 'invitations',
    in_bidding: 'in_bidding',
    not_planned: 'not_planned',
    awarded: 'awarded',
    awarded_admin: 'awarded_admin',
    ongoing: 'ongoing',
    completed: 'complete',
    history: 'history',
    global: 'global',
  }

  /** customer, company, sa */
export const role = {
  customer: "private",
  company: "company",
  admin: "sa",
  driver:"driver"
}

// environment
export const environment = process.env.REACT_APP_ENVIRONMENT;

// pusherConfig.js
export const chatAppId = process.env.REACT_APP_CHAT_APP_ID;
export const chatKey = process.env.REACT_APP_CHAT_KEY;
export const chatSecret = process.env.REACT_APP_CHAT_SECRET;
export const chatCluster = process.env.REACT_APP_CHAT_CLUSTER;

// admin access urls
export const devUrl = process.env.REACT_APP_DEV_URL;
export const stgUrl = process.env.REACT_APP_STG_URL;
export const qaUrl = process.env.REACT_APP_QA_URL;
export const prodUrl = process.env.REACT_APP_PROD_URL;