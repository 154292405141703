import React, { useContext } from "react";
import CommonImage from "../../../../Components/Image/CommonImage";
import { FavoriteCompanyContext } from "../../../../Context/FavoriteCompanyContext";
import { iDummyCompany, iHeartIcon, iRightArrow, iStarIcon } from "../../../../Utility/Sources";
import { BaseUrlSrc } from "../../../../Utility/url";
import RatingFiveStar from "./RatingFiveStar";

const Company = ({ data, isFav = true, selected = true,onClick }) => {
  const {
    selectedIndex,
    setSelectedIndex,
    setSelectedFavIndex,
    getCompanyDetails
  } = useContext(FavoriteCompanyContext);
  return (
    <div className="relative">
      {selectedIndex === data.id && (
        <div className="absolute h-full w-[5px] top-0 left-0 border-l-4 border-gray-500"></div>
      )}
      <div
        className="flex justify-between items-center my-2 py-2 px-2 mx-5 bg-[#F8F8F8] rounded-[5px] cursor-pointer select-none"
        onClick={async() => {
          await setSelectedIndex(data.id);
          getCompanyDetails(data.id)
          setSelectedFavIndex(data.id);
        }}
      >
        <div className="flex items-center">
          <div className="rounded-full overflow-hidden  max-w-[45px] min-w-[45px] h-[45px] bg-[#DFDFDF] flex justify-center items-center ">
            {/* {data.image ? (
              <img
                className="object-cover w-full h-full"
                src={BaseUrlSrc + data.image}
                alt="company-img"
              />
            ) : (
              <img
                className="w-[24px] h-[24px]"
                src={iDummyCompany}
                alt="company-img"
              />
            )} */}
            <CommonImage src={data.image} dummyImage={iDummyCompany}  className="rounded-full max-w-[45px] min-w-[45px] h-[45px]"/>
          </div>
       
          <div className="flex flex-col text-left ml-3 text-[14px]">
            <div className="font-semibold">
              {data.name.toString().length > 40 ? (data.name.substring(0, 40) + '...') : data.name}
              {/* {"  .....->  "}{data.id} */}
            </div>
            <div className="flex items-start pt-1 font-normal">
              <RatingFiveStar rating={parseFloat(data?.rate?.toFixed(1))} />
              <span className="pl-1 pr-5">{parseFloat(data.rate?.toFixed(1))}</span>
              {isFav && (
                <span>
                  <img src={iHeartIcon} alt="" />
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="">
          {isFav ? (
            <img src={iRightArrow} alt="" />
          ) : (
            <input type="radio" checked={selectedIndex === data.id} onChange={() => { }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Company;
