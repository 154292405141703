import { t } from 'i18next';
import axios from "axios";
import {
  kuAddDriver,
  kuDeleteDriver,
  kuDriverList,
  kuEditDriver,
} from "../Utility/url";
import { createContext, useState } from 'react';
import useGeneralStore from '../App/stores/GeneralStore';
import { Toastr, validateEmail } from '../Utility/UtilityFunctions';

export const DriverManagementContext = createContext();

const DriverManagementContextProvider = (props) => {
  const [addDriver, setAddDriver] = useState({
    email: "",
    phone: "",
    comment: "",
  });
  const [updateDriver, setUpdateDriver] = useState({
    email: "",
    phone: "",
    comment: "",
  });
  const [detailsState, setDetailsState] = useState("add");
  const [driver, setDriver] = useState([]);
  const [tempDriver, setTempDriver] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [canUpdateSubmit, setCanUpdateSubmit] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedDriverUID, setSelectedDriverUID] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { setLoading } = useGeneralStore()

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */

  const selectDriver = (index) => {
    if(index === null){
      setSelectedIndex(null);

      updateDriver.email = "";
      updateDriver.name = "";
      updateDriver.phone = "";
      updateDriver.comment = "";
    }else{
      setSelectedIndex(index);
      setSelectedDriver(driver[index]);
      // updateDetailsState("update");
      // console.log("driver",driver)
  
      updateDriver.email = driver[index].email;
      updateDriver.name = driver[index].name;
      updateDriver.phone = driver[index].phone_from_company;
      updateDriver.comment = driver[index].comment;
    }

  };

  // get all drivers
  const getDriverInvitation = async (u_id) => {
    try {
      setLoading(true);
      let res = {}

      if (u_id) res = await axios.get(kuDriverList, { params: { user_id: u_id, } });
      else res = await axios.get(kuDriverList, { params: { user_id: selectedDriverUID, } });

      console.log('GET ALL DRIVER DATA => getDriverInvitation: ', res.data);

      if (res.data.success) {
        setDriver(res?.data?.data);
        setTempDriver(res?.data?.data);
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('getDriverInvitation: ', err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  const addDriverInvitation = async () => {
    try {
      if (!validateEmail(addDriver.email)) {
        Toastr(t("Invalid email address!"), "warning");
        return;
      }

      setLoading(true);
      const body = {
        user_id: selectedDriverUID,
        email: addDriver.email,
        phone: addDriver.phone,
        comment: addDriver.comment,
      };
      const res = await axios.post(kuAddDriver, body);
      console.log('addDriverInvitation: ', res.data);

      if (res.data.success) {
        Toastr("Add Driver successfully", "success")
        setAddDriver({ email: "", phone: "", comment: "" });
        await getDriverInvitation();
      } else {
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log('addDriverInvitation: ', err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  const updateDriverInvitation = async () => {
    try {
      if (!validateEmail(updateDriver.email)) {
        Toastr(t("Invalid email address!"), "warning");
        return;
      }
      setLoading(true);

      const body = {
        id: selectedDriver.id,
        user_id: selectedDriverUID,
        email: updateDriver.email,
        // phone: updateDriver.phone,
        comment: updateDriver.comment,
      };

      //console.log("body",body)

      const res = await axios.post(kuEditDriver, body);
      console.log('updateDriverInvitation', res.data);

      if (res.data.success) {
        Toastr(res.data.message, "success")
        await getDriverInvitation();
      } else {
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  const deleteDriver = async () => {
    try {
      setLoading(true);
      const body = {
        id: selectedDriver.id,
        user_id: selectedDriverUID,
        deleted_by: "company"
      };
      const res = await axios.post(kuDeleteDriver, body);
      console.log('deleteDriver: ', res.data);

      if (res.data.success) {
        Toastr("Delete Driver successfully", "success")
        await getDriverInvitation();
        updateDetailsState("add");
      } else {
        console.log(res.data.message);
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log('deleteDriver: ', err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Helper Functions 
  |--------------------------------------------------------------------------
  */

  const setChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddDriver({ ...addDriver, [name]: value });
  };

  const setUpdateChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUpdateDriver({ ...updateDriver, [name]: value });
  };

  const updateDetailsState = (state) => {
    setDetailsState(state);
    // console.log(state);
  };



  const cancelAddDriver = () => {
    setAddDriver({ email: "", phone: "", comment: "" });
    selectDriver(0);
    updateDetailsState("update");
  };

  const searchDriver = (search) => {
    setSearchKey(search);
    // console.log(event.target.value);    
    setDetailsState("add");
    setSelectedIndex(null);
    // eslint-disable-next-line array-callback-return
    const result = tempDriver.filter((item) => {
      const name = item.name ?? "";
      if (
        name.toLowerCase().includes(search.toLowerCase()) ||
        item.email.toLowerCase().includes(search.toLowerCase())
      ) {
        return item;
      }
    });
    setDriver(result);
  };

  // sharing states
  return (
    <DriverManagementContext.Provider
      value={{
        // states
        addDriver,
        detailsState,
        driver,
        selectedIndex,
        selectedDriver,
        updateDriver,
        isLoading,
        canSubmit,
        searchKey,
        canUpdateSubmit,
        showModal,
        selectedDriverUID,

        // actions
        setSelectedDriverUID,
        setAddDriver,
        setChange,
        setDetailsState,
        updateDetailsState,
        getDriverInvitation,
        setSelectedIndex,
        selectDriver,
        setUpdateDriver,
        setUpdateChange,
        addDriverInvitation,
        setIsLoading,
        cancelAddDriver,
        setCanSubmit,
        updateDriverInvitation,
        deleteDriver,
        searchDriver,
        setCanUpdateSubmit,
        setShowModal,
        setSearchKey,
      }}
    >
      {props.children}
    </DriverManagementContext.Provider>
  );
};

export default DriverManagementContextProvider;
