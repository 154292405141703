/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect } from "react";
import SearchBoxSolid from '../../../../../../Components/Input/SearchBoxSolid';
import ListSkeleton01 from '../../../../../../Components/Skeletons/ListSkeleton01';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { iFilter } from '../../../../../../Utility/Sources';

import { useParams } from "react-router-dom";
import Shift from "../Shift";
import Chip from './Chip';
import LimadiSearchBox from '../../../../../../Components/Input/LimadiSearchBox';
import useDriverStore from '../../../../../../App/stores/DriverStore';

const ShiftPlannerList = () => {
  const {
    searchShift,
    setSearchKey,
    searchKey,
    isLoading,
    shift,
    setIsCompleted,
    setIsDeleted,
    setLicensePlateValue,
    setSelectedDriver,
    setFilterState,
    setShowFilterModal,
    chipData,
    setChipData,
    filterShiftPlannerList,
    isShiftHistory,
    getShiftHistoryList,
    getShiftPlannerList,
    onResetFilterHandler,
    driverShiftSearch, setDriverShiftSearch
  } = useContext(ShiftPlannerContext);
  const { driver_id } = useParams();

  useEffect(() => {
    setIsDeleted(false);
    setIsCompleted(false);
    setLicensePlateValue("");
    setSelectedDriver("");
    setChipData({});
  }, []);

  const closeChip = (key) => {
    const newChipData = { ...chipData };
    delete newChipData[key];
    setChipData(newChipData);
  }

  // useEffect(() => {
  //   // call api with new chip data
  //   let data = {
  //     driver_user_id: driver_id,
  //     start_date: chipData['start_date'] ? chipData['start_date'] : "",
  //     end_date: chipData['end_date'] ? chipData['end_date'] : "",
  //     start_time: chipData['start_time'] ? chipData['start_time'] : "",
  //     end_time: chipData['end_time'] ? chipData['end_time'] : "",
  //     plate_number: chipData['plate_number'] ? chipData['plate_number'] : "",
  //     driver_name: chipData['driver_name'] ? chipData['driver_name'] : "",
  //     status:
  //       (chipData['deleted'] && chipData['completed'])
  //         ? ""
  //         : chipData['deleted']
  //           ? "delete"
  //           : chipData['completed']
  //             ? "complete"
  //             : "",
  //   };

  //   isShiftHistory && (data['type'] = "history");

  //   if (
  //     data?.driver_name !== "" ||
  //     data?.driver_id!== "" ||
  //     data?.end_date !== "" ||
  //     data?.end_time !== "" ||
  //     data?.plate_number !== "" ||
  //     data?.start_date !== "" ||
  //     data?.start_time !== "" ||
  //     data?.status !== ""
  //   ) filterShiftPlannerList(data, true);

  //   console.log(data);
  // }, [chipData])
  const {driverDetails}=useDriverStore();

  return (
    <>
      <div className="">
        <div className="flex-col justify-start pb-5 bg-white shadow rounded-xl">

          {/* hb search box with filter btn setup */}
          <div className="flex items-center justify-between px-5 pt-5 pb-s10">
            <div className="w-full pr-2">
            <LimadiSearchBox
                fullWidth={true}
                placeholder={t("search")}
                type="search"
                withClearSearch={true}
                onSearchClear={() => {
                  setSearchKey("");
                  searchShift("");
                }}
                onChange={(e) => { searchShift(e.target.value) }}
                name="searchKey"
                value={searchKey}
              />
            </div>
            <img
              className="cursor-pointer select-none"
              onClick={() => {
                setShowFilterModal(true);
                setFilterState(true);
              }}
              src={iFilter}
              alt=""
            />
          </div>

          {/* <div className='flex flex-row flex-wrap justify-start mx-5'>
            {
              Object.entries(chipData).map(([key, value], index) =>
                <Chip
                  key={index}
                  title={value}
                  onClick={() => {
                    closeChip(key);
                    if (window.location.pathname === ("/users/drivers/details/" + driver_id + "/shift-planner/shift-history")) {
                      getShiftHistoryList(driver_id, true);
                    } else {
                      getShiftPlannerList(driver_id, true);
                    }
                    setChipData({});
                    onResetFilterHandler();
                  }}
                />
              )
            }
          </div> */}

          {/* hy list ui starts */}
          <ul className="overflow-y-auto scroll-smooth max-h-[60vh]">
            {isLoading ? (
              <ListSkeleton01 />
            ) : (
              <>
                {shift?.length > 0 ? (
                  shift?.map((item) =>
                    <Shift key={item.id} data={item} />
                  )
                ) : (
                  <div className="h-[30px] flex justify-center text-center text-gray-500 font-bold italic text-base">
                    {t("No Data Available!")}
                  </div>
                )}
              </>
            )}

            <div className="mb-3"></div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ShiftPlannerList;
