import { createSlice, current } from '@reduxjs/toolkit'
import axios from 'axios'
import { kuAddBanner, kuDeleteBanner, kuGetMessage, kuGetPackage, } from '../../Utility/url'
import { Toastr, ToastrLoading } from '../../Utility/UtilityFunctions'
import useGeneralStore from '../stores/GeneralStore'


const initialState = {
    messages: [],
    form: {
        id: null,
        subject: '',
        message: ''
    },
    isLoading: false,
    isOpen: false,
    msg: {}
}
export const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        changeValue: (state, action) => {
            const { name, value } = action.payload
            // console.log('name: ', name);
            // console.log('value: ', value);      
            state.form[name] = value
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        deleteMessage: (state, action) => {
            state.messages = action.payload
        },
        setIsOpen: (state, action) => {
            const { messageId, isOpen } = action.payload
            console.log('messageID: ', messageId);
            console.log('isOpen: ', isOpen);

            // state update
            state.isOpen = isOpen;

            if (messageId) {
                const tempMessage = state.messages.find((message) => message.id === messageId)
                console.log(current(tempMessage));

                state.msg = tempMessage
            }
        },
        resetForm: (state) => {
            state.form.id = null
            state.form.subject = ''
            state.form.message = ''
        },
    },
})

// Get Messages
export const handleSetMessages = (state) => async (dispatch) => {
    let toastr = ToastrLoading()
    const { setLoading } = useGeneralStore.getState();
    try {
        setLoading(true);
        dispatch(setLoading(true))
        const res = await axios.get(kuGetMessage)
        console.log('handleSetMessages: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(setMessages(res.data.data))
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
        setLoading(false);
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
        setLoading(false);
    }
}

// Delete Message
export const handleDeleteMessage = (state, id) => async (dispatch) => {
    let toastr = ToastrLoading()

    try {
        const body = { id: id }
        dispatch(setLoading(true))
        const res = await axios.post(kuDeleteBanner, body)

        console.log('handleDeleteMessage: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetMessages())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}

// Add Message
export const handleAddMessage = (state) => async (dispatch) => {
    if (state.form.subject.length === 0) {
        Toastr('Subject is required')
        return
    }

    if (state.form.message.length === '') {
        Toastr('Message is required')
        return
    }


    let toastr = ToastrLoading()

    try {
        const body = { subject: state.form.subject, message: state.form.message }
        dispatch(setLoading(true))
        const res = await axios.post(kuAddBanner, body)

        console.log('handleAddMessage: ', res.data);
        if (res.data.success) {
            ToastrLoading(res.data.message, 'stop', 'success', toastr);
            dispatch(handleSetMessages())
            dispatch(resetForm())
        } else {
            ToastrLoading(res.data.message, 'stop', 'error', toastr);
        }
        dispatch(setLoading(false))
    } catch (error) {
        console.log(error);
        ToastrLoading('An error occurred!', 'stop', 'error', toastr);
        dispatch(setLoading(false))
    }
}


export const { changeValue, setMessages, setLoading, deleteMessage, resetForm, setIsOpen } = messageSlice.actions;
export default messageSlice.reducer