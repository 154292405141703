import React, { useContext } from 'react'
import { updateLicenseStatus } from '../../../../../../App/stores/LicenseStore';
import useCarStore from '../../../../../../App/stores/CarStore';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import { CarManagementContext } from '../../../../../../Context/CarManagementContext';


function CarRejectModal({company_id}) {

    const {updateCar,getAllCar,selectCarFoo,setUpdatingDetails} = useContext(CarManagementContext);

    const { setShowCarRejectModal, showCarRejectModal } = useCarStore();
    let submitForm = {
        id: updateCar?.id,
        status: "rejected",
        license_start_date: updateCar?.license_start,
        license_end_date: updateCar?.license_end
    }
    return (
        <>
            <CommonModal
                showModal={showCarRejectModal}
                setShowModal={setShowCarRejectModal}
                modalTitle="information"
                mainContent={
                    <>
                        <div className='py-5 text-center'>
                            Are you sure you want to Reject this application?

                        </div>
                        <div className="flex justify-center">
                            <CommonButton btnLabel='Reject' colorType='danger' onClick={async() => {
                                let rejectSuccess = await updateLicenseStatus(submitForm)
                                if (rejectSuccess) {
                                    getAllCar(company_id);
                                    await selectCarFoo(0);
                                    await setUpdatingDetails(true);
                                    setShowCarRejectModal(false);
                                }
                            }} />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default CarRejectModal