import React, { useEffect, useState } from 'react'
import useDriverStore, { addNewDriver } from '../../../../App/stores/DriverStore'
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal'
import { CheckEmailValidity, checkValidPassword, FullFormDataValidation, Toastr } from '../../../../Utility/UtilityFunctions';

const AddNewDriver = () => {
    const { showAddDriverModal, setShowAddDriverModal } = useDriverStore();

    const [addDriverFormData, setAddDriverFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'driver'     //driver role is always driver
    });

    useEffect(() => {
        setAddDriverFormData({
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role: 'driver'     //driver role is always driver
        });
    }, [])
    

    const handleAddCompany = async (e) => {
        e.preventDefault();
        if (addDriverFormData?.password.length < 8 && addDriverFormData.password_confirmation.length < 8)
        return Toastr('Password must be 8 character long', "warning");
        if (!CheckEmailValidity(addDriverFormData?.email)) return Toastr('Enter a valid email address!',"warning");
        if (addDriverFormData?.password !== addDriverFormData.password_confirmation) return Toastr('Both password fields should be same !',"warning");
        if (checkValidPassword(addDriverFormData?.password).status === false) return Toastr(checkValidPassword(addDriverFormData?.password).message,"warning");
        if (FullFormDataValidation(addDriverFormData)) {
            let addSuccess = await addNewDriver(addDriverFormData);
            if (addSuccess) {
                setShowAddDriverModal(false);
                setAddDriverFormData({
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    role: 'driver'     //driver role is always driver
                });
            }
        }
        else Toastr('Need to fill up all required fields with valid data!', "warning");
    }


    return (
        <CommonModal
            showModal={showAddDriverModal}
            setShowModal={setShowAddDriverModal}
            modalTitle="Add Driver"
            mainContent={
                <form onSubmit={handleAddCompany}>

                    <div className="pt-5"></div>
                    <CommonInput
                        value={addDriverFormData?.name}
                        required={true}
                        onChange={(e) => setAddDriverFormData({ ...addDriverFormData, name: e.target.value })}
                        label={"Name"}
                        min_input={5}
                        placeholder="Enter driver name"
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        value={addDriverFormData?.email}
                        onChange={(e) => setAddDriverFormData({ ...addDriverFormData, email: e.target.value })}
                        required={true}
                        label={"Email"}
                        type="email"
                        placeholder="Enter driver email"
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        type='password'
                        togglePasswordBtn={true}
                        value={addDriverFormData?.password}
                        onChange={(e) => setAddDriverFormData({ ...addDriverFormData, password: e.target.value })}
                        required={true}
                        placeholder="Enter driver password"
                        label={"password"}
                    />

                    <div className="pt-5"></div>
                    <CommonInput
                        type='password'
                        togglePasswordBtn={true}
                        value={addDriverFormData?.password_confirmation}
                        onChange={(e) => setAddDriverFormData({ ...addDriverFormData, password_confirmation: e.target.value })}
                        required={true}
                        placeholder="Re-enter driver password"
                        label={"confirm password"}
                    />

                    <div className='mt-s20 flex justify-center'><CommonButton type='submit' btnLabel='Save' /></div>

                </form>
            }
        />
    )
}

export default AddNewDriver