import React from 'react'
import { iSearchCross } from '../../../Utility/Sources';
import useGeneralStore from '../../../App/stores/GeneralStore';


const SendImageView = ({ textFieldCount = "342px", image = "", clearImage = () => { } }) => {

  const { setShowImagePreviewSRC, setShowImagePopup } = useGeneralStore();

  return (
    <div
      style={{ height: textFieldCount }}
      className="relative flex items-center justify-center bg-cBgSideBar">
      <div className='absolute mb-4 cursor-pointer left-4 top-4'>
        <img
          src={iSearchCross}
          onClick={() => { clearImage() }}
          alt="clear-search"
          className=" max-w-[12px] min-w-[12px] max-h-[12px] min-h-[12px] "
        />
      </div>
      <div className="flex items-center justify-center mx-4">
        <div
          onClick={() => {
            setShowImagePopup(true);
            setShowImagePreviewSRC(image);
          }}
          className='flex items-center justify-center cursor-pointer'
        >
          <img src={image} alt="" className='rounded-br6  max-h-[calc(100vh-500px)] object-cover' />
        </div>
      </div>
    </div>
  )
}

export default SendImageView