import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomerStore, { getCustomers, searchUsers } from '../../../App/stores/CustomerStore';
import useGeneralStore from '../../../App/stores/GeneralStore';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../Components/CircularProgressBar';
import CommonTable from '../../../Components/Table/CommonTable';
import NoDataRow from '../../../Components/Table/NoDataRow';
import { iFilterBtn, iFilterBtnWhite, iRefresh } from '../../../Utility/Sources';
import { PageTitle } from '../../../Utility/UtilityFunctions';
import CustomerTableRow from './CustomerTableRow';

const ManageCustomers = () => {

    const {
        customerPageUrl,
        setCustomerPageUrl,
        customers,
        all_customers,
        is_searching,
        customerSearchValue,
        setCustomerSearchValue,
        customerTakeAmount,
        setCustomerTakeAmount,
        customerFilterValue
    } = useCustomerStore();

    const { t } = useTranslation();

    //const [searchValue, setSearchValue] = React.useState("");

    useEffect(() => {
        PageTitle('Limadi | Customers');
        // getCustomers();
    }, []);

    useEffect(() => {
        // ! Using debounce
        const timer = setTimeout(async () => {
            if (customerSearchValue) {
                // if (!customerPageUrl) {await setCustomerTakeAmount(10);}
                searchUsers(customerSearchValue, 'customer', customerTakeAmount, customerPageUrl)
            } else {
                if (!customerPageUrl) {
                    console.log("NO CUSTOMER PAGE URL", customerPageUrl);
                    // setCustomerTakeAmount(10);
                    await getCustomers()
                } else await getCustomers(customerPageUrl);
            }

        }, customerSearchValue === "" ? 0 : 500)
        return () => clearTimeout(timer);

    }, [customerSearchValue])


    const header = [
        { index: 1, name: t("name") },
        { index: 2, name: t("email") },
        { index: 3, name: t("phone") },
        { index: 4, name: t("status") },
        // { index: 6, name: t("actions") },
    ];

    return (
        <>
            <TitleBar count={customers?.total} />

            <div className="justify-between w-full space-x-0 md:flex md:space-x-5">

                <CommonTable
                    filterFunction={false}
                    filterComponent={<FilterDropDown />}
                    headers={header}
                    showingFrom={customers?.from}
                    topRightComponent={<TakeItem />}
                    showingTo={customers?.to}
                    totalResult={customers?.total}
                    items={all_customers?.length > 0 ? all_customers?.map((message, index) => <CustomerTableRow key={index} data={message} />) : <NoDataRow />}
                    shoSearchBox={true}
                    searchOnChange={(e) => {
                        setCustomerSearchValue(e.target.value)
                        setCustomerPageUrl("")
                    }}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setCustomerSearchValue("")
                        setCustomerPageUrl("")
                    }}
                    searchValue={customerSearchValue}
                    paginationObject={customers}
                    showPagination={customers?.last_page !== 1 ? true : false}
                    search_loading={is_searching}
                    paginationOnClick={async (url) => {
                        setCustomerPageUrl(url)
                        if (customerSearchValue) {
                            searchUsers(customerSearchValue, 'customer', customerTakeAmount, url,)
                        } else await getCustomers(url)
                        //customerSearchValue !== "" ? searchUsers(customerSearchValue, 'customer',url) : getCustomers(url);
                    }}
                />
            </div>
        </>
    )
}

export default ManageCustomers


const TitleBar = ({ count }) => {

    const { isLoading } = useGeneralStore();
    const { setShowAddCustomer, customerPageUrl, setCustomerSearchValue } = useCustomerStore();

    return (
        <>
            <div className="flex flex-row justify-between w-full p-5 mb-5 bg-white shadow-sm rounded-2xl">
                <div className="flex items-center text-fs28 font-fw600">
                    <div className="capitalize">{'Customers'}</div>
                    <div className="pr-5 pl-s5">({count ?? 0})</div>
                    {
                        isLoading ?
                            <CircularProgressBar />
                            :
                            <img
                                onClick={async () => { await getCustomers(customerPageUrl); setCustomerSearchValue("") }}
                                src={iRefresh}
                                alt="refresh-icon"
                                className='cursor-pointer'
                            />
                    }
                </div>

                <CommonButtonOutlined btnLabel={'Add Customer'} onClick={() => { setShowAddCustomer(true); }} />
            </div>
        </>
    );
}

const TakeItem = () => {
    const { setCustomerTakeAmount, customerTakeAmount, customerSearchValue, customerPageUrl, setCustomerPageUrl } = useCustomerStore();
    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                value={customerTakeAmount ? customerTakeAmount : 10}
                onChange={async (e) => {
                    console.log(e.target.value);
                    await setCustomerTakeAmount(e.target.value);
                    setCustomerPageUrl("")
                    if (customerSearchValue) {
                        await searchUsers(customerSearchValue, 'customer', e.target.value)
                    } else await getCustomers();
                }} className='pl-5 font-semibold h-s30 rounded-br5 select-style space-y-s5'>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={customerTakeAmount === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cMainBlack'
                    selected={customerTakeAmount === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={customerTakeAmount === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={customerTakeAmount === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}



const FilterDropDown = () => {

    const { customerFilterValue, setCustomerSearchValue, setCustomerFilterValue } = useCustomerStore();

    return (
        <div>
            <select onChange={async (e) => {
                // await setLicenseTakeAmount(10);
                await setCustomerSearchValue('')
                // console.log(e.target.value);
                setCustomerFilterValue(e.target.value)
                // searchLicenseApplication('status=' + e.target.value);

            }} id="cars" className='pl-5 font-semibold w-s200 h-s37 rounded-br5 select-style space-y-s5'>
                <option className='py-s10 text-cMainBlack' value="">Choose Status</option>
                <option className='py-s10 text-cMainBlack' selected={customerFilterValue === 1} value="1">Active</option>
                <option className='py-s10 text-cMainBlack' selected={customerFilterValue === 0} value="0">InActive</option>
            </select>
        </div>
    )
}