import { useNavigate } from 'react-router-dom';
import useLicenseStore from '../../../App/stores/LicenseStore';
import CommonImage from '../../../Components/Image/CommonImage';
import { Tooltip } from '@mui/material';
import { formatDate } from '../../../Utility/UtilityFunctions';


export default function ApplicationItem({ data }) {

    const { setShowUpdateApplication, setLicenseApplicationID } = useLicenseStore();

    const navigateTo = useNavigate();

    console.log("data",data)

    //  {licenseDetails?.license_status === "expire_warning" ? 
    //                                 "Expire soon": licenseDetails?.license_status === "no_license" ? "No license": licenseDetails?.license_status }

    return (
        <tr
            onClick={() => {
                setLicenseApplicationID(data?.id)
                localStorage.setItem("l_app", JSON.stringify(data));
                navigateTo("/license/application/" + data?.id + "/details");
            }}
            className='border-b-[1px] border-collapse border-cNmSelect cursor-pointer hover:bg-cGridView'>

            <td className='border-r-[1px] p-s10 text-left max-w-[150px] min-w-[150px] truncate'>
                <div className='flex items-center'>
                    <div className='mx-s5 max-w-[40px] min-w-[40px]'>
                        <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
                    </div>
                    {data?.name ?
                        <Tooltip title={data?.name}>
                            <div className='items-center capitalize truncate pl-s10'>
                                {data?.name}
                            </div></Tooltip> : ""}
                </div>
            </td>

            <td className='text-center p-s10 border-r-[1px] border-collapse border-cNmSelect'>{data?.license?.number ?? ''}</td>
            <td className='text-center p-s10 border-r-[1px] border-collapse border-cNmSelect'>
                {(data?.license_start && data?.license_end) ?`${formatDate(data?.license_start)} - ${formatDate(data?.license_end)}` : ""}
            </td>
            <td className=' border-r-[1px] border-collapse border-cNmSelect text-center truncate p-s10 max-w-[150px] min-w-[150px]'>
                {/* {data?.license_status ==='active' || data?.license_status === "expire_warning" ?<div className='flex flex-col'>
                    <span className='text-fs14'>{formatDate(data?.license_end) ?? 'NA'}</span>
                    <span className='text-fs12'>{dateDiffCalendar(new Date(), data?.license_end)} {" remaining"}</span>
                </div>:<span className='text-fs14'>{formatDate(data?.license_end) ?? 'NA'}</span>} */}
                {
                    data?.company?.name ?
                        <Tooltip title={data?.company?.name}><div className='truncate'>{data?.company?.name}</div></Tooltip> : ""}
            </td>
            <td className='text-center p-s10 border-r-[1px] border-collapse border-cNmSelect capitalize'>
                <span
                    className={`
                    ${data?.license_status === "active" || data?.license_status === "accepted" ?
                            "text-cSuccess"
                            : data?.license_status === "rejected" ? "text-cRed"
                                : data?.license_status === "no_license" ? "text-cRed" : "text-cPrimary"}
                `}
                >
                    {data?.license_status === "no_license" ? "no license" : data?.license_status === "expire_warning" ? "Expire soon" : data?.license_status}
                    {/* {data?.license_status === "expire_warning" ? "about to expire" : data?.license_status} */}
                </span>
            </td>
            <td className='text-center p-s10 border-r-[1px] border-collapse border-cNmSelect'>{formatDate(data?.updated_at) ?? ""}</td>
        </tr>
    )
}
