import { Tooltip } from 'antd';
import React, { useState } from 'react'

import { IoIosArrowForward } from 'react-icons/io';

const SidebarAccordion = ({ isSidebarOpen, isInitOpen = false, icon = '', title = '', body = '', is_active = false, active_image = null }) => {
    // ! isInitOpen = "Decides whether SidebarAccordion should be opened initially"

    const [isOpen, setIsOpen] = useState(isInitOpen);
    const [inHoverState, setInHoverState] = useState(false);

    return (
        <div className={`text-md pl-7 py-2 mb-4 text-cIcon ${isOpen && 'bg-cListItem'}`}>
            <div onClick={(e) => { setIsOpen(!isOpen) }} className='flex justify-between items-center w-full cursor-pointer'>
                <div
                    onMouseEnter={() => setInHoverState(true)}
                    onMouseLeave={() => setInHoverState(false)}
                    className={`flex items-center text-sm font-medium ${is_active || inHoverState ? "text-cBrand font-semibold" : ""}`}>
                    <Tooltip title={isSidebarOpen ? '' : title}>
                        <div className={`flex justify-start items-center ${isSidebarOpen ? 'text-xl mr-2' : 'text-2xl mr-2 w-[100px]'}`}>
                            <img className={` ${isSidebarOpen ? "w-5 h-5" : "w-6 h-6"} `} src={inHoverState ? active_image : icon} alt="" />
                            {/* <img className={` ${isSidebarOpen ? "w-5 h-5" : "w-6 h-6"} `} src={icon} alt="" /> */}
                        </div>
                    </Tooltip>
                    {isSidebarOpen && title}
                </div>
                {isSidebarOpen &&
                    <div className="flex justify-between items-center">
                        <IoIosArrowForward className={`transition-transform ${isOpen && 'rotate-90'}`} />
                        <div className="pr-2.5"></div>
                    </div>
                }
            </div>
            <div className={`overflow-hidden transition-[max-height] duration-300 ${!isOpen ? 'max-h-0' : 'max-h-96'}`}>
                <div className='pt-3'></div>
                {body}
            </div>
        </div>
    )
}

export default SidebarAccordion