import React from 'react'
import { CgChevronRight } from 'react-icons/cg'
import { Link } from 'react-router-dom'

export default function BackLinks({
    linksArray = [
        { label: "one", linkTo: "/test/one" },
        { label: "two", linkTo: "/test/two" }
    ]
}
) {
    return (
        <div className='flex items-center pb-5 text-fs16 hover:text-cBrand'>
            {
                linksArray.map((item, index) =>
                    <Link key={index} to={item.linkTo} className={`capitalize drop-shadow-sm font-medium hover:text-cBrand flex items-center ${linksArray.length === index + 1 ? "text-cBrand" : "text-cIcon"}`} >
                        {item.label}{linksArray.length !== index + 1 ?
                            <CgChevronRight className='text-cIcon text-2xl mr-1.5' />
                            : ""}
                    </Link>
                )
            }
        </div>
    )
}
