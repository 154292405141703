/* eslint-disable jsx-a11y/anchor-is-valid */
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmptyMessage from "../../../../Components/EmptyMessage";
import BackLinks from "../../../../Components/Layout/BackLinks";
import RatingIntType from "../../../../Components/Rating copy/RatingIntType";
import { FavoriteCompanyContext } from "../../../../Context/FavoriteCompanyContext";

function RatingReview() {
    const { favCompanyRatingInfo } = useContext(FavoriteCompanyContext);
    const [reviews, setReviews] = useState([]);
    const [tempReviews, setTempReviews] = useState([]);

    const { customer_id } = useParams();
    const { driver_id } = useParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { setReviews(favCompanyRatingInfo.reviews); setTempReviews(favCompanyRatingInfo.reviews); }, [])

    const filterReviews = (rating) => {
        console.log('rating: ', rating);
        const filteredReviews = tempReviews.filter(review => review.rate === rating);
        setReviews(filteredReviews);
    }

    return (
        
        <>
            {customer_id ?
                <BackLinks
                    linksArray={[
                        { label: "customers", linkTo: "/users/customers" },
                        { label: "customer Details", linkTo: "/users/customers/details/" + customer_id },
                        { label: "favorite company", linkTo: "/users/customers/details/" + customer_id + "/favorite-company/" },
                        { label: "reviews", linkTo: "" },
                    ]}
                />
                :
                <BackLinks
                    linksArray={[
                        { label: "drivers", linkTo: "/users/drivers" },
                        { label: "driver Details", linkTo: "/users/drivers/details/" + driver_id },
                        { label: "favorite company", linkTo: "/users/drivers/details/" + driver_id + "/fav-company/" },
                        { label: "reviews", linkTo: "" },
                    ]}
                />
            }
            <div className="flex flex-col card">
                <p className="text-[24px] font-semibold mb-5 text-center">
                    {t("Total Rating")} ({favCompanyRatingInfo.people_reviewed})
                </p>

                <div className="flex flex-row-reverse justify-start">
                    <div className="w-1/3 text-right">
                        <div className="pt-5">
                            <div className="relative inline-block dropdown">
                                 {
                                    favCompanyRatingInfo.reviews?.length > 0 &&
                                    <button className="flex items-center justify-between px-2 py-2 font-semibold text-center text-gray-700 bg-gray-300 rounded w-s200 text-fs16">
                                        <span className="mr-s10">{t("Filter by Rating")}</span>
                                        <svg
                                            className="w-5 h-5 fill-current"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                                        </svg>
                                    </button>
                                } 
                                <ul className="absolute left-0 hidden pt-1 text-gray-700 dropdown-menu">

                                    <li onClick={() => filterReviews(1)} className="w-[200px] rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer">
                                        <RatingIntType rate={1} />
                                    </li>

                                    <li onClick={() => filterReviews(2)} className="w-[200px] rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer">
                                        <RatingIntType rate={2} />
                                    </li>

                                    <li onClick={() => filterReviews(3)} className="w-[200px] rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer">
                                        <RatingIntType rate={3} />
                                    </li>

                                    <li onClick={() => filterReviews(4)} className="w-[200px] rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer">
                                        <RatingIntType rate={4} />
                                    </li>

                                    <li onClick={() => filterReviews(5)} className="w-[200px] rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap cursor-pointer">
                                        <RatingIntType rate={5} />
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="w-1/3">
                        <RatingIntType rate={favCompanyRatingInfo.rating} />
                    </div>
                </div>

                {
                    (favCompanyRatingInfo.reviews?.length > 0) ?
                        <div className="mt-5 overflow-x-auto">
                            <div className="inline-block min-w-full align-middle">
                                <div className="overflow-hidden ">
                                    <table className="min-w-full divide-y divide-gray-200 table-fixed ">
                                        <thead className="bg-gray-100 ">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="w-[30%]  py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-700 uppercase dark:text-gray-400"
                                                >
                                                    {t("Rating")}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="w-[70%]  py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                                                >
                                                    {t("Comment")}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200 ">

                                            {
                                                reviews.map((item) => (
                                                    <tr
                                                        className="hover:bg-gray-100"
                                                        key={Math.random(100, 999)}
                                                    >
                                                        <td className="w-[30%] flex justify-center items-center py-3 mx-auto font-medium text-gray-900 whitespace-nowrap">
                                                            <RatingIntType rate={item.rate} />
                                                        </td>
                                                        <td className="w-[70%] py-3 px-6 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                            {item.review}
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <EmptyMessage message={t("No Reviews Yet")} />
                }
            </div>
        </>
    );
}

export default RatingReview;
