import axios from "axios";
import React, { createContext, useState } from "react";
import useGeneralStore from "../App/stores/GeneralStore";
import ChangePassword from "../Pages/Settings/Components/ChangePassword";
import ContactLimadi from "../Pages/Settings/Components/ContactLimadi";
import FAQ from "../Pages/Settings/Components/FAQ";
import LanguageSetup from "../Pages/Settings/Components/LanguageSetup";
import TermsAndCondition from "../Pages/Settings/Components/TermsAndCondition";
import { sType } from "../Utility/action";
import {
  kuAddFAQ,
  kuChangePassword,
  kuContactLimadi,
  kuDeleteFAQ,
  kuGetAllFAQ,
  kuGetToggleEmailPushNotificationState,
  kuToggleEmailPushNotification,
  kuToggleFAQ,
  kuUpdateFAQ,
} from "../Utility/url";
import { Toastr, ToastrLoading } from "../Utility/UtilityFunctions";

export const SettingsContext = createContext();

const SettingsContextProvider = (props) => {
  /*
   |--------------------------------------------------------------------------
   | push and email notification 
   |--------------------------------------------------------------------------
   */
  const [faqToggle, setFAQToggle] = useState(false);
  const [pushNotification, setPushNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [allFaqList, setAllFaqList] = useState([]);
  const [isActive, setisActive] = useState("change_password");
  const { setLoading } = useGeneralStore();

  const getToggleNotificationState = async () => {
    try {
      setLoading(true);
      const res = await axios.get(kuGetToggleEmailPushNotificationState);
      console.log(res.data);
      if (res.data.success) {
        setPushNotification(res.data.data.is_push);
        setEmailNotification(res.data.data.is_email);
        // console.log('getFavoriteAddress: ' + res.data);
      } else {
        console.log(res.data.message);
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      Toastr("An error occurred!");
      setLoading(false);
    }
  };

  const handleNotificationToggle = async (type) => {
    try {
      setLoading(true);
      const body =
        type === "email" ? { is_email: "true" } : { is_push: "true" };
      const res = await axios.post(kuToggleEmailPushNotification, body);
      console.log(res.data);
      if (res.data.success) {
        console.log("handleNotificationToggle: " + res.data);
        if (type === "push") {
          setPushNotification(!pushNotification);
        } else if (type === "email") {
          setEmailNotification(!emailNotification);
        }
        Toastr(res.data.message, "success");
      } else {
        console.log(res.data.message);
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      Toastr("An error occurred!");
      setLoading(false);
    }
  };

  /*
    |--------------------------------------------------------------------------
    | contact limadi part state and api
    |--------------------------------------------------------------------------
    */
  const [form, setForm] = useState({ subject: "", message: "" });
  const [canSubmit, setSubmit] = useState(false);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setForm({ ...form, [name]: value });
  };

  const submitContactMessage = async () => {
    try {
      setLoading(true);
      const body = { message: form.message, subject: form.subject };
      setLoading(true);
      const res = await axios.post(kuContactLimadi, body);
      console.log('submitContactMessage: ', res.data);

      if (res.data.success) {
        setForm({ subject: "", message: "" });
        Toastr(res.data.message, "success");
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      Toastr("An error occurred!");
      setLoading(false);
      console.log(err);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | change password 
  |--------------------------------------------------------------------------
  */

  const [type, setType] = useState(sType.CHANGE_PASSWORD);
  ///cp = change password
  const [cpForm, setCpForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const handleCpChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCpForm({ ...cpForm, [name]: value });
  };
  const [cpCanSubmit, setCpSubmit] = useState(false);

  const submitChangePassword = async () => {
    try {
      setLoading(true);
      if (cpForm.newPassword !== cpForm.confirmNewPassword) {
        Toastr("Passwords do not match!", "warning");
        console.log("password not match");
        return;
      }

      const body = {
        old_password: cpForm.currentPassword,
        password: cpForm.newPassword,
        password_confirmation: cpForm.confirmNewPassword,
      };
      const res = await axios.post(kuChangePassword, body);
      console.log(res.data);
      if (res.data.success) {
        console.log("submitChangePassword: " + res.data);
        setForm({ subject: "", message: "" });
        Toastr(res.data.message, "success");
        setCpForm({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      } else {
        Toastr(res.data.message);
        console.log(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log("submitChangePassword" + err);
      Toastr("An error occurred!");
      setLoading(false);
    }
  };

  /*
    |--------------------------------------------------------------------------
    | Language Setup
    |--------------------------------------------------------------------------
    */

  // const appLanguage = ['English', 'Danish'];
  const appLanguage = [{ name: 'English', code: 'en' }, { name: 'Danish', code: 'da' }];
  const [activeLang, setActiveLanguage] = useState(appLanguage[0]);

  //this function return the exact component for selected option in setting page
  

  /*
    |--------------------------------------------------------------------------
    | Terms & Conditions 
    |--------------------------------------------------------------------------
    */
  const [termsAndConditions, setTermsAndConditions] = useState(
    "terms and conditions"
  );

  /*
    |--------------------------------------------------------------------------
    | FAQs 
    |--------------------------------------------------------------------------
    */

  // const getAllFAQ = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.get(kuGetAllFAQ);
  //     console.log((res.data));
  //     if (res.data.success) {
  //       setAllFaqList(res.data.data);
  //       //   console.log('getFavoriteAddress: ' + res.data);
  //     } else {
  //       console.log(res.data.message);
  //       Toastr(res.data.message);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     Toastr("An error occurred!");
  //     setLoading(false);
  //   }
  // };

  // const addFAQ = async (form) => {
  //   console.log("form2: ", form);

  //   try {
  //     setLoading(true);
  //     const body = {
  //       title: form.qus,
  //       description: form.ans,
  //       app_type: form.type,
  //     };
  //     const res = await axios.post(kuAddFAQ, body);
  //     console.log(JSON.stringify(res.data));
  //     if (res.data.success) {
  //       getAllFAQ();
  //       //   console.log('getFavoriteAddress: ' + res.data);
  //       Toastr(res.data.message, "success");
  //     } else {
  //       console.log(res.data.message);
  //       Toastr(res.data.message);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     Toastr("An error occurred!");
  //     setLoading(false);
  //   }
  // };

  // const deleteFAQ = async (item) => {
  //   console.log("Item id:", item);
  //   const loading_toastr = ToastrLoading();
  //   try {
  //     setLoading(true);
  //     const body = {
  //       id: item,
  //     };
  //     const res = await axios.post(kuDeleteFAQ, body);
  //     console.log(JSON.stringify(res.data));
  //     if (res.data.success) {
  //       await getAllFAQ();


  //       //   console.log('getFavoriteAddress: ' + res.data);
  //       ToastrLoading(res.data.message, "stop", "success", loading_toastr);
  //     } else {
  //       console.log(res.data.message);
  //       ToastrLoading(res.data.message, "stop", "error", loading_toastr);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     ToastrLoading("An error occurred!", "stop", "error", loading_toastr);
  //     setLoading(false);
  //   }
  // }

  // const updateFAQ = async (item) => {
  //   console.log("Item From context:", item);
  //   const loading_toastr = ToastrLoading();
  //   try {
  //     setLoading(true);
  //     const body = {
  //       id: item.id,
  //       title: item.title,
  //       description: item.description,
  //       app_type: item.app_type,
  //     };
  //     const res = await axios.post(kuUpdateFAQ, body);
  //     console.log(JSON.stringify(res.data));
  //     if (res.data.success) {
  //       await getAllFAQ();

  //       //   console.log('getFavoriteAddress: ' + res.data);
  //       ToastrLoading(res.data.message, "stop", "success", loading_toastr);
  //     } else {
  //       console.log(res.data.message);
  //       ToastrLoading(res.data.message, "stop", "error", loading_toastr);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     ToastrLoading("An error occurred!", "stop", "error", loading_toastr);
  //     setLoading(false);
  //   }
  // }

  // const handleFAQToggle = async (id) => {
  //   try {
  //     setLoading(true);
  //     const body = {
  //       id: id,
  //     };
  //     // type === "email" ? { is_email: "true" } : { is_push: "true" };
  //     const res = await axios.post(kuToggleFAQ, body);
  //     console.log(res.data);
  //     if (res.data.success) {
  //       console.log("handleFAQToggle: " + res.data);
  //       setFAQToggle(res.data);
  //       // if (type === "push") {
  //       //   setPushNotification(!pushNotification);
  //       // } else if (type === "email") {
  //       //   setEmailNotification(!emailNotification);
  //       // }
  //       Toastr(res.data.message, "success");
  //     } else {
  //       console.log(res.data.message);
  //       Toastr(res.data.message);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     Toastr("An error occurred!");
  //   }
  // };

  /*
    |--------------------------------------------------------------------------
    | End FAQs 
    |--------------------------------------------------------------------------
    */

  // sharing information between components
  return (
    <SettingsContext.Provider
      value={{
        //* States
        form,
        canSubmit,
        type,
        cpForm,
        cpCanSubmit,
        appLanguage,
        activeLang,
        termsAndConditions,
        pushNotification,
        emailNotification,
        allFaqList,
        isActive,
        faqToggle,

        //actions
        setAllFaqList,
        setisActive,

        //* Functions
        handleChange,
        submitContactMessage,
        setSubmit,
        setType,
        // setContent,
        setCpForm,
        handleCpChange,
        setCpSubmit,
        submitChangePassword,
        setActiveLanguage,
        setTermsAndConditions,
        setPushNotification,
        setEmailNotification,
        handleNotificationToggle,
        getToggleNotificationState,
        // getAllFAQ,
        // addFAQ,
        // deleteFAQ,
        // updateFAQ,
        // handleFAQToggle,
        setFAQToggle,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsContextProvider;
