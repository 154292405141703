/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useSettingsStore, { handleTermsGdpr, updateTermsGdpr } from '../../../App/stores/SettingsStore';
import CommonButton from '../../../Components/Button/CommonButton';
import RichTextEditor from '../../../Components/TextEditor/RichTextEditor';


function TermsAndCondition({
    title = "",
    termsData,
    gdprData,
    onSubmitTerms,
    OnSubmitGdpr,
}) {
    const { t } = useTranslation();
    const { termsGdprData, updateTermsData, updateGdprData, editNow, setEditNow } = useSettingsStore();
    const [addNew, setAddNew] = useState(false);

    useEffect(() => {
        handleTermsGdpr(title);
    }, [title])

    return (
        <div>
            <div className="text-2xl font-bold pb-5 capitalize">{title + " "}{t("Terms & Condition")}</div>
            {editNow === "terms" ?
                <div>
                    <div className="h-[318px] mb-10">
                        <RichTextEditor
                            placeholder="Write your business Terms & Condition..."
                            value={termsGdprData?.terms_condition?.description}
                            onChange={(e) => updateTermsData(e)}
                        />
                    </div>
                    <div className="flex justify-between items-center py-5">
                        <CommonButton btnLabel={t("cancel")} colorType='danger' onClick={() => setEditNow("")} />
                        <CommonButton btnLabel={t("save terms")} onClick={() => {
                            let updateDone;
                            if (addNew) {
                                updateDone = updateTermsGdpr(
                                    "",
                                    title,
                                    "terms",
                                    termsGdprData?.terms_condition?.description,
                                    addNew
                                );
                            } else {
                                updateDone = updateTermsGdpr(
                                    termsGdprData?.terms_condition?.id,
                                    termsGdprData?.terms_condition?.app_type,
                                    termsGdprData?.terms_condition?.type,
                                    termsGdprData?.terms_condition?.description,
                                    addNew
                                );
                            }
                            if (updateDone) {
                                setEditNow("");
                            }
                        }} />
                    </div>
                </div>
                :
                <div>
                    <div className="bg-gray-200 h-[25vh] p-5 rounded-br10 overflow-y-auto">
                        <div className="prose prose-lg" dangerouslySetInnerHTML={{
                            __html: termsGdprData?.terms_condition?.description
                        }} />
                    </div>
                    <div className="flex justify-end pt-5">
                        <CommonButton btnLabel={t("edit terms")} onClick={() => {
                            if (termsGdprData?.terms_condition?.id) {
                                setAddNew(false);
                            } else {
                                setAddNew(true);
                            }
                            setEditNow("terms");
                        }} />
                    </div>
                </div>
            }
            {/* <div className="text-2xl font-bold pb-5 capitalize">{title + " "}{t("GDPR")}</div>
            {editNow === "gdpr" ?
                <div>
                    <div className="h-[318px] mb-10">
                        <RichTextEditor
                            placeholder={t("Write GDPR...")}
                            value={termsGdprData?.gdpr?.description}
                            onChange={(e) => updateGdprData(e)}
                        />
                    </div>
                    <div className="flex justify-between items-center pt-5">
                        <CommonButton btnLabel={t("cancel")} colorType='danger' onClick={() => setEditNow("")} />
                        <CommonButton btnLabel={t("save GDPR")} onClick={() => {
                            let updateDone;
                            if (addNew) {
                                updateDone = updateTermsGdpr(
                                    "",
                                    title,
                                    "gdpr",
                                    termsGdprData?.gdpr?.description,
                                    addNew
                                );
                            } else {
                                updateDone = updateTermsGdpr(
                                    termsGdprData?.gdpr?.id,
                                    termsGdprData?.gdpr?.app_type,
                                    termsGdprData?.gdpr?.type,
                                    termsGdprData?.gdpr?.description,
                                    addNew
                                );
                            }

                            if (updateDone) {
                                setEditNow("");
                            }
                        }} />
                    </div>
                </div>
                :
                <div>
                    <div className="bg-gray-200 h-[25vh] p-5 rounded-br10 overflow-y-auto">
                        <div className="prose prose-lg" dangerouslySetInnerHTML={{
                            __html: termsGdprData?.gdpr?.description
                        }} />
                    </div>
                    <div className="flex justify-end pt-5">
                        <CommonButton btnLabel={t("edit GDPR")} onClick={() => {
                            if (termsGdprData?.gdpr?.id) {
                                setAddNew(false);
                            } else {
                                setAddNew(true);
                            }
                            setEditNow("gdpr");
                        }} />
                    </div>
                </div>
            } */}


        </div>
    )
}

export default TermsAndCondition
