import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { CarManagementContext } from '../../../../../../Context/CarManagementContext';

export default function DeleteCarModal() {
    const { t } = useTranslation();
    const { company_id } = useParams();

    const { popDelWarning, setPopDelWarning, deleteCar } = useContext(CarManagementContext);
    return (
        <>
            <CommonModal
                showModal={popDelWarning}
                setShowModal={setPopDelWarning}
                modalTitle={t("Delete Car")}
                mainContent={
                    <>
                        <div>
                            <div className="flex justify-center py-5 text-fs16">
                                {t("Are you sure you want to delete this car ?")}
                            </div>
                            <div className="flex justify-between items-center">
                                <CommonButton
                                    btnLabel={t("Cancel")}
                                    onClick={() => setPopDelWarning(false)}
                                />

                                <CommonButton
                                    colorType={"danger"}
                                    btnLabel={t("Delete")}
                                    onClick={() => {
                                        let delSuccess = deleteCar(company_id);
                                        if (delSuccess) {
                                            setPopDelWarning(false);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}
