import { t } from 'i18next';
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iRefresh } from '../../../../../../Utility/Sources';

const AddStopBar = () => {
  const { getRoutePlannerList, isLoading } = useContext(TrackOnlineContext);
  // const { isShiftDetailsData } = useContext(ShiftPlannerContext);
  const { shift_id } = useParams()
  const navigate = useNavigate()

  //hg helper function to refresh the list
  const refreshFoo = async () => {
    getRoutePlannerList(shift_id);
  };

  return (
    <div className="card flex flex-row justify-between items-center align-middle">
      <div className="flex items-center w-2/3">
        <div className="text-2xl font-bold mt-[5px] mr-5">{t("Add Stop")}</div>
        {/*hp refresh icon button */}
        {!isLoading ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}
      </div>

      <div className="flex justify-center items-center">
        <div onClick={() => navigate(-1)}>
          <CommonButtonOutlined
            btnLabel={t("Back")}
          />
        </div>
      </div>
    </div>
  );
};

export default AddStopBar;
