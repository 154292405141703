import { t } from 'i18next';
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iRefresh } from '../../../../../../Utility/Sources';

const PackageListBar = ({ backLink }) => {
  const location = useLocation();
  const { isShiftDetailsID, getShiftPlannerDetails, isShiftDetailsData } =
    useContext(ShiftPlannerContext);
  const { setSearchPackageKey, PackageList } =
    useContext(TrackOnlineContext);

  const { company_id, driver_id } = useParams();

  const [isLoading, setIsLoadingLocal] = useState(false);

  //fix: refresh with an api call to getShiftPlannerDetails
  const refreshFoo = async () => {
    setIsLoadingLocal(true);
    setSearchPackageKey("");
    console.log("Path:::::: ", location.pathname);


    if (location.pathname === ("/users/companies/details/" + company_id + "/shift-planner/package-details")) {
      await getShiftPlannerDetails(isShiftDetailsID, parseInt(company_id));
    } else if (location.pathname === ("/users/companies/details/" + company_id + "/shift-planner/shift-history/package-details")) {
      await getShiftPlannerDetails(isShiftDetailsID, parseInt(company_id));
    }

    if (location.pathname === ("/users/drivers/details/" + driver_id + "/shift-planner/package-details")) {
      await getShiftPlannerDetails(isShiftDetailsData?.id, parseInt(company_id), true, isShiftDetailsData?.company_user_id);
    } else if (location.pathname === ("/users/drivers/details/" + driver_id + "/shift-planner/shift-history/package-details")) {
      await getShiftPlannerDetails(isShiftDetailsData?.id, parseInt(company_id), true, isShiftDetailsData?.company_user_id);
    }


    setIsLoadingLocal(false);
  };

  const navigate = useNavigate();

  return (
    <div className="card flex flex-row justify-between items-center align-middle">
      <div className="flex items-center w-2/3">
        {/* <div className="text-2xl font-bold mt-[5px] mr-5">Route Planner</div> */}

        <div className="text-2xl font-bold mt-[5px] mr-5">
          {t("Package List")} ({PackageList ? PackageList.length : "0"})
        </div>

        {/* refresh icon button */}
        {!isLoading ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}
      </div>
      <div className="">
        <div onClick={() => navigate(-1)}>
          <CommonButtonOutlined
            btnLabel={t("Back")}
          />
        </div>
      </div>
    </div>
  );
};

export default PackageListBar;
