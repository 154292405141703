/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'

const Dropzone = ({onUpload}) => {
  // !This dropzone accepts multiple files
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {

    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    
    onUpload(acceptedFiles[0])
  }, [])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, maxFiles:1})


  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="flex justify-center items-center border-dashed border-2 border-gray-400 bg-gray-200 p-5 rounded-lg text-sm">
          Drag & Drop or <span className="text-blue-600 mx-2">Browse</span>
      </div>
      
      <div className="grid grid-cols-2 gap-4">
          {files.map(file => (
            <img key={file.name} src={file.preview} className="shadow rounded-lg" alt="Not Found"/>
          ))}
      </div>
    </div>
  )
}

export default Dropzone