import React from 'react'
import Image from './Image';
import CommonModal from '../Modal/CommonModal';
import { iCloseRed } from '../../Utility/Sources';
import useGeneralStore from '../../App/stores/GeneralStore';
import CommonImage from './CommonImage';

const ImagePreviewPopup = () => {

    const { showImagePopup, setShowImagePopup, showImagePreviewSRC } = useGeneralStore();

    const fileExtension = showImagePreviewSRC?.split('.')?.pop()?.toLowerCase();

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];

    return (
        <>
            <CommonModal
                showModal={showImagePopup}
                setShowModal={setShowImagePopup}
                customClose={iCloseRed}
                widthClass="w-fit"
                modalTitle={""}
                padding=""
                mainContent={
                    <div className='flex items-center justify-center w-full'>
                        {
                            imageExtensions?.includes(fileExtension) ?
                                <CommonImage
                                    className={"object-contain max-h-[500px] min-h-[500px] rounded-br6"}
                                    src={showImagePreviewSRC}
                                />
                                : <img
                                    src={showImagePreviewSRC}
                                    alt=''
                                    className='object-contain max-h-[500px] min-h-[500px] rounded-br6'
                                />
                        }

                    </div>
                }
            />
        </>
    )
}

export default ImagePreviewPopup