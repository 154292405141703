import React, { useRef, useState } from 'react'
import { NoUserImage, UploadIcon2 } from '../../App/Utility/ImageImports';
import { BaseUrlSrc } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import { iDemoUser } from '../../Utility/Sources';
// import { BaseUrlSrc } from '../../Utility/url';

export default function ProfileImageUploader({ value = null, onChange = () => { }, finalBase64 = () => { }, setTempUser, user_info, setIsOldImage = () => { } }) {
    const [tempImage, setTempImage] = useState(null);

    const inputFile = useRef(null);

    const onButtonClick = () => inputFile.current.click()

    const onChangeFile = (event) => {

        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')) {
            event.stopPropagation();
            event.preventDefault();
            // console.log(file);
            var reader = new FileReader();
            reader.onloadend = function () {
                setTempImage(reader.result);
                // setTempUser({ ...user_info, image: reader.result });
                // console.log("reader.result: ", reader.result);
                finalBase64(reader.result);
            };
            reader.readAsDataURL(file);
            onChange(file);
        } else {
            Toastr('Invalid file type. Only JPEG and PNG and JPG files are allowed.',"warning");
        }
        // setIsOldImage(false);
    };

    return (
        <>
            {!value ? (
                <div className="h-[100px] w-[100px] rounded-full overflow-hidden select-none relative">
                    <input
                        onChange={onChangeFile.bind(this)}
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        // accept="image/png, image/gif, image/jpeg"
                    />

                    <img
                        className="object-cover w-full h-full"
                        src={
                            tempImage ? tempImage : NoUserImage
                        }
                        alt="profile-pic"
                    />
                    <div
                        onClick={onButtonClick}
                        className="z-50 gs-img-overlay cursor-pointer w-[100px] h-[40px] absolute bottom-0 left-0 opacity-0 hover:opacity-100"
                    >
                        <img
                            src={UploadIcon2}
                            alt="upload a pic"
                            className="w-[34px] mx-auto pt-1 z-20"
                        />
                    </div>
                </div>
            ) : (
                <div className="h-[100px] w-[100px] rounded-full overflow-hidden select-none relative">
                    <input
                        onChange={onChangeFile.bind(this)}
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                    />

                    <img
                        className="object-cover w-full h-full"
                        // src={BaseUrlSrc + value}
                        src={tempImage ?? (BaseUrlSrc + value)}
                        alt="profile-pic"
                        onError={(e) => { e.target.onerror = null; e.target.src = iDemoUser }}
                    />
                    <div
                        onClick={onButtonClick}
                        className="z-50 gs-img-overlay cursor-pointer w-[100px] h-[40px] absolute bottom-0 left-0 opacity-0 hover:opacity-100"
                    >
                        <img
                            src={UploadIcon2}
                            alt="upload a pic"
                            className="w-[34px] mx-auto pt-1 z-20"
                        />
                    </div>
                </div>
            )}
        </>
    )
}
