import axios from 'axios';
import { t } from 'i18next';
import create from 'zustand'
import { kuAddBanner, kuDeleteBanner, kuGetBanners, kuUpdateBanner } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';
import useGeneralStore from './GeneralStore';

const useBannerStore = create((set) => ({

    showDeleteBanner: false,
    setShowDeleteBanner: (showDeleteBanner) => set({ showDeleteBanner }),

    showUpdateBanner: false,
    setShowUpdateBanner: (showUpdateBanner) => set({ showUpdateBanner }),

    messageUserID: 0,
    setMessageUserID: (messageUserID) => set({ messageUserID }),

    selectedBanner: {},
    setSelectedBanner: (selectedBanner) => set({ selectedBanner }),

    contactMessageReply: "",
    setContactMessageReply: (value) => set({ contactMessageReply: value }),

    allBannerList: [],
    setAllBannerList: (allBannerList) => set({ allBannerList }),

}))

export default useBannerStore;


//blue: Get Banners
export const getAllBanners = async () => {
    const { setLoading } = useGeneralStore.getState();
    const { setAllBannerList } = useBannerStore.getState();

    try {
        setLoading(true);
        const res = await axios.get(kuGetBanners)
        console.log('getAllBanners: ', res.data);
        if (res.data.success) {
            setAllBannerList(res.data.data);
        } else {
            Toastr(res.data.message);
        }
        setLoading(false);
    } catch (error) {
        console.log(error);
        Toastr(t("An error occurred!"));
        setLoading(false);
    }
}

// blue: Add Banner
export const createNewBanner = async (form, withImage) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        setLoading(true);
        const body = {
            title: form?.title,
            image: withImage,
            app_type: form?.app_type,
            start_date: form?.start_date,
            end_date: form?.end_date,
            description: form?.description,
        }
        const res = await axios.post(kuAddBanner, body)

        console.log('createNewBanner: ', res.data);
        if (res.data.success) {
            Toastr(res?.data?.message,'success');
            await getAllBanners();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr("An error occurred!");
        setLoading(false);
        return false;
    }
}

// blue: update Banner
export const updateBanner = async (form, withImage = "") => {
    const { setLoading } = useGeneralStore.getState();

    try {
        setLoading(true);
        let body;
        if (withImage) {
            body = {
                id: form.id,
                title: form.title,
                image: withImage,
                app_type: form.app_type,
                start_date: form.start_date,
                end_date: form.end_date,
                description: form.description,
            };
        } else {
            body = {
                id: form.id,
                title: form.title,
                app_type: form.app_type,
                start_date: form.start_date,
                end_date: form.end_date,
                description: form.description,
            };
        }

        console.log(body);

        const res = await axios.post(kuUpdateBanner, body);

        console.log('updateBanner: ', res.data);
        if (res.data.success) {
            Toastr(res?.data?.message,'success');
            await getAllBanners();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr("An error occurred!");
        setLoading(false);
        return false;
    }
}

//blue Delete Banner
export const handleDeleteBanner = async () => {
    const { selectedBanner } = useBannerStore.getState();
    const { setLoading } = useGeneralStore.getState();

    try {
        const body = { id: selectedBanner.id }
        setLoading(true);
        const res = await axios.post(kuDeleteBanner, body)

        console.log('handleDeleteBanner: ', res.data);
        if (res.data.success) {
            Toastr(res?.data?.message,"success");
            getAllBanners();
            setLoading(false);
            return true;
        } else {
            Toastr(res.data.message);
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log(error);
        Toastr("An error occurred!");
        setLoading(false);
        return false;
    }
}
