import { t } from 'i18next';
import { useContext } from 'react'
import useCompanyStore from '../../../../../../App/stores/CompanyStore';
import { takeShiftAction } from '../../../../../../App/stores/ShiftPlannerStore';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';

export default function PauseResumeShiftModal({ showModal, setShowModal, status, shift_id }) {
  const { getShiftPlannerDetails } = useContext(ShiftPlannerContext)
  const { companyID } = useCompanyStore();

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t("Shift Action")}
      mainContent={
        <>
          <div className="py-5 text-center text-fs16">
            {status === 'ongoing' ? t("Are you sure you want to pause this shift?") : t("Are you sure you want to resume this shift?")}
          </div>

          <div className="flex justify-center">
            <CommonButton
              btnLabel={t("Confirm")}
              onClick={async () => {
                let actionSuccess = await takeShiftAction((status === 'ongoing' ? 'break' : 'resume'), shift_id, getShiftPlannerDetails, () => { }, companyID, true);
                if (actionSuccess) setShowModal(false);
              }}
            />
          </div>
        </>
      }
    />
  )
}
