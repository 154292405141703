import { Toolbar } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SummaryInfoItem({ title = 'No title', description = 'No description', className, onClick }) {
  const { t } = useTranslation();
  return (
    <div className='flex flex-row justify-between items-center text-cMainBlack w-full my-[2px] space-x-4'>
      <div className='w-full text-sm font-fw400 overflow-clip whitespace-nowrap'>{t(title)}</div>

      <div>
        <div
          title={t(description)}
          onClick={onClick}
          className={`cursor-pointer text-sm font-fw600 overflow-clip whitespace-nowrap text-right truncate w-full ${className}`}>
          {/* <Toolbar title={description}>{description}</Toolbar> */}
          {description}
        </div>

      </div>

    </div>
  )
}
