/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CircularProgressBar from '../../../../../Components/CircularProgressBar';
import SearchInput from '../../../../../Components/Input/SearchInput';
import EmptyMessage from '../../../../../Components/EmptyMessage';
import { PageTitle } from '../../../../../Utility/UtilityFunctions';
import BackLinks from '../../../../../Components/Layout/BackLinks';
import { iRefresh } from '../../../../../Utility/Sources';
import useGlobalRequestStore, { getViewGlobalRequestsData } from '../../../../../App/stores/GlobalRequestStore';
import { useDebounce } from 'use-debounce';
import RequestItem from '../../../Common/RequestSection/components/RequestItem';
import useRequestStore, { getRequestDetails } from '../../../../../App/stores/RequestStore';
import { k_data_set } from '../../../../../App/Utility/const';
import RequestDetails from '../../../Common/RequestSection/RequestDetails';
import LimadiSearchBox from '../../../../../Components/Input/LimadiSearchBox';

function GlobalRequest({ userType }) {

    const { globalRequestSearch, setGlobalRequestSearch, globalRequestList, setGlobalRequestIndex, globalRequestIndex } = useGlobalRequestStore();
    const { request_details,setRequestActiveSection } = useRequestStore();
    const { company_id } = useParams();
    const { customer_id } = useParams();
    const { driver_id } = useParams();

    const [searchValue] = useDebounce(globalRequestSearch, 500);

    useEffect(() => {
        window.scrollTo(0, 0)
        PageTitle("Limadi | Global Request")
        setGlobalRequestSearch("")
        if (company_id) {
            setRequestActiveSection(k_data_set.invitation); //set Request Active Section
            if (globalRequestSearch) getViewGlobalRequestsData(company_id, globalRequestSearch);
            else getViewGlobalRequestsData(company_id, "");
        }
    }, [])

    useEffect(() => {
        if (company_id) {
            if (globalRequestSearch) getViewGlobalRequestsData(company_id, globalRequestSearch);
            else getViewGlobalRequestsData(company_id, "");
        }
    }, [searchValue])


    return (
        <>
            {company_id ?
                <BackLinks
                    linksArray={[
                        { label: "companies", linkTo: "/users/companies" },
                        { label: "company Details", linkTo: "/users/companies/details/" + company_id },
                        { label: "Global Request", linkTo: "" },
                    ]}
                /> : ""}


            {/* title */}
            <div className='flex flex-row justify-between align-middle card'>
                <div className='flex items-center w-2/3'>
                    <div className='text-2xl font-bold mt-[5px] mr-5'>{t("Global Request")}{" "}({globalRequestList?.length})
                    </div>
                    {/* refresh icon button */}
                    {
                        0 ?
                            <CircularProgressBar />
                            :
                            <img
                                onClick={() => {
                                    setGlobalRequestSearch("");
                                    if (company_id) { getViewGlobalRequestsData(company_id); }
                                }}
                                src={iRefresh}
                                alt="refresh-icon"
                                className='h-8 cursor-pointer'
                            />

                    }
                </div>
            </div>

            {/* content */}
            <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">

                {/* favorite address list */}
                <div className="col-span-12 mt-5 bg-white shadow rounded-xl lg:col-span-4">
                    <div className="flex-col justify-start">
                        {/* search bar */}

                        <div className='self-end px-5 py-5'>
                            <LimadiSearchBox
                                fullWidth={true}
                                placeholder={t('search')}
                                type="search"
                                onChange={(e) => { setGlobalRequestSearch(e.target.value) }}
                                name="searchKey"
                                value={globalRequestSearch}
                                withClearSearch={true}
                                onSearchClear={() => {
                                    setGlobalRequestSearch("");
                                }}
                            />
                        </div>

                        <ul className='overflow-y-auto max-h-[60vh] scroll-smooth'>
                            {
                                globalRequestList?.length > 0 ? globalRequestList?.map((item, index) =>
                                    <RequestItem
                                        title={item?.request_id}
                                        description={(item?.stops_count ?? 0) + '/' + (item?.products_count ?? 0)}
                                        key={index}
                                        index={index}
                                        isSelected={globalRequestIndex === index ? true : false}
                                        onSelect={() => {
                                            if (company_id) {
                                                setGlobalRequestIndex(index)
                                                getRequestDetails(k_data_set.invitation, item?.id, "company",company_id);
                                            }

                                        }}
                                    />
                                )
                                    : <EmptyMessage />
                            }
                        </ul>
                    </div>

                </div>

                {/* request details */}
                <div className="col-span-12 mt-5 lg:col-span-8">
                    {
                        request_details?.id ? <RequestDetails type="global-request"/>
                            :
                            <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
                                <EmptyMessage message={t('Please select a Request to see details.')} />
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default GlobalRequest