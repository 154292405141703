import React from 'react'

function Logo({source, className}) {
  return (
    <div>
        <img className={className} src={source} alt="img" />
    </div>
  )
}

export default Logo