import axios from 'axios';
import { t } from 'i18next';
import { create } from 'zustand';
import { Toastr } from '../../Utility/UtilityFunctions';
import { kuAddTransportationType, kuGetTransportationTypes, kuUpdateTransportationType } from '../../Utility/url';
import useGeneralStore from './GeneralStore';

const useTransportationTypesStore = create((set, get) => ({

    transportationTypes: [],
    setTransportationTypes: (value) => set({ transportationTypes: value }),

    transportationTypesTake: {},
    setTransportationTypesTake: (value) => set({ transportationTypesTake: value }),

    showAddTransportationTypeModal: false,
    setShowAddTransportationTypeModal: (value) => set({ showAddTransportationTypeModal: value }),

    editTransportationTypeMode: null,
    setEditTransportationTypeMode: (value) => set({ editTransportationTypeMode: value }),

}));


// add new transportation type  
export const addTransportationType = async (formData = {}) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        // return console.log("ADD new transportation type", formData);
        setLoading(true);
        const res = await axios.post(kuAddTransportationType, formData);
        console.log('addTransportationType: ', res.data);

        if (res.data.success) {
            Toastr(res.data.message, 'success');
            await getTransportationTypes();
            return true;
        }
        else Toastr(res.data.message);

        setLoading(false);
    } catch (e) {
        Toastr(t("An error occurred!"));
        setLoading(false);
        console.log('addTransportationType: ', e);
    }
}



// get all transportation types 
export const getTransportationTypes = async (paginationUrl = "") => {
    const { setLoading } = useGeneralStore.getState();
    const { setTransportationTypes, transportationTypesTake } = useTransportationTypesStore.getState();

    try {
        setLoading(true);
        let targetUrl = "";
        if (paginationUrl !== "") {
            paginationUrl = JSON.stringify(paginationUrl);
            targetUrl = paginationUrl.slice(
                paginationUrl.indexOf("?"),
                paginationUrl.length - 1
            );
            targetUrl = kuGetTransportationTypes + targetUrl;
        } else {
            targetUrl = kuGetTransportationTypes;
        }

        const res = await axios.get(targetUrl, { params: { take: transportationTypesTake } });
        console.log('getTransportationTypes: ', res.data);

        if (res.data.success) {
            setTransportationTypes(res.data);
            setLoading(false);
            return true
        }
        else {
            Toastr(res.data.message);
            setLoading(false);
            return false
        }
    } catch (e) {
        Toastr(t("An error occurred!"));
        console.log('getTransportationTypes: ', e);
        setLoading(false);
        return false

    }
}


// update transportation type..
export const updateTransportationType = async (formData, id) => {
    const { setLoading } = useGeneralStore.getState();

    try {
        if (formData === null) {
            Toastr(t("An error occurred!"));
            return;
        }
        setLoading(true);
        const res = await axios.put(kuUpdateTransportationType + "/" + id, formData);
        console.log('updateTransportationType: ', res.data);
        if (res.data.success) {
            await getTransportationTypes();
        } else {
            Toastr(res.data.message);
            setLoading(false);
        }
        return true;
    } catch (error) {
        console.log("updateTransportationType", error);
        Toastr(t("An error occurred!"));
        setLoading(false);
        return false;
    }
}

export default useTransportationTypesStore;