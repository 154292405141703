import { iClose } from "../../../../../../Utility/Sources";

const Chip = ({ title, onClick }) => {

  return (
    <>
      <div className="flex flex-row justify-start items-center bg-cLine rounded-full space-x-1 px-1.5 mr-2 mb-2 cursor-pointer">
        <div className="font-normal capitalize  text-cBodyText mr-s5"> {title} </div>
        <div className="select-none" onClick={onClick}> <img src={iClose} alt="close-icon" /> </div>
      </div>
    </>
  );
};

export default Chip;
