/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { BsClock } from 'react-icons/bs';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md';
import { Link } from 'react-router-dom';
import useGeneralStore from '../../App/stores/GeneralStore';
import { NotificationContext } from '../../Context/NotificationContext';
import { formatDate } from '../../Utility/UtilityFunctions';
import NA from '../NA';
import ListSkeleton01 from '../Skeletons/ListSkeleton01';

function NotificationDropdown2() {
    const [open, setOpen] = useState(false)
    const { getNotification, notification, isLoadingNotification, } = useContext(NotificationContext);
    const { numOfUnreadNotification } = useGeneralStore();

    useEffect(() => {
        if (localStorage.getItem('limadi_active_route') !== '/notifications/' && open) getNotification(false);
    }, [open]);

    return (
        <>
            {

                <div className="relative inline-block text-left">
                    <div className='cursor-pointer h-8 w-8 md:h-10 md:w-10 bg-gray-300 flex justify-center items-center rounded-full'
                        onClick={() => setOpen(!open)}>

                        <MdNotifications className="text-2xl" />

                        {(numOfUnreadNotification > 0) ? <div className='h-[8px] w-[8px] bg-cPrimary rounded-full absolute top-[25%] right-[25%]' /> : <></>}

                    </div>


                    {open && <div className={`transform ${open ? ' opacity-100 scale-100' : 'opacity-0 scale-95'} scale-95 origin-top-right absolute right-0 mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">

                        <div className="p-3 overflow-y-auto max-h-screen scroll-smooth bg-white shadow-lg rounded-lg">
                            <div className="flex justify-between items-center">
                                <div className="text-cBodyText text-fs14 font-fw600">{t("Notification")}</div>
                                <div onClick={() => setOpen(!open)} className="text-limadi hover:underline cursor-pointer text-sm">
                                    {notification.length > 0 &&
                                        <Link className='text-cMainBlack hover:text-cBrand underline font-semibold' to={"/notifications/"} >See All</Link>
                                    }
                                </div>
                            </div>

                            {isLoadingNotification ?
                                <div className='w-[400px] h-[400px]'>
                                    <ListSkeleton01 />
                                </div>
                                : notification.length === 0 ? (
                                    <div className='w-[400px] h-[200px]'>
                                        <NA />
                                    </div>
                                ) : notification.slice(0, 5).map((item) => (
                                    <div
                                        key={item.id}
                                        className="bg-gray-100 p-3 rounded-md my-2 cursor-pointer w-[400px]"
                                    >
                                        <div className="font-bold text-sm mb-1">
                                            {item.title}
                                        </div>
                                        <div className="text-xs">
                                            {item.description}
                                        </div>
                                        <div className="flex justify-end items-center text-gray-500 text-xs">
                                            <div className="flex justify-end items-center mr-4">
                                                {" "}
                                                <FaRegCalendarAlt className="mr-1 text-sm" /> {formatDate(item.created_date)}
                                            </div>
                                            <div className="flex justify-end items-center ">
                                                {" "}
                                                <BsClock className="mr-1 text-sm" /> 20.30
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>}


                </div>
            }
        </>
    )
}

export default NotificationDropdown2