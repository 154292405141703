// icons & customs
import { iScan, iSearch } from "../../Utility/Sources";

const ScanSearchSolidBox = ({
  onClick,
  onChange,
  name,
  value,
  label,
  placeholder,
  type = "text",
  className,
}) => {
  return (
    <div className={`relative ${className}`}>
      <div className="absolute top-[8px] left-[10px] cursor-pointer select-none">
        <img src={iSearch} alt="" />
      </div>
      <div className="absolute top-[6px] right-[10px] cursor-pointer select-none">
        <img src={iScan} alt="" />
      </div>
      <input
        onClick={onClick}
        onChange={onChange}
        type={type}
        name={name}
        value={value}
        className="bg-cListItem px-4 py-2 w-full text-sm outline-none rounded-br5 pl-10 pr-10"
        placeholder={placeholder}
      />
    </div>
  );
};

export default ScanSearchSolidBox;
