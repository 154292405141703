import React from "react";

export default function CommonLabel({
  labelText = "CanonDale...",
  required = false,
  text="text-fs14"
}) {
  return (
    <div className={`text-sm font-semibold capitalize ${text} text-cChipText`}>
      {labelText}
      {required ? <span className="text-cRed"> *</span> : ""}
    </div>
  );
}
