import { t } from 'i18next';
import { useContext } from 'react';
import { releaseStop, useTrackOnlineDriverPatchStore } from '../../../../../../App/stores/TrackOnlineDriverStore';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';

export default function ReleaseStopModal({ showModal, setShowModal, shift_id, route_id, setTargetStop, u_id }) {
  const { setShowReleaseStopModal, isShowReleaseStopModal } = useTrackOnlineDriverPatchStore();

  const {
    getRoutePlannerList,
    setSearchStoptKey,
    getTrackOnlineList,
    setSelectedStop,
    setCurrentRouteSelection
  } = useContext(TrackOnlineContext);
  return (
    <CommonModal
      showModal={isShowReleaseStopModal}
      setShowModal={setShowReleaseStopModal}
      modalTitle={'Stop Action'}
      mainContent={
        <>
          <div className="py-5 text-center text-fs16">
            {'Are you sure you want to release this stop?'}
          </div>

          <div className="flex justify-center items-center" >
            <CommonButton
              btnLabel={t("Confirm")}
              onClick={async () => {
                const success = releaseStop(route_id, 'un_hold');
                if (success) {
                  setSearchStoptKey("");
                  setShowModal(false);
                  setTargetStop(null);
                  setSelectedStop(null);
                  setCurrentRouteSelection(null);
                  // await getRoutePlannerList(shift_id);
                  await getTrackOnlineList(false, u_id);
                  await getRoutePlannerList(shift_id, true);

                }
              }}
            />
          </div>

        </>
      }
    />
  )
}
