/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ThreeDots } from 'react-loader-spinner';
import { useState } from 'react';
import useChatStore, { getChatUserList, getMessageList } from '../../../App/stores/chatStore';
import CommonModal from '../../../Components/Modal/CommonModal';
import LimadiSearchBox from '../../../Components/Input/LimadiSearchBox';
import { role } from '../../../App/Utility/const';

import CommonImage from '../../../Components/Image/CommonImage';

const AddChatModal = () => {

    const { t } = useTranslation();

    const { selectChat,setTextFieldCount, setAddChatUserList, chat_list, setSelectChat, showAddChatModal, setShowAddChatModal, add_chat_user_list, user_search, setUserSearch, setAddMessageArray } = useChatStore();

    const [searchValue] = useDebounce(user_search, 500);

    const [scroll, setScroll] = useState(false);

    const incrementData = async () => {
        setScroll(true);
        const success = await getChatUserList({ search: user_search,pagination: true, isLoading: false })
        if (success?.success) {
            setAddChatUserList({
                current_page: success?.data?.current_page,
                last_page: success?.data?.last_page,
                data: [...add_chat_user_list?.data, ...success?.data?.data],
                total: success?.data?.total
            });
            setScroll(false);
        } else {
            setScroll(false);
        }
    }

    useEffect(() => {
        setUserSearch("")
        if (showAddChatModal) {
            getChatUserList({ search: user_search, pagination: false, isLoading: true });
        }
    }, [showAddChatModal])

    useEffect(() => {
        if (showAddChatModal) {
            getChatUserList({ search: user_search,  pagination: false, isLoading: false });
        }
    }, [searchValue])

    return (
        <div>
            <CommonModal
                showModal={showAddChatModal}
                setShowModal={setShowAddChatModal}
                modalTitle={t("Chat with")}
                modalSpace={true}
                mainContent={
                    <>
                        <div className='my-5'>
                            <LimadiSearchBox
                                value={user_search}
                                onChange={(e) => { setUserSearch(e.target.value) }}
                                fullWidth={true}
                                onSearchClear={() => { setUserSearch("") }}
                                withClearSearch={true}
                                roundedFull={false}
                            />
                        </div>

                        <div className='space-y-3'>
                            {
                                add_chat_user_list?.data?.length > 0 ?
                                    <div id="scrollableDiv">
                                        <InfiniteScroll
                                            height={add_chat_user_list?.data?.length > 6 ? 400 : add_chat_user_list?.data?.length * 60}
                                            dataLength={add_chat_user_list?.data?.length ?? 0} //This is important field to render the next data
                                            next={() => {
                                                if (!scroll && (add_chat_user_list?.current_page < add_chat_user_list?.last_page)) {
                                                    incrementData()
                                                }
                                            }}
                                            hasMore={!scroll && add_chat_user_list?.current_page < add_chat_user_list?.last_page}
                                            scrollableTarget="scrollableDiv"
                                            scrollThreshold={0.9}
                                        >
                                            {
                                                add_chat_user_list?.data?.map((item, index) => (
                                                    <CommonUserList
                                                        onClick={async () => {
                                                            setAddMessageArray([]);
                                                            setTextFieldCount(40);
                                                            await setSelectChat({
                                                                id: item?.chat_id,
                                                                channel_name: item?.user_role === role.company ? role.admin +  "-" + item?.user_role : item?.user_role+  "-" + role.admin,
                                                                image: item?.image,
                                                                is_active: 1,
                                                                name: item?.name,
                                                                user_id: item?.id,
                                                                user_type: item?.user_role,
                                                                new:selectChat?.new?!selectChat?.new:true
                                                            });
                                                            if (item?.chat_id) {
                                                                 await getMessageList({ "chat_id": item?.chat_id, take: 10 })
                                                            }

                                                            setShowAddChatModal(false);
                                                            if(chat_list?.data?.length > 0){
                                                                const index = chat_list?.data?.findIndex(i => i?.id === item?.chat_id);
                                                                chat_list.data[index].last_message.to_is_read = 1;
                                                            }
                                                        }}
                                                        key={index}
                                                        name={item?.name}
                                                        user_type={item?.user_role}
                                                        imageUrl={item?.image}
                                                    />
                                                ))
                                            }

                                            {
                                                scroll && <div className='flex justify-center w-full'>
                                                    {<ThreeDots
                                                        height="40"
                                                        width="40"
                                                        radius="9"
                                                        color="#F89818"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />}
                                                </div>
                                            }
                                        </InfiniteScroll>
                                    </div>
                                    :
                                    <div className='text-center'>No user found !</div>
                            }
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default AddChatModal


const CommonUserList = ({ name = "", user_type = "",imageUrl, onClick = () => { } }) => {

    return (
        <div onClick={onClick} className='flex space-x-2 cursor-pointer hover:bg-[#DFEAFB] p-2 rounded-br4'>
            <div className='bg-cTintBlue rounded-full w-[44px] h-[44px] flex items-center justify-center'>

                <CommonImage
                    alt=""
                    src={imageUrl}
                    className='max-w-[44px] min-w-[44px] h-[44px] rounded-full'
                />

            </div>
            <div>
                <div className='capitalize text-[#0E1826] font-fw500 text-fs16'>{name}</div>
                <div className='capitalize text-[#89919E] font-fw400 text-fs12 mt-s2'>
                    {user_type}
                </div>
            </div>
        </div>
    )
}