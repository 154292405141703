import { t } from 'i18next';
import axios from 'axios'
import create from 'zustand'
import useGeneralStore from './GeneralStore';
import { kuShiftAction } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';


const useShiftPlannerStore = create((set) => ({
  isShowShiftPauseResumeModal: false,
  setShowShiftPauseResumeModal: (value) => set({ isShowShiftPauseResumeModal: value }),

  isShowShiftStartStopModal: false,
  setShowShiftStartStopModal: (value) => set({ isShowShiftStartStopModal: value }),

  hideShiftDetails: false,
  setHideShiftDetails: (data) => set((state) => state.hideShiftDetails = data),

  companyTrackOnlineDrive: "",
  setCompanyTrackOnlineDrive: (value) => set({ companyTrackOnlineDrive: value }),
}))

const { setLoading } = useGeneralStore.getState()


export const takeShiftAction = async (action, shift_id, getShiftPlannerDetails, getShiftPlannerList, company_id, isDriver = false) => {
  try {
    if (action === null) {
      Toastr(t("Invalid Action!", 'warning'))
      return
    } else if (shift_id === null) {
      Toastr(t("Invalid Shift!", 'warning'))
      return
    }

    console.log('takeShiftAction: BODY:::', action, " ", shift_id, " company_id::", company_id, " isDriver::", isDriver);
    // return;

    setLoading(true)
    let res = await axios.post(kuShiftAction, { id: shift_id, action: action, user_id: company_id });
    console.log('takeShiftAction: ', res.data);

    if (res.data.success) {
      if (action === 'complete') {
        if (isDriver) getShiftPlannerList(company_id, true);
        else getShiftPlannerList(company_id);
        // useShiftPlannerStore.getState().setHideShiftDetails(true)
      } else {
        if (isDriver) getShiftPlannerDetails(shift_id, company_id, true, company_id);
        else getShiftPlannerDetails(shift_id, company_id);
      }
      return true;
    } else {
      Toastr(res.data.message);
    }

    setLoading(false)
  } catch (err) {
    Toastr(t("An error occurred!"));
    setLoading(false)
  }
}

// helper functions

export const checkCanShiftStart = ({ shiftInfo }) => {
  if (shiftInfo?.status === "init" && shiftInfo?.starts_in_time_unit !== "days") {
    if (shiftInfo?.starts_in_time_unit === 'hours' && shiftInfo?.starts_in_raw >= 2) return false;
    return true
  }
  else return false
}

export const checkIfShiftIsRunning = ({ shiftInfo }) => {
  if (shiftInfo?.status === "ongoing" || shiftInfo?.status === "break") {
    return true;
  } else {
    return false;
  }
}

export const checkIfShiftIsExpired = ({ shiftInfo }) => {
  if (shiftInfo?.status === "init" && shiftInfo?.starts_in_time_unit === "days" && shiftInfo?.starts_in_raw <= 0) {
    return true;
  } else {
    return false;
  }
}


export default useShiftPlannerStore