import React from 'react'
import CommonModal from '../../../../../Components/Modal/CommonModal'
import useRequestStore from '../../../../../App/stores/RequestStore';
import CommonImage from '../../../../../Components/Image/CommonImage';
import { iDummyCompany } from '../../../../../Utility/Sources';
const BiddingCompaniesDetails = () => {

    const { biddingCompaniesDetails,  biddingCompaniesDetailsModal, setBiddingCompaniesDetailsModal } = useRequestStore();

    return (
        <div>
            <CommonModal
                showModal={biddingCompaniesDetailsModal}
                setShowModal={setBiddingCompaniesDetailsModal}
                modalTitle={"Company Details"}
                mainContent={
                    <div className=" mt-s20">
                        <>
                            <div className='mt-s20'>
                                <div className='grid grid-cols-12 gap-3'>

                                    <div className='col-span-12 md:col-span-5'>
                                        <div className='flex flex-col items-center justify-center p-2 space-y-1'>
                                            <div className='relative'>
                                                {/* <Image src={company_details?.image} dummyImage={iFavCompanyNormal} alt="" className='object-cover  w-[150px] h-[150px] rounded-full' /> */}
                                                <div className={`max-w-[160px] min-w-[160px] h-s160 rounded-full ring-1 ring-cGrey flex justify-center items-center`}>
                                                    <CommonImage
                                                        src={biddingCompaniesDetails?.company_info?.image}
                                                        dummyImage={iDummyCompany}
                                                        className='max-w-[150px] min-w-[150px] h-s150 bg-cover bg-center rounded-full bg-clip-border'
                                                        alt=""
                                                    />
                                                </div>
                                                {/* {biddingCompaniesDetails?.company_info??.is_favorite &&
                                                <div className='p-[6px] shadow-md bg-slate-50 rounded-full absolute top-3 right-3'>
                                                    <img className="" src={iFavoriteIcon} alt="" />
                                                </div>
                                            } */}
                                            </div>
                                            <div className='text-center break-all text-fs14 text-cSecondaryTextColor font-fw600'>{biddingCompaniesDetails?.company_info?.name}</div>

                                            {/* <div className='cursor-pointer' onClick={() => { 
                                            setSelectedCompanyId(company_details?.id)
                                            setShowReviewModal(true)
                                            }}>
                                            <RatingFiveStar rating={Math.round(company_details?.rating)} />
                                        </div> */}

                                        </div>
                                    </div>


                                    <div className='col-span-12 md:col-span-7'>
                                        {<div className=''>
                                            <div className='text-fs16 font-fw600 text-cMainBlack'>Bidding Details</div>

                                            <div className='justify-between mt-s10 mb-s8'>
                                                <div className="text-fs14 text-cMainBlack font-fw600">Budget</div>
                                                <div>{'DKK ' + biddingCompaniesDetails?.budget?.toLocaleString("da-DK")}</div>
                                            </div>

                                            <div className='justify-between mt-s5 mb-s8'>
                                                <div className="text-fs14 text-cMainBlack font-fw600">Description</div>
                                                <div>{ biddingCompaniesDetails?.details ?? "No description"}</div>
                                            </div>

                                            {/* <CommonViewComponent labelText={t('Description')} value={bidding_info?.details} /> */}


                                        </div>}




                                    </div>
                                </div>
                            </div>
                        </>

                    </div>
                }
            />
        </div>
    )
}

export default BiddingCompaniesDetails