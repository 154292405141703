import 'antd/dist/antd.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './App/store';
import AuthContextProvider from './Context/AuthContext';
import CarManagementContextProvider from './Context/CarManagementContext';
import CloudRequestContextProvider from './Context/CloudRequestContext';
import CompanyContextProvider from './Context/CompanyContext';
import DriverManagementContextProvider from './Context/DriverManagementContext';
import FavoriteAddressContextProvider from './Context/FavoriteAddressContext';
import FavoriteCompanyContextProvider from './Context/FavoriteCompanyContext';
import NotificationContextProvider from './Context/NotificationContext';
import RequestContextProvider from './Context/RequestContext';
import SettingsContextProvider from "./Context/SettingsContext";
import ShiftPlannerContextProvider from './Context/ShiftPlannerContext';
import TrackOnlineContextProvider from './Context/TrackOnlineContext';
import './i18n';
import './index.css';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <ShiftPlannerContextProvider>
          <TrackOnlineContextProvider>
            <CloudRequestContextProvider>
              <FavoriteCompanyContextProvider >
                <CarManagementContextProvider >
                  <DriverManagementContextProvider>
                    <FavoriteAddressContextProvider>
                      <RequestContextProvider>
                        <SettingsContextProvider>
                          <CompanyContextProvider>
                            <NotificationContextProvider>
                              <Provider store={store}>
                                <Suspense fallback="">
                                  <App />
                                </Suspense>
                              </Provider>
                            </NotificationContextProvider>
                          </CompanyContextProvider>
                        </SettingsContextProvider>
                      </RequestContextProvider>
                    </FavoriteAddressContextProvider>
                  </DriverManagementContextProvider>
                </CarManagementContextProvider>
              </FavoriteCompanyContextProvider>
            </CloudRequestContextProvider>
          </TrackOnlineContextProvider>
        </ShiftPlannerContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);