import { t } from 'i18next';
import { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CircularProgressBar from '../../../../../../Components/CircularProgressBar';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iAddStop, iBackIcon, iCalculateStop, iRefresh } from '../../../../../../Utility/Sources';

const RoutePlannerBar = ({ shift_id, setTargetStop }) => {
  const {
    getRoutePlannerList,
    routeList,
    isLoading,
    setSearchStoptKey,
    getRouteCalculatedList,
    setSelectedStop,
    setCurrentRouteSelection
  } = useContext(TrackOnlineContext);

  const { company_id } = useParams();

  //hg helper function to refresh the list
  const refreshFoo = async () => {
    setSearchStoptKey("");
    getRoutePlannerList(shift_id, true);
    setSelectedStop(null);
    setCurrentRouteSelection(null);
    setTargetStop(null)
  };

  const navigate = useNavigate();

  return (
    <div className="card flex flex-row justify-between items-center align-middle">
      <div className="flex items-center w-2/3">
        <div className="text-2xl font-bold mt-[5px] mr-5">
          {t("Route Planner")} ({routeList ? routeList.length : "--"})
        </div>

        {/* refresh icon button */}
        {!isLoading ? (
          <img
            onClick={refreshFoo}
            src={iRefresh}
            alt="refresh-icon"
            className="h-8 cursor-pointer"
          />
        ) : (
          <CircularProgressBar />
        )}
      </div>

      <div className="flex justify-center items-center">
        <Link to="add-stop">
          <button className="">
            <span className="hidden xl:block">
              <CommonButtonOutlined
                btnLabel={t("Add Stop")}
              />
            </span>
            <span className='xl:hidden flex items-center justify-center  text-cPrimary hover:text-cWhite border-[2px]  bg-cPrimary rounded-lg text-[16px] text-center h-[43px] w-[50px] font-semibold outline-none'>
              <img
                src={iAddStop}
                alt=""
                className="w-[25px] h-[25px] gs-text-primary "
              />
            </span>
          </button>
        </Link>


        <button
          onClick={() => {
            getRouteCalculatedList(shift_id, company_id);
          }}
          className="px-5"
        >
          <span className="hidden xl:block">
            <CommonButtonOutlined
              btnLabel={t("Calculate Route")}
            />
          </span>
          <span className='xl:hidden flex items-center justify-center  text-cPrimary hover:text-cWhite border-[2px]  bg-cPrimary rounded-lg text-[16px] text-center h-[43px] w-[50px] font-semibold outline-none'>
            <img
              src={iCalculateStop}
              alt=""
              className="w-[25px] h-[25px] gs-text-primary xl:hidden"
            />
          </span>
        </button>

        <div onClick={() => navigate(-1)}>
          <button>
            <span className="hidden xl:block">
              <CommonButtonOutlined
                btnLabel={t("Back")}
              />
            </span>
            <span className='xl:hidden flex items-center justify-center  text-cPrimary hover:text-cWhite border-[2px]  bg-cPrimary rounded-lg text-[16px] text-center h-[43px] w-[50px] font-semibold outline-none'>
              <img
                src={iBackIcon}
                alt=""
                className="w-[25px] h-[25px] gs-text-primary xl:hidden"
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoutePlannerBar;
