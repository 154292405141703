import axios from "axios";
import { t } from 'i18next';
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useGeneralStore from '../App/stores/GeneralStore';
import { addMarker, useMapPatchStore } from '../App/stores/MapStore';
import useTrackOnlineDriverStore, { useTrackOnlineDriverPatchStore } from '../App/stores/TrackOnlineDriverStore';
import { kuAddCustomStop, kuDeleteCustomStop, kuRouteAction, kuRouteCalculate, kuRoutePlannerList, kuShiftAction, kuTrackOnlineList } from '../Utility/url';
import { Toastr } from "../Utility/UtilityFunctions";
import { ShiftPlannerContext } from "./ShiftPlannerContext";

export const TrackOnlineContext = createContext();

const TrackOnlineContextProvider = (props) => {
  const { getShiftPlannerDetails, isShiftDetailsData } = useContext(ShiftPlannerContext)
  const { shift_id } = useTrackOnlineDriverStore()
  const { setLoading } = useGeneralStore()
  const navigate = useNavigate()
  const location = useLocation()

  //Track List
  const [trackList, setTrackList] = useState([]);
  const [trackListTemp, setTrackListTemp] = useState([]);

  //Route List
  const [routeList, setRouteList] = useState([]);
  const [routeListTemp, setRouteListTemp] = useState([]);

  //Request List
  const [requestList, setRequestList] = useState([]);
  const [requestListTemp, setRequestListTemp] = useState([]);
  const [searchRequestKey, setSearchRequestKey] = useState("");
  const [showReqDetails, setShowReqDetails] = useState(false);

  //Stop List
  const [stopList, setStopList] = useState([]);
  const [stopListTemp, setStopListTemp] = useState([]);
  const [searchStoptKey, setSearchStoptKey] = useState("");

  //Package List
  const [PackageList, setPackageList] = useState([]);
  const [PackageListTemp, setPackageListTemp] = useState([]);
  const [searchPackageKey, setSearchPackageKey] = useState("");

  const [isRoutePlanner, setIsRoutePlanner] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isTrackShiftDetails, setIsTrackShiftDetails] = useState(false);
  const [currentFilterSelection, setCurrentFilterSelection] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [currentRouteSelection, setCurrentRouteSelection] = useState(-1);
  const [selectionStarted, setSelectionStarted] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  //action
  const [shiftAction, setShiftAction] = useState();
  const [shiftActionID, setShiftActionID] = useState();

  //popup modals actions
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [showStopShiftModal, setShowStopShiftModal] = useState(false);
  const [showPauseShiftModal, setShowPauseShiftModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showIncompleteDeliveryModal, setShowIncompleteDeliveryModal] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [confirmationComment, setConfirmationComment] = useState("");

  //stops
  const [stopId, setStopId] = useState();
  const [selectedRequest, setSelectedRequest] = useState(0);
  const [selectedStop, setSelectedStop] = useState();

  //packages
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedPackageRequest, setSelectedPackageRequest] = useState();

  //accordions
  const [isOpenFIrstAccordion, setIsOpenFIrstAccordion] = useState("");

  //add stop
  const [addStopName, setAddStopName] = useState("");
  const [addStopStartDate, setAddStopStartDate] = useState("");
  const [addStopStartTime, setAddStopStartTime] = useState("");
  const [addStopEndTime, setAddStopEndTime] = useState("");
  const [addStopAddress, setAddStopAddress] = useState("");
  const [addStopLat, setAddStopLat] = useState("");
  const [addStopLng, setAddStopLng] = useState("");
  const [showFavAddress, setShowFavAddress] = useState(false);
  const [addStopComment, setAddStopComment] = useState("");
  const [addStopAttachment, setAddStopAttachment] = useState(null);
  const [clearOnSubmit, setClearOnSubmit] = useState(false);

  // const {  } = useMapStore()


  const statusData = {
    ongoing: "started",
    break: "started",
    init: "not started",
    1: "in maintenance",
  };

  /*
  |--------------------------------------------------------------------------
  | Helper Functions
  |--------------------------------------------------------------------------
  */

  //search a track with free text
  const searchTrack = (event) => {
    setSearchKey(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = trackListTemp.filter((item) => {
      if (item.car_name) {
        let name = "";

        name = item.car_name ?? "";

        if (
          name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.car_license_plate_number
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.status.toLowerCase().includes(event.target.value.toLowerCase())
        ) {
          return item;
        } else {
          // eslint-disable-next-line array-callback-return
          Object.keys(statusData).filter((index) => {
            if (statusData[index].includes(event.target.value.toLowerCase())) {
              console.log(index);
              if (item.status === index) {
                console.log(item);
                return item;
              }
            } else return null;
          });
        }
      }
    });

    setTrackList(result);
  };

  //search a Request with free text
  const searchRequest = (event) => {
    setSearchRequestKey(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = requestListTemp.filter((item) => {
      if (item.title) {
        let name = "";

        name = item.title ?? "";

        if (
          name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.pickup_address
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        ) {
          return item;
        }
      }
    });

    setRequestList(result);
  };

  //search a Stop with free text
  const searchStop = (event) => {
    setSearchStoptKey(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = routeListTemp.filter((item) => {
      if (item.title) {
        let name = "";

        name = item.title ?? "";

        if (
          name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.address
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.stop_details.products.length === event.target.value.toLowerCase()
        ) {
          return item;
        }
      }
    });

    setRouteList(result);
  };

  //search a Stop only with free text
  const searchStopListOnly = (event) => {
    setSearchStoptKey(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = stopListTemp.filter((item) => {
      if (item.title) {
        if (
          item.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.address
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item?.products.length === event.target.value.toLowerCase()
        ) {
          return item;
        }
      }
    });

    setStopList(result);
  };

  //search a Package with free text
  const searchPackage = (event) => {
    setSearchPackageKey(event.target.value);

    // eslint-disable-next-line array-callback-return
    const result = PackageListTemp.filter((item) => {
      if (item.text) {
        let name = "";

        name = item.text ?? "";

        if (
          name.toLowerCase().includes(event.target.value.toLowerCase()) ||
          item.stop_title
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        ) {
          return item;
        }
      }
    });

    setPackageList(result);
  };

  //filtering track
  const filterTrack = () => {
    // setSearchKey(event.target.value);
    let result;
    // eslint-disable-next-line array-callback-return
    if (currentFilterSelection === 1) {
      // show::started
      // eslint-disable-next-line array-callback-return
      result = trackListTemp.filter((item) => {
        if (item.status !== "init" && item.is_maintenance === 0) {
          return item;
        }
      });
    }
    if (currentFilterSelection === -1) {
      // show::started
      // eslint-disable-next-line array-callback-return
      result = trackListTemp.filter((item) => {
        if (item.status === "init" && item.is_maintenance === 0) {
          return item;
        }
      });
    }
    if (currentFilterSelection === -2) {
      // show::started
      // eslint-disable-next-line array-callback-return
      result = trackListTemp.filter((item) => {
        if (item.is_maintenance === 1) {
          return item;
        }
      });
    }

    setTrackList(result);
  };

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */



  // get track online main page list
  const getTrackOnlineList = async (isAddMarker = true, user_id) => {
    const { setHasMarkerPoint, setIsSetPickupPoint, setPickupPoints } = useTrackOnlineDriverPatchStore.getState();

    setLoading(true)
    try {
      const res = await axios.get(kuTrackOnlineList, { params: { "user_id": user_id } })
      console.log("getTrackOnlineList: ", res.data)

      if (res.data.success) {
        if (isAddMarker) {
          let x = generatePickupPoints(res.data.data);
          if (x.length > 0) {
            setHasMarkerPoint(true);
            setIsSetPickupPoint(true);
            setPickupPoints(x);
            addMarker(x);
          } else setHasMarkerPoint(false)
          console.log('getTrackOnlineList - pickup_points: ', x)
        } else {
          setIsSetPickupPoint(false);
        }

        setTrackList(res.data.data)
        setTrackListTemp(res.data.data)

      } else {
        Toastr(res.data.message);
        console.log(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      console.log(err);
      Toastr(t("An error occurred!"));
      console.log("An error occurred!");
      setLoading(false);
    }
  }

  const generatePickupPoints = (shifts) => {
    const { setHasMarkerPoint } = useTrackOnlineDriverPatchStore.getState();

    let x = [];
    shifts.forEach((shift) => {
      if (shift.status === 'ongoing' && shift.lat !== null && shift.lng !== null)
        x.push({ pickup_lat: shift.lat, pickup_lng: shift.lng });
    })
    console.log('generatePickupPoints: ', x);
    if (x.length > 0) setHasMarkerPoint(true)
    else setHasMarkerPoint(false)
    return x;
  }

  //get main list of route planner
  const getRoutePlannerList = async (shift_id, performRouteOperation = false) => {
    const { setPickupPoints, setRoutePlannerPickupPoints, setHasRoutePlannerMarkerPoint } = useTrackOnlineDriverPatchStore.getState();
    try {
      setLoading(true);
      const res = await axios.get(kuRoutePlannerList, { params: { shift_id: shift_id } })
      console.log("getRoutePlannerList: ", res.data)

      if (res.data.success) {
        if (performRouteOperation) {
          let x = generateStopsPickupPoints(res.data.data)
          setRoutePlannerPickupPoints(x)
          // console.log('pickup_points: ', x);
          if (x.length > 0) {
            setHasRoutePlannerMarkerPoint(true)
            setPickupPoints(x);
            addMarker(x, true);
          } else setHasRoutePlannerMarkerPoint(false);
        }

        setRouteList(res.data.data)
        setRouteListTemp(res.data.data)
      } else {
        Toastr(res.data.message)
        console.log(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      Toastr(t("An error occurred!"))
      console.log(err);
      setLoading(false)
    }
  }

  const generateStopsPickupPoints = (stops) => {
    const { setHasMarkerPoint } = useTrackOnlineDriverPatchStore.getState();

    let x = [];
    stops.forEach((stop) => {
      if (stop.lat !== null && stop.lng !== null)
        x.push({ pickup_lat: stop.lat, pickup_lng: stop.lng });
    })
    if (x.length > 0) setHasMarkerPoint(true)
    else setHasMarkerPoint(false)
    return x;
  }

  //Calculate main list of route planner
  const getRouteCalculatedList = async (shift_id, u_id = 0) => {
    const { setPickupPoints } = useTrackOnlineDriverPatchStore.getState();
    const { setIsSetPloyLine } = useMapPatchStore.getState();

    try {
      setLoading(true);
      const res = await axios.get(kuRouteCalculate, { params: { shift_id: shift_id } });
      console.log("getRouteCalculatedList: ", res.data);

      if (res.data.success) {
        console.log("TRACKED!!  getRouteCalculatedList: ", shift_id, u_id);
        setIsSetPloyLine(true);
        let x = generateStopsPickupPoints(res?.data?.data);
        console.log('pickup_points: ', x)
        setPickupPoints(x);
        setSelectedStop(null);
        setRouteList(res.data.data);
        setRouteListTemp(res.data.data);
        setCurrentSelection(null);
        getTrackOnlineList(false, u_id);
      } else {
        Toastr(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      Toastr(t("An error occurred!"))
      setLoading(false)
    }
  }

  //!take action for the ongoing stop details actions: not delivered, delivered, hold, etc.
  const takeStopAction = async (actionToTake, u_id = 0) => {
    let targetID = isShiftDetailsData?.on_going_stop?.route_id;
    if (!targetID) targetID = selectedStop
    if (!targetID) {
      Toastr(t("Invalid stop selected!"))
      return
    }
    console.log("takeStopAction: ", location.pathname);

    try {
      setLoading(true)

      let body;
      if (actionToTake === "hold") {
        body = { id: targetID, action: actionToTake }
      } else {
        body = {
          id: targetID,
          action: actionToTake,
          comment: confirmationComment,
          attachment: attachmentImage,
          signature: signatureImage,
        };
      }
      // console.log(body);

      const res = await axios.post(kuRouteAction, body);
      console.log('takeStopAction: ', res.data)

      if (res.data.success) {
        await getTrackOnlineList(true, u_id);
        if (!currentSelection) setCurrentSelection(shift_id)
        currentSelection && await getShiftPlannerDetails(currentSelection);
        if (location.pathname.includes('/track-online-driver/route-planner/')) {
          console.log('shift_id', shift_id);
          await getRoutePlannerList(shift_id)
          setSelectedStop(null)
        }
      } else {
        Toastr(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      Toastr(t("An error occurred!"));
      setLoading(false)
    }
  }

  //take action for the ongoing shift details actions: stop, pause, resume.
  const takeShiftAction = async (action = null, u_id = 0) => {
    try {
      if (!currentSelection) {
        Toastr(t("Invalid shift"))
        return
      } else if (!action) {
        Toastr(t("Invalid action"))
        return
      }

      setLoading(true)
      let body;
      if (currentSelection !== null) {
        body = {
          action: action,
          id: currentSelection,
        };
      }
      console.log("takeShiftAction: ", body);
      // return;

      const res = await axios.post(kuShiftAction, body);


      console.log('takeShiftAction: ', res.data);

      if (res.data.success) {
        if (action !== 'complete') await getShiftPlannerDetails(currentSelection);
        if (action === 'complete') setCurrentSelection(null)
        getTrackOnlineList(true, u_id);
      } else {
        setShiftAction(null);
        Toastr(res.data.message);
      }
      setLoading(false)
    } catch (err) {
      Toastr(t("An error occurred!"));
      setShiftAction(null);
      setLoading(false)
    }
  }

  const addCustomStop = async (shift_id) => {
    if (!shift_id) {
      Toastr(t("Invalid shift"))
      return
    }
    // const { is_custom_stop_date_valid, shift_date_time_range } = useTrackOnlineDriverStore.getState();
    const { is_custom_stop_date_valid, shift_date_time_range } = useTrackOnlineDriverPatchStore.getState();

    if (!is_custom_stop_date_valid) {
      Toastr('Selected date time is not in shift date time range! Valid date time range is' + shift_date_time_range, 'warning')
      return;
    }

    setLoading(true)

    try {
      const body = {
        title: addStopName,
        shift_id: shift_id,
        date: addStopStartDate,
        start_time: addStopStartTime,
        address: addStopAddress,
        comment: addStopComment,
        end_time: addStopEndTime,
        lat: addStopLat,
        lng: addStopLng,
        attachment: addStopAttachment,
      };
      console.log("addCustomStop BODY: ", body);

      const res = await axios.post(kuAddCustomStop, body);
      console.log('addCustomStop: ', res.data)

      if (res.data.success) {
        setClearOnSubmit(true)
        navigate(-1)
        setLoading(false)
        return true
      } else {
        setLoading(false)
        Toastr(res.data.message)
        return false;
      }
    } catch (err) {
      Toastr(t("An error occurred!"))
      setLoading(false)
      return false;
    }
  }

  const deleteCustomStop = async (targetStopId) => {
    try {
      if (!targetStopId) {
        Toastr(t("Invalid stop"))
        return
      }

      setLoading(true)
      const res = await axios.post(kuDeleteCustomStop, { id: targetStopId })
      console.log('deleteCustomStop: ', res.data)

      if (res.data.success) {
        //todo:: may be we need to refresh the list
      } else {
        Toastr(res.data.message)
      }

      setLoading(false)
    } catch (err) {
      Toastr(t("An error occurred!"))
      setLoading(false)
    }
  }

  //ALL list updates
  useEffect(() => {
    //request
    setRequestList(isShiftDetailsData?.reqs);
    setRequestListTemp(isShiftDetailsData?.reqs);

    //package
    setPackageList(isShiftDetailsData?.products);
    setPackageListTemp(isShiftDetailsData?.products);

    //Stop list
    setStopList(isShiftDetailsData?.stops);
    setStopListTemp(isShiftDetailsData?.stops);
  }, [isShiftDetailsData]);

  return (
    <TrackOnlineContext.Provider
      value={{
        // state
        isLoading,
        currentSelection,
        selectionStarted,
        showFilterModal,
        currentFilterSelection,
        isRoutePlanner,
        currentRouteSelection,
        trackList,
        trackListTemp,
        isTrackShiftDetails,
        searchKey,
        shiftAction,
        stopId,
        selectedRequest,
        selectedStop,
        isOpenFIrstAccordion,
        selectedPackage,
        selectedPackageRequest,
        routeList,
        routeListTemp,
        shiftActionID,
        showHoldModal,
        showDeliveryModal,
        showIncompleteDeliveryModal,
        signatureImage,
        attachmentImage,
        confirmationComment,
        showStopShiftModal,
        addStopAddress,
        addStopLat,
        addStopLng,
        showFavAddress,
        addStopAttachment,
        addStopName,
        addStopStartDate,
        addStopStartTime,
        addStopComment,
        addStopEndTime,
        clearOnSubmit,
        requestList,
        requestListTemp,
        stopList,
        stopListTemp,
        PackageList,
        PackageListTemp,
        searchRequestKey,
        searchPackageKey,
        searchStoptKey,
        showPauseShiftModal,
        showReqDetails,

        // actions
        setIsLoading,
        setCurrentSelection,
        setSelectionStarted,
        setShowFilterModal,
        setCurrentFilterSelection,
        setIsRoutePlanner,
        setCurrentRouteSelection,
        setTrackList,
        setTrackListTemp,
        setIsTrackShiftDetails,
        setSearchKey,
        searchTrack,
        setShiftAction,
        setStopId,
        setSelectedRequest,
        setSelectedStop,
        setIsOpenFIrstAccordion,
        setSelectedPackage,
        setSelectedPackageRequest,
        setRouteList,
        setRouteListTemp,
        setShiftActionID,
        setShowHoldModal,
        setShowDeliveryModal,
        setShowIncompleteDeliveryModal,
        setSignatureImage,
        setAttachmentImage,
        setConfirmationComment,
        setShowStopShiftModal,
        setAddStopAddress,
        setAddStopLat,
        setAddStopLng,
        setShowFavAddress,
        setAddStopAttachment,
        setAddStopName,
        setAddStopStartDate,
        setAddStopStartTime,
        setAddStopComment,
        setAddStopEndTime,
        setClearOnSubmit,
        setRequestList,
        setRequestListTemp,
        setStopList,
        setStopListTemp,
        setPackageList,
        setPackageListTemp,
        setSearchRequestKey,
        setSearchPackageKey,
        setSearchStoptKey,
        setShowPauseShiftModal,
        setShowReqDetails,

        // api functions
        getTrackOnlineList,
        takeShiftAction,

        // helper functions
        filterTrack,
        getRoutePlannerList,
        takeStopAction,
        getRouteCalculatedList,
        addCustomStop,
        deleteCustomStop,
        searchRequest,
        searchStop,
        searchPackage,
        searchStopListOnly,
        generatePickupPoints,
      }}
    >
      {props.children}
    </TrackOnlineContext.Provider>
  );
};

export default TrackOnlineContextProvider;
