import { Tooltip } from "@mui/material";
import React from "react";
import BannerItemButton from "../../Components/Button/BannerItemButton";
import CommonImage from "../../Components/Image/CommonImage";
import { NoImage } from "../../Utility/Sources";
import { calculateDate } from "../../Utility/UtilityFunctions";

export default function BannerItem({ data }) {
  
  return (
    <>
      <div className="bg-white rounded-md w-full h-full shadow-sm">        
        <CommonImage
          className="rounded-t-md w-full h-s150 object-cover"
          src={data.image}
          dummyImage={NoImage}
          alt=""
        />
       
        
        <div className="flex justify-between mx-2 items-center mb-2">
          <Tooltip title={data.title}>
         <p className="text-lg m-2 truncate w-[200px]">{data.title}</p>
         </Tooltip>
          {/* <span className="text-sm text-gray-400">{ calculateDate(data.end_date, data.start_date) } Days Left</span> */}
          <BannerItemButton id={data.id} item={data} />
        </div>
      </div>
    </>
  );
}
