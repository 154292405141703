import axios from "axios";
import { t } from 'i18next';
import { createContext, useState } from "react";
import useFavAddressStore from "../App/stores/FavAddressStore";

import useGeneralStore from '../App/stores/GeneralStore';
import {
  kuAddFavoriteAddress,
  kuDeleteFavoriteAddress,
  kuGetFavoriteAddress,
  kuUpdateFavoriteAddress
} from "../Utility/url";
import { Toastr } from "../Utility/UtilityFunctions";

export const FavoriteAddressContext = createContext();

const FavoriteAddressContextProvider = (props) => {
  const [address, setAddress] = useState({
    name: "",
    addressValue: "",
    lat: "",
    lng: "",
  });

  // hg add fav address
  const [addAddressName, setAddAddressName] = useState("");
  const [addAddressLabel, setAddAddressLabel] = useState("");
  const [addAddressLat, setAddAddressLat] = useState("");
  const [addAddressLng, setAddAddressLng] = useState("");

  // hb update fav address
  const [updateAddressName, setUpdateAddressName] = useState("");
  const [updateAddressLabel, setUpdateAddressLabel] = useState("");
  const [updateAddressLat, setUpdateAddressLat] = useState("");
  const [updateAddressLng, setUpdateAddressLng] = useState("");

  const [detailsState, setDetailsState] = useState("add");
  const [favoriteAddress, setFavoriteAddress] = useState([]);
  const [tempFavoriteAddress, setTempFavoriteAddress] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedFavAddress, setSelectedFavAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [clearForm, setClearForm] = useState(false);

  const { setLoading } = useGeneralStore()

  /*
  |--------------------------------------------------------------------------
  | API Related Functions
  |--------------------------------------------------------------------------
  */
  const getFavoriteAddress = async (company_id) => {
    try {
      setLoading(true)
      const res = await axios.get(kuGetFavoriteAddress, { params: { user_id: company_id } });
      console.log('getFavoriteAddress: ', res.data);

      if (res.data.success) {
        setFavoriteAddress(res.data.data);
        setTempFavoriteAddress(res.data.data);
      } else {
        Toastr(res.data.message);
      }

      setLoading(false)
    } catch (err) {
      setLoading(false);
      Toastr(t("An error occurred!"));
      console.log('getFavoriteAddress: ', err);
    }
  };

  const addFavoriteAddress = async () => {
    try {
      if (addAddressLat === null || addAddressLng === null) {
        Toastr(t("Invalid Address!"));
        return;
      }

      setLoading(true)
      const body = {
        title: addAddressName,
        address: addAddressLabel,
        lat: addAddressLat,
        lng: addAddressLng,
      };
      // console.log("addFavoriteAddress:", body);
      const res = await axios.post(kuAddFavoriteAddress, body);
      console.log('addFavoriteAddress: ', res.data);

      if (res.data.success) {
        setAddress({ name: "", addressValue: "" });
        setClearForm(true);
        await getFavoriteAddress();
      } else {
        Toastr(res.data.message);
      }
      setLoading(false)
    } catch (err) {
      setLoading(false);
      Toastr(t("An error occurred!"));
      console.log('addFavoriteAddress: ', err);
    }
  };

  const updateFavoriteAddress = async () => {
    try {
      if (updateAddressLat === null || updateAddressLng === null) {
        Toastr(t("Invalid Address!"));
        return;
      }

      setLoading(true);
      const body = {
        fav_id: selectedFavAddress.id,
        title: updateAddressName,
        address: updateAddressLabel,
        lat: updateAddressLat,
        lng: updateAddressLng,
      };

      // console.log("updateFavoriteAddress ", body);
      const res = await axios.post(kuUpdateFavoriteAddress, body);
      console.log('updateFavoriteAddress: ', res.data);

      if (res.data.success) {
        await getFavoriteAddress();
      } else {
        Toastr(res.data.message);
      }

      setLoading(false);
    } catch (err) {
      console.log('updateFavoriteAddress', err);
      setLoading(false);
      Toastr(t("An error occurred!"));
    }
  };

  const deleteFavoriteAddress = async () => {
    try {
      setLoading(true);
      const body = { fav_id: selectedFavAddress.id };
      const res = await axios.post(kuDeleteFavoriteAddress, body);
      console.log('deleteFavoriteAddress', res.data);

      if (res.data.success) {
        updateDetailsState("add");
        await getFavoriteAddress();
      } else {
        Toastr(res.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log('deleteFavoriteAddress', err);
      Toastr(t("An error occurred!"));
      setLoading(false);
    }
  };

  /*
  |--------------------------------------------------------------------------
  | Helper Functions 
  |--------------------------------------------------------------------------
  */

  const setChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAddress({ ...address, [name]: value });
  };



  const updateDetailsState = (state) => {
    setDetailsState(state);
    // console.log(state);
  };

  const selectFavoriteAddress = (index) => {
    setSelectedIndex(index);
    setSelectedFavAddress(favoriteAddress[index]);
    updateDetailsState("update");

    setUpdateAddressName(favoriteAddress[index]?.title);
    setUpdateAddressLabel(favoriteAddress[index]?.address);
    setUpdateAddressLat(favoriteAddress[index]?.lat);
    setUpdateAddressLng(favoriteAddress[index]?.lng);
  };

  const cancelAddFavoriteAddress = () => {
    setAddress({ name: "", addressValue: "" });
    selectFavoriteAddress(0);
    updateDetailsState("update");
  };

  // const searchFavoriteAddress = (event) => {
  //   setSearchKey(event.target.value);
  //   console.log(event.target.value);
  //   setDetailsState("add");

  //   // eslint-disable-next-line array-callback-return
  //   const result = tempFavoriteAddress.filter((item) => {
  //     if (
  //       item.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
  //       item.address.toLowerCase().includes(event.target.value.toLowerCase())
  //     ) {
  //       console.log(item);
  //       return item;
  //     }
  //   });

  //   setFavoriteAddress(result);
  // };

  const searchFavoriteAddress = (search) => {

    const { setSearchKey, allFavAddressListTemp, setAllFavAddressList } = useFavAddressStore.getState();

    setSearchKey(search);
    console.log(search);
    // setDetailsState("add");

    // eslint-disable-next-line array-callback-return
    const result = allFavAddressListTemp.filter((item) => {
      if (
        item.title.toLowerCase().includes(search.toLowerCase()) ||
        item.address.toLowerCase().includes(search.toLowerCase())
      ) {
        console.log(item);
        return item;
      }
    });

    setAllFavAddressList(result);
  };


  // sharing states
  return (
    <FavoriteAddressContext.Provider
      value={{
        // states
        address,
        detailsState,
        favoriteAddress,
        selectedIndex,
        selectedFavAddress,
        isLoading,
        canSubmit,
        searchKey,
        showModal,
        clearForm,
        addAddressName,
        addAddressLabel,
        addAddressLat,
        addAddressLng,
        updateAddressName,
        updateAddressLabel,
        updateAddressLat,
        updateAddressLng,

        // functions
        setAddress,
        setChange,
        setDetailsState,
        updateDetailsState,
        getFavoriteAddress,
        setSelectedIndex,
        selectFavoriteAddress,
        addFavoriteAddress,
        setIsLoading,
        cancelAddFavoriteAddress,
        setCanSubmit,
        updateFavoriteAddress,
        deleteFavoriteAddress,
        searchFavoriteAddress,
        setShowModal,
        setClearForm,
        setAddAddressName,
        setAddAddressLabel,
        setAddAddressLat,
        setAddAddressLng,
        setUpdateAddressName,
        setUpdateAddressLabel,
        setUpdateAddressLat,
        setUpdateAddressLng,
        setSearchKey,
      }}
    >
      {props.children}
    </FavoriteAddressContext.Provider>
  );
};

export default FavoriteAddressContextProvider;
